import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { colors, Switch, Text } from '../../UIkit'
import './styles.scss'

const Notifications = ({ isSubscribing, handleUpdateSwitch }) => {
  const { t } = useTranslation()

  const settings = [
    {
      header: t('settings:notifications:orderFinished'),
      description: t('settings:notifications:orderFinishedText'),
      key: 'order.finished',
    },
    {
      header: t('settings:notifications:orderCanceled'),
      description: t('settings:notifications:orderCanceledText'),
      key: 'order.canceled',
    },
    {
      header: t('settings:notifications:customerFeedback'),
      description: t('settings:notifications:customerFeedbackText'),
      key: 'feedback.create',
    },
    {
      header: t('settings:notifications:withdrawalSuccess'),
      description: t('settings:notifications:withdrawalSuccessText'),
      key: 'withdrawal.success',
    },
    {
      header: t('settings:notifications:withdrawalFailed'),
      description: t('settings:notifications:withdrawalFailedText'),
      key: 'withdrawal.failed',
    },
  ]

  return (
    <div className='settings-notifications'>
      <Text variant={'body-main1'} color={colors.gray[500]}>
        {t('settings:notifications:description')}
      </Text>
      <div className='settings-notifications__container'>
        {settings.map((setting) => (
          <div className='settings-notifications__item' key={setting.key}>
            <div className='settings-notifications__item-info'>
              <Text variant={'body-main2'}>{setting.header}</Text>
              <Text variant={'body-secondary1'}>{setting.description}</Text>
            </div>
            <Switch index={setting.key} checked={isSubscribing(setting)} onChange={(e) => handleUpdateSwitch(e)} />
          </div>
        ))}
      </div>
    </div>
  )
}

Notifications.propTypes = {
  isSubscribing: PropTypes.func.isRequired,
  handleUpdateSwitch: PropTypes.func.isRequired,
}

export default Notifications
