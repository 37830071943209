import axios from 'axios'
import config from 'config'
import { toSnakeCase } from './converter'

axios.defaults.withCredentials = true


export default {
  get: (path, params) => axios.get(`${config.apiUrl}${path}`, { params: toSnakeCase(params) }),
  post: (path, params) => axios.post(`${config.apiUrl}${path}`, toSnakeCase(params)),
  put: (path, params) => axios.put(`${config.apiUrl}${path}`, toSnakeCase(params)),
  delete: (path, params) => axios.delete(`${config.apiUrl}${path}`, toSnakeCase(params)),
}
