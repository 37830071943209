import { useTranslation } from 'react-i18next'
import { orderBy } from 'lodash'

import { WITHDRAWAL_METHOD_TYPE } from '../constants'

export const combineWithdrawalMethods = (bankAccounts) => {
  const { t } = useTranslation()

  const bankAccountMethods = bankAccounts.map((object) => ({
    id: object.id,
    type: WITHDRAWAL_METHOD_TYPE.bank,
    name: t('settings:withdrawalMethod:bankAccount'),
    ownerName: object.recipient,
    number: object.iban,
    mfo: object.mfo,
    code: object.code,
    default: object.default,
    createdAt: object.createdAt
  }))

  const widthdrawalMethods = [...bankAccountMethods]

  return orderBy(widthdrawalMethods, 'createdAt', 'desc')
}

export default combineWithdrawalMethods
