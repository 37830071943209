import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, colors } from '../../UIkit'

const EmptyNotification = ({ subject }) => {
  const { t } = useTranslation()

  return (
    <div className='empty-notification'>
      <div className='empty-notification__message'>
        <Text variant='body-main2'>{t('dashboard:empty:title')}</Text>
        <Text color={colors.gray[500]} variant='body-secondary1'>
          {t(`dashboard:empty:description:${subject}`)}
        </Text>
      </div>
    </div>
  )
}

export default EmptyNotification
