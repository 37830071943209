import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { staffNetworksFetchRequest } from '../../../store/staff/networks/actions'
import { Pagination, Text } from '../../UIkit'
import NetworksTable from './List/NetworksTable'

const NetworksView = () => {
  const dispatch = useDispatch()
  const { data: networks, pages: totalPages, fetching: fetching } = useSelector(state => state.staffNetworks)

  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(20)

  const getNetworks = useCallback((params) => {
    dispatch(staffNetworksFetchRequest({ params: params }))
  }, [dispatch, staffNetworksFetchRequest])

  const onChangePage = useCallback((page = 1) => {
    if (page > totalPages) return

    setPage(page)
    getNetworks({ page: page, per: rowsPerPage })
  }, [totalPages, rowsPerPage])

  useEffect(() => {
    onChangePage(1)
  }, [rowsPerPage])

  const emptyCondition = !networks.length

  if (fetching) return null  

  return (
    <>
      <Text className='mb-3' variant='h2'>Networks</Text>

      {emptyCondition ? (
        <Text variant='subheading'>Networks list is empty</Text>
      ) : (
        <NetworksTable networks={networks} />
      )}

      <div className='w-100'>
        <Pagination
          activePage={page}
          perPage={rowsPerPage}
          handleChangePage={onChangePage}
          handleChangePerPage={setRowsPerPage}
          totalPages={totalPages}
          isShow={networks.length}
        />
      </div>
    </>
  )
}

export default NetworksView
