import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { Calendar, Text, RadioButton, colors } from '../../../components/UIkit'
import { Arrow } from '../../../components/UIkit/svgs'
import useOnClickOutside from '../../../hooks/useOnClickOutside'

import './styles.scss'

const Filters = ({ activeFilter, setActiveFilter, network }) => {
  const { t } = useTranslation()

  moment.updateLocale('en-gn', {
    week: {
      dow: 1,
      doy: 7,
    },
  })

  const FILTER_TYPE = {
    year: t('dashboard:calendarYear'),
    month: t('dashboard:calendarMonth'),
    range: t('dashboard:customRange'),
  }

  const [isOpen, setIsOpen] = useState(false)
  const [activeType, setActiveType] = useState(FILTER_TYPE.month)
  const [years, setYears] = useState([])
  const [yearOfMonths, setYearOfMonths] = useState(null)

  const firstVendorCreatedAt = network.firstVendorCreatedAt?.split(' ')[0] || moment()

  const diff = moment().diff(moment(firstVendorCreatedAt).format('YYYY'), 'years') || 0

  useEffect(
    () =>
      setYears(
        Array(diff + 1)
          .fill(null)
          .map((_, i) => moment().year() - i)
      ),
    [diff]
  )
  useEffect(() => {
    setYearOfMonths(years[0])
  }, [years])

  const handleOpen = () => {
    setIsOpen((prev) => !prev)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleChangeType = (value) => () => {
    if (value !== activeType) {
      setActiveType(value)
    }
  }

  const isChecked = (type, month, year) => {
    if (activeFilter.type == FILTER_TYPE[type]) {
      return moment(`01-${month}-${year}`, 'DD-MMMM-YYYY').isSame(activeFilter.dateFrom)
    }
  }

  const isDisabled = (month, year) => {
    const start = moment(firstVendorCreatedAt).startOf('month')

    return (
      !moment(`01-${month}-${year}`, 'DD-MMMM-YYYY').isSameOrAfter(start) ||
      moment().isBefore(moment(`${month}-${yearOfMonths}`, 'MMMM-YYYY'))
    )
  }

  const handleChangeYear = ({ target }) => {
    setActiveFilter({
      type: activeType,
      dateFrom: moment(`01-01-${target.value}`, 'DD-MM-YYYY').startOf('year'),
      dateTo: moment(`01-01-${target.value}`, 'DD-MM-YYYY').endOf('year'),
    })

    handleClose()
  }

  const handleChangeMonth = ({ target }) => {
    setActiveFilter({
      type: activeType,
      dateFrom: moment(`01-${target.value}-${yearOfMonths}`, 'DD-MMMM-YYYY').startOf('month'),
      dateTo: moment(`01-${target.value}-${yearOfMonths}`, 'DD-MMMM-YYYY').endOf('month'),
    })

    handleClose()
  }

  const handleChangeRange = (value) => {
    setActiveFilter({
      type: activeType,
      dateFrom: moment(value[0]),
      dateTo: moment(value[1]),
    })

    handleClose()
  }

  const handleRenderRange = () => {
    switch (activeFilter.type) {
      case FILTER_TYPE.year:
        return activeFilter.dateFrom?.format('YYYY')
      case FILTER_TYPE.month:
        return t(`month:${activeFilter.dateFrom.format('MMMM')}`) + ` ${activeFilter.dateFrom.format('YYYY')}`
      case FILTER_TYPE.range:
        return `${activeFilter.dateFrom.format('DD MMM YYYY')} - ${activeFilter.dateTo.format('DD MMM YYYY')}`
    }
  }

  const handleChangeSeletedYear = (action) => () => {
    if (action == 'add' && yearOfMonths !== years[0]) {
      setYearOfMonths((prev) => prev + 1)
    }

    if (action == 'minus' && yearOfMonths !== years[years.length - 1]) {
      setYearOfMonths((prev) => prev - 1)
    }
  }

  const filtersRef = useRef()
  useOnClickOutside(filtersRef, handleClose)

  return (
    <div className='chart-filters' ref={filtersRef}>
      <div className={`chart-filters__input ${isOpen && 'chart-filters__input__with_shadow'}`} onClick={handleOpen}>
        <Text variant='body-main1'>{activeFilter.type}</Text>
        <Text variant='body-main1' color={colors.gray[300]}>
          {handleRenderRange()}
          <Arrow className={`chart-filters__input__arrow${isOpen ? '-up' : '-down'}`} color={colors.gray[400]} />
        </Text>
      </div>

      {isOpen && (
        <div className={'chart-filters__body'}>
          <div
            onClick={handleChangeType(FILTER_TYPE.year)}
            className={`chart-filters__body__type${activeType == FILTER_TYPE.year ? '-active' : ''}`}
          >
            <Text color={activeType == FILTER_TYPE.year ? colors.primary[500] : colors.gray[800]} variant='body-main2'>
              {t('dashboard:calendarYear')}
            </Text>
          </div>

          {activeType == FILTER_TYPE.year &&
            years.map((year, index) => (
              <div key={index} className='chart-filters__body__type__item'>
                <RadioButton
                  name={year}
                  value={year}
                  label={year}
                  isChecked={isChecked('year', 'January', year)}
                  handleChange={handleChangeYear}
                />
              </div>
            ))}

          <div
            onClick={handleChangeType(FILTER_TYPE.month)}
            className={`chart-filters__body__type${activeType == FILTER_TYPE.month ? '-active' : ''}`}
          >
            <Text color={activeType == FILTER_TYPE.month ? colors.primary[500] : colors.gray[800]} variant='body-main2'>
              {t('dashboard:calendarMonth')}
            </Text>

            {activeType == FILTER_TYPE.month && (
              <div className='chart-filters__body__type__actions'>
                <Arrow onClick={handleChangeSeletedYear('minus')} color={colors.primary[500]} />

                <Text color={colors.primary[500]} variant='body-main2'>
                  {yearOfMonths}
                </Text>

                <Arrow onClick={handleChangeSeletedYear('add')} color={colors.primary[500]} />
              </div>
            )}
          </div>

          {activeType == FILTER_TYPE.month && (
            <div className='chart-filters__body__months'>
              <div className='chart-filters__body__months__block'>
                {moment
                  .months()
                  .slice(0, 6)
                  .map((month, index) => (
                    <div key={index} className='chart-filters__body__type__item'>
                      <RadioButton
                        name={month}
                        value={month}
                        label={t(`month:${month}`)}
                        isChecked={isChecked('month', month, yearOfMonths)}
                        disabled={isDisabled(month, yearOfMonths)}
                        handleChange={handleChangeMonth}
                      />
                    </div>
                  ))}
              </div>

              <div className='chart-filters__body__months__block'>
                {moment
                  .months()
                  .slice(6, 12)
                  .map((month, index) => (
                    <div key={index} className='chart-filters__body__type__item'>
                      <RadioButton
                        name={month}
                        value={month}
                        label={t(`month:${month}`)}
                        isChecked={isChecked('month', month, yearOfMonths)}
                        disabled={isDisabled(month, yearOfMonths)}
                        handleChange={handleChangeMonth}
                      />
                    </div>
                  ))}
              </div>
            </div>
          )}

          <div
            onClick={handleChangeType(FILTER_TYPE.range)}
            className={`chart-filters__body__type${activeType == FILTER_TYPE.range ? '-active' : ''}`}
          >
            <Text color={activeType == FILTER_TYPE.range ? colors.primary[500] : colors.gray[800]} variant='body-main2'>
              {t('dashboard:customRange')}
            </Text>
          </div>

          {activeType == FILTER_TYPE.range && (
            <Calendar
              minDate={new Date(firstVendorCreatedAt)}
              date={activeFilter.type == FILTER_TYPE.range && [activeFilter.from, activeFilter.to]}
              onChange={handleChangeRange}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default Filters
