import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { RouteNames } from '../../../../router/index'
import { Text } from '../../../UIkit'
import { MenuDrinks, MenuDesserts, MenuFood, MenuGoods, MenuSupplements } from '../../../UIkit/svgs'
import './styles.scss'

const MenuTypeComponent = ({ type }) => {
  const { slug, name } = type
  const history = useNavigate()
  const { t } = useTranslation()

  const handleIcon = () => {
    switch (slug) {
      case 'drinks':
        return <MenuDrinks />
      case 'desserts':
        return <MenuDesserts />
      case 'food':
        return <MenuFood />
      case 'goods':
        return <MenuGoods />
      case 'supplements':
        return <MenuSupplements />
      default:
        return null
    }
  }

  const handleClick = () => {
    history(`${RouteNames.MENU_TYPES}/${slug}`)
  }

  return (
    <div onClick={handleClick} className='menuType'>
      <div className='menuType__icon'>{handleIcon()}</div>
      <div className='menuType__title'>
        <Text variant='subheading'>{t(`dashboard:${name.toLowerCase()}`)}</Text>
      </div>
    </div>
  )
}

MenuTypeComponent.propTypes = {
  type: PropTypes.object,
}

export default MenuTypeComponent
