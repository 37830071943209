import React from 'react'

import { Table, TableBody, TableHead, TableHeader, Text } from '../../../../../../UIkit'
import ProductRow from './ProductRow'

const tableHeaders = ['Image', 'Name', 'Type', 'Category', 'Price', 'Availability', 'Discount']

const ProductsTable = ({ products }) => {
  return (
    <div className='overflow-auto w-100'>
      <Table>
        <TableHead>
          {tableHeaders.map(title => (
            <TableHeader key={title}>
              <Text variant='subheading'>{title}</Text>
            </TableHeader>
          ))}
        </TableHead>
        <TableBody>
          {products.map(product => <ProductRow key={product.id} product={product} />)}
        </TableBody>
      </Table>
    </div>
  )
}

export default ProductsTable
