import {
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAILURE,
  USERS_GET_REQUEST,
  USERS_GET_SUCCESS,
  USERS_GET_FAILURE,
  USER_SMS_CODE_REQUEST,
  USER_SMS_CODE_SUCCESS,
  USER_SMS_CODE_FAILURE,
  USER_SMS_CODE_CLEAR,
  USER_PHONE_REGISTRATION_REQUEST,
} from './actions'

const initialState = {
  user: {},
  fetching: false,
  error: null
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case USER_CREATE_REQUEST:
      return { ...state, fetching: true }
    case USER_CREATE_SUCCESS:
      return { ...state, fetching: false }
    case USER_CREATE_FAILURE:
      return { ...state, fetching: false }

    case USERS_GET_REQUEST:
      return { ...state, fetching: true }
    case USERS_GET_SUCCESS:
      return { ...state, items: payload, item: {}, fetching: false }
    case USERS_GET_FAILURE:
      return { ...state, fetching: false }

    case USER_SMS_CODE_REQUEST:
      return { ...state, fetching: true }
    case USER_SMS_CODE_SUCCESS:
      return { ...state, fetching: false, smsSent: true }
    case USER_SMS_CODE_FAILURE:
      return { ...state, fetching: false }
    case USER_SMS_CODE_CLEAR:
      return { ...state, smsSent: false }

    case USER_PHONE_REGISTRATION_REQUEST:
      return { ...state, fetching: true }

    default:
      return state
  }
}
