import {
  MANAGERS_GET_REQUEST,
  MANAGERS_GET_SUCCESS,
  MANAGERS_GET_FAILURE,
  MANAGERS_CLEAR,
  MANAGER_GET_REQUEST,
  MANAGER_GET_SUCCESS,
  MANAGER_GET_FAILURE,
  MANAGER_UPDATE_REQUEST,
  MANAGER_UPDATE_SUCCESS,
  MANAGER_UPDATE_FAILURE,
  MANAGER_CREATE_REQUEST,
  MANAGER_CREATE_SUCCESS,
  MANAGER_CREATE_FAILURE,
  MANAGER_DELETE_REQUEST,
  MANAGER_DELETE_SUCCESS,
  MANAGER_DELETE_FAILURE,
} from './actions'

const initialState = {
  items: [],
  item: {},
  fetching: false,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case MANAGERS_GET_REQUEST:
      return { ...state, fetching: true }
    case MANAGERS_GET_SUCCESS:
      return { ...state, items: payload, fetching: false }
    case MANAGERS_GET_FAILURE:
      return { ...state, fetching: false }
    case MANAGER_GET_REQUEST:
      return { ...state }
    case MANAGER_GET_SUCCESS:
      return { ...state, item: payload, fetching: false }
    case MANAGER_GET_FAILURE:
      return { ...state, fetching: false }
    case MANAGERS_CLEAR:
      return initialState
    case MANAGER_UPDATE_REQUEST:
      return { ...state, fetching: true }
    case MANAGER_UPDATE_SUCCESS:
      return { ...state, item: payload, fetching: false }
    case MANAGER_UPDATE_FAILURE:
      return { ...state, fetching: false }
    case MANAGER_CREATE_REQUEST:
      return { ...state, fetching: true }
    case MANAGER_CREATE_SUCCESS:
      return { ...state, item: payload, fetching: false }
    case MANAGER_CREATE_FAILURE:
      return { ...state, fetching: false }
    case MANAGER_DELETE_REQUEST:
      return { ...state, fetching: true }
    case MANAGER_DELETE_SUCCESS:
      return { ...state, fetching: false }
    case MANAGER_DELETE_FAILURE:
      return { ...state, fetching: false }
    default:
      return state
  }
}
