import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { Dashboard } from '../../components/Dashboard'
import { useFilters } from '../../contexts/filtersContext'
import {
  cashTurnoverGetRequest,
  selesByCategoryGetRequest,
  totalOrdersGetRequest,
  baristaEfficiencyGetRequest,
  vendorComparisonGetRequest,
} from '../../store/statistics/actions'
import { networkGetRequest } from '../../store/networks/actions'

const DashboardContainer = () => {
  const { cityFilter, vendorFilter } = useFilters()
  const { t } = useTranslation()

  const { network } = useSelector((state) => state.networks)
  const {
    cashTurnover,
    cashTurnoverFecthing,
    totalOrders,
    totalOrdersFetching,
    salesByCategory,
    salesByCategoryFetching,
    baristaEfficiency,
    baristaEfficiencyFetching,
    vendorComparison,
    vendorComparisonFetching,
  } = useSelector((state) => state.statistics)

  const FILTER_TYPE = {
    year: t('dashboard:calendarYear'),
    month: t('dashboard:calendarMonth'),
    range: t('dashboard:customRange'),
  }

  const [activeFilter, setActiveFilter] = useState({
    type: FILTER_TYPE.month,
    dateFrom: moment().startOf('month'),
    dateTo: moment().endOf('month'),
    city: '',
    vendorId: '',
  })

  useEffect(() => {
    setActiveFilter((prev) => ({
      ...prev,
      city: cityFilter,
      vendorId: vendorFilter,
    }))
  }, [cityFilter, vendorFilter])

  const networkId = localStorage.getItem('networkId')
  const dispatch = useDispatch()

  useEffect(() => {
    const params = {
      networkId,
      params: activeFilter,
    }

    dispatch(cashTurnoverGetRequest(params))
    dispatch(totalOrdersGetRequest(params))
    dispatch(selesByCategoryGetRequest(params))
    dispatch(vendorComparisonGetRequest(params))
    dispatch(baristaEfficiencyGetRequest(params))
    dispatch(networkGetRequest(networkId))
  }, [activeFilter])

  return (
    <Dashboard
      network={network}
      activeFilter={activeFilter}
      setActiveFilter={setActiveFilter}
      cashTurnover={cashTurnover}
      cashTurnoverFecthing={cashTurnoverFecthing}
      totalOrders={totalOrders}
      totalOrdersFetching={totalOrdersFetching}
      salesByCategory={salesByCategory}
      salesByCategoryFetching={salesByCategoryFetching}
      baristaEfficiency={baristaEfficiency}
      baristaEfficiencyFetching={baristaEfficiencyFetching}
      vendorComparison={vendorComparison}
      vendorComparisonFetching={vendorComparisonFetching}
    />
  )
}

export default DashboardContainer
