import { takeLatest, call, put } from 'redux-saga/effects'
import * as actions from './actions'

import Api from '../../lib/api'
import notifications from '../../lib/notifications'
import { sessionDeleteSuccess } from '../authentication/actions'

export default function* watcherSaga() {
  yield takeLatest(actions.ADMIN_UPDATE_REQUEST, watchResourceUpdateRequest),
  yield takeLatest(actions.ADMIN_DELETE_REQUEST, watchResourceDeleteRequest)
}

function* watchResourceUpdateRequest({ payload }) {
  try {
    const response = yield call(Api.Admin.update, payload)
    yield put(actions.adminUpdateSuccess(response))
    notifications.createNotification('edit-success', response.data.message)
  } catch (error) {
    yield put(actions.adminUpdateFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}

function* watchResourceDeleteRequest({ payload: { history } }) {
  try {
    yield call(Api.Admin.delete)

    yield put(actions.adminDeleteSuccess())
    notifications.createNotification('delete-success')

    yield call(Api.Session.delete)
    yield put(sessionDeleteSuccess())

    localStorage.removeItem('session')
    localStorage.removeItem('networkId')
    history('/admin')
  } catch (error) {
    yield put(actions.adminDeleteFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}
