import React from 'react'
import { useTranslation } from 'react-i18next'

import { File } from '../UIkit/svgs'
import { Button, Text, colors } from '../UIkit'

const Options = ({ handleClick }) => {
  const { t } = useTranslation()

  return (
    <>
      <File />

      <Text variant='body-tertiary1' color={colors.gray[500]}>
        jpeg, png &bull; {t('uploader:upTo5MB')}
      </Text>

      <Text variant='body-main1' color={colors.gray[500]}>
        {t('uploader:dragAndDropToUploaderOr')}
      </Text>

      <Button onClick={handleClick('new')} variant='tertiary' size='small'>
        {t('btn:browse')}
      </Button>
    </>
  )
}

export default Options
