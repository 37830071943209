// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/landing/IntroBusiness/cat-left.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../assets/images/landing/IntroBusiness/cat-right.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&family=Unbounded:wght@300;500&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "#intro-business{flex:1;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-repeat:no-repeat;background-size:30%,21%;background-position:bottom 120px left,top 150px right;align-items:center;padding-top:150px;min-height:calc(100vh - 144px)}#intro-business .content{flex-direction:column;align-items:center;width:1205px}#intro-business .content .title{font-family:\"Unbounded\",cursive;font-weight:300;font-size:64px;line-height:90px;color:#3f3947;margin-bottom:.25em;text-align:center;text-transform:uppercase}#intro-business .content .title b{display:contents}#intro-business .content .description{color:#71667f;font-size:20px;line-height:26px;margin-bottom:2.5em;width:778px;text-align:center}#intro-business .content .join-us-container{flex-direction:column}#intro-business .content .join-us-container .two-buttons-business{display:flex}#intro-business .content .join-us-container .two-buttons-business .btn-landing{display:block;border-radius:6px;min-width:194px;padding:14px 20px;font-weight:500;font-size:18px;line-height:22px;text-decoration:none;text-align:center}#intro-business .content .join-us-container .two-buttons-business .btn-landing.btn-sign-up{background-color:#3fc1c9;margin-right:18px;color:#fff}#intro-business .content .join-us-container .two-buttons-business .btn-landing.btn-sign-up:hover{background-color:#ec1259}#intro-business .content .join-us-container .two-buttons-business .btn-landing.btn-login{background-color:#eae9ec;color:#3f3947}#intro-business .content .join-us-container .two-buttons-business .btn-landing.btn-login:hover{background-color:#ec1259}", ""]);
// Exports
module.exports = exports;
