import { takeLatest, call, put } from 'redux-saga/effects'
import * as actions from './actions'

import Api from '../../lib/api'
import notifications from '../../lib/notifications'

export default function* watcherSaga() {
  yield takeLatest(actions.MANAGERS_GET_REQUEST, watchResourcesGetRequest),
  yield takeLatest(actions.MANAGER_GET_REQUEST, watchResourceGetRequest),
  yield takeLatest(actions.MANAGER_UPDATE_REQUEST, watchResourceUpdateRequest),
  yield takeLatest(actions.MANAGER_CREATE_REQUEST, watchResourceCreateRequest),
  yield takeLatest(actions.MANAGER_DELETE_REQUEST, watchResourceDeleteRequest)
}

function* watchResourcesGetRequest({ payload }) {
  try {
    const response = yield call(Api.Managers.get, payload)

    yield put(actions.managersGetSuccess(response))
  } catch (error) {
    yield put(actions.managersGetFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}

function* watchResourceGetRequest({ payload }) {
  try {
    const response = yield call(Api.Manager.get, payload)

    yield put(actions.managerGetSuccess(response))
  } catch (error) {
    yield put(actions.managerGetFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}

function* watchResourceUpdateRequest({ payload }) {
  try {
    const response = yield call(Api.Manager.update, payload)

    yield put(actions.managerUpdateSuccess(response))
    notifications.createNotification('edit-success')
    yield put(actions.managerGetRequest(payload.id))
  } catch (error) {
    yield put(actions.managerUpdateFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}

function* watchResourceCreateRequest({ payload }) {
  try {
    const response = yield call(Api.Manager.create, payload)

    yield put(actions.managerCreateSuccess(response))
    notifications.createNotification('create-success')
    yield put(actions.managersGetRequest())
  } catch (error) {
    yield put(actions.managerCreateFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}

function* watchResourceDeleteRequest({ payload }) {
  try {
    const response = yield call(Api.Manager.delete, payload)

    yield put(actions.managerDeleteSuccess(response))
    notifications.createNotification('edit-success')
    yield put(actions.managersGetRequest())
  } catch (error) {
    yield put(actions.managerDeleteFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}
