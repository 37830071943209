import React from 'react'

import { App1, App2, App3 } from '../../../../assets/images/landing/Applications'
import './styles.scss'
import './responsiveStyles.scss'

function Applications() {
  return (
    <section id='applications' className='business-section'>
      <h1>Наші додатки</h1>
      <div className='section-wrapper'>
        <div className='app'>
          <img src={App1} alt='App1'/>
          <h6>Додаток для барист</h6>
          <ul>
            <li>Отримання замовлень</li>
            <li>Деталі кожного замовлення</li>
            <li>Зміна статусу опрацювання</li>
            <li>Cтатистика</li>
            <li>QR сканер</li>
          </ul>
        </div>
        <div className='app'>
          <img src={App2} alt='App2'/>
          <h6>Вебсайт для бізнесу</h6>
          <ul>
            <li>Менеджмент персоналу</li>
            <li>Статистика та звіти</li>
            <li>Управління мережею</li>
            <li>Додавання меню</li>
            <li>Аналітика закладів</li>
          </ul>
        </div>
        <div className='app'>
          <img src={App3} alt='App3'/>
          <h6>Додаток для відвідувачів</h6>
          <ul>
            <li>Пошук закладів</li>
            <li>Вибір напоїв та їжі</li>
            <li>Передзамовлення</li>
            <li>Програма лояльності</li>
            <li>Справжній take-and-go</li>
          </ul>
        </div>
      </div>
    </section>
  )
}

export default Applications
