import React from 'react'

import { Table, TableBody, TableHead, TableHeader, Text } from '../../../../../../UIkit'
import SupplementRow from './SupplementRow'

const tableHeaders = ['Name', 'Category', 'Price', 'Availability']

const SupplementsTable = ({ supplements }) => {
  return (
    <div className='overflow-auto w-100'>
      <Table>
        <TableHead>
          {tableHeaders.map(title => (
            <TableHeader key={title}>
              <Text variant='subheading'>{title}</Text>
            </TableHeader>
          ))}
        </TableHead>
        <TableBody>
          {supplements.map(supplement => <SupplementRow key={supplement.id} supplement={supplement} />)}
        </TableBody>
      </Table>
    </div>
  )
}

export default SupplementsTable
