import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { sumBy } from 'lodash'

import { EmptyNotification, EmptyDoughnutChart, DoughnutChart, ResultsFor } from '../'
import { Text, Loader } from '../../UIkit'

import './styles.scss'

const SalesByCategory = ({ salesByCategory, loading }) => {
  const { t } = useTranslation()

  const label = [
    t('dashboard:drinks'),
    t('dashboard:desserts'),
    t('dashboard:food'),
    t('dashboard:goods'),
    t('dashboard:supplements'),
  ]
  const data = [
    sumBy(salesByCategory, 'drinksRevenue'),
    sumBy(salesByCategory, 'dessertsRevenue'),
    sumBy(salesByCategory, 'foodRevenue'),
    sumBy(salesByCategory, 'goodsRevenue'),
    sumBy(salesByCategory, 'supplementsRevenue'),
  ]

  const isEmptyData = sumBy(data) == 0
  return (
    <div className='sales'>
      <div className='sales__header'>
        <Text variant='subheading-bold'>{t('dashboard:salesByCategory')}</Text>
      </div>

      <div className='sales__content'>
        <ResultsFor />

        <div className='sales__chart'>
          {!salesByCategory.length && !loading && <EmptyNotification subject='vendor' />}

          {loading && <Loader size={50} inBlock />}

          {!loading && !isEmptyData && <DoughnutChart labels={label} data={data} />}
          {!loading && isEmptyData && <EmptyDoughnutChart />}
        </div>
      </div>
    </div>
  )
}

SalesByCategory.propTypes = {
  salesByCategory: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default SalesByCategory
