import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { StaffRootContext } from '../../contexts/staff/rootContext'

import StaffHeader from './Header/StaffHeader'
import StaffSidebar from './Sidebar/StaffSidebar'
import './styles.scss'

const AppStaff = ({ children }) => {
  const staffSession = useSelector((state) => state.staffAuthentication)

  const rootContextValue = useMemo(
    () => ({
      authenticated: staffSession.authenticated,
      currentSession: staffSession.currentSession,
    }),
    [staffSession]
  )

  return (
    <StaffRootContext.Provider value={rootContextValue}>
      <div>
        <StaffHeader />
        <div className='d-flex h-100 mx-4 justify-content-center'>
          {rootContextValue.authenticated ? (
            <>
              <StaffSidebar />
              <div className='content-container'>
                {children}
              </div>
            </>
          ) : children}
        </div>
      </div>
    </StaffRootContext.Provider>
  )
}

export default AppStaff
