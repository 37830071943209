export const STAFF_VENDORS_GET_REQUEST = 'STAFF_VENDORS_GET_REQUEST'
export const STAFF_VENDORS_GET_SUCCESS = 'STAFF_VENDORS_GET_SUCCESS'
export const STAFF_VENDORS_GET_FAILURE = 'STAFF_VENDORS_GET_FAILURE'

export const staffVendorsGetRequest = (id) => ({
  type: STAFF_VENDORS_GET_REQUEST,
  id
})

export const staffVendorsGetSuccess = (payload) => ({
  type: STAFF_VENDORS_GET_SUCCESS,
  payload,
})

export const staffVendorsGetFailure = (error) => ({
  type: STAFF_VENDORS_GET_FAILURE,
  error,
})
