import { takeLatest, call, put } from 'redux-saga/effects'
import * as actions from './actions'

import Api from '../../lib/api'
import notifications from '../../lib/notifications'
import { toCamelCase } from '../../lib/converter'

export default function* watcherSaga() {
  yield takeLatest(actions.EMPLOYEES_GET_REQUEST, watchResourcesGetRequest),
  yield takeLatest(actions.EMPLOYEES_GET_BARISTAS_REQUEST, watchResourcesGetBaristasRequest),
  yield takeLatest(actions.EMPLOYEE_CREATE_REQUEST, watchResourceCreateRequest),
  yield takeLatest(actions.EMPLOYEE_UPDATE_REQUEST, watchResourceUpdateRequest)
}

function* watchResourcesGetRequest({ payload }) {
  try {
    const response = yield call(Api.Baristas.get, payload.params)

    yield put(actions.employeesGetSuccess(toCamelCase(response?.data)))
  } catch (error) {
    yield put(actions.employeesGetFailure(error))
    notifications.createNotification('error', error.response?.data.errors)
  }
}

function* watchResourceCreateRequest({ payload }) {
  try {
    const response = yield call(Api.Barista.create, payload.data)

    yield put(actions.employeeCreateSuccess(toCamelCase(response.data)))
    yield payload.callback()
    notifications.createNotification('create-success')
  } catch (error) {
    yield put(actions.employeeCreateFailure(error))
    notifications.createNotification('error', error.response?.data.errors)
  }
}

function* watchResourceUpdateRequest({ payload }) {
  try {
    const response = yield call(Api.Barista.update, payload.id, payload.params)

    yield put(actions.employeeUpdateSuccess(toCamelCase(response.data)))
    notifications.createNotification('edit-success')
  } catch (error) {
    yield put(actions.employeeUpdateFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}

function* watchResourcesGetBaristasRequest({ payload }) {
  try {
    const response = yield call(Api.Baristas.get, payload.params)

    yield put(actions.employeesGetBaristasSuccess(toCamelCase(response.data)))
  } catch (error) {
    yield put(actions.employeesGetBaristasFailure(error))
  }
}
