import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { VendorContext } from '../../../vendorContext'
import { Pagination, Text } from '../../../../../../UIkit'
import ProductsTable from './ProductsTable'
import { staffVendorProductsFetchRequest } from '../../../../../../../store/staff/vendors/products/actions'

const VendorProducts = () => {
  const { vendorId } = useContext(VendorContext)
  const dispatch = useDispatch()
  const { data: products, pages: totalPages, fetching } = useSelector(
    state => state.staffVendorProducts
  )

  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const getProducts = useCallback((params) => {
    dispatch(staffVendorProductsFetchRequest({ vendorId: vendorId, pagyParams: params }))
  }, [dispatch, staffVendorProductsFetchRequest, vendorId])

  const onChangePage = useCallback((page = 1) => {
    if (page > totalPages) return

    setPage(page)
    getProducts({ page: page, per: rowsPerPage })
  }, [totalPages, rowsPerPage, vendorId])

  useEffect(() => {
    onChangePage(1)
  }, [rowsPerPage, vendorId])

  const isProductsExists = !!products.length

  if (fetching) return null

  return (
    <div className='d-flex flex-column'>
      <Text variant='h3' className='mb-3'>Products</Text>

      {isProductsExists ? (
        <ProductsTable products={products} />
      ) : (
        <Text variant='subheading'>Products list is empty</Text>
      )}

      <div className='w-100'>
        <Pagination
          activePage={page}
          perPage={rowsPerPage}
          handleChangePage={onChangePage}
          handleChangePerPage={setRowsPerPage}
          totalPages={totalPages}
          isShow={isProductsExists}
        />
      </div>
    </div>
  )
}

export default VendorProducts
