export const ADMIN_UPDATE_REQUEST = 'ADMIN_UPDATE_REQUEST'
export const ADMIN_UPDATE_SUCCESS = 'ADMIN_UPDATE_SUCCESS'
export const ADMIN_UPDATE_FAILURE = 'ADMIN_UPDATE_FAILURE'

export const ADMIN_DELETE_REQUEST = 'ADMIN_DELETE_REQUEST'
export const ADMIN_DELETE_SUCCESS = 'ADMIN_DELETE_SUCCESS'
export const ADMIN_DELETE_FAILURE = 'ADMIN_DELETE_FAILURE'

export const adminUpdateRequest = (payload) => ({
  type: ADMIN_UPDATE_REQUEST,
  payload,
})

export const adminUpdateSuccess = (payload) => ({
  type: ADMIN_UPDATE_SUCCESS,
  payload,
})

export const adminUpdateFailure = (error) => ({
  type: ADMIN_UPDATE_FAILURE,
  error,
})

export const adminDeleteRequest = (payload) => ({
  type: ADMIN_DELETE_REQUEST,
  payload
})

export const adminDeleteSuccess = () => ({
  type: ADMIN_DELETE_SUCCESS
})

export const adminDeleteFailure = (error) => ({
  type: ADMIN_DELETE_FAILURE,
  error
})
