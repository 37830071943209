import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import colors from '../colors'
import { Arrow, DoubleArrow } from '../svgs'
import Text from '../Text'
import './styles.scss'

const Pagination = ({ activePage, handleChangePage, perPage, handleChangePerPage, totalPages, isShow }) => {
  const { t } = useTranslation()

  const toFirstPage = () => {
    if (!!activePage && activePage !== 1) {
      handleChangePage(1)
    }
  }
  const toPrevPage = () => {
    if (!!activePage && activePage > 1) {
      handleChangePage(activePage - 1)
    }
  }
  const toNextPage = () => {
    if (activePage < totalPages) {
      handleChangePage(activePage + 1)
    }
  }
  const toLastPage = () => {
    if (activePage !== totalPages) {
      handleChangePage(totalPages)
    }
  }

  const handleCheckRange = ({ target }) => {
    if (target.value < 5) {
      return (target.value = 5)
    }
    if (target.value > 20) {
      return (target.value = 20)
    } else {
      handleChangePerPage(target.value)
    }
  }

  const handleKeyPress = (e) => {
    if (!/[0-9]/.test(e.key)) e.preventDefault()
    if (e.target.value > 9) e.preventDefault()
    if (e.key === 'Enter') handleCheckRange(e)
  }

  const leftArrowsColor = useMemo(() => (activePage <= 1 ? colors.gray[200] : colors.gray[800]), [activePage])

  const rightArrowsColor = useMemo(() => (activePage === totalPages ? colors.gray[200] : colors.gray[800]), [
    activePage,
    totalPages,
  ])

  return (
    <>
      {isShow ? (
        <div className='pagination'>
          <div className='pagination__page-block'>
            <DoubleArrow onClick={toFirstPage} color={leftArrowsColor} />
            <Arrow style={{ transform: 'rotate(90deg)' }} color={leftArrowsColor} onClick={toPrevPage} />
            <div className='pagination__pages'>
              <input
                type='number'
                min={1}
                max={totalPages}
                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                value={activePage}
                onChange={(e) => handleChangePage(e.target.value)}
              />
              <Text variant='body-main1' color={colors.gray[500]}>
                {t('pagination:of')} {totalPages}
              </Text>
            </div>
            <Arrow style={{ transform: 'rotate(270deg)' }} color={rightArrowsColor} onClick={toNextPage} />
            <DoubleArrow style={{ transform: 'rotate(180deg)' }} color={rightArrowsColor} onClick={toLastPage} />
          </div>
          {perPage && (
            <div className='pagination__per-page-block'>
              <Text variant={'body-main1'} color={colors.gray[500]}>
                {t('pagination:rowsPerPage')}
              </Text>
              <input
                type='number'
                min={5}
                max={20}
                onKeyPress={(e) => handleKeyPress(e)}
                defaultValue={perPage}
                onBlur={(e) => handleCheckRange(e)}
              />
            </div>
          )}
        </div>
      ) : null}
    </>
  )
}

Pagination.defaultProps = {
  activePage: 1,
  totalPages: 1,
}

export default Pagination
