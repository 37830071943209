import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { sessionDeleteRequest } from '../../store/authentication/actions'
import { clearNotifications } from '../../store/notifications/actions'
import { Text, colors } from '../UIkit'
import { Edit, Password, SignOut } from '../UIkit/svgs'
import useOnClickOutside from '../../hooks/useOnClickOutside'

const ProfileMenu = ({ currentUser, showChangePassword, handleClose }) => {
  const dispatch = useDispatch()
  const history = useNavigate()
  const { t } = useTranslation()

  const modalRef = useRef()

  const signOut = () => {
    dispatch(clearNotifications())
    dispatch(sessionDeleteRequest({ history }))
  }

  const handleOnClick = () => {
    history('/settings')
    handleClose()
  }

  useOnClickOutside(modalRef, handleClose)

  return (
    <div className='header__profile-menu' ref={modalRef}>
      <div className='header__user-info'>
        <Text variant='subheading'>{currentUser.profile.full_name}</Text>
        <Text variant='body-secondary1' color={colors.gray[500]}>
          {t('employees:owner')}
        </Text>
      </div>
      <div className='header__menu'>
        <div className='header__menu-item' onClick={handleOnClick}>
          <Edit />
          <Text variant='body-main1'>{t('header:editProfile')}</Text>
        </div>
        <div className='header__menu-item' onClick={() => showChangePassword(true)}>
          <Password />
          <Text variant='body-main1'>{t('header:changePassword:title')}</Text>
        </div>
        <div className='header__menu-item' onClick={() => signOut()}>
          <SignOut />
          <Text variant='body-main1'>{t('header:logOut')}</Text>
        </div>
      </div>
    </div>
  )
}

export default React.memo(ProfileMenu)
