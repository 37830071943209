export const ORDERS_GET_REQUEST = 'ORDERS_GET_REQUEST'
export const ORDERS_GET_SUCCESS = 'ORDERS_GET_SUCCESS'
export const ORDERS_GET_FAILURE = 'ORDERS_GET_FAILURE'
export const ORDER_GET_REQUEST = 'ORDER_GET_REQUEST'
export const ORDER_GET_SUCCESS = 'ORDER_GET_SUCCESS'
export const ORDER_GET_FAILURE = 'ORDER_GET_FAILURE'
export const ORDERS_CLEAR = 'ORDERS_CLEAR'

export const ordersGetRequest = (payload) => ({
  type: ORDERS_GET_REQUEST,
  payload,
})

export const ordersGetSuccess = (payload) => ({
  type: ORDERS_GET_SUCCESS,
  payload,
})

export const ordersGetFailure = (error) => ({
  type: ORDERS_GET_FAILURE,
  error,
})

export const orderGetRequest = (payload) => ({
  type: ORDER_GET_REQUEST,
  payload,
})

export const orderGetSuccess = (payload) => ({
  type: ORDER_GET_SUCCESS,
  payload,
})

export const orderGetFailure = (error) => ({
  type: ORDER_GET_FAILURE,
  error,
})

export const ordersClear = () => ({
  type: ORDERS_CLEAR,
})
