import React from 'react'
import { useTranslation } from 'react-i18next'

import { Text, colors } from '../UIkit'
import { Arrow } from '../UIkit/svgs'

const Control = ({ imageIndex, setImageIndex, galleryImages, setFile }) => {
  const { t } = useTranslation()

  const handleNextImage = () => {
    const nextImageIndex = imageIndex + 1
    const image = galleryImages[nextImageIndex]

    if (nextImageIndex < galleryImages.length) {
      setImageIndex(nextImageIndex)
      setFile(image)
    }
  }

  const handlePreviousImage = () => {
    const previousImageIndex = imageIndex - 1
    const image = galleryImages[previousImageIndex]

    if (previousImageIndex >= 0) {
      setImageIndex(previousImageIndex)
      setFile(image)
    }
  }

  const imageNumber = imageIndex + 1
  const isLastImage = !galleryImages.length || imageNumber == galleryImages.length
  const isFirstImage = imageNumber == 1

  return (
    <div className='control'>
      {!galleryImages.length && <Text variant='body-main2'>{t('uploader:uploadUpTo7Photos')}</Text>}

      {!!galleryImages.length && <Text variant='body-main2'>{t('uploader:photo')} {`${imageNumber}/${galleryImages.length}`}</Text>}

      <div className='control__buttons'>
        <Arrow
          className={`${isFirstImage && 'control__disable-button'}`}
          color={isFirstImage ? colors.gray[200] : colors.gray[500]}
          onClick={handlePreviousImage}
        />
        <Arrow
          className={`${isLastImage && 'control__disable-button'}`}
          color={isLastImage ? colors.gray[200] : colors.gray[500]}
          onClick={handleNextImage}
        />
      </div>
    </div>
  )
}

export default Control
