import React from 'react'

import { Step1, Step2, Step4 } from '../../../../assets/images/landing/HowItWorks'
import './styles.scss'
import './responsiveStyles.scss'

function HowItWorks() {
  return (
    <section id='how-it-works' className='business-section'>
      <div className='underline-landing'></div>
      <h1 className='section-title'>Як це працює?</h1>
      <div className='section-users-wrapper'>
        <div className='step'>
          <div className='section-subtitle'>
            <span className='number'>01</span>
            <img src={Step1} alt='Step 1' />
          </div>
          <div className='description'>
            <h6>Зареєструйся</h6>
            <p>Створи акаунт та додай банківську карту для оплати майбутніх замовлень</p>
          </div>
        </div>
        <div className='step'>
          <div className='section-subtitle'>
            <span className='number'>02</span>
            <img src={Step2} alt='Step 2' />
          </div>
          <div className='description'>
            <h6>Зроби замовлення</h6>
            <p>Обери заклад, сформуй замовлення та вкажи час, коли тобі зручно його забрати</p>
          </div>
        </div>
        <div className='step'>
          <div className='section-subtitle'>
            <span className='number'>03</span>
            <img src={Step4} alt='Step 3' />
          </div>
          <div className='description'>
            <h6>Насолоджуйся</h6>
            <p>Отримай своє замовлення в баристи, відсканувавши QR-код.</p>
            <p>Смачного!</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HowItWorks
