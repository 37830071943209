import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { find } from 'lodash'

import { useFilters } from '../../contexts/filtersContext'
import { Text, colors } from '../UIkit'

const ResultsFor = () => {
  const { t, i18n } = useTranslation()

  const { cityFilter, vendorFilter } = useFilters()

  const [activeFilter, setActiveFilter] = useState({
    city: '',
    vendorId: '',
  })

  const { addresses } = useSelector((state) => state.vendors)

  useEffect(() => {
    const city = !cityFilter.length ? t('dashboard:cityFilter') : cityFilter
    const vendorAddress = find(addresses, ['value', vendorFilter])

    const vendor = vendorAddress ? vendorAddress.label : t('dashboard:vendorFilter')

    setActiveFilter({
      city: city,
      vendorId: vendor,
    })
  }, [cityFilter, vendorFilter, i18n.language])

  return (
    <div className='results-for'>
      <Text variant='body-tertiary2' color={colors.gray[300]}>
        {vendorFilter == '' && `${t('dashboard:resultsFor')}: ${activeFilter.city}, ${activeFilter.vendorId}`}
        {vendorFilter !== '' && `${t('dashboard:resultsFor')}: ${activeFilter.vendorId}`}
      </Text>
    </div>
  )
}

export default ResultsFor
