const MINUTES_IN_DAY = 60 * 24

export const buildTimeOptions = ({ gap = 30, startFrom, startAfter, upTo, before } = {}) => {
  const getIndexByTime = (timeString) => {
    if (!timeString) return null
    const [hr, min] = timeString.split(':').map((v) => Number(v))

    return Math.floor((hr * 60 + min) / gap)
  }

  let start = (startAfter ? getIndexByTime(startAfter) + 1 : getIndexByTime(startFrom)) || 0
  let end = (before ? getIndexByTime(before) - 1 : getIndexByTime(upTo)) || Math.floor(MINUTES_IN_DAY / gap)

  let result = []
  for (let i = start; i <= end; i++) {
    const timeInMinutes = i * gap
    const formattedTime = `${applyZero(Math.floor(timeInMinutes / 60))}:${applyZero(timeInMinutes % 60)}`
    result.push({ value: formattedTime, label: formattedTime })
  }

  return result
}

const applyZero = (str) => {
  return ('0' + str).slice(-2)
}
