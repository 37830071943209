import React from 'react'
import './styles.scss'

const IconButton = ({ children, ...props }) => {
  return (
    <button className='iconButton' {...props}>
      {children}
    </button>
  )
}

export default React.memo(IconButton)
