import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, colors, Modal, Text } from '../../UIkit'

const EmployeeNoVendors = ({ isShown, closeModal, navigateToNetwork }) => {
  const { t } = useTranslation()

  return (
    <Modal show={isShown} handleClose={closeModal} withoutExit>
      <div className='deactivate-employee'>
        <div className='deactivate-employee__title'>
          <Text variant='subheading'>{t('employees:noVendor')}</Text>
        </div>
        <div className='deactivate-employee__description'>
          <Text variant='body-secondary1' color={colors.gray[500]}>
            {t('employees:noVendorText')}
          </Text>
        </div>
        <div className='deactivate-employee__buttons'>
          <Button onClick={navigateToNetwork}>{t('network:addVendor')}</Button>
        </div>
      </div>
    </Modal>
  )
}

export default EmployeeNoVendors
