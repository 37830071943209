import React, { memo } from 'react'

import { Text } from '../index'
import './styles.scss'

const Tabs = ({ tabs, currentTab, onTabChange }) => {
  return (
    <div className='tabs-container'>
      {tabs.map(tab => (
        <button
          key={tab}
          className={`tab-wrapper ${tab === currentTab ? 'tab-wrapper-active' : ''}`}
          onClick={() => onTabChange(tab)}
        >
          <Text
            variant='body-main1'
            className={`tab-text ${tab === currentTab ? 'tab-text-active' : ''}`}
          >
            {tab}
          </Text>
        </button>
      ))}
    </div>
  )
}

export default memo(Tabs)
