import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Api from '../../../../lib/api'
import { Modal, Dropdown, Button, Text } from '../../../UIkit'
import notifications from '../../../../lib/notifications'
import './styles.scss'

const MenuCategoryAddComponent = ({ isShown, closeModal, addCategory }) => {
  const { t } = useTranslation()

  const networkId = localStorage.getItem('networkId')
  const { type } = useParams()
  const { items: categories } = useSelector((state) => state.categories)
  const [list, setList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [selectedId, setSelectedId] = useState(undefined)

  useEffect(() => {
    setIsLoading(true)
    Api.ProductCategories.fetch(networkId, { type_slug: type })
      .then((res) => {
        let formatedArray = []
        const data = res.data.filter((c) => !categories.find((el) => el.id === c.id))
        data?.map((i) => formatedArray.push({ label: t(`menu:${i.slug.toLowerCase()}`), value: i.id }))
        setList(formatedArray)
        setSelectedId(formatedArray[0]?.value)
        setIsLoading(false)
      })
      .catch(() => {
        notifications.createNotification('error')
        closeModal()
      })
  }, [])

  const handleSubmit = () => {
    addCategory(selectedId)
    closeModal()
  }

  return (
    <Modal show={isShown} fetching={isLoading} handleClose={closeModal} title={t('menu:addCategory')}>
      <div className='menuCategories__createModal'>
        {list.length > 0 ? (
          <div className='menuCategories__dropdown-scroll'>
            <Dropdown
              placeholder={t('input:category')}
              list={list}
              onChange={(value) => setSelectedId(value)}
              value={selectedId}
            />
            <div className='menuCategories__createModal_actions'>
              <span className='menuCategories__createModal_button'>
                <Button disabled={!selectedId} onClick={handleSubmit}>
                  {t('btn:add')}
                </Button>
              </span>
            </div>
          </div>
        ) : (
          <>
            <div className='menuCategories__createModal_empty'>
              <Text variant='body-secondary1'>{t('menu:noMoreCategories')}</Text>
            </div>
            <div className='menuCategories__createModal_actions'>
              <span className='menuCategories__createModal_button'>
                <Button variant='secondary' onClick={closeModal}>
                  {t('btn:cancel')}
                </Button>
              </span>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export default MenuCategoryAddComponent
