import React, { useState, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import useOnClickOutside from '../../../hooks/useOnClickOutside'
import Text from '../Text'
import { Arrow } from '../svgs'
import Loader from '../Loader'
import capitalize from '../../../lib/capitalize'
import './styles.scss'
import { colors } from '../index'

const Dropdown = ({ isDisabled, value, placeholder, list, onChange, loading, iconHeader }) => {
  const [isListOpen, setIsListOpen] = useState(false)
  const dropdownRef = useRef()

  const memoizedValue = useMemo(
    () => ({
      value: value,
      label: list.find((item) => item.value === value)?.label,
    }),
    [value, list]
  )

  const handleClose = () => {
    setIsListOpen(false)
  }

  const handleToggle = () => {
    setIsListOpen((prev) => !prev)
  }

  const selectItem = (item) => {
    setIsListOpen(false)
    onChange(item.value)
  }

  useOnClickOutside(dropdownRef, handleClose)

  const memoizedList = useMemo(() => {
    return list.map((item, index) => (
      <option className='dropdown__list-item' key={index} value={item.value} onClick={() => selectItem(item)}>
        {item.label}
      </option>
    ))
  }, [list])

  return (
    <div className={`dropdown ${iconHeader ? 'icon-variant' : 'default-variant'}`} ref={dropdownRef}>
      {!!placeholder && <span className='dropdown__header-placeholder'>{placeholder}</span>}
      <div
        className={`dropdown__header ${iconHeader && 'icon'} ${isDisabled && 'disabled'} ${isListOpen && !isDisabled && 'with-shadow'}`}
        onClick={handleToggle}
      >
        {!iconHeader ? 
          <div className='dropdown__header-title'>
            <Text variant='body-main1' color={isDisabled ? colors.gray[300] : colors.gray[800]}>
              {capitalize(memoizedValue?.label || list[0]?.label)}
            </Text>
          </div> : 
          iconHeader 
        }
        {!isDisabled && !iconHeader && <Arrow className={`dropdown__header-toggle ${isListOpen ? 'reflect' : ''}`} />}
      </div>
      {isListOpen && !isDisabled && (
        <div className={`dropdown__list ${iconHeader ? 'icon-list' : ''}`} role='list'>
          {loading ? (
            <Loader size={36} inBlock={true} />
          ) : (
            <div className='dropdown__list-scrollable'>{memoizedList}</div>
          )}
        </div>
      )}
    </div>
  )
}

Dropdown.propTypes = {
  value: PropTypes.any,
  list: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
}

export default React.memo(Dropdown)
