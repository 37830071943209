import { takeLatest, call, put } from 'redux-saga/effects'
import * as actions from './actions'
import Api from '../../lib/api'
import notifications from '../../lib/notifications'

export default function* watcherSaga() {
  yield takeLatest(actions.QUESTION_CREATE_REQUEST, watchResourcesCreateRequest)
}

function* watchResourcesCreateRequest({ payload }) {
  try {
    const response = yield call(Api.ContactUs.create, payload)
    yield put(actions.questionCreateSuccess(response))
    notifications.createNotification('mail-sent')
  } catch (error) {
    yield put(actions.questionCreateFailure(error))
    notifications.createNotification('error')
  }
}
