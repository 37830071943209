import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'
import Filter from '../../components/Header/Filter'
import Profile from '../../components/Header/Profile'
import { useFilters } from '../../contexts/filtersContext'
import { vendorsGetRequest, vendorsCitiesGetRequest } from '../../store/vendors/actions'
import {
  notificationsGetRequest,
  notificationsGetOpenedRequest,
  updateNotificationsList,
} from '../../store/notifications/actions'
import { subscriptionsGetRequest } from '../../store/subscriptions/actions'
import { consumerConnect } from '../../lib/consumerConnect'
import { toCamelCase } from '../../lib/converter'
import { consumer } from '../../services/cable'
import './styles.scss'
import { BurgerMenu } from '../../components/UIkit/svgs'

const HeaderContainer = ({ toggleSidebar }) => {
  const dispatch = useDispatch()
  const networkId = localStorage.getItem('networkId')

  const { id: userId } = useSelector((state) => state.authentication.currentSession)

  const { cityFilter } = useFilters()

  const handleUpdateNotifications = (notification) => {
    dispatch(updateNotificationsList(toCamelCase(notification)))
  }

  useEffect(() => {
    dispatch(notificationsGetRequest({ networkId, userId, params: { page: 1, filter: 'unopened' } }))
  }, [networkId, userId])

  useEffect(() => {
    dispatch(notificationsGetOpenedRequest({ networkId, userId, params: { page: 1, filter: 'opened' } }))
  }, [networkId, userId])

  useEffect(() => {
    dispatch(subscriptionsGetRequest({ networkId, userId }))
  }, [networkId, userId])

  useEffect(() => {
    consumerConnect('NetworkUserNotificationsChannel', userId, handleUpdateNotifications)

    return () => consumer.disconnect()
  }, [])

  useEffect(() => {
    dispatch(vendorsCitiesGetRequest(networkId))
  }, [networkId])

  useEffect(() => {
    dispatch(vendorsGetRequest({ networkId: networkId, params: { city: cityFilter } }))
  }, [networkId, cityFilter])

  return (
    <div className='header'>
      {!isMobile ?
        <Filter /> : 
        <div onClick={toggleSidebar}><BurgerMenu /></div>}
      <Profile />
    </div>
  )
}

export default React.memo(HeaderContainer)
