import { takeLatest, call, put } from 'redux-saga/effects'
import * as actions from './actions'

import Api from '../../lib/api'
import notifications from '../../lib/notifications'
import i18n from '../../services/i18n'

export default function* watcherSaga() {
  yield takeLatest(actions.SESSION_CREATE_REQUEST, watchResourceCreateRequest),
  yield takeLatest(actions.SESSION_CREATE_REQUEST_PHONE, watchResourceCreateRequestPhone),
  yield takeLatest(actions.SESSION_DELETE_REQUEST, watchResourceDeleteRequest),
  yield takeLatest(actions.SESSION_RESTORE_REQUEST, watchResourceRestoreRequest),
  yield takeLatest(actions.SESSION_SMS_CODE_REQUEST, watchResourceSmsCodeRequest)
}

function* watchResourceCreateRequest({ payload: { data, history } }) {
  try {
    const response = yield call(Api.Session.create, data)
    const responseLanguage = response.data.profile.language === 'ua' ? 'uk' : response.data.profile.language

    yield put(actions.sessionCreateSuccess(response.data))
    localStorage.setItem('session', JSON.stringify(response.data))
    localStorage.setItem('i18nextLng', responseLanguage)
    localStorage.setItem('networkId', response.data.network_id)
    i18n.changeLanguage(responseLanguage)
    history('/dashboard')

    notifications.createNotification('login-success')
  } catch (error) {
    yield put(actions.sessionCreateFailure(error.response.data.errors))
    notifications.createNotification('error', error.response.data.errors)
  }
}

function* watchResourceCreateRequestPhone({ payload: { data, history } }) {
  try {
    const response = yield call(Api.Session.createPhone, data)
    const responseLanguage = response.data.profile.language === 'ua' ? 'uk' : response.data.profile.language

    yield put(actions.sessionCreateSuccess(response.data))
    localStorage.setItem('session', JSON.stringify(response.data))
    localStorage.setItem('i18nextLng', responseLanguage)
    localStorage.setItem('networkId', response.data.network_id)
    i18n.changeLanguage(responseLanguage)
    history('/dashboard')

    notifications.createNotification('login-success')
  } catch (error) {
    yield put(actions.sessionCreateFailure(error.response.data.errors))
    notifications.createNotification('error', error.response.data.errors)
  }
}

function* watchResourceDeleteRequest({ payload: { history } }) {
  try {
    yield call(Api.Session.delete)

    yield put(actions.sessionDeleteSuccess())

    localStorage.removeItem('session')
    localStorage.removeItem('networkId')
    history('/admin')
  } catch (error) {
    yield put(actions.sessionDeleteFailure(error))
    notifications.createNotification('error')
  }
}

function* watchResourceRestoreRequest() {
  try {
    const response = yield call(Api.Session.restore)

    yield put(actions.sessionRestoreSuccess(response.data))
    localStorage.setItem('session', JSON.stringify(response.data))
  } catch (error) {
    if (error.response.status === 401) localStorage.removeItem('session')
    yield put(actions.sessionRestoreFailure(error))
  }
}

function* watchResourceSmsCodeRequest({ payload }) {
  try {
    yield call(Api.SmsCodes.requestCode, payload)

    yield put(actions.sessionSmsCodeSuccess())
  } catch (error) {
    yield put(actions.sessionSmsCodeFailure())
    notifications.createNotification('error', error.response.data.errors)
  }
}
