import { useSelector } from 'react-redux'

export const findCategory = (id) => {
  const { items: categories } = useSelector((state) => state.categories)

  return categories.find((c) => c.id === id)
}

export const findBoardProduct = (id) => {
  const { clusters } = useSelector((state) => state.products)

  let boardProducts = []
  Object.values(clusters).forEach((a) => a.map((o) => boardProducts.push(o)))
  return boardProducts.find((p) => p.id === id)
}

export const findBoardSupplement = (id) => {
  const { clusters } = useSelector((state) => state.supplements)

  let boardSupplements = []
  Object.values(clusters).forEach((a) => a.map((o) => boardSupplements.push(o)))
  return boardSupplements.find((p) => p.id === id)
}

