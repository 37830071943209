import React, { Component } from 'react'
import PropTypes from 'prop-types'
import editProduct from '../../assets/images/edit.png'
import { Button, Modal, ModalBody, FormGroup, Input, Form, Label } from 'reactstrap'
import { DEFAULT_IMAGE } from '../../lib/constants'
import trashIco from '../../assets/images/trash.png'

import '../Layout/Table/index.css'

class ProductElement extends Component {
  constructor(props) {
    super(props)

    this.state = {
      productEditModalOpened: false,
      productName: props.product.name,
      productImage: props.product.image,
      productId: props.product.id,
      productType: props.product.product_type || '',
    }
  }

  image = () => (this.state.productImage.url ? this.state.productImage.url : DEFAULT_IMAGE)

  handleChooseImage = (e) => {
    const file = e.target.files[0]
    const reader = new FileReader()

    reader.readAsDataURL(file)
    reader.onload = (event) => this.setState({ productImage: { url: event.target.result } })
  }

  toggleEditProductModal = () => {
    this.setState({
      productEditModalOpened: !this.state.productEditModalOpened,
      errors: false,
    })
  }

  onInputChange = ({ target }) => {
    this.setState({ [target.name]: target.value })
  }

  updateProduct = () => {
    const { productId, productName, productImage, productType } = this.state

    this.props.updateProduct(productId, productName, productImage, productType)
    this.toggleEditProductModal()
  }

  render() {
    const { index, deleteProduct } = this.props
    const { productName, productId, productType } = this.state

    return (
      <tbody className='no-hover'>
        <tr>
          <td className='responsive-td vertical-align'>
            <img
              src={this.image()}
              style={{
                width: '160px',
                height: '100px',
                borderRadius: '10px',
                objectFit: 'cover',
              }}
            />
          </td>
          <td className='responsive-td vertical-align'>{productName}</td>
          <td className='responsive-td vertical-align'>{productType}</td>
          <td className='responsive-td vertical-align '>
            <div>
              <img
                src={editProduct}
                onClick={this.toggleEditProductModal}
                style={{ cursor: 'pointer', marginRight: '5px' }}
              />
              <img alt='Logo' className='delete-icon' src={trashIco} onClick={deleteProduct(productId, index)} />
            </div>
          </td>
        </tr>
        <Modal
          centered
          id='edit-products-modal'
          isOpen={this.state.productEditModalOpened}
          toggle={this.toggleEditProductModal}
        >
          <ModalBody>
            <h1 className='title'>Edit Product</h1>
            <Form>
              <FormGroup className='flex f-column'>
                <div className='row'></div>
                <Label for='name' style={{ paddingTop: '20px' }}>
                  Input new name:{' '}
                </Label>
                <Input id='name' value={productName} name='name' onChange={this.onInputChange} />
                <FormGroup className='flex f-column'>
                  <Label for='productType'>Type: </Label>
                  <Input
                    type='select'
                    name='productType'
                    id='productType'
                    value={this.state.productType}
                    onChange={this.onInputChange}
                  >
                    <option value='drink'>Drink</option>
                    <option value='extra'>Extra</option>
                    <option value='snack'>Snack</option>
                  </Input>
                </FormGroup>
                <Label style={{ paddingTop: '20px' }}>Current image: </Label>
                <img src={this.image()} className='width-150 b-radius-10' />
                <FormGroup className='flex f-column'>
                  <Label style={{ paddingTop: '20px' }}>Choose a new file: </Label>
                  <Input className='file' type='file' id='file' multiple onChange={this.handleChooseImage} />
                </FormGroup>
              </FormGroup>
              <div className='flex a-items-center j-c-center'>
                <Button size={'lg'} onClick={this.updateProduct} disabled={productName === ''}>
                  Save
                </Button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </tbody>
    )
  }
}

ProductElement.propTypes = {
  product: PropTypes.any,
  index: PropTypes.any,
  deleteProduct: PropTypes.func,
  updateProduct: PropTypes.func,
}

export default ProductElement
