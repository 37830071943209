export const MANAGERS_GET_REQUEST = 'MANAGERS_GET_REQUEST'
export const MANAGERS_GET_SUCCESS = 'MANAGERS_GET_SUCCESS'
export const MANAGERS_GET_FAILURE = 'MANAGERS_GET_FAILURE'
export const MANAGERS_CLEAR = 'MANAGERS_CLEAR'
export const MANAGER_GET_REQUEST = 'MANAGER_GET_REQUEST'
export const MANAGER_GET_SUCCESS = 'MANAGER_GET_SUCCESS'
export const MANAGER_GET_FAILURE = 'MANAGER_GET_FAILURE'
export const MANAGER_UPDATE_REQUEST = 'MANAGER_UPDATE_REQUEST'
export const MANAGER_UPDATE_SUCCESS = 'MANAGER_UPDATE_SUCCESS'
export const MANAGER_UPDATE_FAILURE = 'MANAGER_UPDATE_FAILURE'
export const MANAGER_CREATE_REQUEST = 'MANAGER_CREATE_REQUEST'
export const MANAGER_CREATE_SUCCESS = 'MANAGER_CREATE_SUCCESS'
export const MANAGER_CREATE_FAILURE = 'MANAGER_CREATE_FAILURE'
export const MANAGER_DELETE_REQUEST = 'MANAGER_DELETE_REQUEST'
export const MANAGER_DELETE_SUCCESS = 'MANAGER_DELETE_SUCCESS'
export const MANAGER_DELETE_FAILURE = 'MANAGER_DELETE_FAILURE'

export const managersGetRequest = (payload) => ({
  type: MANAGERS_GET_REQUEST,
  payload,
})

export const managersGetSuccess = (payload) => ({
  type: MANAGERS_GET_SUCCESS,
  payload,
})

export const managersGetFailure = (error) => ({
  type: MANAGERS_GET_FAILURE,
  error,
})

export const managerGetRequest = (payload) => ({
  type: MANAGER_GET_REQUEST,
  payload,
})

export const managerGetSuccess = (payload) => ({
  type: MANAGER_GET_SUCCESS,
  payload,
})

export const managerGetFailure = (error) => ({
  type: MANAGER_GET_FAILURE,
  error,
})

export const managerUpdateRequest = (payload) => ({
  type: MANAGER_UPDATE_REQUEST,
  payload,
})

export const managerUpdateSuccess = (payload) => ({
  type: MANAGER_UPDATE_SUCCESS,
  payload,
})

export const managerUpdateFailure = (error) => ({
  type: MANAGER_GET_FAILURE,
  error,
})

export const managerCreateRequest = (payload) => ({
  type: MANAGER_CREATE_REQUEST,
  payload,
})

export const managerCreateSuccess = (payload) => ({
  type: MANAGER_CREATE_SUCCESS,
  payload,
})

export const managerCreateFailure = (error) => ({
  type: MANAGER_CREATE_FAILURE,
  error,
})

export const managerDeleteRequest = (payload) => ({
  type: MANAGER_DELETE_REQUEST,
  payload,
})

export const managerDeleteSuccess = (payload) => ({
  type: MANAGER_DELETE_SUCCESS,
  payload,
})

export const managerDeleteFailure = (error) => ({
  type: MANAGER_DELETE_FAILURE,
  error,
})
