import {
  EMPLOYEES_GET_REQUEST,
  EMPLOYEES_GET_SUCCESS,
  EMPLOYEES_GET_FAILURE,
  EMPLOYEE_UPDATE_REQUEST,
  EMPLOYEE_UPDATE_SUCCESS,
  EMPLOYEE_UPDATE_FAILURE,
  EMPLOYEE_CREATE_REQUEST,
  EMPLOYEE_CREATE_SUCCESS,
  EMPLOYEE_CREATE_FAILURE,
  EMPLOYEES_CLEAR,
  EMPLOYEES_GET_BARISTAS_REQUEST,
  EMPLOYEES_GET_BARISTAS_SUCCESS,
  EMPLOYEES_GET_BARISTAS_FAILURE,
  EMPLOYEES_CLEAR_BARISTAS,
} from './actions'

const initialState = {
  items: [],
  fetching: false,
  error: null,
  limit: 9,
  baristas: [],
}

let _getData, _currentPage

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case EMPLOYEES_GET_REQUEST: {
      _currentPage = payload.params.page
      return { ...state, fetching: true }
    }
    case EMPLOYEES_GET_SUCCESS: {
      return {
        ...state,
        items: payload,
        fetching: false,
        pages: payload.length ? payload[0].pages : 0,
      }
    }
    case EMPLOYEES_GET_FAILURE:
      return { ...state, fetching: false, error: payload }
    case EMPLOYEE_UPDATE_REQUEST:
      return { ...state, fetching: true }
    case EMPLOYEE_UPDATE_SUCCESS:
      return {
        ...state,
        items: state.items.map((i) => (i.id == payload.id ? payload : i)),
        fetching: false,
      }
    case EMPLOYEE_UPDATE_FAILURE:
      return { ...state, fetching: false, error: payload }
    case EMPLOYEE_CREATE_REQUEST:
      return { ...state, fetching: true }
    case EMPLOYEE_CREATE_SUCCESS:
      if (_currentPage == 1) {
        _getData = [payload, ...state.items].slice(0, state.limit)
      } else {
        _getData = state.items
      }
      return { ...state, items: _getData, fetching: false }
    case EMPLOYEE_CREATE_FAILURE:
      return { ...state, fetching: false, error: payload }
    case EMPLOYEES_GET_BARISTAS_REQUEST: {
      return { ...state, fetching: true }
    }
    case EMPLOYEES_GET_BARISTAS_SUCCESS:
      return { ...state, baristas: [...payload.map((b) => ({ value: b.id, label: b.fullName }))], fetching: false }
    case EMPLOYEES_GET_BARISTAS_FAILURE:
      return { ...state, fetching: false, error: payload }
    case EMPLOYEES_CLEAR:
      return initialState
    case EMPLOYEES_CLEAR_BARISTAS:
      return { ...state, baristas: [] }
    default:
      return state
  }
}
