export const EMAIL_CONFIRMATION_REQUEST = 'EMAIL_CONFIRMATION_REQUEST'
export const EMAIL_CONFIRMATION_SUCCESS = 'EMAIL_CONFIRMATION_SUCCESS'
export const EMAIL_CONFIRMATION_FAILURE = 'EMAIL_CONFIRMATION_FAILURE'

export const emailConfirmationRequest = (token, history) => ({
  type: EMAIL_CONFIRMATION_REQUEST,
  token,
  history,
})

export const emailConfirmationSuccess = () => ({
  type: EMAIL_CONFIRMATION_SUCCESS,
})

export const emailConfirmationFailure = (error) => ({
  type: EMAIL_CONFIRMATION_FAILURE,
  payload: error,
})
