import { takeLatest, call, put } from 'redux-saga/effects'
import * as actions from './actions'

import Api from '../../../lib/api'
import notifications from '../../../lib/notifications'
import { toCamelCase } from '../../../lib/converter'

export default function* watcherSaga() {
  yield takeLatest(actions.STAFF_VENDORS_GET_REQUEST, watchResourcesGetRequest)
}

function* watchResourcesGetRequest({ id }) {
  try {
    const response = yield call(Api.Staff.Vendors.get, id)

    yield put(actions.staffVendorsGetSuccess(toCamelCase(response?.data)))
  } catch (error) {
    yield put(actions.staffVendorsGetFailure(error))
    notifications.createNotification('error', error.response?.data)
  }
}
