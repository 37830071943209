import React from 'react'
import propTypes from 'prop-types'

import { SIGNUP_STEPS } from '../../constants'

const ProgressBar = ({ step, handleMoveToPrevStep }) => {
  const firstStep = step == SIGNUP_STEPS.user ? 'active' : 'checked'
  const secondStep = step == SIGNUP_STEPS.network ? 'active' : 'inactive'

  return (
    <div className='auth-page__progress-bar'>
      <div className={firstStep} onClick={handleMoveToPrevStep} />
      <div className={secondStep} />
    </div>
  )
}

ProgressBar.propTypes = {
  step: propTypes.string.isRequired,
  handleMoveToPrevStep: propTypes.func.isRequired,
}

export default ProgressBar
