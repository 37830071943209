export const STAFF_OWNERS_FETCH_REQUEST = 'STAFF_OWNERS_FETCH_REQUEST'
export const STAFF_OWNERS_FETCH_SUCCESS = 'STAFF_OWNERS_FETCH_SUCCESS'
export const STAFF_OWNERS_FETCH_FAILURE = 'STAFF_OWNERS_FETCH_FAILURE'

export const staffOwnersFetchRequest = (payload) => ({
  type: STAFF_OWNERS_FETCH_REQUEST,
  payload
})

export const staffOwnersFetchSuccess = (payload) => ({
  type: STAFF_OWNERS_FETCH_SUCCESS,
  payload,
})

export const staffOwnersFetchFailure = (error) => ({
  type: STAFF_OWNERS_FETCH_FAILURE,
  error,
})
