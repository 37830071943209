import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

import EmployeeEdit from '../Edit/EmployeeEdit'
import EmployeeDeactivate from '../Deactivate/EmployeeDeactivate'
import EmployeeChangePassword from '../ChangePassword/EmployeeChangePassword'
import useOnClickOutside from '../../../hooks/useOnClickOutside'
import { Text, TableRow, TableCell, TableActions, colors, Tooltip, Modal } from '../../UIkit'
import { VerticalDots, Check, Exit, Edit, Reload, Hidden, Arrow, Password } from '../../UIkit/svgs'
import { ExpandedItem, ExpandedTableRow } from '../../Dashboard/Table'

const EmployeesElement = ({ employee, updateEmployee, key, headers }) => {
  const { t } = useTranslation()

  const [showActions, setShowActions] = useState(false)
  const [showEditEmployee, setShowEditEmployee] = useState(false)
  const [showDeactivateEmployee, setShowDeactivateEmployee] = useState(false)
  const [showChangePasswordEmployee, setShowChangePasswordEmployee] = useState(false)
  const [expandedRow, setExpandedRow] = useState(null)

  const actionsRef = useRef()

  const isDisableTooltips = window.innerWidth > 1500

  const { addresses } = useSelector((state) => state.vendors)

  const handleExpandRow = (event, index) => {
    event.stopPropagation()
    if (index === expandedRow) {
      setExpandedRow(null)
    } else {
      setExpandedRow(index)
    }
  }

  const toggleActions = () => {
    setShowActions((prev) => !prev)
  }

  const handleShowEditModal = () => {
    setShowEditEmployee(true)
    setShowActions(false)
  }

  const handleShowDeactivateModal = () => {
    setShowDeactivateEmployee(true)
    setShowActions(false)
  }

  const handleShowChangePasswordModal = () => {
    setShowChangePasswordEmployee(true)
    setShowActions(false)
  }

  const handleCloseEditModal = () => {
    setShowEditEmployee(false)
  }

  const handleCloseDeactivateModal = () => {
    setShowDeactivateEmployee(false)
  }

  const handleCloseChangePasswordModal = () => {
    setShowChangePasswordEmployee(false)
  }

  const handleInviteAgain = () => {
    const data = {
      id: employee.id,
      params: {
        networkEmployee: {
          active: true,
        },
      },
    }

    updateEmployee(data)
    setShowActions(false)
  }

  useOnClickOutside(actionsRef, () => setShowActions(false))

  return (
    <>
      <TableRow>
        {isMobile &&
          <TableCell onClick={(e) => handleExpandRow(e, key)}>
            {expandedRow !== key ? <Arrow /> : <div className='employees__rotated-arrow'><Arrow /></div>}
          </TableCell>
        }
        <TableCell>
          <div className='employees__personal-info'>
            <div
              className='employees__avatar'
              style={{
                backgroundImage: `url(${employee.avatarUrl})`,
              }}
            ></div>
            <Text variant='body-main1' className='employees__full-name'>
              {employee.fullName}
            </Text>
          </div>
        </TableCell>
        <TableCell>
          {employee.active ? (
            <div className='status active'>
              <Check width='20' height='20' color={colors.secondary[500]} />
              <Text variant='body-tertiary2' color={colors.secondary[500]}>
                {t('employees:active')}
              </Text>
            </div>
          ) : (
            <div className='status'>
              <Exit width='20' height='20' color={colors.gray[400]} />
              <Text variant='body-tertiary2' color={colors.gray[400]}>
                {t('employees:inactive')}
              </Text>
            </div>
          )}
        </TableCell>
        {!isMobile &&
          <>
            <TableCell>
              <Tooltip content={employee.vendor?.fullAddress} disableHoverListener={isDisableTooltips}>
                <Text style='employees__max-width' variant='body-main1'>
                  {employee.vendor?.fullAddress}
                </Text>
              </Tooltip>
            </TableCell>
            <TableCell title={employee.email}>
              <Tooltip content={employee.email} disableHoverListener={isDisableTooltips}>
                <Text style='employees__max-width' variant='body-main1'>
                  {employee.email}
                </Text>
              </Tooltip>
            </TableCell>
            <TableCell>
              <Text variant='body-main1'>{employee.phoneNumber}</Text>
            </TableCell>
          </>}
        <TableCell>
          <div ref={actionsRef}>
            <VerticalDots className='employees__dots-icon' onClick={toggleActions} />
            {showActions &&
                  !isMobile ? (
                <TableActions elemRef={actionsRef.current}>
                  <div className='employees__action' onClick={handleShowEditModal}>
                    <Edit />
                    <Text variant='body-main2'>{t('actions:edit')}</Text>
                  </div>
                  <div className='employees__action' onClick={handleShowChangePasswordModal}>
                    <Password />
                    <Text variant='body-main2'>{t('actions:changePassword')}</Text>
                  </div>
                  {employee.active ? (
                    <div className='employees__action' onClick={handleShowDeactivateModal}>
                      <Hidden color={colors.primary[500]} />
                      <Text variant='body-main2'>{t('actions:deactivate')}</Text>
                    </div>
                  ) : (
                    <div className='employees__action' onClick={handleInviteAgain}>
                      <Reload />
                      <Text variant='body-main2'>{t('actions:inviteAgain')}</Text>
                    </div>
                  )}
                </TableActions>
              ) : (
                <Modal title='Options' handleClose={toggleActions} show={showActions} mobileModal subModal withoutExit >
                  <div className='employees__action' onClick={handleShowEditModal}>
                    <Edit />
                    <Text variant='body-main2'>{t('actions:edit')}</Text>
                  </div>
                  <div className='employees__action' onClick={handleShowChangePasswordModal}>
                    <Password />
                    <Text variant='body-main2'>{t('actions:changePassword')}</Text>
                  </div>
                  {employee.active ? (
                    <div className='employees__action' onClick={handleShowDeactivateModal}>
                      <Hidden color={colors.primary[500]} />
                      <Text variant='body-main2'>{t('actions:deactivate')}</Text>
                    </div>
                  ) : (
                    <div className='employees__action' onClick={handleInviteAgain}>
                      <Reload />
                      <Text variant='body-main2'>{t('actions:inviteAgain')}</Text>
                    </div>
                  )}
                </Modal>
              )
            }
            <EmployeeEdit
              isShown={showEditEmployee}
              closeModal={handleCloseEditModal}
              employee={employee}
              updateEmployee={updateEmployee}
              vendorsList={addresses}
            />
            <EmployeeDeactivate
              isShown={showDeactivateEmployee}
              closeModal={handleCloseDeactivateModal}
              employeeId={employee.id}
              updateEmployee={updateEmployee}
            />
            <EmployeeChangePassword
              isShown={showChangePasswordEmployee}
              closeModal={handleCloseChangePasswordModal}
              employeeId={employee.id}
              updateEmployee={updateEmployee}
            />
          </div>
        </TableCell>
      </TableRow>
      <ExpandedTableRow key={key} expandedRow={expandedRow} tableHeaders={headers}>
        <div className='custom-table__expanded-body'>
          <div className='custom-table__expanded-titles'>
            <ExpandedItem title={t('employees:vendor')} text={employee.vendor?.fullAddress} />
            <ExpandedItem title={t('employees:email')} text={employee.email} />
            <ExpandedItem title={t('employees:phone')} text={employee.phoneNumber} />
          </div>
        </div>
      </ExpandedTableRow>
    </>
  )
}

export default React.memo(EmployeesElement)
