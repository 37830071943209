import React from 'react'
import PropTypes from 'prop-types'
import { createWorkingHours } from '../../lib'
import { Text, colors } from '../UIkit'

const WorkingHours = ({ elemRef, workTimes }) => {
  const workingHours = createWorkingHours(workTimes)

  const viewHeight = window.innerHeight
  const viewSpaceBelow = viewHeight - elemRef.getBoundingClientRect()?.top
  const isBottomBlock = viewSpaceBelow <= 180

  const style = {
    bottom: isBottomBlock ? '70px' : 'auto',
    top: !isBottomBlock ? '55px' : 'auto',
    flexDirection: isBottomBlock ? 'column-reverse' : 'column'
  }

  const triangleStyle = {
    transform: isBottomBlock && 'rotate(180deg)' 
  }

  return (
    <div style={style} className='working-hours'>
      <div style={triangleStyle} className='working-hours__triangle' />
      <div className='working-hours__body'>
        <div className='working-hours__body__day'>
          {workingHours.map((workingHour, index) => (
            <Text key={index} variant='body-secondary2'>{workingHour.day}</Text>
          ))}
        </div>

        <div className='working-hours__body__border' />

        <div className='working-hours__body__time'>
          {workingHours.map((workingHour, index) => (
            <Text 
              key={index}
              variant='body-secondary1' 
              color={colors.gray[500]}
            >
              {workingHour.time}
            </Text>
          ))}
        </div>
      </div>
    </div>
  )
}

WorkingHours.propTypes = {
  workTimes: PropTypes.array.isRequired,
  elemRef: PropTypes.object.isRequired
}

export default WorkingHours
