import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroller'
import { isLength } from 'lodash'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

import { Text, Loader, BoardColumn, colors, Button, Modal } from '../../../../UIkit'
import { Plus, Trash, VerticalDots } from '../../../../UIkit/svgs'
import ProductCard from '../Product/ProductComponent'
import SupplementCard from '../Supplement/SupplementComponent'
import './styles.scss'

const MenuBoardCategoryComponent = ({
  vendorFilter,
  category,
  products,
  supplements,
  fetchProducts,
  updateProduct,
  fetchSupplements,
  updateSupplement,
  handleRemoveCategoryModal,
  handleCreateProductModal,
  handleEditProductModal,
  handleDeleteProductModal,
  handleCreateSupplementModal,
  handleEditSupplementModal,
  handleDeleteSupplementModal,
}) => {
  const { t } = useTranslation()

  const [showOptionsModal, setShowOptionsModal] = useState(false)

  const toggleActions = () => {
    setShowOptionsModal((prev) => !prev)
  }

  const handleDeleteModal = () => {
    handleRemoveCategoryModal(category.id)
  }

  const renderColumnHeader = () => {
    return (
      <>
        <Text variant='body-main2'>{t(`menu:${category.slug.toLowerCase()}`)}</Text>
        {isMobile ? 
          <VerticalDots onClick={toggleActions} /> : 
          <Trash className='customBoard__columnActions' color={colors.gray[500]} onClick={handleDeleteModal} />
        }
      </>
    )
  }

  const itsSupplements = category.type.slug === 'supplements'

  const handleAddProduct = () => {
    if (itsSupplements) return handleCreateSupplementModal(category.id)
    handleCreateProductModal(category.id)
  }

  const renderColumnFooter = () => {
    if (!vendorFilter)
      return (
        <Button variant='tertiary' onClick={handleAddProduct}>
          <Plus color={colors.primary[500]}/>
          {itsSupplements ? t('menu:addSupplement') : t('menu:addProduct')}
        </Button>
      )
  }

  const pageRef = useRef(0)
  const setCurrentPage = (page) => (pageRef.current = page)

  const storeProductsLoading = useSelector((state) => state.products.fetching)
  const storeSupplementsLoading = useSelector((state) => state.supplements.fetching)

  const [localDataLoading, setLocalDataLoading] = useState(true)
  const localLoadingCallback = () => setLocalDataLoading(false)

  const loadMoreData = () => {
    if (!storeProductsLoading && !storeSupplementsLoading) {
      setLocalDataLoading(true)
      const fetchPage = pageRef.current + 1
      if (itsSupplements) {
        fetchSupplements(category.id, fetchPage, localLoadingCallback)
      } else {
        fetchProducts(category.id, fetchPage, localLoadingCallback)
      }
      setCurrentPage(fetchPage)
    }
  }

  useEffect(() => {
    loadMoreData()
  }, [])

  const storeDataObject = itsSupplements ? supplements : products
  const storeDataArray = storeDataObject[category.id] || []

  const totalPages = storeDataArray[0]?.pages || 1
  const hasMorePages = totalPages > pageRef.current

  return (
    <BoardColumn renderHeader={renderColumnHeader} renderFooter={renderColumnFooter}>
      <InfiniteScroll
        hasMore={hasMorePages}
        threshold={200}
        loadMore={loadMoreData}
        useWindow={false}
        initialLoad={false}
      >
        {itsSupplements
          ? isLength(storeDataArray.length) &&
            storeDataArray.map((supplement) => (
              <SupplementCard
                key={supplement.id}
                supplement={supplement}
                updateSupplement={updateSupplement}
                vendorFilter={vendorFilter}
                handleEditSupplementModal={handleEditSupplementModal}
                handleDeleteSupplementModal={handleDeleteSupplementModal}
              />
            ))
          : isLength(storeDataArray.length) &&
            storeDataArray.map((product) => (
              <ProductCard
                key={product.id}
                product={product}
                updateProduct={updateProduct}
                vendorFilter={vendorFilter}
                handleEditProductModal={handleEditProductModal}
                handleDeleteProductModal={handleDeleteProductModal}
              />
            ))}
        {localDataLoading && (
          <div className='menuProducts__column_loader'>
            <Loader size={30} inBlock />
          </div>
        )}
      </InfiniteScroll>
      {showOptionsModal && 
        <Modal title='Options' handleClose={toggleActions} show={showOptionsModal} subModal mobileModal withoutExit scrollable>
          <div className='menuProducts__modal'>
            <div className='menuProducts__modal-button' onClick={handleAddProduct}>
              <Plus color={colors.primary[500]} />
              <Text>{t('menu:addProduct')}</Text>
            </div>
            <div className='menuProducts__modal-button' onClick={handleDeleteModal}>
              <Trash className='customBoard__columnActions' color={colors.primary[500]} />
              <Text>{t('menu:deleteCategory')}</Text>
            </div>
          </div>
        </Modal>}
    </BoardColumn>
  )
}

MenuBoardCategoryComponent.propTypes = {
  vendorFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  category: PropTypes.object,
  products: PropTypes.object,
  supplements: PropTypes.object,
  fetchProducts: PropTypes.func,
  updateProduct: PropTypes.func,
  fetchSupplements: PropTypes.func,
  updateSupplement: PropTypes.func,
  handleRemoveCategoryModal: PropTypes.func,
  handleCreateProductModal: PropTypes.func,
  handleEditProductModal: PropTypes.func,
  handleDeleteProductModal: PropTypes.func,
  handleCreateSupplementModal: PropTypes.func,
  handleEditSupplementModal: PropTypes.func,
  handleDeleteSupplementModal: PropTypes.func,
}

export default MenuBoardCategoryComponent
