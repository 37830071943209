export const CATEGORIES_CLEAR = 'CATEGORIES_CLEAR'

export const CATEGORIES_FETCH_REQUEST = 'CATEGORIES_FETCH_REQUEST'
export const CATEGORIES_FETCH_SUCCESS = 'CATEGORIES_FETCH_SUCCESS'
export const CATEGORIES_FETCH_FAILURE = 'CATEGORIES_FETCH_FAILURE'

// CATEGORIES FETCH

export const categoriesFetchRequest = (payload, history) => ({
  type: CATEGORIES_FETCH_REQUEST,
  payload,
  history,
})

export const categoriesFetchSuccess = (payload) => ({
  type: CATEGORIES_FETCH_SUCCESS,
  payload,
})

export const categoriesFetchFailure = (error) => ({
  type: CATEGORIES_FETCH_FAILURE,
  error,
})

// CATEGORY ADD
export const CATEGORY_ADD_REQUEST = 'CATEGORY_ADD_REQUEST'
export const CATEGORY_ADD_SUCCESS = 'CATEGORY_ADD_SUCCESS'
export const CATEGORY_ADD_FAILURE = 'CATEGORY_ADD_FAILURE'

export const categoryAddRequest = (payload) => ({
  type: CATEGORY_ADD_REQUEST,
  payload,
})

export const categoryAddSuccess = (payload) => ({
  type: CATEGORY_ADD_SUCCESS,
  payload,
})

export const categoryAddFailure = (error) => ({
  type: CATEGORY_ADD_FAILURE,
  error,
})

// CATEGORY DELETE
export const CATEGORY_DELETE_REQUEST = 'CATEGORY_DELETE_REQUEST'
export const CATEGORY_DELETE_SUCCESS = 'CATEGORY_DELETE_SUCCESS'
export const CATEGORY_DELETE_FAILURE = 'CATEGORY_DELETE_FAILURE'

export const categoryDeleteRequest = (payload) => ({
  type: CATEGORY_DELETE_REQUEST,
  payload,
})

export const categoryDeleteSuccess = (payload) => ({
  type: CATEGORY_DELETE_SUCCESS,
  payload,
})

export const categoryDeleteFailure = (error) => ({
  type: CATEGORY_DELETE_FAILURE,
  error,
})

// CLEAR
export const categoriesClear = () => ({
  type: CATEGORIES_CLEAR,
})
