import React, { useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { VendorContext } from './vendorContext'
import { staffVendorsGetRequest } from '../../../../store/staff/vendors/actions'
import VendorHeader from './VendorHeader'
import VendorInfo from './VendorInfo'
import VendorTabs from './VendorTabs/VendorTabs'

const VendorView = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { vendor, fetching } = useSelector(state => state.staffVendors)

  useEffect(() => {
    dispatch(staffVendorsGetRequest(id))
  }, [id])

  const vendorContextProps = useMemo(() => ({
    vendorId: vendor.id,
    networkId: vendor.networkId
  }), [vendor])

  if (fetching) return null

  return (
    <VendorContext.Provider value={vendorContextProps}>
      <VendorHeader vendor={vendor} />
      <VendorInfo vendor={vendor} />
      <VendorTabs />
    </VendorContext.Provider>
  )
}

export default VendorView
