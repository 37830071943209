import React from 'react'

import { Step1, Step2, Step3, Step4 } from '../../../../assets/images/landing/HowItWorks'
import './styles.scss'
import './responsiveStyles.scss'

function HowItWorks() {
  return (
    <section id='how-it-works' className='business-section'>
      <h1>Як це працює?</h1>
      <div className='section-wrapper'>
        <div className='step'>
          <span className='number'>01</span>
          <img src={Step1} alt='Step 1' />
          <h6>Зареєструйтесь</h6>
          <p>Введіть ім&apos;я, прізвище, email та придумайте пароль</p>
        </div>
        <div className='step'>
          <span className='number'>02</span>
          <img src={Step2} alt='Step 2' />
          <h6>Заповніть інформацію</h6>
          <p>Додайте назву, вебсайт (якщо є) та опис вашого бізнесу</p>
        </div>
        <div className='step'>
          <span className='number'>03</span>
          <img src={Step3} alt='Step 3' />
          <h6>Додайте співробітників</h6>
          <p>Заповніть інформацію про працівників та надайте їм потрібний рівень доступу</p>
        </div>
        <div className='step'>
          <span className='number'>04</span>
          <img src={Step4} alt='Step 4' />
          <h6>Насолоджуйтесь</h6>
          <p>Готово! Тепер вам доступні основні функції додатків Pinka</p>
        </div>
      </div>
    </section>
  )
}

export default HowItWorks
