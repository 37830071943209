import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Upload from '../../lib/upload'

import {
  ProgressBar,
  Control,
  Gallery,
  ValidationError,
  Options,
  Actions
} from './'
import { Plus } from '../UIkit/svgs'
import { Text, colors } from '../UIkit'

import './styles.scss'

const MAX_SIZE_BYTES =  5e+6 // 5 MB
const MAX_IMAGES = 7

const Uploader = ({ name, onChange, image = {}, images = [], isMultiple = false, onDelete, onRewrite, mobile }) => {
  const { t } = useTranslation()

  const [file, setFile] = useState('')
  const [galleryImages, setGalleryImages] = useState([])

  const [imageIndex, setImageIndex] = useState(0)
  const [actionType, setActionType] = useState('new')
  const [imageSignedId, setImageSignedId] = useState('')


  const [isSizeValid, setIsSizeValid] = useState(true)
  const [isHovering, setHovering] = useState(false)
  const [showActions, setShowActions] = useState(false)

  const inputRef = useRef()
  const blockRef = useRef()
  const imageBlockRef = useRef()

  const maxImages = galleryImages.length >= MAX_IMAGES

  useEffect(() => { !isMultiple && setFile(image) }, [])

  useEffect(() => {
    if (isMultiple && !!images?.length) {
      setGalleryImages(images)
      setFile(images[0])
    }
  }, [])

  const validateSize = (file) => new Promise((resolve, reject) => {
    if (file.size < MAX_SIZE_BYTES) {
      resolve(setIsSizeValid(true))
    } else {
      reject()
    }
  })

  const handleSelect = (e) => {
    const count = MAX_IMAGES - galleryImages.length

    if (e.target.files.length > count){
      return alert(`${t('uploader:maxFiles')} ${count} ${t('uploader:files')}`)
    }

    Array.from(e.target.files).forEach((file) => {
      validateSize(file).then(() => {
        startUpload(file)
      })
        .catch(() => {
          setIsSizeValid(false)
        })
    })
  }

  const handleHover = (value) => () => file.url && setShowActions(value)

  const handleClick = (action, signedId) => () => {
    setActionType(action)
    setImageSignedId(signedId)

    inputRef.current.click()
  }

  const handleDragStart = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setHovering(true)
  }

  const handleDragIn = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setHovering(true)
  }

  const handleDragOut = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setHovering(false)
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setHovering(true)
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const file = e.dataTransfer.files[0]

    setHovering(false)
    startUpload(file)
  }

  const handleNewImage = (image) => {
    setGalleryImages((prev) => [...prev, image])
    setImageIndex(galleryImages.length)
    onChange(image.signedId)
  }

  const handleRewriteImage = (image) => {
    setGalleryImages((prev) => prev.map((i) => (i.signedId == imageSignedId ? image : i)))
    onRewrite(imageSignedId, image.signedId)
  }

  const setImage = (image) => {
    setFile(image)
    if (image.state == 'finished') {
      actionType == 'change' ? handleRewriteImage(image) : handleNewImage(image)
    }
  }

  const startUpload = (file) => {
    const upload = new Upload(file, { onChangeFile: setImage })

    upload.start()
  }

  useEffect(() => {
    const ref = blockRef.current
    const imageRef = imageBlockRef.current

    ref.addEventListener('dragstart', handleDragStart)
    ref.addEventListener('dragenter', handleDragIn)
    ref.addEventListener('dragleave', handleDragOut)
    ref.addEventListener('dragover', handleDragOver)
    ref.addEventListener('drop', handleDrop)
    imageRef.addEventListener('dragstart', handleDragStart)
    imageRef.addEventListener('dragenter', handleDragIn)
    imageRef.addEventListener('dragleave', handleDragOut)
    imageRef.addEventListener('dragover', handleDragOver)
    imageRef.addEventListener('drop', handleDrop)

    return () => {
      ref.removeEventListener('dragstart', handleDragStart)
      ref.removeEventListener('dragenter', handleDragIn)
      ref.removeEventListener('dragleave', handleDragOut)
      ref.removeEventListener('dragover', handleDragOver)
      ref.removeEventListener('drop', handleDrop)
      imageRef.removeEventListener('dragstart', handleDragStart)
      imageRef.removeEventListener('dragenter', handleDragIn)
      imageRef.removeEventListener('dragleave', handleDragOut)
      imageRef.removeEventListener('dragover', handleDragOver)
      imageRef.removeEventListener('drop', handleDrop)
    }
  }, [])

  const handleChangeImage = (image, index) => () => {
    setFile(image)

    setImageIndex(index)
  }

  const handleDeleteImage = (id) => () => {
    setFile({})
    setShowActions(false)

    let images = galleryImages

    const isLastImage = imageIndex + 1 === galleryImages.length
    const nextImageIndex = isLastImage ? imageIndex - 1 : imageIndex

    if (isMultiple) {
      images = galleryImages.filter((image) => image.signedId !== id)
      setGalleryImages(images)
      images.length > 1 ? setFile(images[nextImageIndex]) : setFile(images[0])
      images.length === 0 && setFile({})
      setImageIndex(nextImageIndex)
    }

    onDelete(id)
  }

  const iconColor = maxImages ? colors.gray[300] : colors.primary[500]

  return (
    <div>
      {isMultiple && !mobile &&
        <Control
          imageIndex={imageIndex}
          setImageIndex={setImageIndex}
          setFile={setFile}
          galleryImages={galleryImages}
        />
      }

      <div
        className={`uploader ${!file.url && 'uploader-border'}
                           ${isHovering && 'uploader-hover'}
                           ${!isSizeValid && 'uploader-error'}
                           ${mobile && 'uploader-mobile'}`}
        onMouseEnter={handleHover(true)}
        onMouseLeave={handleHover(false)}
        ref={imageBlockRef}
        style={{ backgroundImage: `url(${file.url && isSizeValid && file.url})` }}>
        <input
          className='d-none'
          accept={['image/png', 'image/jpeg'].join(',')}
          name={name}
          multiple={isMultiple}
          onChange={handleSelect}
          ref={inputRef}
          type='file'
        />

        {!file.url && !file.state && isSizeValid &&
          !mobile && <Options handleClick={handleClick} />
        }

        {file.state == 'uploading' &&
          <ProgressBar progress={file.progress} filename={file.filename} />
        }

        {!isSizeValid && <ValidationError handleClick={handleClick} />}

        {showActions && file.state !== 'uploading' && isSizeValid &&
          <Actions
            isMultiple={isMultiple}
            handleClick={handleClick}
            handleDelete={handleDeleteImage}
            filename={file.filename}
            signedId={file.signedId}
          />
        }
      </div>

      <div
        ref={blockRef}
      >
        {isMultiple &&
            <Gallery
              imageIndex={imageIndex}
              galleryImages={galleryImages}
              handleChangeImage={handleChangeImage}
              mobile={mobile}
              handleDelete={handleDeleteImage}
            />
        }
      </div>
      {mobile &&      
        <div className='uploader-mobile__button' onClick={!maxImages && handleClick('new')}>
          <Plus color={iconColor} />
          <Text color={iconColor} variant='body-main2'>{t('network:loadImages')}</Text>
        </div>
      }
    </div>
  )
}

Uploader.propTypes = {
  name:     PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  image:    PropTypes.object,
  images:   PropTypes.array,
  onRewrite: PropTypes.func,
}

export default Uploader
