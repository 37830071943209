import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Text, Button, Input, PasswordInput, colors, Loader } from '../../UIkit'
import { UserIcon } from '../../UIkit/svgs'
import {
  userSmsCodeClear,
  userSmsCodeRequest,
} from '../../../store/users/actions'
import { Validator, formatPhoneNumber } from '../../../lib'
import Captcha from '../../Captcha'

const UserForm = ({ userData, checkCredentials, setUserData, handleMoveToNextStep }) => {
  const { t } = useTranslation()

  const { smsSent, fetching } = useSelector(({ users }) => users)
  const credentials = useSelector(({ checkCredentials }) => checkCredentials)
  const credentialsError = credentials.error
  const dispatch = useDispatch()
  const session = localStorage.getItem('session')

  useEffect(() => {
    if (session) {
      handleNext()
    }
  }, [])
  const [form, setForm] = useState({
    firstName: userData.firstName,
    lastName: userData.lastName,
    email: userData.email,
    password: userData.password,
    passwordConfirmation: userData.passwordConfirmation,
    phoneNumber: userData.phoneNumber,
    smsCode: userData.smsCode,
  })

  const [errors, setErrors] = useState({})
  const [method, setMethod] = useState('email')
  const [timer, setTimer] = useState(30)
  const [smsResent, setSmsResent] = useState(false)

  const timerInterval = useRef()
  const [captcha, setCaptcha] = useState('')

  useEffect(() => {
    setForm((ps) => ({ ...userData, firstName: ps.firstName, lastName: ps.lastName }))
    if (method === 'email' && smsSent) dispatch(userSmsCodeClear())
  }, [method])

  useEffect(() => {
    if (smsSent || smsResent) {
      timerInterval.current = setInterval(() => {
        setTimer((ps) => ps - 1)
      }, 1000)

      return () => {
        clearInterval(timerInterval.current)
      }
    }
  }, [smsSent, smsResent])

  useEffect(() => {
    if (timer <= 0) {
      clearInterval(timerInterval.current)
      setSmsResent(false)
    }
  }, [timer])

  useEffect(() => {
    if (method === 'email') {
      setErrors({ ...errors, email: credentialsError })
    } else {
      setErrors({ ...errors, phoneNumber: credentialsError })
    }
  }, [credentialsError])

  const handleUpdateField = ({ target: { name, value } }) => {
    setForm((prev) => ({ ...prev, [name]: name === 'phoneNumber' ? formatPhoneNumber(value) : value }))
  }

  const requestSendCode = () => {
    if (smsSent) setSmsResent(true)

    dispatch(userSmsCodeRequest({ phoneNumber: form.phoneNumber, captcha, reason: 'registration' }))
    setCaptcha('')
    setTimer(30)
  }

  const checkIsFieldValid = () => {
    if (form.email.length > 0 || form.phoneNumber.length > 6) {
      let data = {
        field: method === 'email' ? 'email' : 'phone_number',
        value: method === 'email' ? form.email : form.phoneNumber
      }
      checkCredentials(data)
    }
  }

  const handleValidate = ({ saveErrors } = { saveErrors: true }) => {
    let validationFields = []

    if (method === 'email') {
      validationFields = [
        ['email', Validator.concepts.emailRegex, [form.email]],
        ['password', Validator.concepts.isLongerThan, [6, form.password]],
        ['passwordConfirmation', Validator.concepts.isLongerThan, [6, form.password]],
        ['passwordConfirmation', Validator.concepts.isMatching, [form.password, form.passwordConfirmation]],
      ]
    } else {
      validationFields = [
        ['phoneNumber', Validator.concepts.phoneRegex, [form.phoneNumber]],
      ]
      if (!smsSent) validationFields.push(['captcha', Validator.concepts.isFilled, [captcha]])
    }

    const errors = Validator.validate(validationFields)
    saveErrors && setErrors(errors)
    Validator.clear()
    return isEmpty(errors)
  }

  const handleNext = () => {
    handleMoveToNextStep()
    dispatch(userSmsCodeClear())
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (handleValidate()) {
      if (method === 'phone') {
        if (smsSent) {
          setUserData(form)
          handleNext()
        } else requestSendCode()

        setCaptcha('')
      } else {
        setUserData(form)
        handleNext()
      }
    }
  }

  const processCaptcha = (newCaptcha) => {
    setCaptcha(newCaptcha)
  }

  const isDisabled = () => {
    if (smsSent) {
      return form.smsCode.length < 4
    }
    return !handleValidate({ saveErrors: false })
  }

  if (fetching)
    return (
      <div className='auth-page__form'>
        <div className='auth-page__form__headline'>
          <UserIcon />
          <Text variant='subheading'>{t('signup:userForm:title')}</Text>
        </div>
        <Loader size={48} inBlock />
      </div>
    )

  return (
    <form className='auth-page__form' onSubmit={handleSubmit}>
      <div className='auth-page__form__headline'>
        <UserIcon />
        <Text variant='subheading'>{t('signup:userForm:title')}</Text>
      </div>
      {smsSent ? (
        <>
          <Input
            id='smsCode'
            name='smsCode'
            placeholder={t('input:smsCode')}
            value={form.smsCode}
            onChange={handleUpdateField}
          />
          <div className='w-100 mb-3 text-center'>
            <div className='mb-2'>
              {timer > 0 && <Text variant='body-main1'>{t('signup:userForm:resendCodeTimer') + ` ${timer}`}</Text>}
            </div>
            <Button type='button' onClick={requestSendCode} disabled={timer > 0}>
              {t('signup:userForm:resendCode')}
            </Button>
          </div>
        </>
      ) : (
        <>
          <Input
            id='first-name'
            name='firstName'
            placeholder={t('input:firstName')}
            value={form.firstName}
            onChange={handleUpdateField}
          />
          <Input
            id='last-name'
            name='lastName'
            placeholder={t('input:lastName')}
            value={form.lastName}
            onChange={handleUpdateField}
          />
          <div className='w-100 mb-2 text-center'>
            <Text variant='body-main1'>{t('signup:userForm:chooseMethod')}</Text>
          </div>
          <div className='d-flex justify-content-between align-items-center w-100 mb-3'>
            <Button type='button' onClick={() => setMethod('email')}>
              {t('signup:userForm:email')}
            </Button>
            <div className='mx-3'>
              <Text variant='body-main1'>{t('signup:userForm:or')}</Text>
            </div>
            <Button type='button' onClick={() => setMethod('phone')}>
              {t('signup:userForm:phone')}
            </Button>
          </div>

          {method === 'email' ? (
            <>
              <Input
                id='email'
                name='email'
                type='email'
                placeholder={t('input:email')}
                value={form.email}
                onBlurCallback={checkIsFieldValid}
                onChange={handleUpdateField}
                error={errors.email}
              />
              <PasswordInput
                id='password'
                name='password'
                placeholder={t('input:password')}
                value={form.password}
                onChange={handleUpdateField}
                error={errors.password}
              />
              <PasswordInput
                id='password-confirmation'
                name='passwordConfirmation'
                placeholder={t('input:passwordConfirmation')}
                value={form.passwordConfirmation}
                onChange={handleUpdateField}
                error={errors.passwordConfirmation}
              />
            </>
          ) : (
            <Input
              id='phoneNumber'
              name='phoneNumber'
              type='tel'
              placeholder={t('input:phoneNumber')}
              value={form.phoneNumber}
              onBlurCallback={checkIsFieldValid}
              onChange={handleUpdateField}
              error={errors.phoneNumber}
            />
          )}
        </>
      )}

      {method === 'phone' && !smsSent && <Captcha processCaptcha={processCaptcha} />}
      <Button type='submit' disabled={isDisabled()}>
        {t('btn:next')}
      </Button>

      <div className='auth-page__form__description'>
        <Text variant={'body-secondary1'} color={colors.gray[500]}>
          {t('signup:userForm:haveAccount')}&nbsp;
        </Text>
        <Text variant={'body-secondary1'}>
          <Link to='/admin'>{t('btn:signin')}</Link>
        </Text>
      </div>
    </form>
  )
}

UserForm.propTypes = {
  userData: PropTypes.object.isRequired,
  setUserData: PropTypes.func.isRequired,
  handleMoveToNextStep: PropTypes.func.isRequired,
}

export default UserForm
