import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import AppAdmin from './AppAdmin'

const ProtectedRoute = ({ session }) => {
  return session.authenticated ? (
    <AppAdmin>
      <Outlet />
    </AppAdmin>
  ) : (
    <Navigate to={'/admin'} />
  )
}

export default ProtectedRoute
