import {
  CHECK_CREDENTIALS_REQUEST,
  CHECK_CREDENTIALS_SUCCESS,
  CHECK_CREDENTIALS_FAILURE
} from './actions'

const initialState = {
  fetching: true,
  error: null
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CHECK_CREDENTIALS_REQUEST:
      return { ...state }
    case CHECK_CREDENTIALS_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: payload || null
      }
    case CHECK_CREDENTIALS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: payload
      }

    default:
      return state
  }
}
