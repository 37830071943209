import React from 'react'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import config from 'config'
import { useNavigate } from 'react-router-dom'

import { EmptyPageMessage, Input, Text, Button } from '../../UIkit'
import Loader from '../../Loader'
import MenuType from './Type/TypeComponent'
import './styles.scss'

import { networkGetRequest, posterDeleteRequest } from '../../../store/networks/actions'
import { Logo } from '../../UIkit/svgs'
import { getPosterAuthURL } from '../../../lib/posterApi'

const MenuComponent = ({ types, fetching }) => {
  const [menu, setMenu] = useState('')
  const [companyName, setCompanyName] = useState('')

  const networkId = localStorage.getItem('networkId')
  const dispatch = useDispatch()
  const history = useNavigate()
  const { t } = useTranslation()

  const networkPoster = useSelector((state) => state.networks.network.integrationType === 'poster')

  const unsubscribeFromPoster = () => {
    setMenu('')
    dispatch(posterDeleteRequest(history))
  }

  const sendCompanyName = () => {
    const authURL = getPosterAuthURL(companyName)
    window.location.href = authURL
  }

  useEffect(() => {
    dispatch(networkGetRequest(networkId))
  }, [])

  return (
    <div className='menuTypes'>
      <div className='menuTypes__title'>
        <Text variant='h3-medium'>{t('menu:title')}</Text>
      </div>
      {!menu && !networkPoster && (
        <div className='chooseMenu'>
          <div onClick={() => setMenu('pinka')} className='menuType'>
            <div className='chooseMenuButton '>
              <Logo width={120} height={120} />
            </div>
          </div>
          <div onClick={() => setMenu('poster')} className='menuType'>
            <div className='chooseMenuButton'>
              <img src={`${config.s3MediaUrl}/poster-logo.png`} style={{ width: '120px', height: '120px' }} />
            </div>
          </div>
        </div>
      )}
      <Loader size={54} isLoading={fetching} />
      {menu === 'pinka' && !fetching && types.length > 0 && (
        <div className='chooseMenuButton'>
          <div className='menuTypes__list-wrapper'>
            <Button onClick={() => setMenu('')}>{t('menu:back')}</Button>
          </div>
          <div className='menuTypes__list-wrapper'>
            {types.map((type) => (
              <div key={type.id}>
                <MenuType type={type} />
              </div>
            ))}
          </div>
        </div>
      )}
      {networkPoster && (
        <div className='menuTypes__list-poster-wrapper'>
          <Text variant='h3-medium'>{t('menu:poster')}</Text>
          <div>
            <Button onClick={unsubscribeFromPoster}>{t('menu:deletePoster')}</Button>
          </div>
        </div>
      )}
      {menu === 'poster' && !networkPoster && (
        <div className='menuTypes__list'>
          <div>
            <div className='menuTypes__list-wrapper'>
              <Button onClick={() => setMenu('')}>{t('menu:back')}</Button>
            </div>
            <div className='titlePosterInput'>
              <Text variant='h3-medium'>{t('menu:inputTitle')}</Text>
            </div>
            <div>
              <form>
                <Input
                  id='company-name'
                  name='companyName'
                  placeholder={t('menu:companyName')}
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
                {companyName && (
                  <Button type='button' onClick={sendCompanyName}>
                    {t('menu:submit')}
                  </Button>
                )}
              </form>
            </div>
          </div>
        </div>
      )}
      {!fetching && types.length < 1 && <EmptyPageMessage text={t('menu:empty')} />}
    </div>
  )
}

MenuComponent.propTypes = {
  types: PropTypes.array,
  fetching: PropTypes.bool,
}

export default MenuComponent
