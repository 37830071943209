import React from 'react'

import './styles.scss'


export const Switch = ({ index, label, checked, onChange }) => {
  return (
    <div className='switch-container'>
      <div className='switch-toggle'>
        <input type='checkbox' className='checkbox'
          name={label} id={index} onChange={onChange} checked={checked} />
        <label className='label' htmlFor={index}>
          <span className='inner' />
          <span className='switch' />
        </label>
      </div>
      {label}{' '}
    </div>
  )
}

export const SmallSwitch = ({ index, name, checked, onChange }) => {
  return (
    <div className='toggle-switch-small'>
      <input type='checkbox' className='checkbox'
        name={name} id={index} onChange={onChange} checked={checked} />
      <label className='label' htmlFor={index}>
        <span className='inner' />
        <span className='switch' />
      </label>
    </div>
  )
}
