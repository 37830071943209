import {
  STAFF_VENDORS_GET_REQUEST,
  STAFF_VENDORS_GET_SUCCESS,
  STAFF_VENDORS_GET_FAILURE,
} from './actions'

const initialState = {
  vendor: {},
  fetching: true,
  error: null,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case STAFF_VENDORS_GET_REQUEST:
      return { ...state }
    case STAFF_VENDORS_GET_SUCCESS:
      return {
        ...state,
        vendor: payload,
        fetching: false,
        error: null,
      }
    case STAFF_VENDORS_GET_FAILURE:
      return { ...state, error: payload, fetching: false }
    default:
      return state
  }
}
