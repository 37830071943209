export const CASH_TURNOVER_GET_REQUEST = 'CASH_TORNOVER_GET_REQUEST'
export const CASH_TURNOVER_GET_SUCCESS = 'CASH_TURNOVER_GET_SUCCESS'
export const CASH_TURNOVER_GET_FAILURE = 'CASH_TURNOVER_GET_FAILURE'

export const TOTAL_ORDERS_GET_REQUEST = 'TOTAL_ORDERS_GET_REQUEST'
export const TOTAL_ORDERS_GET_SUCCESS = 'TOTAL_ORDERS_GET_SUCCESS'
export const TOTAL_ORDERS_GET_FAILURE = 'TOTAL_ORDERS_GET_FAILURE'

export const SALES_BY_CATEGORY_GET_REQUEST = 'SALES_BY_CATEGORY_GET_REQUEST'
export const SALES_BY_CATEGORY_GET_SUCCESS = 'SALES_BY_CATEGORY_GET_SUCCESS'
export const SALES_BY_CATEGORY_GET_FAILURE = 'SALES_BY_CATEGORY_GET_FAILURE'

export const BARISTA_EFFICIENCY_GET_REQUEST = 'BARISTA_EFFICIENCY_GET_REQUEST'
export const BARISTA_EFFICIENCY_GET_SUCCESS = 'BARISTA_EFFICIENCY_GET_SUCCESS'
export const BARISTA_EFFICIENCY_GET_FAILURE = 'BARISTA_EFFICIENCY_GET_FAILURE'

export const VENDOR_COMPARISON_GET_REQUEST = 'VENDOR_COMPARISON_GET_REQUEST'
export const VENDOR_COMPARISON_GET_SUCCESS = 'VENDOR_COMPARISON_GET_SUCCESS'
export const VENDOR_COMPARISON_GET_FAILURE = 'VENDOR_COMPARISON_GET_FAILURE'

// CASH_TORNOVER
export const cashTurnoverGetRequest = (payload) => ({
  type: CASH_TURNOVER_GET_REQUEST,
  payload
})
  
export const cashTurnoverGetSuccess = (payload) => ({
  type: CASH_TURNOVER_GET_SUCCESS,
  payload
})
  
export const cashTurnoverGetFailure = (error) => ({
  type: CASH_TURNOVER_GET_FAILURE,
  error
})

// TOTAL_ORDERS
export const totalOrdersGetRequest = (payload) => ({
  type: TOTAL_ORDERS_GET_REQUEST,
  payload
})

export const totalOrdersGetSuccess = (payload) => ({
  type: TOTAL_ORDERS_GET_SUCCESS,
  payload
})
    
export const totalOrdersGetFailure = (error) => ({
  type: TOTAL_ORDERS_GET_FAILURE,
  error
})

// SALES_BY_CATEGORY
export const selesByCategoryGetRequest = (payload) => ({
  type: SALES_BY_CATEGORY_GET_REQUEST,
  payload
})
  
export const selesByCategoryGetSuccess = (payload) => ({
  type: SALES_BY_CATEGORY_GET_SUCCESS,
  payload
})
      
export const selesByCategoryGetFailure = (error) => ({
  type: SALES_BY_CATEGORY_GET_FAILURE,
  error
})

// BARISTA_EFFICIENCY
export const baristaEfficiencyGetRequest = (payload) => ({
  type: BARISTA_EFFICIENCY_GET_REQUEST,
  payload
})
  
export const baristaEfficiencyGetSuccess = (payload) => ({
  type: BARISTA_EFFICIENCY_GET_SUCCESS,
  payload
})
      
export const baristaEfficiencyGetFailure = (error) => ({
  type: BARISTA_EFFICIENCY_GET_FAILURE,
  error
})

// VENDOR_COMPARISON
export const vendorComparisonGetRequest = (payload) => ({
  type: VENDOR_COMPARISON_GET_REQUEST,
  payload
})
  
export const vendorComparisonGetSuccess = (payload) => ({
  type: VENDOR_COMPARISON_GET_SUCCESS,
  payload
})
      
export const vendorComparisonGetFailure = (error) => ({
  type: VENDOR_COMPARISON_GET_FAILURE,
  error
})


  
