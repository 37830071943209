import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Calendar, colors, Dropdown, RadioButton, Text } from '../UIkit'
import DateRangeDropdown from '../UIkit/Calendar/DateRangeDropdown'

const OrdersFilters = ({
  dateFilter,
  baristaFilter,
  baristasFilterList,
  baristasFetching,
  statusFilter,
  dateRangesList,
  handleChangeDate,
  handleChangeStatus,
  handleChangeBarista,
  handleResetFilters,
  handleApplyFilters,
}) => {
  const { t } = useTranslation()

  const isChecked = (value) => {
    return statusFilter === value
  }

  return (
    <>
      <div className='orders-filters__content'>
        <div className='orders-filters-scrollable'>
          <div className='orders-filters__block'>
            <Text shouldBeUppercase variant='body-tertiary1' color={colors.gray[500]}>
              {t('orders:timePeriod')}
            </Text>
            <DateRangeDropdown value={dateFilter} list={dateRangesList} onChange={handleChangeDate} />
            <Calendar className={'with-shadow'} date={dateFilter} onChange={handleChangeDate} />
          </div>
          <div className='orders-filters__block'>
            <Text shouldBeUppercase variant='body-tertiary1' color={colors.gray[500]}>
              {t('orders:barista')}
            </Text>
            <Dropdown
              value={baristaFilter}
              list={baristasFilterList}
              onChange={handleChangeBarista}
              loading={baristasFetching}
            />
          </div>
          <div className='orders-filters__block'>
            <Text shouldBeUppercase variant='body-tertiary1' color={colors.gray[500]}>
              {t('orders:orderStatus')}
            </Text>
            <RadioButton
              name='any-status'
              value=''
              label={t('orders:anyStatus')}
              isChecked={isChecked('')}
              handleChange={handleChangeStatus}
            />
            <RadioButton
              name='finished'
              value='finished'
              label={t('dashboard:finished')}
              isChecked={isChecked('finished')}
              handleChange={handleChangeStatus}
            />
            <RadioButton
              name='canceled'
              value='canceled'
              label={t('dashboard:canceled')}
              isChecked={isChecked('canceled')}
              handleChange={handleChangeStatus}
            />
            <RadioButton
              name='new'
              value='new'
              label={t('dashboard:new')}
              isChecked={isChecked('new')}
              handleChange={handleChangeStatus}
            />
            <RadioButton
              name='in_progress'
              value='in_progress'
              label={t('dashboard:in_progress')}
              isChecked={isChecked('in_progress')}
              handleChange={handleChangeStatus}
            />
            <RadioButton
              name='ready'
              value='ready'
              label={t('dashboard:ready')}
              isChecked={isChecked('ready')}
              handleChange={handleChangeStatus}
            />
            <RadioButton
              name='failed'
              value='failed'
              label={t('dashboard:failed')}
              isChecked={isChecked('failed')}
              handleChange={handleChangeStatus}
            />
          </div>
        </div>
      </div>
      <div className='orders-filters__buttons'>
        <Button variant='secondary' onClick={handleResetFilters}>
          {t('btn:reset')}
        </Button>
        <Button variant='primary' onClick={handleApplyFilters}>
          {t('btn:apply')}
        </Button>
      </div>
    </>
  )
}

export default OrdersFilters
