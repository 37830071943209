import {
  STAFF_OWNERS_FETCH_REQUEST,
  STAFF_OWNERS_FETCH_SUCCESS,
  STAFF_OWNERS_FETCH_FAILURE,
} from './actions'

const initialState = {
  data: [],
  fetching: true,
  error: null,
  pages: 1
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case STAFF_OWNERS_FETCH_REQUEST:
      return { ...state }
    case STAFF_OWNERS_FETCH_SUCCESS:
      return {
        ...state,
        data: payload,
        fetching: false,
        error: null,
        pages: payload.length ? payload[0].pages : 0,
      }
    case STAFF_OWNERS_FETCH_FAILURE:
      return { ...state, error: payload, fetching: false }
    default:
      return state
  }
}
