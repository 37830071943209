export const CHECK_CREDENTIALS_REQUEST = 'CHECK_CREDENTIALS_REQUEST'
export const CHECK_CREDENTIALS_SUCCESS = 'CHECK_CREDENTIALS_SUCCESS'
export const CHECK_CREDENTIALS_FAILURE = 'CHECK_CREDENTIALS_FAILURE'

export const checkCredentialsRequest = (payload) => ({
  type: CHECK_CREDENTIALS_REQUEST,
  payload
})

export const checkCredentialsSuccess = (payload) => ({
  type: CHECK_CREDENTIALS_SUCCESS,
  payload
})

export const checkCredentialsFailure = (error) => ({
  type: CHECK_CREDENTIALS_FAILURE,
  error
})
