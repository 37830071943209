export const STAFF_BARISTAS_FETCH_REQUEST = 'STAFF_BARISTAS_FETCH_REQUEST'
export const STAFF_BARISTAS_FETCH_SUCCESS = 'STAFF_BARISTAS_FETCH_SUCCESS'
export const STAFF_BARISTAS_FETCH_FAILURE = 'STAFF_BARISTAS_FETCH_FAILURE'
export const STAFF_BARISTAS_CLEAR = 'STAFF_BARISTAS_CLEAR'

export const staffBaristasFetchRequest = (payload) => ({
  type: STAFF_BARISTAS_FETCH_REQUEST,
  payload
})

export const staffBaristasFetchSuccess = (payload) => ({
  type: STAFF_BARISTAS_FETCH_SUCCESS,
  payload,
})

export const staffBaristasFetchFailure = (error) => ({
  type: STAFF_BARISTAS_FETCH_FAILURE,
  error,
})

export const staffBaristasClear = () => ({
  type: STAFF_BARISTAS_CLEAR
})
