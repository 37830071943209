import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'

import { Button, colors, Dropdown, Input, Modal, Text } from '../../UIkit'
import { LoadingDots, SavedCircle, Trash } from '../../UIkit/svgs'
import notifications from '../../../lib/notifications'
import { Validator, formatPhoneNumber } from '../../../lib'
import { parseUah } from '../../../lib/currencyParser'
import formatedPhoneNumber from '../../../lib/formatedPhoneNumber'
import './styles.scss'

const EditProfile = ({ deleteAdminProfile, updateProfile, profile, updating }) => {
  const { t } = useTranslation()

  const [form, setForm] = useState({
    firstName: profile?.profile?.first_name,
    lastName: profile?.profile?.last_name,
    email: profile?.email,
    phoneNumber: profile?.phone_number,
    language: profile?.profile?.language,
  })

  const [formErrors, setFormErrors] = useState({})
  const [saved, setSaved] = useState(false)
  const [updatingProfile, setUpdatingProfile] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const network = useSelector((state) => state.networks.network)
  const userBalanceExists = network.balance > 0

  const { i18n } = useTranslation()

  const handleSetForm = (field, value) => {
    setForm((prev) => ({
      ...prev,
      [field]: field == 'phoneNumber' ? formatPhoneNumber(value) : value,
    }))
  }

  useEffect(() => {
    updatingProfile && !updating && setSaved(true)

    const timer = setTimeout(() => {
      updatingProfile && !updating && setSaved(false)
    }, 3000)
    return () => clearTimeout(timer)
  }, [updatingProfile, updating])

  const onUpdateField = ({ target }) => {
    handleSetForm(target.name, target.value)
  }

  const handleValidate = () =>
    new Promise((resolve, reject) => {
      const errors = Validator.validate([
        ['firstName', Validator.concepts.isFilled, [form.firstName]],
        ['lastName', Validator.concepts.isFilled, [form.lastName]],
        ['email', Validator.concepts.emailRegex, [form.email]],
        ['phoneNumber', Validator.concepts.phoneRegex, [formatedPhoneNumber(form.phoneNumber)]],
        ['language', Validator.concepts.isFilled, [form.language]],
      ])

      Validator.clear()
      setFormErrors(errors)

      isEmpty(errors) ? resolve() : reject(errors)
    })

  const handleSubmit = () => {

    const data = {
      owner: {
        email: form.email,
        phoneNumber: form.phoneNumber,
        profileAttributes: {
          firstName: form.firstName,
          lastName: form.lastName,
          language: form.language,
        },
        setting_attributes: {
          localization: form.language,
        },
      },
    }

    handleValidate().then(() => {
      data.owner.email !== profile?.email && notifications.createNotification('email-confirmation-required')
      updateProfile(data)
      setUpdatingProfile(true)
      i18n.changeLanguage(form.language)
      localStorage.setItem('i18nextLng', form.language || 'uk')
    })
  }

  const handleOpenModal = () => {
    setDeleteModalOpen(true)
  }

  const handleCloseModal = () => {
    setDeleteModalOpen(false)
  }

  const handleDeleteAdminProfile = () => {
    deleteAdminProfile()
  }

  const languages = [
    { value: profile?.profile?.available_languages[0], label: t('settings:editProfile:ukrainian') },
    { value: profile?.profile?.available_languages[1], label: t('settings:editProfile:english') },
  ]

  const renderModal = () => (
    <Modal 
      modaltitle={t('settings:editProfile:deleteProfile')} 
      show={deleteModalOpen} 
      handleClose={() => setDeleteModalOpen(false)} withoutExit>
      <div className='delete-profile'>
        {!isMobile &&        
          <div className='delete-profile__title'>
            <Text variant='subheading-bold'>{t('profile:delete')}</Text>
          </div>
        }
        <div className='delete-profile__description'>
          <Text variant='body-secondary1' color={!userBalanceExists ? colors.gray[400] : colors.alerts.error}>
            {`${!userBalanceExists ? t('deleteInfo:description') : t('deleteInfo:balanceInfo')} ₴ ${parseUah(
              network?.balance
            )} `}
          </Text>
        </div>
        <div className='delete-profile__buttons'>
          <Button variant='secondary' onClick={handleCloseModal}>
            {t('btn:cancel')}
          </Button>
          <Button onClick={handleDeleteAdminProfile}>{t('btn:deleteProfile')}</Button>
        </div>
      </div>
    </Modal>
  )

  return (
    <>
      {renderModal()}
      <div className='edit_profile'>
        {!isMobile &&   
          <Text variant='body-tertiary1' color={colors.gray[500]} shouldBeUppercase>
            {t('settings:editProfile:profileInfo')}
          </Text>
        }
        <div className='edit_profile__info'>
          <Input
            id='first-name'
            name='firstName'
            placeholder={t('input:firstName')}
            value={form.firstName}
            onChange={onUpdateField}
            error={formErrors.firstName}
          />
          <Input
            id='last-name'
            name='lastName'
            placeholder={t('input:secondName')}
            value={form.lastName}
            onChange={onUpdateField}
            error={formErrors.lastName}
          />
          <Input
            id='email'
            name='email'
            placeholder={t('input:email')}
            value={form.email}
            onChange={onUpdateField}
            error={formErrors.email}
          />
          <Input
            id='phoneNumber'
            type='tel'
            value={form.phoneNumber}
            name='phoneNumber'
            onChange={onUpdateField}
            placeholder={t('input:phone')}
            error={formErrors.phoneNumber}
          />
        </div>
        <Text className='edit_profile__language-dropdown' wrapper variant='body-main1'>
          <Dropdown
            placeholder={t('input:language')}
            value={form.language}
            onChange={(value) => handleSetForm('language', value)}
            list={languages}
          />
        </Text>
        <div className='edit_profile__save-wrapper'>
          {!isMobile &&  
            <div className='edit_profile__save-button-wrapper'>
              <Button
                disabled={updating}
                onClick={handleSubmit}
                variant='primary'
                size='small'
                style={{ width: '160px', height: '48px' }}
              >
                {t('btn:save')}
              </Button>
            </div>
          }
          <div className='edit_profile__save-button-wrapper'>
            <Button
              onClick={handleOpenModal}
              variant='tertiary'
              size='small'
              style={{ width: !isMobile ? '160px' : '175px', height: '48px' }}
            >
              <Trash />
              {t('btn:deleteProfile')}
            </Button>
          </div>
          {updating && (
            <div className='edit_profile__loading-wrapper'>
              <LoadingDots className='edit_profile__loading-dots' />
              <Text variant='body-main1'>{t('actions:saving')}</Text>
            </div>
          )}
          {saved && (
            <div className='edit_profile__saved-wrapper'>
              <SavedCircle className='edit_profile__saved-icon' />
              <Text variant='body-main1'>{t('actions:saved')}</Text>
            </div>
          )}
        </div>
        {isMobile &&  
            <div className='edit_profile__save-button-wrapper'>
              <Button
                disabled={updating}
                onClick={handleSubmit}
                variant='primary'
                size='small'
                style={{ width: '160px', height: '48px' }}
              >
                {t('btn:save')}
              </Button>
            </div>
        }
      </div>
    </>
  )
}

export default EditProfile
