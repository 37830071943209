import { takeLatest, call, put } from 'redux-saga/effects'
import * as actions from './actions'

import Api from '../../lib/api'
import { toCamelCase } from '../../lib/converter'

import notifications from '../../lib/notifications'

export default function* watcherSaga() {
  yield takeLatest(actions.VENDORS_GET_REQUEST, watchResourcesGetRequest)
  yield takeLatest(actions.VENDOR_CREATE_REQUEST, watchResourceCreateRequest)
  yield takeLatest(actions.VENDOR_DELETE_REQUEST, watchResourceDeleteRequest)
  yield takeLatest(actions.VENDOR_GET_REQUEST, watchResourceGetRequest)
  yield takeLatest(actions.VENDOR_UPDATE_REQUEST, watchResourceUpdateRequest)
  yield takeLatest(actions.VENDORS_CITIES_GET_REQUEST, watchResourcesCitiesGetRequest)
  yield takeLatest(actions.VENDOR_RATING_GET_REQUEST, watchResourcesRatingGetRequest)
  yield takeLatest(actions.VENDOR_FEEDBACKS_GET_REQUEST, watchResourcesFeedbacksGetRequest)

}

function* watchResourcesGetRequest({ payload }) {
  try {
    const response = yield call(Api.Vendors.get, payload.networkId, payload.params)

    yield put(actions.vendorsGetSuccess(toCamelCase(response.data)))
  } catch (error) {
    yield put(actions.vendorsGetFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}

function* watchResourceCreateRequest({ payload, callback }) {
  try {
    const response = yield call(Api.Vendor.create, payload.networkId, payload.vendor)
    const parsedData = toCamelCase(response.data)

    yield put(actions.vendorCreateSuccess(parsedData))
    if (parsedData.liqpayAccountConfirmToken?.length > 1) {
      localStorage.setItem('confirmToken', parsedData.liqpayAccountConfirmToken)
      callback()
    }
    notifications.createNotification('create-success')
  } catch (error) {
    yield put(actions.vendorCreateFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}

function* watchResourceDeleteRequest({ payload }) {
  try {
    yield call(Api.Vendor.delete, payload.networkId, payload.id)

    yield put(actions.vendorDeleteSuccess(payload.id))
    yield put(actions.vendorsCitiesGetRequest(payload.networkId))
    notifications.createNotification('delete-success')
  } catch (error) {
    yield put(actions.vendorDeleteFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}

function* watchResourceGetRequest({ payload }) {
  try {
    const response = yield call(Api.Vendor.get, payload.networkId, payload.id)

    yield put(actions.vendorGetSuccess(toCamelCase(response.data)))
  } catch (error) {
    yield put(actions.vendorGetFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}

function* watchResourceUpdateRequest({ payload }) {
  try {
    const response = yield call(Api.Vendor.update, payload.networkId, payload.id, payload.vendor)

    yield put(actions.vendorUpdateSuccess(toCamelCase(response.data)))
    notifications.createNotification('edit-success')
  } catch (error) {
    yield put(actions.vendorUpdateFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}

function* watchResourcesCitiesGetRequest({ payload }) {
  try {
    const response = yield call(Api.VendorsCities.get, payload)

    yield put(actions.vendorsCitiesGetSuccess(response.data))
  } catch (error) {
    yield put(actions.vendorsCitiesGetFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}

function* watchResourcesRatingGetRequest({ payload }) {
  try {
    const response = yield call(Api.Vendor.Rating.get, payload.networkId, payload.id)

    yield put(actions.vendorRatingGetSuccess(toCamelCase(response.data)))
  } catch (error) {
    yield put(actions.vendorRatingGetFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}


function* watchResourcesFeedbacksGetRequest({ payload }) {
  try {
    const response = yield call(Api.Vendor.Feedbacks.get, payload.networkId, payload.id)

    yield put(actions.vendorFeedbacksGetSuccess(toCamelCase(response.data)))
  } catch (error) {
    yield put(actions.vendorFeedbacksGetFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}
