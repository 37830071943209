export const SUBSCRIPTIONS_GET_REQUEST = 'SUBSCRIPTIONS_GET_REQUEST'
export const SUBSCRIPTIONS_GET_SUCCESS = 'SUBSCRIPTIONS_GET_SUCCESS'
export const SUBSCRIPTIONS_GET_FAILURE = 'SUBSCRIPTIONS_GET_FAILURE'
export const SUBSCRIPTION_SUBSCRIBE_REQUEST = 'SUBSCRIPTION_SUBSCRIBE_REQUEST'
export const SUBSCRIPTION_SUBSCRIBE_SUCCESS = 'SUBSCRIPTION_SUBSCRIBE_SUCCESS'
export const SUBSCRIPTION_SUBSCRIBE_FAILURE = 'SUBSCRIPTION_SUBSCRIBE_FAILURE'
export const SUBSCRIPTION_UNSUBSCRIBE_REQUEST = 'SUBSCRIPTION_UNSUBSCRIBE_REQUEST'
export const SUBSCRIPTION_UNSUBSCRIBE_SUCCESS = 'SUBSCRIPTION_UNSUBSCRIBE_SUCCESS'
export const SUBSCRIPTION_UNSUBSCRIBE_FAILURE = 'SUBSCRIPTION_UNSUBSCRIBE_FAILURE'

export const subscriptionsGetRequest = (payload) => ({
  type: SUBSCRIPTIONS_GET_REQUEST,
  payload,
})

export const subscriptionsGetSuccess = (payload) => ({
  type: SUBSCRIPTIONS_GET_SUCCESS,
  payload,
})

export const subscriptionsGetFailure = (error) => ({
  type: SUBSCRIPTIONS_GET_FAILURE,
  error,
})

export const subscriptionSubscribeRequest = (payload) => ({
  type: SUBSCRIPTION_SUBSCRIBE_REQUEST,
  payload,
})

export const subscriptionSubscribeSuccess = (payload) => ({
  type: SUBSCRIPTION_SUBSCRIBE_SUCCESS,
  payload,
})

export const subscriptionSubscribeFailure = (error) => ({
  type: SUBSCRIPTION_SUBSCRIBE_FAILURE,
  error,
})

export const subscriptionUnsubscribeRequest = (payload) => ({
  type: SUBSCRIPTION_UNSUBSCRIBE_REQUEST,
  payload,
})

export const subscriptionUnsubscribeSuccess = (payload) => ({
  type: SUBSCRIPTION_UNSUBSCRIBE_SUCCESS,
  payload,
})

export const subscriptionUnsubscribeFailure = (error) => ({
  type: SUBSCRIPTION_UNSUBSCRIBE_FAILURE,
  error,
})
