import React from 'react'
import PropTypes from 'prop-types'
import colors from '../colors'

import './styles.scss'

const Loader = ({ size, color, inBlock }) => {

  const borderColor = `transparent ${color} ${color} ${color}`
  return (
    <div style={{ width: size, height: size }} className={`loader${inBlock ? '__block' : ''}`}>
      <div style={{ width: size, height: size, borderColor: borderColor }} className='loader__circle'></div>
    </div>
  )
}

Loader.defaultProps = {
  inBlock: false,
  color: colors.gray[100],
}

Loader.propTypes = {
  size: PropTypes.number,
}

export default Loader
