import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Text, BoardCard, colors, SmallSwitch } from '../../../../UIkit'
import { Edit, Trash } from '../../../../UIkit/svgs'
import './styles.scss'

const MenuBoardProductComponent = ({
  product,
  vendorFilter,
  updateProduct,
  handleEditProductModal,
  handleDeleteProductModal,
}) => {
  const { t } = useTranslation()

  const { id, image, name, options, vendorsAvailability } = product

  const vendorAvailability = vendorsAvailability.find((a) => a.vendorId === vendorFilter)

  const handleEdit = () => {
    handleEditProductModal(id)
  }

  const handleDelete = () => {
    handleDeleteProductModal(id)
  }

  const handleChangeAvailability = () => {
    const nextStatus = vendorAvailability?.status === 'active' ? 'inactive' : 'active'

    const data = {
      networkProduct: {
        vendorProductsAttributes: {
          id: vendorAvailability?.id,
          status: nextStatus,
        },
      },
    }

    updateProduct(id, data)
  }

  const isAvailable = (vendorAvailability) => vendorAvailability?.status === 'active'

  const unit = options?.length ? options[0].unit : ''
  const sizes = options?.map((o) => o.size)?.join(' / ') || []
  const prices = options?.map((o) => o.price)?.join(' / ') || []

  return (
    <BoardCard>
      <div className='menuProducts__productProps'>
        <div style={{ backgroundImage: `url(${image?.url})` }} className='menuProducts__productImage' />
        <div className='menuProducts__productInfo'>
          <div className='menuProducts__productName'>
            <Text variant='body-main2'>{name}</Text>
          </div>
          <div className='menuProducts__productSize'>
            <Text variant='body-secondary1'>
              {sizes} {unit}
            </Text>
          </div>
          <div className='menuProducts__productPrice'>
            <Text shouldBeUppercase variant='body-secondary2'>
              {prices} {t('menu:uah')}
            </Text>
          </div>
        </div>
      </div>
      {vendorFilter ? (
        <div className='menuProducts__productAvailability'>
          <Text variant={'body-tertiary1'}>{t('menu:availability')}</Text>
          <SmallSwitch
            index={id}
            name={name}
            checked={isAvailable(vendorAvailability)}
            onChange={handleChangeAvailability}
          />
        </div>
      ) : (
        <div className='menuProducts__productTools'>
          <Edit color={colors.gray[500]} onClick={handleEdit} style={{ cursor: 'pointer' }} />
          <Trash color={colors.gray[500]} onClick={handleDelete} style={{ cursor: 'pointer' }} />
        </div>
      )}
    </BoardCard>
  )
}

MenuBoardProductComponent.propTypes = {
  product: PropTypes.object,
  vendorFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  updateProduct: PropTypes.func,
  openEditProduct: PropTypes.func,
  openDeleteProduct: PropTypes.func,
}

export default MenuBoardProductComponent
