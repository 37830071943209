import React from 'react'
import { useState } from 'react'
import { Search, Erase, Shown, Hidden, Error } from '../svgs'
import Text from '../Text'
import colors from '../colors'
import './styles.scss'

export const SearchInput = ({ value, onChange, ...props }) => {
  const [showErase, setShowErase] = useState(false)
  const [focused, setFocused] = useState(false)

  const handleChange = (search) => {
    onChange(search)
    setShowErase(!!search)
  }

  const clearInput = () => {
    onChange('')
    setShowErase(false)
  }

  const style = {
    boxShadow: focused ? '0px 2px 4px rgba(88, 79, 99, 0.08), 0px 4px 8px rgba(88, 79, 99, 0.08)' : 'none',
  }

  return (
    <div className='search-input' style={style}>
      <Search />
      <input
        {...props}
        type='search'
        value={value}
        autoComplete='nope'
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onChange={(e) => handleChange(e.target.value)}
      />
      {showErase && <Erase onClick={() => clearInput()} />}
    </div>
  )
}

export const Checkbox = ({ label, checked, onChange, className, ...props }) => {
  return (
    <div className={`default-checkbox ${className}`}>
      <div className='default-checkbox__item'>
        <input checked={checked} onChange={(e) => onChange(e)} type='checkbox' {...props} />
        <label className='default-checkbox__label' htmlFor={props.id || props.name}>
          <Text variant='body-main1'>{label}</Text>
        </label>
      </div>
    </div>
  )
}

export const PromocodeInput = ({ value, onChange, ...props }) => {
  return <input {...props} value={value} onChange={(e) => onChange(e.target.value)} />
}

export const PasswordInput = ({ error, placeholder, value, onChange, ...props }) => {
  const [showPassword, setShowPassword] = useState(false)
  const [focused, setFocused] = useState(false)

  const handleKeyDown = (e) => {
    if (e.key === ' ') {
      e.preventDefault()
    }
  }

  const inputStyle = {
    borderColor: !!error && colors.alerts.error,
  }

  const placeholderStyle = { color: !!error && colors.alerts.error }

  return (
    <div className={`password-input ${focused && 'with-input-shadow'}`} style={inputStyle}>
      <input
        {...props}
        type={showPassword ? 'text' : 'password'}
        autoComplete='new-password'
        value={value}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onKeyDown={(e) => handleKeyDown(e)}
        onChange={(e) => onChange(e)}
      />
      <span className='password-input__placeholder' style={placeholderStyle}>
        {placeholder}
      </span>
      {showPassword ? (
        <Hidden onClick={() => setShowPassword((prev) => !prev)} />
      ) : (
        <Shown
          color={error ? colors.alerts.error : colors.gray[400]}
          onClick={() => setShowPassword((prev) => !prev)}
        />
      )}
      {!!error && (
        <div className='password-input__error'>
          <Text variant='body-tertiary1' color={colors.alerts.error}>
            {error}
          </Text>
        </div>
      )}
    </div>
  )
}

export const Input = ({ type = 'text', error, placeholder, value, onBlurCallback, onChange, ...props }) => {
  const [focused, setFocused] = useState(false)

  const inputStyle = {
    borderColor: !!error && colors.alerts.error,
  }

  const placeholderStyle = { color: !!error && colors.alerts.error }

  return (
    <div className={`default-input ${focused && 'with-input-shadow'}`} style={inputStyle}>
      <input
        {...props}
        type={type}
        value={value}
        onFocus={() => setFocused(true)}
        onBlur={() => {
          setFocused(false)
          onBlurCallback()
        }}
        onChange={(e) => onChange(e)}
      />
      <span className='default-input__placeholder' style={placeholderStyle}>
        {placeholder}
      </span>

      {!!error && <Error />}

      {!!error && (
        <div className='default-input__error'>
          <Text variant='body-tertiary1' color={colors.alerts.error}>
            {error}
          </Text>
        </div>
      )}
    </div>
  )
}

export const TextArea = ({ rows = 3, error, placeholder, value, onChange, ...props }) => {
  const [focused, setFocused] = useState(false)

  const inputStyle = {
    borderColor: !!error && colors.alerts.error,
  }

  const placeholderStyle = { color: !!error && colors.alerts.error }

  return (
    <div className={`default-textarea ${focused && 'with-input-shadow'}`} style={inputStyle}>
      <textarea
        {...props}
        rows={rows}
        value={value}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onChange={(e) => onChange(e)}
      />
      <span
        className={`default-input__placeholder ${value !== '' && 'default-textarea__placeholder'}`}
        style={placeholderStyle}
      >
        {placeholder}
      </span>
      {!!error && (
        <div className='default-input__error'>
          <Text variant='body-tertiary1' color={colors.alerts.error}>
            {error}
          </Text>
        </div>
      )}
    </div>
  )
}
