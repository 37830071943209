import React from 'react'
import PropTypes from 'prop-types'

import { Board } from '../../../UIkit'
import CategoryComponent from './Category/CategoryComponent'

const MenuProductBoardComponent = ({
  vendorFilter,
  itsSupplements,
  categories,
  products,
  supplements,
  fetchProducts,
  updateProduct,
  fetchSupplements,
  updateSupplement,
  handleRemoveCategoryModal,
  handleCreateProductModal,
  handleEditProductModal,
  handleDeleteProductModal,
  handleCreateSupplementModal,
  handleEditSupplementModal,
  handleDeleteSupplementModal,
}) => {
  return (
    <Board>
      {categories.map((category) => (
        <CategoryComponent
          key={category.id}
          vendorFilter={vendorFilter}
          itsSupplements={itsSupplements}
          category={category}
          products={products}
          supplements={supplements}
          fetchProducts={fetchProducts}
          updateProduct={updateProduct}
          fetchSupplements={fetchSupplements}
          updateSupplement={updateSupplement}
          handleCreateProductModal={handleCreateProductModal}
          handleEditProductModal={handleEditProductModal}
          handleRemoveCategoryModal={handleRemoveCategoryModal}
          handleDeleteProductModal={handleDeleteProductModal}
          handleCreateSupplementModal={handleCreateSupplementModal}
          handleEditSupplementModal={handleEditSupplementModal}
          handleDeleteSupplementModal={handleDeleteSupplementModal}
        />
      ))}
    </Board>
  )
}

MenuProductBoardComponent.propTypes = {
  vendorFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  itsSupplements: PropTypes.bool,
  categories: PropTypes.array,
  products: PropTypes.object,
  supplements: PropTypes.object,
  fetchProducts: PropTypes.func,
  updateProduct: PropTypes.func,
  fetchSupplements: PropTypes.func,
  updateSupplement: PropTypes.func,
  handleRemoveCategoryModal: PropTypes.func,
  handleCreateProductModal: PropTypes.func,
  handleEditProductModal: PropTypes.func,
  handleDeleteProductModal: PropTypes.func,
  handleCreateSupplementModal: PropTypes.func,
  handleEditSupplementModal: PropTypes.func,
  handleDeleteSupplementModal: PropTypes.func,
}

export default MenuProductBoardComponent
