import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

import { Sections } from './'
import { Text } from '../UIkit'

const Settings = ({ activeSection, setActiveSection, renderSection, setOpen, renderMobileSection }) => {
  const { t } = useTranslation()

  return (
    <div className='settings'>
      <div className='settings__header'>
        <Text variant='h3-medium'>{t('settings:title')}</Text>
      </div>
      <div className='settings__content'>
        <Sections activeSection={activeSection} setActiveSection={setActiveSection} setOpen={setOpen} />
        {!isMobile ? renderSection() : renderMobileSection()}
      </div>
    </div>
  )
}

Settings.propTypes = {
  activeSection: PropTypes.string.isRequired,
  setActiveSection: PropTypes.func.isRequired,
  renderSection: PropTypes.func.isRequired,
}

export default Settings
