/* eslint-disable */

import React, { Component } from 'react'
import { Footer, Header } from '../../components/Landing/Sections'

import './styles.css'

class TermsContainer extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <Header />
        <div className='container'>
          <br />
          <div>
            <strong><a href="#loyalty">Умови програми лояльності Pinka.coffee</a></strong>
          </div>
          <br />
          <strong id="terms">Загальні умови користування та співпраці (публічна оферта)</strong>
          <div>
            <br />
            Ці Загальні умови користування та юридична інформація (надалі — “Загальні умови”)
            застосовуються до веб-сайту Pinka.coffee. (надалі — “Pinka”), доменом якого є  <a href='https://pinka.coffee/'>https://pinka.coffee/</a>
            і пов'язаного з ним мобільному додатку, а також всіх пов'язаних із ним сайтів або платформ, на які можна перейти за посиланням, опублікованим Pinka.
            Цей веб-сайт є власністю компанії Корпань Є.О. Використовуючи сайт, ви тим самим приймаєте ці умови та погоджуєтеся з ними. Якщо ви не згодні з цими
          </div>
          <br />
          <div>
            Цим документом Pinka надає користувачам (надалі — “Користувач” або “Користувачі”)
            доступ до веб-сайту та мобільного додатку Pinka (надалі разом іменуються — “Платформа”).
          </div>
          <br />
          <div>
          Відповідно до положень чинного законодавства нижче наведено ідентифікаційні дані компанії-власника веб-сайту:
          </div>
          <br />
          <div>
            • Назва компанії: <strong>ФОП “Корпань Євгеній Олександрович”</strong>
          </div>
          <div>
            • Юридична адреса: <strong>Припортова 42/1, Черкаси, 18000, Україна</strong>
          </div>
          <div>
            • Дані про запис у комерційному реєстрі:
            <strong> 20170000000011799.</strong>
          </div>
          <div>
            • Ідентифікаційний номер платника податків (NIF): <strong>3166017811</strong>
          </div>
          <br />
          <strong>Тлумачення Термінів</strong>
          <div>
            <br />
            Для цілей цих Загальних умов та для уникнення непорозумінь у спірних ситуацій у
            майбутньому, нижче наведені визначення понять, що використовуються у цих Загальних умовах.
          </div>
          <br />
          <div>
            <strong>Сервіс Pinka.coffee</strong> – веб-сайт, та мобільні додатки що розміщені за адресою:
            <a href='https://pinka.coffee/'> https://pinka.coffee/</a>
          </div>
          <br />
          <div>
          <strong>Товар</strong> – усі готові страви, включаючи кавові напої, та інші страви, які продають представники
          </div>
          <br />
          <div>
          <strong>Мобільний додаток</strong> – комп’ютерна програма у вигляді онлайн-сервісу, за допомогою якої
          здійснюється доступ до Платформи та яка дозволяє використовувати функціонал Платформи щодо доступу до інформації про товари або послуги Партнерів, розміщення Замовлень.
          </div>
          <br />
          <div>
            <strong>Платформа</strong> – технологічна платформа, власником якої є компанія Корпань Є.О.,
            що дозволяє за допомогою Мобільного додатку пропонувати або розміщувати інформацію про свої
            товари з метою подальшого їх продажу Користувачам, а Користувачам дає можливість здійснювати
            пошук відповідних товарів або послуг та розміщення Замовлень щодо таких товарів або послуг.
          </div>
          <br />
          <div>
            <strong>Замовлення</strong> – розміщене Користувачем за допомогою Платформи
            (з використанням Мобільного додатку) доручення щодо придбання певних товарів.
          </div>
          <br />
          <div>
            <strong>Партнер</strong> – суб’єкт господарювання, який за допомогою Платформи
            розміщує інформацію про свої товари або послуги з метою їх подальшого продажу Користувачам.
          </div>
          <br />
          <div>
            <strong>Користувач</strong> – будь-яка фізична особа, яка досягла 18 років, має повну цивільну
            правоздатність та дієздатність та є зареєстрована на Платформі (за допомогою Мобільного додатку або Веб-сайту).
          </div>
          <br />
          <div>
            <strong>Бариста</strong> – зареєстрована у Мобільному додатку фізична особа-підприємець,
            яка наймана Партнером у мережу чи окремий заклад (надалі - “Кав’ярня”) для виконання
            замовлень та відповідальна за віддачу замовлень Користувачам.
          </div>
          <br />
          <div>
          <strong>Послуги Партнерів</strong> – інформаційно-консультаційні послуги, що надаються Партнером або Виконавцем.
          </div>
          <br />
          <div>
            <strong>Послуги Pinka</strong> – послуги щодо надання Користувачам доступу до Платформи.
          </div>
          <br />
          <div>
            <strong>Компанія ФОП Корпань Є.О.</strong> – технологічна компанія, основною діяльністю якої є
            розробка та управління Платформою, а також, Сервісом та Мобільним додатком.
          </div>
          <br />
          <div>
            <strong>Фізична особа-підприємець</strong> – Корпань Є.О.
          </div>
          <br />
          <div>
            <strong>Код ЄДРПОУ</strong> – 3166017811
          </div>
          <br />
          <strong>1. Предмет Договору</strong>
          <div>
            <br />
            Компанія Корпань Є.О. — технологічна компанія, основною діяльністю якої є розробка й управління технологічною платформою,
            яка дозволяє кав'ярням у деяких регіонах пропонувати свої продукти та/або послуги за допомогою мобільного або веб-додатка
            (надалі — “ДОДАТОК”). Крім того, в разі розміщення відповідного запиту в ДОДАТКУ користувачами ДОДАТКА та клієнтами відповідних
            місцевих закладів, Pinka.coffee як сервіс компнії Корпань Є.О виступає в ролі посередника для здійснення показу товарів та
            пропонує можливість швидкого розміщення та отримання замовлення.
          </div>
          <br />
          <div>
          Корпань Є.О. розробила сервіс Pinka.coffee, який включає в себе додаток для користувачів та баристів і платформу для партнерів,
            за допомогою якої різні продавці, з якими Корпань Є.О. може укласти комерційну угоду на використання Платформи, рекламують до
            продажу різноманітні продукти та/або послуги. Користувачі можуть розмістити запит на покупку й/або отримання продуктів і послуг
            у таких продавців особисто за допомогою доручення, наданого ними третій особі при розміщенні замовлення через мобільний додаток.
            У таких випадках Корпань Є.О виступає в ролі звичайного посередника і тому не може брати та не бере на себе жодної відповідальності
            за якість продуктів або за належне надання послуг, які пропонуються безпосередньо продавцями або такими третіми особами.
            Користуючись Платформою та Мобільним додатком Користувачі визнають, що Pinka.coffee не надає логістичні послуги, послуги з постачання
            будь-яких товарів, медичні та пов'язані з ними інформаційно-консультаційні послуги, не виконує роль транспортної компанії,
            не здійснює виконання Замовлень Користувачів, а такі логістичні послуги, послуги з постачання товарів, медичні та пов'язані з
            ними інформаційно-консультаційні послуги, здійснюються незалежними третіми особами, які не є співробітниками компанії Корпань Є.О. чи її афілійованих осіб.
          </div>
          <br />
          <div>
          Крім того, сервіс Pinka.coffee — це мультикатегорійна технологічна Платформа, яка виконує роль посередника в наданні підряду на послуги “за запитом” з
            використанням дистанційних електронних засобів зв'язку. Її мета — забезпечити осіб дистанційним замовлення товарів та полегшити отримання замовлень в
            зазначеному місці (надалі — “Користувачі”), можливість делегування таких завдань третім особам, які були наймані партнерами для виготовлення замовлень (надалі — “Баристи”).
          </div>
          <br />
          <div>
            Крім того, сервіс Pinka.coffee — це мультикатегорійна технологічна Платформа, яка виконує роль посередника в
            наданні підряду на послуги “за запитом” з
            використанням дистанційних електронних засобів зв'язку. Її мета — забезпечити осіб дистанційним замовлення
            товарів та полегшити отримання замовлень в
            зазначеному місці (надалі — “Користувачі”), можливість делегування таких завдань третім особам, які були
            наймані партнерами для виготовлення замовлень (надалі — “Баристи”).
          </div>
          <br />
          <div>
          Таким чином, Баристи представляють собою найманого співробітника, який працює на Партнера, що представляє заклад або мережу закладів (надалі — “Кав’ярні”).
            Коли Партнер вважає їх готовими виготовляти та віддавати замовлення Користувачам, вони входять у свій обліковий запис використовуючи посилання надіслане
            Партнерам та отримують доступ до додатку для Барист та зобов’язуються протягом певного періоду часу надати послугу, доручену їм Користувачами за допомогою
            вищезгаданого додатку. Як наслідок, сервіс Pinka.coffee не може нести відповідальність за терміни виготовлення замовлення, оскільки вони залежать від успішності
            роботи місцевих закладів, процесу приготування замовлень Баристами і інформації, наданої Користувачами при розміщенні замовлень, або доступності та реагування
          </div>
          <br />
          <div>
          Сервіс Pinka.coffee має право залучати третіх осіб, в тому числі посередників (агентів) для виконання своїх зобовʼязань перед Користувачами.
          </div>
          <br />
          <strong>2. Доступ і реєстрація Користувачів</strong>
          <div>
            <br />
            Для того, щоб стати Користувачем Платформи, ви повинні відповідати таким вимогам:
          </div>
          <br />
          <div>
            <strong>•</strong> Користувачі повинні надати правдиву інформацію в обов'язкових полях реєстраційної форми,
            де потрібно вказати особисті дані, такі як ім'я користувача, адресу електронної пошти, номер телефону;
          </div>
          <div>
            <strong>•</strong> Користувачі повинні погодитися з цими Умовами користування та співпраці;
          </div>
          <br />
          <div>
          Користувач гарантує, що вся інформація щодо його особистості та дієздатності, вказана у формах реєстрації на Платформі,
            є правдивою, точною та вичерпною. Крім того, Користувачі зобов'язуються підтримувати актуальність своїх даних.
            Якщо Користувач надає неправдиву, неточну або неповну інформацію або якщо Pinka.coffee обґрунтовано сумнівається в правдивості,
            точності або цілісності такої інформації, Pinka.coffee може відмовити цьому Користувачеві в поточному або майбутньому доступі до
            Платформи або будь-якого контенту та/або послуг і їх використанні.
          </div>
          <br />
          <div>
          Під час реєстрації на Платформі Користувачі повинні вибрати ім’я користувача та пароль. І ім'я користувача, і пароль є суворо конфіденційними,
            персональними та не підлягають передачі. З метою підвищення безпеки облікових записів Pinka.coffee рекомендує Користувачам не використовувати
            ті самі облікові дані, що й на інших платформах. Якщо Користувач використовує ті самі дані для входу, що й на інших платформах, Pinka.coffee
            не зможе гарантувати безпеку облікового запису або гарантувати, що Користувач є єдиною особою, яка здійснює вхід до профілю.
          </div>
          <br />
          <div>
          Користувачі зобов'язуються не розкривати дані свого облікового запису і не дозволяти доступ до нього третім особам.
            Користувачі несуть повну відповідальність за будь-яке використання таких даних або сервісів Сайту третіми особами,
            включаючи заяви й/або контент, опубліковані на Платформі, або за будь-які інші дії з використанням їх імені користувача й/або чи пароля
          </div>
          <br />
          <div>
          Pinka.coffee не може гарантувати особистість зареєстрованих користувачів і, отже, не несе відповідальності за використання особистості
            зареєстрованого Користувача незареєстрованими третіми особами. Користувачі зобов'язуються негайно повідомити Pinka.coffee,
            використовуючи канали зв'язку, надані Pinka.coffee, в разі, якщо їх облікові дані вкрадені, розголошені або загублені.
          </div>
          <br />
          <strong>3. Профіль</strong>
          <div>
            <br />
            Щоб завершити реєстрацію на Платформі, Користувачі повинні надати певні дані, включаючи ім'я користувача,
            адресу електронної пошти, номер телефону та дані банківської картки.
            Після завершення процесу реєстрації всі Користувачі отримають доступ до свого профілю,
            а також зможуть заповнювати та редагувати його на власний розсуд. Pinka.coffee не зберігає платіжні дані
            Користувачів — їхня обробка та зберігання здійснюється постачальником платіжних послуг,
            як зазначено в цих Умовах і в Політиці конфіденційності.
          </div>
          <br />
          <strong>4. Порядок укладення Договору та оформлення Замовлення</strong>
          <div>
            <br />
            <strong>4.1</strong> Користувач може розмістити через Платформу запит на особисту покупку ряду продуктів
            і/або послуг, запропонованих закладами; при цьому наявність комерційної угоди між закладом і Pinka.coffee
            не є обов'язковою умовою. Використовуючи випадаюче меню на Платформі, Користувач може вибрати один з декількох
            варіантів, для яких виключно в ознайомлювальних цілях можуть бути вказані характеристики та ціна продукту або послуги,
            а іноді навіть може бути опублікована фотографія. Якщо Користувач хоче отримати інформацію про склад і харчову цінність продуктів,
            доступних на Платформі, він повинен безпосередньо зв'язатися з відповідною установою, щоб отримати повну інформацію про продукти.
          </div>
          <br />
          <div>
            <strong>4.2</strong> При цьому Користувач усвідомлює, що зовнішній вигляд Товарів може відрізнятися від зображень у сервісі Pinka.coffee,
            в той час як інформація про Товари наведена у загальному вигляді та містить приблизний опис Товарів та/або їхніх інгредієнтів.
          </div>
          <br />
          <div>
            <strong>4.3</strong> Після того, як Користувач вибрав один з варіантів, він також може доповнити замовлення,
            використовуючи надане текстове поле, щоб надати більш детальну інформацію або інструкції для Баристи,
            який буде виконувати замовлення. Після ознайомлення з інформацією про Товари Користувач самостійно оформляє
            Замовлення через сервіс Pinka.coffee шляхом додавання обраних Товарів у “Кошик” та подальшого підтвердження Замовлення.
          </div>
          <br />
          <div>
            <strong>4.4</strong> Підтверджуючи Замовлення Користувач також підтверджує, що він:
            <br />
            <div>
              <br />
              <strong>4.4.1</strong> повністю ознайомлений з умовами цього Договору;
            </div>
            <div>
              <br />
              <strong>4.4.2</strong> дає дозвіл на збір, обробку та передачу персональних даних на умовах,
              визначених нижче в розділі “Обробка персональних даних”. Дозвіл на обробку персональних даних
              діє протягом усього терміну дії Договору, а також протягом необмеженого терміну після закінчення його дії.
              Крім цього, підтверджуючи Замовлення Користувач підтверджує, що він ознайомлений зі своїми правами,
              які передбачені Законом України "Про захист персональних даних", про цілі збору даних, а також про те,
              що його персональні дані передаються Партнерам, Фінансовим компаніям та будь-яким іншим третім особам,
              з метою виконання Замовлення, можливістю проведення взаєморозрахунків, а також для отримання рахунків,
              актів та інших документів. Також Користувач підтверджує, що зміст його прав, як суб'єкта персональних
              даних відповідно до Закону України "Про захист персональних даних", йому відомий і зрозумілий.
            </div>
          </div>
          <div>
            <br />
            <strong>4.5</strong> Користувач несе повну відповідальність за повноту та достовірність даних,
            наведених ним під час оформлення Замовлення. Також, Користувачі можуть надати будь-які коментарі,
            які вони вважатимуть корисними, в розділі “Коментарі”для більш зручної ідентифікації замовлення.
          </div>
          <div>
            <br />
            <strong>4.6</strong> Після підтвердження Замовлення Користувач має право змінити або відмінити Замовлення тільки у статусі New (новий),
            у випадку переходу замовлення у статус In Progress (в процесі) Користувачу більше недоступна можливість вносити зміни до замовлення.
          </div>
          <div>
            <br />
            <strong>4.7</strong>Користувач звільняє Pinka.coffee й Бариста від відповідальності за недбалість або помилку в інструкціях,
            наданих Користувачем для покупки продукту у фізичному закладі від його імені. Отже, Користувач несе витрати,
            пов'язані з наданням на Платформі неправильних інструкцій щодо продуктів.
          </div>
          <div>
            <br />
            <strong>4.8</strong> Якщо Користувач відсутній в обумовленій кав’ярні , Бариста повинен зберігати продукт протягом 24 годин
            (у разі швидкопсувних продуктів (кави) термін зберігання становить 5-10 хвилин). Крім того, Користувач повинен сплатити вартість продукту.
            За жодних обставин Бариста не нестиме відповідальності за псування або закінчення терміну придатності продукту, пов'язаного з дорученням.
          </div>
          <div>
            <br />
            <strong>4.9</strong> Отримання Замовлення відбувається безпосередньо на місці зазначеної адреси Партнером. Бариста розпізнає Замовлення
            за допомогою відсканування QR-кода, який розміщений у додатку Користувача. У разі не співвідношення замовлення з зазначеною інформацією
          </div>
          <br />
          <strong>5. Вартість послуг і виставлення рахунку</strong>
          <div>
            <br />
            Користувачі можуть зареєструватися на Платформі та використовувати її безкоштовно; при цьому Pinka.coffee залишає за собою право в
            будь-який час змінити цінову політику. Користувачам може знадобитися оплатити певні послуги на Платформі відповідно до цих Загальних
          </div>
          <br />
          <div>
            Користувач оплачує лише за кожну послугу, запитану через Платформу для замовлення продуктів і
            обміну інформацією, а також за послуги виконання доручень, що надаються третіми особами.
          </div>
          <br />
          <div>
            <div>
            Стосовно послуг, які передбачають покупку продукту, Користувач повинен сплатити вартість такого продукту.
            Реєструючись на Платформі і надаючи необхідні банківські реквізити, Користувачі явно уповноважують Pinka.coffee
            </div>
            <div>
            Ціни на продукти фіксуються партнерами на платформі. Pinka.coffee не несе відповідальності за встановлення цін,
              а лише репрезентує їх для Користувача у Мобільному додатку.
            </div>
            <div>
            Якщо Користувач скасовує послугу після того, як замовлення було переведено у статус In Progress і Користувач
              був проінформований про це, Pinka.coffee має право стягувати з Користувача повну вартість замовлення.
            </div>
          </div>
          <br />
          <strong>6. Право на відкликання й анулювання замовлень:</strong>
          <div>
          <br />
            З огляду на характер послуги, яку пропонує Pinka.coffee, Користувач обізнаний про те, що після того,
            як замовлення було переведено Баристою в статус In Progress (в процесі), означаючи,
            що виконання доручення на купівлю вважається розпочатим, і, отже, Користувач не може безкоштовно відкликати запит на надання послуги.
          </div>
          <br />
          <div>
            Загальна вартість скасування відображається на екрані Користувача після натискання кнопки “Скасувати” у
            Мобільному додатку. Оскільки кава та кавові вироби вважаються швидкопсувними продуктами, то з Користувача знімається повна вартість замовлення.
          </div>
          <br />
          <div>
          У разі незадоволення Користувача, Pinka.coffee надає Користувачам офіційні форми претензій щодо наданих послуг,
          які доступні на офіційній мові країни, в якій представлена Pinka.coffee. Користувачі можуть затребувати вищезгадані
            форми претензій у службі підтримки користувачів Pinka.coffee — доступ до них буде надано автоматично.
            В електронному листі Користувача має бути зазначено точне місце, з якого відправляється претензія і яке
            повинно збігатися з місцем, де надається послуга. У разі сумнівів претензія повинна бути зроблена з місця, де надається послуга
          </div>
          <br />
          <strong>7. Використання платіжних сервісів</strong>
          <div>
            <br />
            <strong>7.1 Додавання способу оплати.</strong> Коли ви додаєте спосіб оплати до свого облікового запису Pinka,
            вас попросять надати платіжну інформацію, як-от ім’я, платіжну адресу та інформацію про фінансовий інструмент,
            або для сервіс Pinka.coffee, або для сторонніх платіжних систем. Ви дозволяєте сервісу Pinka.coffee і його
            постачальникам платіжних послуг збирати та зберігати інформацію про ваш спосіб оплати. Оплата Послуг Pinka може
            бути здійснена Користувачем через Мобільний Додаток за допомогою Google Pay, Apple Pay, за умови завантаження
          </div>
          <br />
          <div>
            <strong>7.2 Терміни оплати.</strong> Платежі Pinka зазвичай стягують загальну суму після того,
            як Користувач оформлює замовлення та передає інформацію про замовлення Партнеру.
            Після того, як платіж за запит на замовлення буде успішно завершено, ви отримаєте оповіщення в мобільному додатку.
          </div>
          <br />
          <div>
            <strong>7.3 Статус запиту замовлення.</strong> Замовлення може бути відмінене Користувачем у випадку статусу New (Новий),
            що дає можливість повернути кошти у повному обсязі. У разі переведення статусу замовлення Партнером In Progress (В процесі),
            Користувач не має більше можливості відмінити замовлення на повернення коштів, оскільки замовлення прийнято Партнером в обробку.
          </div>
          <br />
          <div>
            <strong>7.4 Постачальники платіжних послуг.</strong> Методи оплати можуть передбачати використання сторонніх постачальників платіжних послуг.
            Ці постачальники платіжних послуг можуть стягувати з вас додаткові комісії під час обробки платежів у зв’язку з платіжними послугами,
            і сервіс Pinka.coffee не несе відповідальності за будь-які такі комісії та відмовляється від будь-якої відповідальності в цьому відношенні.
            Ваш спосіб оплати також може регулюватися додатковими умовами використання. Перегляньте їх, перш ніж використовувати спосіб оплати.
          </div>
          <br />
          <div>
            <strong>7.5 Використання промокодів.</strong> Користувач погоджується, що промокоди не можна копіювати, продавати, передавати або оприлюднювати;
            промокоди можуть бути визнані недійсними або скасовані Адміністратором у будь-який час та з будь-якої причини; промокоди можна використовувати тільки
            відповідно до певних умов, які встановлюються для таких промокодів; промокоди обміну чи поверненню не підлягають, при втраті промокод не відновлюється;
            заміна промокоду грошима чи будь-яким іншим благом не допускається; за одну поїздку можна використати лише один промокод.
          </div>
          <div>
            <br />
            <strong>8. Умови для партнерів</strong>
            <br />
            <br />
            <strong>8.1 Загальні умови </strong>
            <span>Сервіс Pinka.coffee, ФОП Корпань Є.О. не є учасником угод між Партнерами та Користувачами. Сервіс Pinka.coffee виступає виключно як інформаційний посередник, що надає технологічну платформу для взаємодії Партнерів та Користувачів.</span>
            <br />
            <br />
            <span>
              Партнерам надаються інформаційні послуги доступу та користування Сервісом Pinka.coffee. Ці послуги включають розміщення інформації про товари та послуги Партнерів, обробку замовлень та забезпечення комунікації між Партнерами та Користувачами.
              При наданні послуг Партнерам та для виконання своїх зобовʼязань перед Партнерами, Сервіс Pinka.coffee має право залучати третіх осіб, в тому числі посередників (агентів).
            </span>
            <br />
            <br />
            <span>Партнер самостійно виступає продавцем (постачальником) у правовідносинах з Користувачами. Партнер несе повну відповідальність за якість товарів та послуг, що надаються Користувачам, а також за виконання своїх зобов'язань перед Користувачами.</span>
            <br />
            <br />
            <span>Сервіс Pinka.coffee не бере участі у формуванні цін на товари та послуги Партнерів, не впливає на якість товарів та послуг, що надаються Партнерами, та не несе відповідальності за виконання зобов'язань Партнерів перед Користувачами.</span>
            <br />
            <br />
            <span>У разі виникнення спорів між Партнерами та Користувачами, такі спори вирішуються безпосередньо між цими сторонами. Сервіс Pinka.coffee може надавати інформаційну підтримку для вирішення спорів, але не виступає стороною в таких спорах.</span>
            <br />
            <br />
            <span>Партнери розуміють та погоджуються, що, виконуючи замовлення через Сервіс Pinka.coffee, вони вступають у прямі договірні відносини (купівлі-продажу) з Користувачами, а не з Сервісом Pinka.coffee.</span>
            <br />
            <br />
            <strong>8.2 Отримання платежів.</strong> Сервіс Pinka.coffee, як правило, стягує загальну вартість замовлення на момент передачі замовлення Користувача Партнеру.
            <span>
              Якщо інше не передбачене цими Загальними умовами чи відповідним повідомленням,надісланим Сервісом Pinka.coffee безпосередньо Партнерам,або якщо про інше не зазначене на веб-сайті чи
              в Мобільному додатку, отримання платежів Сервісом Pinka.coffee здійснюється через платіжний сервіс LiqPay.
            <br />
            Партнери повідомлені про те, що платіжний сервіс LiqPay утримує комісію за обробку платежів у розмірі 1,5%
              від суми платежу, який отримує Партнер після утримання Комісії Сервісу Pinka.coffee.
            <br />
            <br />
            Партнери повідомлені, що комісія за обробку платежів може змінюватися, про що платіжний сервіс LiqPay може попереджати користувачів завчасно на власний розсуд.
            <br />
            <br />
            Сервіс Pinka.coffee залишає за собою право змінювати платіжний сервіс в односторонньому порядку, без попередження Партнерів.
            </span>
          </div>
          <br />
          <div>
            <strong>8.3 Валідація платіжних методів.</strong> Щоб отримати виплату, ви повинні мати дійсний спосіб виплати,
            пов’язаний із вашим обліковим записом Pinka. Коли ви додаєте спосіб виплати до свого облікового запису
            Pinka, вас запросять надати таку інформацію, як ім’я, ідентифікаційний номер, платіжну адресу та
            інформацію про фінансовий інструмент або для cервісу Pinka.coffee, або для сторонніх платіжних систем.
            Також, виплата послуг Pinka може бути здійснена Партнеру через Мобільний Додаток за допомогою
            Google Pay, Apple Pay, за умови завантаження відповідних мобільних додатків на свій мобільний пристрій.
            Для того, щоб метод виплати був дійсним, потрібно надати необхідну інформацію. Інформація, необхідна
            сервісу Pinka.coffee для дійсного методу виплати, може змінитися, і сервіс Pinka.coffeeможе запросити
            додаткову інформацію в будь-який час; Відмова від надання запитуваної інформації може призвести до того,
            що сервіс Pinka.coffee тимчасово затримає, призупинить або скасує будь-яку виплату, доки інформація не
            буде надана та, якщо необхідно, підтверджена. Ви дозволяєте сервісу Pinka.coffee збирати та зберігати інформацію про ваш спосіб виплати.
            <br />
            <span> Якщо для валідації платіжних методів необхідне вчинення дій Партнером
            безпосередньо взаємодіючи, при цьому, з відповідним платіжним сервісом, Партнер зобов’язується самостійно
            вчинити такі дії. Партнер розуміє, що невчинення зазначених дій може призвести до неможливості отримання виплат.
            </span>
            <span>Партнери погоджуються, що вони можуть виступати у якості самостійної сторони у правовідносинах з платіжними сервісами.
              Сервіс Pinka.coffee, в такому випадку, виступатиме як технічний посередник, між Партнером та платіжним сервісом,
              який здійснюватиме технічне налаштування можливості отримання платежів Партнером.
            </span>
          </div>
          <br />
          <div>
            <strong>8.4 Терміни виплати.</strong> За умови успішного отримання платежів від Користувача
            сервіс Pinka.coffee ініціюють виплати тільки після того, як Замовлення перейшло у статус Finished
            (Виконане), що означає отримання Замовлення Користувачем від Бариста. У випадку відміни
            Замовлення Користувачем, ви не отримуєте виплати, адже вони повертаються Користувачеві.
            Даний запит можливий лише у випадку перебування Замовлення у статусі New (Новий).
          </div>
          <br />
          <div>
            <strong>8.5 Обмеження щодо виплат.</strong> Сервіс Pinka.coffee може тимчасово призупинити, призупинити
            або скасувати будь-які виплати з метою запобігання незаконній діяльності чи шахрайству, оцінки ризиків,
            безпеки або завершення розслідування; або якщо ми не можемо підтвердити вашу особу, або отримати
            або перевірити запитувану інформацію. Крім того, сервіс Pinka.coffee може тимчасово затримати,
            призупинити або відстрочити ініціювання або обробку будь-якої виплати, яка належить вам згідно з
            Умовами, у результаті великої кількості скасувань замовлень.
          </div>
          <br />
          <div>
            <strong>8.6 Постачальники платіжних послуг.</strong> Методи виплат можуть передбачати використання
            сторонніх постачальників платіжних послуг. Ці постачальники платіжних послуг можуть
            стягувати з вас додаткові комісії під час обробки платежів у зв’язку з платіжними послугами,
            і сервіс Pinka.coffee не несе відповідальності за будь-які такі комісії та відмовляється від
            будь-якої відповідальності в цьому відношенні. Ваш спосіб виплат також може регулюватися
            додатковими умовами використання. Перегляньте їх, перш ніж використовувати спосіб виплат.
          </div>
          <br />
          <strong>Особливі умови щодо платформи Pinka Business.</strong>
          <div>
            Pinka створила платформу для контролю та аналізу замовлень, призначену для користуавння
            підприємстави та професійними фахівцями. Положення цього розділу регулюють надання послуг,
            що їх пропонує Pinka.coffee на веб-сайті <a href='https://pinka.coffee/'>https://pinka.coffee/</a>. Щодо всіх питань, не розглянутих у цьому розділі,
            застосовуються Загальні умови користування та співпраці.
          </div>
          <br />
          <strong>8.7 Комісії Сервісу Pinka.coffee</strong>
          <div>
            <span>
            За надання послуг, Сервіс Pinka.coffee утримує з Партнера комісію у розмірі 7% від суми кожного замовлення,
            оплаченого Користувачами на користь Партнера (далі – «Комісія Сервісу»).
            <br />
            У разі приєднання Партнера до Програми лояльності Сервісу Pinka.coffee, з суми кожного замовлення,
            оплаченого Користувачами на користь Партнера, Сервіс Pinka.coffee додатково утримує
            суму у розмірі до 10% від загальної суми оплаченого замовлення, відповідно до умов Програми лояльності
            Сервісу Pinka.coffee. Партнер отримує виплати за вирахуванням сум, утриманих Сервісом Pinka.coffee.
            </span>
          </div>
          <br />
          <strong>9. Предмет</strong>
          <div>
          <br />
          Партнер може увійти до свого облікового запису в сервісі Pinka Business, використовуючи своє ім'я користувача
          та
          пароль, зареєстровані на Платформі. За допомогою сервісу Pinka Business Партнер може розмістити інформацію
          про свою мережу закладів або заклад з відповідним меню та іншими властивими характеристиками.Замовлення
          виконується безпосередньо Баристою.
          </div>
          <br />
          <strong>10. Тип послуги</strong>
          <div>
            <br />
            На платформі Pinka.coffee Business доступні такі види послуг:
          </div>
          <br />
          <div>
            - “Замовлення на певну годину”: замовлення готується Баристою на певний час вказаний Користувачем;
          </div>
          <div>
            - “Замовлення ASAP”: замовлення готується Баристою відразу після заходження в систему.
          </div>
          <br />
          <div>
            У кожному замовленні може використовуватися поле з довільним текстом, де Користувачі можуть надати
            будь-які коментарі для Баристи.
          </div>
          <br />
          <div>
            Після того, як Користучав розмістив замовлення, Бариста підлаштовує замовлення під певний час та починає
            його виготовляти. Користувачі можуть скасувати Замовлення безкоштовно до тих пір, поки
            Замовлення не було переведено Баристою In Progress (“В процесі”). Якщо Користувач скасовує Замовлення,
            вже “В Процесі”, Користувач зобов'язується сплатити 100% вартості послуги як штраф за скасування замовлення,
            що вже виконується.
          </div>
          <br />
          <div>
            Якщо у Користувача виникають проблеми при розміщенні Замовлення, він повинен
            звернутися в службу підтримки Pinka.coffee.
          </div>
          <br />
          <div>
            Платформа Pinka.coffee Business доступна в ті дні та години, коли платформа активна.
          </div>
          <br />
          <strong>11. Порядок виконання замовлення </strong>
          <div>
            <br />
            Виконання Замовлення можливе виключно у робочий час Продавця. Якщо час доставки у Замовленні не
            співпадає з робочим часом Продавця, Користучав не матиме моживість оформити замовлення. Графік роботи
            закладу або мережі закладів Користувач матиме змогу перевірити за допомогою мобільного додатку.
          </div>
          <br />
          <div>Відповідно до інформації, наведеної у Замовленні, Бариста починає приготування кавових виробів або іншиї страв.
          </div>
          <div>В ході виконання замовлення Бариста слідує виключно рецепту, який йому був наданий Партнерами.</div>
          <br />
          <div>
            Про суттєві зміни в ході виконання замовлення Бариста повідомляє Користувача за казаними ним засобами зв’язку.
            Суттєвими є такі зміни: відсутність окремих Товарів із Замовлення, збільшення ціни Товару більше ніж на
            10% у порівнянні з даними у Замовленні. Якщо Користувач не відповідає на телефонний дзвінок,
            Бариста очікує 5 хвилин і після цього може відмовитися від виконання Замовлення.
          </div>
          <br />
          <div>
            Отримання замовлення відбувається виключно на місці розташування закладу
            в межах обраного Користувачем часу доставки
          </div>
          <div>
            Користувач здійснює перевірку Товарів в момент їх отримання.
          </div>
          <div>
            Користувач має право відмовитись від прийняття Товарів, що можуть бути повернені відповідно
            до норм чинного законодавства Продавцеві. Відмова від прийняття Товарів не є підставою для
            відмови від компенсації Користувачеві витрат на їх придбання та доставку
          </div>
          <br />
          <div>
            З моменту прийняття Товарів та здійснення оплати за прийняті Товари Користувачем,
            замовлення вважається виконаним належним чином у повному обсязі.
          </div>
          <br />
          <strong>12. Геолокація</strong>
          <div>
            <br />
            Pinka.coffee може збирати, використовувати та передавати дані про точне місцезнаходження, включаючи
            географічне розташування комп'ютера або мобільного пристрою Користувача в режимі реального часу,
            за умови, що це дозволено Користувачем. Pinka.coffee може збирати та використовувати вищезгадані
            дані про місцезнаходження для показу Користувачам розташування найближчів закладів для оформлення
            та отримання замовлення. У зв'язку з цим, Користувачі прямо погоджуються на передачу геолокаційних
            даних іншим Користувачам і Постачальникам у будь-який момент часу з метою успішного виконання доручення.
            Користувачі можуть вимкнути функцію геолокації на своїх пристроях відповідно до інструкцій.
          </div>
          <br />
          <div>
            Партнер несе відповідальність за надання правильних адрес для видачі користувачам замовлених продуктів.
            У зв'язку з цим Pinka.coffe не несе відповідальності за помилки або неточності в адресах, наданих Партнером.
          </div>
          <br />
          <strong>13. Обов’язки Партнера</strong>
          <div>
            <br />
            Партнери несуть повну відповідальність за належне використання та доступ до свого профілю
            та іншого контенту Платформи відповідно до чинного міжнародного законодавства та національного законодавства
            країни, з якої здійснюється доступ до Платформи, а також згідно з принципами добросовісності,
            моралі та загальноприйнятих звичаїв і норм. Зокрема, вони зобов’язуються сумлінно дотримуватися
            цих Загальних умов користування.
          </div>
          <br />
          <div>
            Кожен Партнер несе відповідальність за правильне введення свого індивідуального імені користувача та пароля,
            які повинні бути достатньо складними та не передаватися іншим особам, а також за використання унікального
            імені користувача та пароля, які відмінні від тих, що використовуються на інших платформах,
            з метою захисту свого облікового запису від використання третіми особами, що не мають стосунку до Платформи,
            із шахрайською метою.
          </div>
          <br />
          <div>
            Партнерам слід утримуватися від використання свого профілю та іншого контенту Платформи в незаконних цілях
            або для отримання незаконних результатів, що завдають шкоди правам та інтересам третіх
            осіб або які можуть будь-яким чином пошкодити, вимкнути або негативним чином вплинути на Платформу
            та її контент і послуги. Крім того, Партнерам заборонено перешкоджати нормальному використанню Платформ іншими Партнерами.
          </div>
          <br />
          <div>
            Pinka.coffee не несе редакційної відповідальності та чітко заявляє, що не ототожнює себе з думками,
            які можуть висловлювати Користувачі Платформи. Відповідальність за наслідки висловлювання таких
            думок несуть виключно Користувачі, які їх висловили.
          </div>
          <br />
          <div>
            Особи, які порушили вищезазначені зобов'язання, несуть відповідальність за заподіяні збитки та шкоду.
            Pinka.coffee не несе відповідальності за наслідки, втрати або збитки, які можуть виникнути внаслідок
            незаконного використання або доступу, здійснених третіми особами.
          </div>
          <br />
          <div>
            Загалом, Партнери беруть на себе такі зобов'язання (цей список не є вичерпним):
          </div>
          <div>
            <strong>•</strong> утримуватися від повної або часткової зміни або модифікації Платформи шляхом обходу, відключення або
            будь-якого іншого втручання в її функції або послуги;
          </div>
          <div>
            <strong>•</strong> утримуватися від порушення прав на об'єкти промислової та інтелектуальної власності або законодавства про захист персональних даних;
          </div>
          <div>
            <strong>•</strong> утримуватися від використання Платформи для образи, наклепу, залякування або
            переслідування інших користувачів або заподіяння шкоди їх іміджу; - утримуватися від доступу до облікових
            записів електронної пошти інших користувачів;
          </div>
          <div>
            <strong>•</strong> утримуватися від розміщення комп'ютерних вірусів, пошкоджених файлів або будь-якого іншого програмного
            забезпечення, яке може привести до пошкодження або зміни контенту або систем Pinka.coffee або третіх осіб;
          </div>
          <div>
            <strong>•</strong> утримуватися від надсилання масових і/або повторюваних електронних листів численним особам,
            а також від передачі адрес електронної пошти третіх осіб без їх згоди;
          </div>
          <div>
            <strong>•</strong> утримуватися від рекламування товарів або послуг без попередньої згоди Pinka.coffee.
          </div>
          <br />
          <div>
          Будь-який користувач може поскаржитися на іншого користувача, якщо він підозрює останнього в порушенні цих Загальних
            умов користування. Аналогічно, Користувач може сповістити Pinka.coffee про зловживання або
            порушення цих умов, скориставшись Контактною формою. Pinka.coffee розгляне отриману скаргу у максимально
            короткий термін і вдасться до кроків, які Pinka.coffee вважатиме доцільними; при цьому Pinka.coffee залишає
            за собою право тимчасово або повністю припинити надання послуг на Платформі Користувачеві, який порушив
            ці Загальні умови користування. Крім того, Pinka.coffee залишає за собою право видалити та/або
            заблокувати будь-яке повідомлення незаконного або образливого характеру без попереднього попередження
            або подальшого повідомлення.
          </div>
          <br />
          <strong>14. Політика ціноутворення</strong>
          <br />
          <br />
          <span>Партнер зобов'язується забезпечити ідентичність цін на товари та послуги, представлені на Платформі Pinka.coffee, з цінами, встановленими у фізичному закладі Партнера.</span>
          <br />
          <br />
          <span>Забороняється підвищення цін на Платформі Pinka.coffee з метою компенсації комісії Сервісу, витрат на участь у Програмі лояльності або з будь-яких інших причин, пов'язаних із співпрацею з Pinka.coffee.</span>
          <br />
          <br />
          <span>У разі виявлення розбіжностей між цінами на Платформі та у фізичному закладі, Партнер зобов'язаний негайно привести їх у відповідність.</span>
          <br />
          <br />
          <span>Pinka.coffee залишає за собою право проводити вибіркові перевірки дотримання Партнером цієї політики ціноутворення.</span>
          <br />
          <br />
          <span>Недотримання цих умов може бути розцінено як істотне порушення договору та може призвести до застосування санкцій з боку Pinka.coffee, включаючи, але не обмежуючись, тимчасовим призупиненням доступу до Платформи або припиненням співпраці.</span>
          <br />
          <br />
          <span>Партнер підтверджує своє розуміння та згоду з тим, що дотримання єдиної цінової політики є ключовим фактором для забезпечення довіри Користувачів до Платформи та успішної співпраці між Партнером та Pinka.coffee.</span>
          <br />
          <br />
          <strong>15. Видалення Користувачами свого облікового запису</strong>
          <div>
            <br />
            Партнери можуть видалити свій обліковий запис на Платформі, скориставшись для цього Контактною формою на
            Платформі.
          </div>
          <br />
          <strong>16. Відповідальність Pinka.coffee </strong>
          <div>
            <br />
            Користувачі несуть відповідальність за наявність необхідних послуг та обладнання для доступу до Інтернету та Платформи.
            Користувачі можуть повідомляти Pinka.coffee про будь-які інциденти або проблеми з доступом до Платформи,
            використовуючи доступні канали для зв'язку. Pinka.coffee вивчить проблему та надасть Користувачеві інструкції,
            які допоможуть якомога швидше вирішити проблему.
          </div>
          <br />
          <div>
            Pinka.coffee не контролює та не несе відповідальності за контент, завантажений Користувачами через Платформу.
            Відповідальність за законність такого контенту несуть Користувачі.
          </div>
          <br />
          <div>
            Pinka.coffee не несе відповідальності за перебої в роботі сервісу, помилки підключення, недоступність
            або несправність інтернет-сервісу, збої інтернет-з'єднання та будь-які інші проблеми, що перебувають
            за межами контролю Pinka.coffee.
          </div>
          <br />
          <div>
            Pinka.coffee не несе відповідальності за можливі помилки у системі безпеки та за будь-яку шкоду, яка може
            бути заподіяна комп'ютерній системі (апаратному та програмному забезпеченню) Користувача або файлам чи документам,
            що зберігаються у ній, через:
          </div>
          <br />
          <div>
            <strong>•</strong> наявність вірусу в комп'ютерній системі або мобільному телефоні Користувача, що використовується для
            підключення до контенту і сервісів Платформи;
          </div>
          <div>
          <strong>•</strong> несправність браузера;
          </div>
          <div>
          <strong>•</strong> використання застарілих версій.
          </div>
          <br />
          <strong>17. Відповідальність за вміст Замовлень</strong>
          <div>
            <br />
            Pinka.coffee не контролює (і не зобов'язана контролювати) порядок використання Платформи Партнерами.
            Отже, Pinka.coffee, не гарантує, що Партнери будуть використовувати Платформу відповідно до цих
            Загальних умов користування або бути при цьому обачними й уважними. Pinka.coffee не перевіряє
            (і не зобов'язана перевіряти) особистість користувачів або правдивість, актуальність, повноту та/або
            справжність наданих ними даних.
          </div>
          <br />
          <div>
            Pinka.coffee не перевіряє продукти, надіслані або доставлені в рамках замовлень, розміщених на Платформі.
            Отже, Партнери і Баристи звільняють Pinka.coffee від будь-якої відповідальності, яка може виникнути в зв'язку
            з доступністю та/або виготовленням продуктів, що вимагають певних дозволів або ліцензій, або продуктів,
            заборонених чинним законодавством України.
          </div>
          <br />
          <div>
            Pinka.coffee не несе жодної відповідальності за будь-які збитки, які може виникнути в результаті незаконного
            використання Платформи Партнерами або через інформацію, надану Користувачами іншим Користувачам про свою особистість,
            яка не відповідає дійсності, втратила свою актуальність і/або є неповною або помилковою,
            в тому числі за будь-які збитки чи шкоду, які можуть виникнути через те, що Користувач видає себе за третю особу
            під час будь-якого обміну інформацією, що здійснюється через Платформу. Зокрема, Pinka.coffee не несе
            відповідальності за використання Платформи або за розміщення замовлень третьою особою з облікового запису
            Користувача.
          </div>
          <br />
          <div>
            Незважаючи на вищесказане, Pinka.coffee залишає за собою право повністю або частково обмежувати доступ певних
            Партнерів до Платформи, а також скасовувати, призупиняти, блокувати або видаляти певні типи контенту за
            допомогою відповідних технологічних інструментів, якщо Pinka.coffee стане відомо чи Pinka.coffee виявить
            ознаки того, що дії або інформація є незаконними або завдають шкоди правам чи власності третіх осіб.
            У зв'язку з цим Pinka.coffee може встановлювати будь-які фільтри, які можуть знадобитися для запобігання
            використання сервісу з метою завантаження незаконного або шкідливого контенту в Інтернет. Надаючи контент
            через Платформу, Користувачі передають Pinka.coffee всі права на використання контенту, що випливають з
            наданого таким чином контенту.
          </div>
          <br />
          <div>
            Pinka.coffee залишає за собою право визначати алгоритми та параметри, що застосовуються при розрахунку
            рейтингу продавців (ресторанів і/або закладів) і продуктів, що представлені на Платформі, а також змінювати
            й оновлювати їх. Користувач визнає та погоджується з тим, що такий рейтинг може залежати від численних
            факторів і показників, у тому числі близькості та доступності закладу, попередніх відгуків, а також
            будь-яких угод, укладених Pinka.coffee із зазначеними продавцями чи дистриб'юторами продукції,
            пов'язаних із їх позиціонуванням і видимістю в додатку.
          </div>
          <br />
          <strong>18. Оновлення Платформи та внесення змін</strong>
          <div>
            <br />
            Pinka.coffee залишає за собою право в будь-який час і без попереднього повідомлення вносити зміни до цих
            Загальних умов користування, Політики конфіденційності та Політики використання файлів cookie.
            Користувачі повинні уважно прочитати ці Загальні умови при здійсненні доступу до Платформи.
            У будь-якому випадку прийняття Загальних умов є важливим кроком, який необхідно зробити перед
            здійснення доступу до сервісів і контенту, доступних за допомогою Платформи Pinka.coffee.
          </div>
          <br />
          <div>
            Крім того, Pinka.coffee залишає за собою право в будь-який час і без попереднього повідомлення оновлювати,
            змінювати або видаляти інформацію, що міститься на Платформі, в частині, що стосується макета,
            презентації та умов доступу, не беручи при цьому жодної відповідальності у зв'язку з такими діями.
            Pinka.coffee не гарантує відсутність помилок або перебоїв у доступі до Платформи або її контенту,
            а також не гарантує актуальність контенту. Незважаючи на вищевикладене, Pinka.coffee зобов'язується
            (за винятком випадків, коли це важко або неможливо) зробити всі кроки для виправлення таких помилок,
            відновлення зв'язку й оновлення контенту, щойно Pinka.coffee стане відомо про помилки, перебої
            або втрату контентом своєї актуальності.
          </div>
          <br />
          <strong>19. Порядок оформлення та виконання замовлень Баристою</strong>
          <div>
            <br />
            За допомоги Платформи, Партнер надсилає на вказану Баристою адресу електронної пошти посилання для
            завантаження мобільного додатку, розробленого Pinka.coffee для приймання та виконання замовлень.
          </div>
          <br />
          <div>
            Партнер надає Баристі унікальний логін і пароль для доступу до сервісу Pinka.coffee Barista.
            Бариста зобов’язаний вживати належні заходи для недопущення розголошення отриманого логіну і
            паролю будь-яким третім особам.
          </div>
          <br />
          <div>
            Факт авторизації Барист у сервісі Pinka.coffee Barista за допомогою наданого Партнером логіну та паролю
            підтверджує беззастережну згоду Баристи з усіма умовами договору.
          </div>
          <br />
          <div>
            Авторизуючись у мобільному додатку Бариста також підтверджує, що він:
          </div>
          <div>
            - повністю ознайомлений з умовами цього Договору;
          </div>
          <div>
            - Pдає дозвіл на збір, обробку та передачу персональних даних. Дозвіл на обробку персональних даних діє
            протягом усього терміну дії Договору, а також протягом необмеженого терміну після закінчення його дії.
            Крім цього Бариста підтверджує, що він повідомлений про свої права, передбачені Законом України
            "Про захист персональних даних", про цілі збору даних, а також про те, що його персональні дані
            передаються Продавцям, Покупцям та будь-яким іншим третім особам, з метою виконання цього Договору,
            можливості проведення взаєморозрахунків, а також для отримання рахунків, актів та інших документів.
            Також Бариста підтверджує, що зміст його прав, як суб'єкта персональних даних відповідно до Закону
            України “Про захист персональних даних”, йому відомий і зрозумілий.
          </div>
          <br />
          <div>
            В рамках сервісу Pinka.coffee Barista Партнер надає Баристі інформацію щодо поточних замовлень Користувачів.
          </div>
          <div>
            Бариста підтверджує свій намір і зобов’язання виконати конкретне Замовлення у сервісі Pinka.coffee Barista.
            Таке підтвердження є Дорученням Баристи Партнеру на укладення з Користувачем від імені Баристи договору
            про видачу і виготовлення замовлень та отримання плати за надання таких послуг.
          </div>
          <div>
          З моменту отримання такого Доручення Бариста приступає до його виконання і не може відмовитися від надання своїх послуг.
          </div>
          <br />
          <strong>20. Обов’язки Баристи</strong>
          <div>
            <br />
            Кожен Партнер бере на себе такі зобов'язання:
          </div>
          <div>
            - укласти від імені Баристи договір про надання кур’єрських послуг;
          </div>
          <div>
          - перерахувати Баристі кошти, отримані від Покупця за надання послуг, якщо такі послуги Покупець оплатив он-лайн.
          </div>
          <div>
            Кожен Бариста несе наступні забов’язання:
          </div>
          <div>
            - надаючи послуги забезпечувати своєчасну виготовлення та віддачу замовлень відповідно до часу,
            зазначеного користувачем у Замовленні, забезпечувати збереження Товарів;
          </div>
          <div>
            - не передавати доступ до свого аккаунту у сервісі Pinka.coffee Barista стороннім особам;
          </div>
          <div>
            - не виконувати замовлення у стані алкогольного, наркотичного чи іншого сп’яніння;
          </div>
          <div>
            - дотримуватися всіх податкових зобов'язань, що виникають щодо нього відповідно до чинного законодавства
            стосовно надання послуги баристи, включно з (за необхідності) (1) сплатою податку на прибуток,
            податку на дохід фізичних осіб, податку на соціальне страхування або будь-якого іншого застосовного податку;
            і (2) виконанням всіх зобов'язань щодо податкової реєстрації та розрахунку і перерахуванням всіх податкових
            зобов'язань, пов'язаних із наданням послуг баристи, як того вимагає чинне законодавство.
          </div>
          <br />
          <strong>21. Відповідальність сторін</strong>
          <div>
          <br />
            Pinka.coffee не несе відповідальності за збої у функціонуванні сервісу Pinka.coffee Barista,
            які виникли не з вини сервісу Pinka.coffee. Pinka.coffee не несе відповідальності за спотворення інформації
            про Покупців та Товари. Pinka.coffee не несе відповідальності за дії Баристи щодо використання ним інформації,
            наданої в рамках отриманої від Pinka.coffee інформаційної послуги.
          </div>
          <br />
          <div>
            Сторони звільняються від відповідальності за порушення умов Договору, якщо таке порушення викликане
            дією обставин непереборної сили (форс-мажор), включаючи: дії органів державної влади, пожежа, повінь,
            землетрус, інші стихійні дії, відсутність електроенергії і / або збої роботи комп'ютерної мережі, страйки,
            громадянські заворушення, безлади, будь-які інші обставини, не обмежуючись перерахованим, які можуть
            вплинути на виконання Сторонами умов Договору та непідконтрольні Сторонам.
          </div>
          <br />
          <div>
            Бариста відповідає за схоронність замовлення під час його формування та за дотримання правил виконання
            товарів вказаних у рецепті наданим Партнером.
          </div>
          <br />
          <br />
          <div> ДОДАТОК I — РЕКВІЗИТИ КОМПАНІЇ</div>
          <br />
          <div>Назва компанії: Корпань Є.О.</div>
          <br />
          <div>Код ЄДРПОУ: 3166017811</div>
          <br />
          <br />
          <br />
          <br />
          <strong id="loyalty">Умови програми лояльності Pinka.coffee</strong>
          <div>
            <br />
            <strong>1. Загальні положення та визначення</strong>
          </div>
          <div>
            <strong>1.1</strong> - Ця програма лояльності (далі – «Програма») розроблена та впроваджена
            ФОП Корпань Є.О. для Сервісу Pinka.coffee з метою заохочення постійних клієнтів та підвищення їхньої лояльності.
          </div>
          <div>
            <strong>1.2</strong> - Участь у Програмі є добровільною та безкоштовною.
          </div>
          <div>
            <strong>1.3</strong> - Приєднуючись до Програми, учасник погоджується з усіма її умовами.
          </div>
          <div>
            <strong>1.4</strong> - Основні визначення:
            <div>
              • Програма лояльності – система, яка дозволяє Користувачам накопичувати рівні лояльності, що надають знижки на товари чи послуги в закладах-партнерах.
            </div>
            <div>
              • Сервіс Pinka.coffee – веб-сайт, та мобільні додатки що розміщені за адресою:
              <a href="http://pinka.coffee"> https://pinka.coffee/</a>
            </div>
            • Платформа – технологічна платформа, власником якої є ФОП Корпань Є.О., що дозволяє
            за допомогою мобільного додатку пропонувати або розміщувати інформацію про свої товари з метою подальшого їх продажу Користувачам,
            а Користувачам дає можливість здійснювати пошук відповідних товарів або послуг та розміщення
            замовлень щодо таких товарів або послуг.
          </div>
          <div>
            • Користувач – будь-яка фізична особа, яка досягла 18 років, має повну цивільну правоздатність та
            дієздатність та є зареєстрована на Платформі (за допомогою мобільного додатку або веб-сайту).
          </div>
          <div>
            • Партнер – суб’єкт господарювання, який за допомогою Платформи розміщує інформацію про свої товари або послуги з метою їх подальшого продажу Користувачам.
          </div>
          <div>
            • Рівень лояльності – показник активності користувача у програмі, що визначає розмір знижки (від 0% до 10%).
          </div>
          <div>
            • Знижка – відсоток зниження вартості товарів чи послуг, наданий користувачам Партнерами, що приєдналися до Програми, в залежності від Рівня лояльності.
          </div>
          <div>
            • Компанія – ФОП Корпань Є.О. (РНОКПП: 3166017811), вул. Припортова 42/1, Черкаси, 18000, Україна.
          </div>
          <br />
          <div>
            Інші визначення та терміни вживаються у значеннях, згідно чинного законодавства України, положень
            <a href="#terms"> Загальних умови користування та співпраці (публічна оферта)</a>
          </div>
          <br />
          <strong>2. Участь у Програмі</strong>
          <div>
            <br />
            <strong>2.1</strong> - Право на участь у Програмі мають фізичні особи, які досягли 18-річного віку.
          </div>
          <div>
            <strong>2.2</strong> - Для участі в Програмі Користувач повинен:
          </div>
          <div>
            <strong>2.2.1</strong> - Зареєструватися на Платформі.
          </div>
          <div>
            <strong>2.2.2</strong> - Ознайомитися та погодитися з умовами Програми лояльності, шляхом проставлення
            відповідної відмітки на Платформі (за допомогою мобільного додатку або веб-сайту).
          </div>
          <div>
            <strong>2.2.3</strong> - Після реєстрації Користувач отримує нульовий Рівень лояльності (0%) і може почати виконувати дії для підвищення Рівня лояльності.
          </div>
          <div>
            <strong>2.2.4</strong> - Компанія залишає за собою право відмовити у участі в Програмі без пояснення причин.
          </div>
          <div>
            <strong>
              <br />3. Структура Програми та набуття Рівнів лояльності</strong>
          </div>
          <div>
            <br />
            <strong>3.1</strong> - Програма має 10 Рівнів лояльності.
          </div>
          <div>
            <strong>3.2</strong> - Кожен Рівень лояльності надає Користувачу додатковий 1% знижки на купівлі через Платформу в Партнерів, які приєдналися до Програми.
          </div>
          <div>
            <strong>3.3</strong> - Користувач може ознайомитися з чинним переліком Партнерів, які приєдналися до Програми, у своєму особистому кабінеті на Платформі.
          </div>
          <div>
            <strong>3.4</strong> - Максимальний розмір знижки становить 10% при досягненні 10-го рівня.
          </div>
          <div>
            <strong>3.5</strong> - Користувач починає з 0-го Рівня лояльності (0% знижки).
          </div>
          <div>
            <strong>3.6</strong> - Для підвищення Рівня лояльності Користувач повинен виконувати певні дії, включаючи, але не обмежуючись:
          </div>
          <br />
          <div>
            А) Здійснення замовлень у Партнерів;
          </div>
          <div>
            Б) Написання відгуків;
          </div>
          <div>
            В) Рекомендації друзям;
          </div>
          <div>
            Г) Участь у акціях Компанії;
          </div>
          <div>
            Д) Вчинення інших дій.
          </div>
          <br />
          <div>
            <strong>3.7</strong> - Точна кількість та тип дій, необхідних для підвищення рівня, визначаються Компанією та можуть змінюватися за її рішенням.
          </div>
          <div>
            <strong>3.8</strong> - Користувач може ознайомитися з чинним переліком дій, необхідних для підвищення Рівня лояльності у своєму особистому кабінеті на Платформі.
          </div>
          <div>
            <strong>3.9</strong> - Компанія зобовʼязується повідомляти Користувачів про зміни умов підвищення Рівня лояльності не пізніше ніж за 30 днів до їх впровадження.
          </div>
          <div>
            <strong>4.0</strong> - Підвищення Рівня лояльності фіксується на Платформі автоматично після виконання
            Користувачем необхідних дій. Користувач може перевірити свій поточний Рівень у своєму особистому кабінеті на Платформі.
          </div>
          <div>
            <br />
            <strong>4. Порядок приєднання Партнерів</strong>
          </div>
          <br />
          <div>
            <strong>4.1</strong> - Для приєднання до Програми лояльності Партнер повинен:
          </div>
          <div>
            <strong>4.1.2</strong> - Увійти до свого особистого кабінету на Платформі;
          </div>
          <div>
            <strong>4.1.3</strong> - У розділі редагування інформації про заклад активувати опцію «Приєднатись до системи лояльності».
          </div>
          <div>
            <strong>4.2</strong> - Приєднуючись до Програми лояльності, Партнер автоматично погоджується з усіма її умовами та зобов’язується:
          </div>
          <div>
            <strong>4.2.1</strong> - Надавати знижки Користувачам Програми відповідно до їх Рівня лояльності,відповідно до Розділу 5.
          </div>
          <div>
            <strong>4.2.2</strong> - Забезпечувати актуальність інформації про свої товари чи послуги на Платформі.
          </div>
          <div>
            <strong>4.3</strong> - Інформація про участь Партнера у Програмі буде автоматично відображатися на Платформі,
            дозволяючи Користувачам бачити заклади, які підтримують Програму лояльності.
          </div>
          <div>
            <strong>4.4</strong> - Партнер може в будь-який момент вийти з Програми лояльності, деактивувавши відповідну опцію в налаштуваннях свого облікового запису.
          </div>
          <br />
          <div>
            <strong>5. Застосування знижок та утримання коштів</strong>
          </div>
          <br />
          <div>
            <strong>5.1</strong> - Знижка застосовується до вартості товарів чи послуг Партнерів, якщо інше не зазначено в умовах конкретної акції чи пропозиції.
          </div>
          <div>
            <strong>5.2</strong> - Знижка не застосовується до податків, зборів та інших обовʼязкових платежів.
          </div>
          <div>
            <strong>5.3</strong> - Знижка не може бути обміняна на грошовий еквівалент.
          </div>
          <div>
            <strong>5.4</strong> - У разі приєднання Партнера до Програми лояльності Сервісу Pinka.coffee, з суми
            кожного замовлення, оплаченого Користувачами на користь Партнера, Сервіс Pinka.coffee додатково утримує суму
            у розмірі до 10% від загальної суми оплаченого замовлення, відповідно до умов цієї Програми.
          </div>
          <div>
            <strong>5.5</strong> - Утримання коштів Сервісом Pinka.coffee:
          </div>
          <div>
            <strong>5.5.1</strong> - Розмір коштів, які Утримуються Сервісом Pinka.coffee залежить від Рівня лояльності Користувача, який здійснив замовлення.
          </div>
          <div>
            <strong>5.5.2</strong> - Утримання відбувається за наступною схемою:
          </div>
          <div>
            <br />
            - Рівень лояльності 0 - утримується 0% від суми замовлення;
          </div>
          <div>
            - Рівень лояльності 1 - утримується 1% від суми замовлення;
          </div>
          <div>
            - Рівень лояльності 2 - утримується 2% від суми замовлення;
          </div>
          <div>
            - Рівень лояльності 3 - утримується 3% від суми замовлення;
          </div>
          <div>
            - Рівень лояльності 4 - утримується 4% від суми замовлення;
          </div>
          <div>
            - Рівень лояльності 5 - утримується 5% від суми замовлення;
          </div>
          <div>
            - Рівень лояльності 6 - утримується 6% від суми замовлення;
          </div>
          <div>
            - Рівень лояльності 7 - утримується 7% від суми замовлення;
          </div>
          <div>
            - Рівень лояльності 8 - утримується 8% від суми замовлення;
          </div>
          <div>
            - Рівень лояльності 9 - утримується 9% від суми замовлення;
          </div>
          <div>
            - Рівень лояльності 10 - утримується 10% від суми замовлення;
          </div>
          <br />
          <div>
            <strong>5.5.3</strong> - Партнер отримує виплати за вирахуванням сум, утриманих Сервісом Pinka.coffee.
          </div>
          <div>
            <strong>5.6</strong> - Партнер погоджується з тим, що суми утримань можуть змінюватися відповідно до змін у
            Програмі лояльності. Про такі зміни Партнер буде повідомлений не пізніше ніж за 30 днів до їх впровадження.
          </div>
          <div>
            <br />
            <strong>6. Строк дії та анулювання участі</strong>
          </div>
          <div>
            <br />
            <strong>6.1</strong> - Участь у Програмі діє безстроково до моменту її припинення Користувачем, Партнером або Компанією.
          </div>
          <div>
            <strong>6.2</strong> - Користувач має право в будь-який момент відмовитися від участі у Програмі лояльності
            шляхом деактивації відповідної опції у своєму особистому кабінеті на Платформі.
          </div>
          <div>
            <strong>6.3</strong> - Після виходу з Програми Користувач:
          </div>
          <div>
            <strong>6.3.1</strong> - Втрачає всі накопичені Рівні лояльності та право на знижки.
          </div>
          <div>
            <strong>6.3.2</strong> - Може повторно приєднатися до Програми, але почне з нульового рівня лояльності.
          </div>
          <div>
            <strong>6.4</strong> - Партнер має право в будь-який момент відмовитися від участі у Програмі лояльності
            шляхом деактивації відповідної опції у своєму особистому кабінеті на Платформі.
          </div>
          <div>
            <strong>6.5</strong> - Після виходу з Програми Партнер:
          </div>
          <div>
            <strong>6.5.1</strong> - Зобовʼязується виконати всі зобовʼязання перед Користувачами та Сервісом Pinka.coffee до дати виходу з Програми.
          </div>
          <div>
            <strong>6.6</strong> - Компанія має право анулювати участь Користувача чи Партнера в Програмі у разі порушення умов Програми або
            <a href="#terms"> Загальних умови користування та співпраці (публічна оферта)</a>
          </div>
          <div>
            <br />
            <strong>7. Зміни умов Програми</strong>
          </div>
          <br />
          <div>
            <strong>7.1</strong> - Компанія залишає за собою право вносити зміни до умов Програми.
          </div>
          <div>
            <strong>7.2</strong> - Про суттєві зміни умов Програми Компанія зобовʼязується повідомляти Користувачів та Партнерів не пізніше ніж за 30 днів до їх впровадження.
          </div>
          <br />
          <div>
            <strong>8. Заключні положення</strong>
          </div>
          <br />
          <div>
            <strong>8.1</strong> - Ця ці умови складені відповідно до чинного законодавства України.
          </div>
          <div>
            <strong>8.2</strong> - У випадку, якщо будь-яке положення цих умов буде визнано недійсним, це не впливає на дійсність інших положень.
          </div>
          <div>
            <strong>8.3</strong> - Компанія залишає за собою право припинити дію Програми у будь-який момент з попереднім повідомленням усіх її учасників за 60 днів до дати припинення.
          </div>
          <div>
            <strong>8.4</strong> - Ці умови є невід’ємною складовою частиною <a href="#terms"> Загальних умови користування та співпраці (публічна оферта)</a>
          </div>
          <br />
        </div>
        <Footer />
      </div>
    )
  }
}

export default TermsContainer
