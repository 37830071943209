import { camelCase, isArray, isPlainObject, snakeCase, transform } from 'lodash'

export const toSnakeCase = (object) =>
  transform(object, (result, value, key) => {
    if (key.charAt(0) === '_') {
      result[key] = value
    } else if (isPlainObject(value)) {
      result[snakeCase(key)] = toSnakeCase(value)
    } else if (isArray(value)) {
      result[snakeCase(key)] = transform(
        value,
        (arrayResult, arrayValue) => {
          if (isPlainObject(arrayValue)) {
            arrayResult.push(toSnakeCase(arrayValue))
          } else {
            arrayResult.push(arrayValue)
          }
        },
        []
      )
    } else {
      result[snakeCase(key)] = value
    }
  })

export const toCamelCase = (object) =>
  transform(object, (result, value, key) => {
    if (isPlainObject(value)) {
      result[camelCase(key)] = toCamelCase(value)
    } else if (isArray(value)) {
      result[camelCase(key)] = transform(
        value,
        (arrayResult, arrayValue) => {
          if (isPlainObject(arrayValue)) {
            arrayResult.push(toCamelCase(arrayValue))
          } else {
            arrayResult.push(arrayValue)
          }
        },
        []
      )
    } else {
      result[camelCase(key)] = value
    }
  })

export default {
  toCamelCase,
  toSnakeCase,
}
