export const VENDORS_GET_REQUEST = 'VENDORS_GET_REQUEST'
export const VENDORS_GET_SUCCESS = 'VENDORS_GET_SUCCESS'
export const VENDORS_GET_FAILURE = 'VENDORS_GET_FAILURE'

export const VENDOR_CREATE_REQUEST = 'VENDOR_CREATE_REQUEST'
export const VENDOR_CREATE_SUCCESS = 'VENDOR_CREATE_SUCCESS'
export const VENDOR_CREATE_FAILURE = 'VENDOR_CREATE_FAILURE'

export const VENDOR_DELETE_REQUEST = 'VENDOR_DELETE_REQUEST'
export const VENDOR_DELETE_SUCCESS = 'VENDOR_DELETE_SUCCESS'
export const VENDOR_DELETE_FAILURE = 'VENDOR_DELETE_FAILURE'

export const VENDOR_GET_REQUEST = 'VENDOR_GET_REQUEST'
export const VENDOR_GET_SUCCESS = 'VENDOR_GET_SUCCESS'
export const VENDOR_GET_FAILURE = 'VENDOR_GET_FAILURE'

export const VENDOR_UPDATE_REQUEST = 'VENDOR_UPDATE_REQUEST'
export const VENDOR_UPDATE_SUCCESS = 'VENDOR_UPDATE_SUCCESS'
export const VENDOR_UPDATE_FAILURE = 'VENDOR_UPDATE_FAILURE'

export const VENDORS_CITIES_GET_REQUEST = 'VENDORS_CITIES_GET_REQUEST'
export const VENDORS_CITIES_GET_SUCCESS = 'VENDORS_CITIES_GET_SUCCESS'
export const VENDORS_CITIES_GET_FAILURE = 'VENDORS_CITIES_GET_FAILURE'

export const VENDOR_RATING_GET_REQUEST = 'VENDOR_RATING_GET_REQUEST'
export const VENDOR_RATING_GET_SUCCESS = 'VENDOR_RATING_GET_SUCCESS'
export const VENDOR_RATING_GET_FAILURE = 'VENDOR_RATING_GET_FAILURE'

export const VENDOR_FEEDBACKS_GET_REQUEST = 'VENDOR_FEEDBACKS_GET_REQUEST'
export const VENDOR_FEEDBACKS_GET_SUCCESS = 'VENDOR_FEEDBACKS_GET_SUCCESS'
export const VENDOR_FEEDBACKS_GET_FAILURE = 'VENDOR_FEEDBACKS_GET_FAILURE'


export const vendorsGetRequest = (payload) => ({
  type: VENDORS_GET_REQUEST,
  payload
})

export const vendorsGetSuccess = (payload) => ({
  type: VENDORS_GET_SUCCESS,
  payload
})

export const vendorsGetFailure = (error) => ({
  type: VENDORS_GET_FAILURE,
  error
})

export const vendorCreateRequest = (payload, callback) => ({
  type: VENDOR_CREATE_REQUEST,
  payload,
  callback
})

export const vendorCreateSuccess = (payload) => ({
  type: VENDOR_CREATE_SUCCESS,
  payload
})

export const vendorCreateFailure = (payload) => ({
  type: VENDOR_CREATE_FAILURE,
  payload
})

export const vendorDeleteRequest = (payload) => ({
  type: VENDOR_DELETE_REQUEST,
  payload
})

export const vendorDeleteSuccess = (payload) => ({
  type: VENDOR_DELETE_SUCCESS,
  payload
})

export const vendorDeleteFailure = (error) => ({
  type: VENDOR_DELETE_FAILURE,
  error
})

export const vendorGetRequest = (payload) => ({
  type: VENDOR_GET_REQUEST,
  payload
})

export const vendorGetSuccess = (payload) => ({
  type: VENDOR_GET_SUCCESS,
  payload
})

export const vendorGetFailure = (error) => ({
  type: VENDOR_GET_FAILURE,
  error
})

export const vendorUpdateRequest = (payload) => ({
  type: VENDOR_UPDATE_REQUEST,
  payload
})

export const vendorUpdateSuccess = (payload) => ({
  type: VENDOR_UPDATE_SUCCESS,
  payload
})

export const vendorUpdateFailure = (error) => ({
  type: VENDOR_UPDATE_FAILURE,
  error
})

export const vendorsCitiesGetRequest = (payload) => ({
  type: VENDORS_CITIES_GET_REQUEST,
  payload
})

export const vendorsCitiesGetSuccess = (payload) => ({
  type: VENDORS_CITIES_GET_SUCCESS,
  payload
})

export const vendorsCitiesGetFailure = (error) => ({
  type: VENDORS_CITIES_GET_FAILURE,
  error
})

export const vendorRatingGetRequest = (payload) => ({
  type: VENDOR_RATING_GET_REQUEST,
  payload
})

export const vendorRatingGetSuccess = (payload) => ({
  type: VENDOR_RATING_GET_SUCCESS,
  payload
})

export const vendorRatingGetFailure = (error) => ({
  type: VENDOR_RATING_GET_FAILURE,
  error
})

export const vendorFeedbacksGetRequest = (payload) => ({
  type: VENDOR_FEEDBACKS_GET_REQUEST,
  payload
})

export const vendorFeedbacksGetSuccess = (payload) => ({
  type: VENDOR_FEEDBACKS_GET_SUCCESS,
  payload
})

export const vendorFeedbacksGetFailure = (error) => ({
  type: VENDOR_FEEDBACKS_GET_FAILURE,
  error
})
