export const PROFILE_GET_REQUEST = 'PROFILE_GET_REQUEST'
export const PROFILE_GET_SUCCESS = 'PROFILE_GET_SUCCESS'
export const PROFILE_GET_FAILURE = 'PROFILE_GET_FAILURE'

export const PROFILE_UPDATE_REQUEST = 'PROFILE_UPDATE_REQUEST'
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS'
export const PROFILE_UPDATE_FAILURE = 'PROFILE_UPDATE_FAILURE'

export const profileGetRequest = () => ({
  type: PROFILE_GET_REQUEST
})

export const profileGetSuccess = (payload) => ({
  type: PROFILE_GET_SUCCESS,
  payload
})

export const profileGetFailure = (error) => ({
  type: PROFILE_GET_FAILURE,
  error
})

export const profileUpdateRequest = (payload) => ({
  type: PROFILE_UPDATE_REQUEST,
  payload
})

export const profileUpdateSuccess = (payload) => ({
  type: PROFILE_UPDATE_SUCCESS,
  payload
})

export const profileUpdateFailure = (error) => ({
  type: PROFILE_UPDATE_FAILURE,
  error
})
