import React, { useCallback, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { StaffRootContext } from '../../../contexts/staff/rootContext'
import { sessionDeleteRequest } from '../../../store/staff/authentication/actions'
import { Button } from '../../UIkit'
import { Logo, LogoAdmin } from '../../UIkit/svgs'
import '../../Landing/Sections/Header/styles.scss'
import '../../Landing/Sections/Header/responsiveStyles.scss'

const StaffHeader = () => {
  const dispatch = useDispatch()
  const history = useNavigate()
  const { authenticated } = useContext(StaffRootContext)

  const onLogout = useCallback(() => {
    dispatch(sessionDeleteRequest({ history }))
  }, [dispatch, history])

  return (
    <div className='header'>
      <div>
        <Logo className='side-bar__logo' />
        <LogoAdmin className='side-bar__logo' width={30} height={20} />
      </div>

      <div>
        {authenticated && (
          <Button size='small' onClick={onLogout}>Log out</Button>
        )}
      </div>
    </div>
  )
}

export default StaffHeader
