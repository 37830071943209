import { createContext, useContext } from 'react'

const defaultValue = {
  cityFilter: '',
  setCityFilter: () => {},
  vendorFilter: '',
  setVendorFilter: () => {},
}

export const FiltersContext = createContext(defaultValue)

export const useFilters = () => {
  return useContext(FiltersContext)
}
