import React, { useState, useMemo, useEffect } from 'react'
import { PerPageContext } from '../contexts/perPageContext'
import { FiltersContext } from '../contexts/filtersContext'
import SideBarContainer from '../containers/SideBar'
import HeaderContainer from '../containers/Header'
import { countRowsPerPage } from '../lib'
import BottomBarContainer from '../containers/BottomBar/BottomBarContainer'
import { isMobile } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'
import LiqpayForm from './LiqpayForm'
import { liqpayAccountCreateRequest } from '../store/liqpayAccounts/actions'

const AppAdmin = ({ children }) => {
  const dispatch = useDispatch()

  const [cityFilter, setCityFilter] = useState('')
  const [vendorFilter, setVendorFilter] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(countRowsPerPage())
  const [isOpen, setIsOpen] = useState(false)
  const { currentSession } = useSelector(({ authentication }) => authentication)
  const [showLiqpayForm, setShowLiqpayForm] = useState(false)

  const createLiqpayAccount = (data) => dispatch(liqpayAccountCreateRequest(data, currentSession.networkId, closeForm ))

  const toggleSidebar = () => {
    setIsOpen(!isOpen)
  }

  const closeForm = () => {
    setShowLiqpayForm(false)
  }

  useEffect(() => {
    if (currentSession.liqpay_account_public_key === null || currentSession.liqpay_account_public_key === undefined) {
      setShowLiqpayForm(true)
    }
  }, [])

  const perPageContextValue = useMemo(
    () => ({
      rowsPerPage: rowsPerPage,
      setRowsPerPage: setRowsPerPage,
    }),
    [rowsPerPage]
  )

  const filtersContextValue = useMemo(
    () => ({
      cityFilter: cityFilter,
      setCityFilter: setCityFilter,
      vendorFilter: vendorFilter,
      setVendorFilter: setVendorFilter,
    }),
    [cityFilter, vendorFilter]
  )

  return (
    <PerPageContext.Provider value={perPageContextValue}>
      <FiltersContext.Provider value={filtersContextValue}>
        <div className='root-wrapper'>
          <SideBarContainer isOpen={isOpen} onClose={toggleSidebar} />
          <div className='main-container'>
            {showLiqpayForm
              ? <LiqpayForm createLiqpayAccount={createLiqpayAccount} />
              : <>
                <HeaderContainer toggleSidebar={toggleSidebar} />
                {children}
                {isMobile && <BottomBarContainer/>}
              </>
            }
          </div>
        </div>
      </FiltersContext.Provider>
    </PerPageContext.Provider>
  )
}

export default AppAdmin
