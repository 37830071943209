import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Text, BoardCard, colors, SmallSwitch } from '../../../../UIkit'
import { Edit, Trash } from '../../../../UIkit/svgs'
import './styles.scss'

const MenuBoardSupplementComponent = ({
  supplement,
  updateSupplement,
  vendorFilter,
  handleEditSupplementModal,
  handleDeleteSupplementModal,
}) => {
  const { t } = useTranslation()

  const { id, name, price, vendorsAvailability } = supplement

  const vendorAvailability = vendorsAvailability.find((a) => a.vendorId === vendorFilter)

  const handleEdit = () => {
    handleEditSupplementModal(id)
  }

  const handleDelete = () => {
    handleDeleteSupplementModal(id)
  }

  const handleChangeAvailability = () => {
    const nextStatus = vendorAvailability?.status === 'active' ? 'inactive' : 'active'

    const data = {
      networkSupplement: {
        vendorSupplementsAttributes: {
          id: vendorAvailability?.id,
          status: nextStatus,
        },
      },
    }

    updateSupplement(id, data)
  }

  const isAvailable = (vendorAvailability) => vendorAvailability?.status === 'active'

  return (
    <BoardCard>
      <div className='menuProducts__supplementProps'>
        <div className='menuProducts__supplementInfo'>
          <div className='menuProducts__supplementName'>
            <Text variant='body-main2'>{name}</Text>
          </div>
          <div className='menuProducts__supplementPrice'>
            <Text shouldBeUppercase variant='body-secondary2'>
              {price} {t('menu:uah')}
            </Text>
          </div>
        </div>
      </div>
      {vendorFilter ? (
        <div className='menuProducts__supplementAvailability'>
          <Text variant={'body-tertiary1'}>{t('menu:availability')}</Text>
          <SmallSwitch
            index={id}
            name={name}
            checked={isAvailable(vendorAvailability)}
            onChange={handleChangeAvailability}
          />
        </div>
      ) : (
        <div className='menuProducts__supplementTools'>
          <Edit color={colors.gray[500]} onClick={handleEdit} style={{ cursor: 'pointer' }} />
          <Trash color={colors.gray[500]} onClick={handleDelete} style={{ cursor: 'pointer' }} />
        </div>
      )}
    </BoardCard>
  )
}

MenuBoardSupplementComponent.propTypes = {
  openEditProduct: PropTypes.func,
  openDeleteProduct: PropTypes.func,
}

export default MenuBoardSupplementComponent
