import React from 'react'
import { Calendar as ReactCalendar } from 'react-calendar'
import './styles.scss'

const Calendar = ({ date, onChange, ...props }) => {
  return (
    <ReactCalendar
      {...props}
      onChange={onChange}
      value={date}
      selectRange={true}
      showFixedNumberOfWeeks
      minDetail={'month'}
      maxDate={new Date()}
      next2Label={null}
      prev2Label={null}
    />
  )
}
export default Calendar
