import React from 'react'
import { Intro, Footer, Header } from './Sections'
import './styles.scss'
import './responsiveStyles.scss'
import HowItWorks from './Sections/HowItWorks'
import Loyalty from './Sections/Loyalty'


function LandingComponent() {
  return (
    <>
      <Header />
      <main className='landing-main'>
        <Intro />
        <HowItWorks/>
        <Loyalty/>
        <Footer />
      </main>
    </>
  )
}

export default LandingComponent
