import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { getOrdersByWeek } from '../../../lib/statisticHelper'
import { EmptyNotification, BarChart, ResultsFor } from '../'
import { Text, Loader } from '../../UIkit'

import './styles.scss'

const TotalOrders = ({ totalOrders, loading }) => {
  const { t } = useTranslation()

  const orders = getOrdersByWeek(totalOrders)

  const labels = orders.map((obj) => obj.date)
  const data = orders.map((obj) => ({ canceledOrders: obj.canceledOrders, finishedOrders: obj.finishedOrders }))

  return (
    <div className='total-orders'>
      <div className='total-orders__header'>
        <Text variant='subheading-bold'>{t('dashboard:totalOrders')}</Text>
        <div className='total-orders__header__legends'>
          <div className='total-orders__header__legends__legend'>
            <div className='total-orders__header__legends__finished' />
            <Text variant='body-tertiary2'>{t('dashboard:finished')}</Text>
          </div>

          <div className='total-orders__header__legends__legend'>
            <div className='total-orders__header__legends__canceled' />
            <Text variant='body-tertiary2'>{t('dashboard:canceled')}</Text>
          </div>
        </div>
      </div>

      <div className='total-orders__content'>
        <ResultsFor />

        <div className='total-orders__chart'>
          {!totalOrders.length && !loading && <EmptyNotification subject='vendor' />}

          {loading && <Loader size={50} inBlock />}

          {!loading && <BarChart labels={labels} data={data} loading={loading} />}
        </div>
      </div>
    </div>
  )
}

TotalOrders.propTypes = {
  totalOrders: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default TotalOrders
