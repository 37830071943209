export const PRODUCTS_CLEAR = 'PRODUCTS_CLEAR'
export const PRODUCTS_CLUSTER_DELETE_REQUEST = 'PRODUCTS_CLUSTER_DELETE_REQUEST'

export const PRODUCTS_GET_REQUEST = 'PRODUCTS_GET_REQUEST'
export const PRODUCTS_GET_SUCCESS = 'PRODUCTS_GET_SUCCESS'
export const PRODUCTS_GET_FAILURE = 'PRODUCTS_GET_FAILURE'
export const PRODUCT_CREATE_REQUEST = 'PRODUCT_CREATE_REQUEST'
export const PRODUCT_CREATE_SUCCESS = 'PRODUCT_CREATE_SUCCESS'
export const PRODUCT_CREATE_FAILURE = 'PRODUCT_CREATE_FAILURE'
export const PRODUCT_UPDATE_REQUEST = 'PRODUCT_UPDATE_REQUEST'
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS'
export const PRODUCT_UPDATE_FAILURE = 'PRODUCT_UPDATE_FAILURE'
export const PRODUCT_DELETE_REQUEST = 'PRODUCT_DELETE_REQUEST'
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS'
export const PRODUCT_DELETE_FAILURE = 'PRODUCT_DELETE_FAILURE'

export const productsGetRequest = (params) => ({
  type: PRODUCTS_GET_REQUEST,
  params,
})

export const productsGetSuccess = (payload) => ({
  type: PRODUCTS_GET_SUCCESS,
  payload,
})

export const productsGetFailure = (error) => ({
  type: PRODUCTS_GET_FAILURE,
  error,
})

export const productCreateRequest = (payload) => ({
  type: PRODUCT_CREATE_REQUEST,
  payload,
})

export const productCreateSuccess = (payload) => ({
  type: PRODUCT_CREATE_SUCCESS,
  payload,
})

export const productCreateFailure = (payload) => ({
  type: PRODUCT_CREATE_FAILURE,
  payload,
})

export const productUpdateRequest = (payload) => ({
  type: PRODUCT_UPDATE_REQUEST,
  payload,
})

export const productUpdateSuccess = (payload) => ({
  type: PRODUCT_UPDATE_SUCCESS,
  payload,
})

export const productUpdateFailure = (payload) => ({
  type: PRODUCT_UPDATE_FAILURE,
  payload,
})

export const productDeleteRequest = (payload) => ({
  type: PRODUCT_DELETE_REQUEST,
  payload,
})

export const productDeleteSuccess = (payload) => ({
  type: PRODUCT_DELETE_SUCCESS,
  payload,
})

export const productDeleteFailure = (payload) => ({
  type: PRODUCT_DELETE_FAILURE,
  payload,
})

// BOARD PRODUCTS

export const BOARD_PRODUCTS_FETCH_REQUEST = 'BOARD_PRODUCTS_FETCH_REQUEST'
export const BOARD_PRODUCTS_FETCH_SUCCESS = 'BOARD_PRODUCTS_FETCH_SUCCESS'
export const BOARD_PRODUCTS_FETCH_FAILURE = 'BOARD_PRODUCTS_FETCH_FAILURE'
export const BOARD_PRODUCT_CREATE_REQUEST = 'BOARD_PRODUCT_CREATE_REQUEST'
export const BOARD_PRODUCT_CREATE_SUCCESS = 'BOARD_PRODUCT_CREATE_SUCCESS'
export const BOARD_PRODUCT_CREATE_FAILURE = 'BOARD_PRODUCT_CREATE_FAILURE'
export const BOARD_PRODUCT_UPDATE_REQUEST = 'BOARD_PRODUCT_UPDATE_REQUEST'
export const BOARD_PRODUCT_UPDATE_SUCCESS = 'BOARD_PRODUCT_UPDATE_SUCCESS'
export const BOARD_PRODUCT_UPDATE_FAILURE = 'BOARD_PRODUCT_UPDATE_FAILURE'
export const BOARD_PRODUCT_DELETE_REQUEST = 'BOARD_PRODUCT_DELETE_REQUEST'
export const BOARD_PRODUCT_DELETE_SUCCESS = 'BOARD_PRODUCT_DELETE_SUCCESS'
export const BOARD_PRODUCT_DELETE_FAILURE = 'BOARD_PRODUCT_DELETE_FAILURE'

export const boardProductsFetchRequest = (payload, callback) => ({
  type: BOARD_PRODUCTS_FETCH_REQUEST,
  payload,
  callback,
})

export const boardProductsFetchSuccess = (payload) => ({
  type: BOARD_PRODUCTS_FETCH_SUCCESS,
  payload,
})

export const boardProductsFetchFailure = (error) => ({
  type: BOARD_PRODUCTS_FETCH_FAILURE,
  error,
})

export const boardProductCreateRequest = (payload) => ({
  type: BOARD_PRODUCT_CREATE_REQUEST,
  payload,
})

export const boardProductCreateSuccess = (payload) => ({
  type: BOARD_PRODUCT_CREATE_SUCCESS,
  payload,
})

export const boardProductCreateFailure = (error) => ({
  type: BOARD_PRODUCT_CREATE_FAILURE,
  error,
})

export const boardProductUpdateRequest = (payload) => ({
  type: BOARD_PRODUCT_UPDATE_REQUEST,
  payload,
})

export const boardProductUpdateSuccess = (payload) => ({
  type: BOARD_PRODUCT_UPDATE_SUCCESS,
  payload,
})

export const boardProductUpdateFailure = (error) => ({
  type: BOARD_PRODUCT_UPDATE_FAILURE,
  error,
})

export const boardProductDeleteRequest = (payload) => ({
  type: BOARD_PRODUCT_DELETE_REQUEST,
  payload,
})

export const boardProductDeleteSuccess = (payload) => ({
  type: BOARD_PRODUCT_DELETE_SUCCESS,
  payload,
})

export const boardProductDeleteFailure = (error) => ({
  type: BOARD_PRODUCT_DELETE_FAILURE,
  error,
})

// CLEAR

export const productsClusterDeleteRequest = (payload) => ({
  type: PRODUCTS_CLUSTER_DELETE_REQUEST,
  payload,
})

export const productsClear = () => ({
  type: PRODUCTS_CLEAR,
})
