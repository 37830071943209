import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Modal, Button, Text, colors } from '../UIkit'

import './styles.scss'

const VendorDelete = ({ isShown, handleDelete, closeModal }) => {
  const { t } = useTranslation()

  return (
    <Modal show={isShown} handleClose={closeModal} withoutExit>
      <div className='vendor-delete'>
        <div className='vendor-delete__title'>
          <Text variant='subheading'>{t('network:deleteVendor')}?</Text>
        </div>
        <div className='vendor-delete__description'>
          <Text variant='body-secondary1' color={colors.gray[500]}>
            {t('network:deleteMessage')}
          </Text>
        </div>
        <div className='vendor-delete__buttons'>
          <Button variant='secondary' onClick={closeModal}>
            {t('btn:cancel')}
          </Button>
          <Button onClick={handleDelete}>{t('actions:delete')}</Button>
        </div>
      </div>
    </Modal>
  )
}

VendorDelete.propTypes = {
  isShown: PropTypes.bool.isRequired,
  subject: PropTypes.string.isRequired,
  handleDelete: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default VendorDelete
