import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, colors, Text } from '../../../UIkit'
import { Email } from '../../../UIkit/svgs'

const CheckEmailForm = ({ email, handleCancel, toPrevStep }) => {
  const { t } = useTranslation()

  return (
    <div className='auth-page__form'>
      <div className='auth-page__form__headline'>
        <div className='auth-page__form__headline-icon'>
          <Email />
        </div>
        <Text variant='subheading'>{t('checkEmail:title')}</Text>
      </div>
      <div className='auth-page__form__info'>
        <Text variant={'body-main1'}>{t('checkEmail:description')}</Text>
        <Text variant={'body-main2'}>{email}</Text>
      </div>
      <div className='auth-page__form__buttons'>
        <Button variant={'secondary'} onClick={handleCancel}>
          {t('btn:cancel')}
        </Button>
      </div>
      <div className='auth-page__form__description'>
        <Text variant={'body-secondary1'} color={colors.gray[500]}>
          {t('checkEmail:notReceive')}&nbsp;
        </Text>
        <Text style='link' variant={'body-secondary2'} color={colors.secondary[500]} onClick={toPrevStep}>
          {t('btn:resend')}
        </Text>
      </div>
    </div>
  )
}

export default CheckEmailForm
