import React from 'react'

import { colors } from '../../UIkit'
import {
  Coffee,
  CoffeeFilled,
  Users,
  UsersFilled
} from '../../UIkit/svgs'

export const links = [
  { path: 'staff/owners', text: 'Owners', svg: <Users />, filledSvg: <UsersFilled color={colors.primary[500]} /> },
  { path: 'staff/networks', text: 'Networks', svg: <Coffee />, filledSvg: <CoffeeFilled color={colors.primary[500]} /> },
]
