import React from 'react'
import { useSelector } from 'react-redux'
import { Routes, Route } from 'react-router-dom'

import { privateRoutes, publicRoutes, privateStaffRoutes } from '../router'
import ProtectedRoute from './ProtectedRoute'
import ProtectedStaffRoute from './Staff/ProtectedStaffRoute'

const AppRouter = () => {
  const session = useSelector((state) => state.authentication)
  const staffSession = useSelector((state) => state.staffAuthentication)

  return (
    <Routes>
      <Route element={<ProtectedRoute session={session} />}>
        {privateRoutes.map((route) => (
          <Route path={route.path} element={route.element} key={route.path} />
        ))}
      </Route>

      {publicRoutes.map((route) => (
        <Route path={route.path} element={route.element} key={route.path} />
      ))}

      <Route element={<ProtectedStaffRoute session={staffSession} />}>
        {privateStaffRoutes.map((route) => (
          <Route path={route.path} element={route.element} key={route.path} />
        ))}
      </Route>
    </Routes>
  )
}

export default AppRouter
