import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFilters } from '../../contexts/filtersContext'
import { useRowsPerPage } from '../../contexts/perPageContext'
import { ordersGetRequest, ordersClear } from '../../store/orders/actions'
import OrderContainer from './OrderContainer'
import OrdersOptions from '../../components/Orders/OrdersOptions'
import OrdersPage from '../../components/Orders/List/OrdersPage'
import { EmptyPageMessage, Loader, Pagination, Text } from '../../components/UIkit'
import './styles.scss'

const OrdersContainer = () => {
  const { t } = useTranslation()

  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState({
    dateFrom: null,
    dateTo: null,
    baristaId: null,
    status: '',
  })
  const [openModalFromNotifications, setOpenModalFromNotifications] = useState(false)
  const [modalOrderId, setModalOrderId] = useState(null)

  const { cityFilter, vendorFilter } = useFilters()

  const { rowsPerPage, setRowsPerPage } = useRowsPerPage()

  const { items: orders, fetching, pages: totalPages } = useSelector((state) => state.orders)

  const networkId = localStorage.getItem('networkId')
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const getOrders = (id, params) => {
    dispatch(ordersGetRequest({ networkId: id, params: params }))
  }

  const clearOrders = () => dispatch(ordersClear())

  const handleChangeFilters = (value) => {
    setFilters({
      dateFrom: value.dateFrom,
      dateTo: value.dateTo,
      baristaId: value.baristaId,
      status: value.status,
    })
  }

  const handleResetFilters = () => {
    setFilters({
      dateFrom: null,
      dateTo: null,
      baristaId: null,
      status: '',
    })
  }

  const handleChangePage = (page) => {
    if (page <= totalPages) {
      setPage(page)
      if (page)
        getOrders(networkId, { page: page, per: rowsPerPage, city: cityFilter, vendorId: vendorFilter, ...filters })
    }
  }

  const handleCloseModal = () => {
    navigate({ state: null })
    setOpenModalFromNotifications(false)
  }

  useEffect(() => {
    if (location.state && location.state.openModal) {
      setOpenModalFromNotifications(true)
      setModalOrderId(location.state.id)
    }
  }, [location.state])

  useEffect(() => {
    setPage(1)
  }, [networkId, rowsPerPage, cityFilter, vendorFilter, filters])

  useEffect(() => {
    getOrders(networkId, { page: 1, per: rowsPerPage, city: cityFilter, vendorId: vendorFilter, ...filters })

    return () => {
      clearOrders()
    }
  }, [networkId, rowsPerPage, cityFilter, vendorFilter, filters])

  return (
    <>
      <div className='orders'>
        <div className='orders__title'>
          <Text variant='h3-medium'>{t('orders:title')}</Text>
          <OrdersOptions
            filters={filters}
            handleChangeFilters={handleChangeFilters}
            handleResetFilters={handleResetFilters}
          />
        </div>
        {fetching ? (
          <Loader size={54} />
        ) : orders.length ? (
          <OrdersPage orders={orders} />
        ) : (
          <EmptyPageMessage text={t('orders:empty')} />
        )}
        <Pagination
          activePage={page}
          perPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangePerPage={setRowsPerPage}
          totalPages={totalPages}
          isShow={orders.length}
        />
      </div>
      {openModalFromNotifications && (
        <OrderContainer id={modalOrderId} isShown={openModalFromNotifications} closeModal={handleCloseModal} />
      )}
    </>
  )
}

export default OrdersContainer
