import { takeLatest, call, put } from 'redux-saga/effects'
import * as actions from './actions'

import Api from '../../lib/api'
import notifications from '../../lib/notifications'
import { sessionRestoreRequest } from '../authentication/actions'

export default function* watchSaga() {
  yield takeLatest(actions.LIQPAY_ACCOUNT_CREATE_REQUEST, watchResourceCreateRequest)
  yield takeLatest(actions.LIQPAY_ACTIVATION_CREATE_REQUEST, watchActivationCraeteRequest)
}

function* watchResourceCreateRequest({ payload, id, callback }) {
  try {
    yield call(Api.LiqpayAccounts.create, payload, id)
    yield put(actions.liqpayAccountCreateSuccess())
    notifications.createNotification('create-success')
    yield put(sessionRestoreRequest())
    if (callback) callback()
  } catch (error) {
    yield put(actions.liqpayAccountCreateFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}

function* watchActivationCraeteRequest({ payload, id, callback }) {
  try {
    yield call(Api.LiqpayAccounts.update, payload, id)
    yield put(actions.liqpayActivationCreateSuccess())
    yield localStorage.removeItem('confirmToken')
    notifications.createNotification('create-success')
    callback()
  } catch (error) {
    yield put(actions.liqpayActivationCreateFailure(error))
    notifications.createNotification('error', error.response.errors)
  }
}
