export const TYPES_CLEAR = 'TYPES_CLEAR'

export const TYPES_FETCH_REQUEST = 'TYPES_FETCH_REQUEST'
export const TYPES_FETCH_SUCCESS = 'TYPES_FETCH_SUCCESS'
export const TYPES_FETCH_FAILURE = 'TYPES_FETCH_FAILURE'

export const typesFetchRequest = (payload) => ({
  type: TYPES_FETCH_REQUEST,
  payload,
})

export const typesFetchSuccess = (payload) => ({
  type: TYPES_FETCH_SUCCESS,
  payload,
})

export const typesFetchFailure = (error) => ({
  type: TYPES_FETCH_FAILURE,
  error,
})

export const TYPE_VERIFY_REQUEST = 'TYPE_VERIFY_REQUEST'
export const TYPE_VERIFY_SUCCESS = 'TYPE_VERIFY_SUCCESS'
export const TYPE_VERIFY_FAILURE = 'TYPE_VERIFY_FAILURE'

export const typeVerifyRequest = (history, payload) => ({
  type: TYPE_VERIFY_REQUEST,
  payload,
  history,
})

export const typeVerifySuccess = (payload) => ({
  type: TYPE_VERIFY_SUCCESS,
  payload,
})

export const typeVerifyFailure = (error) => ({
  type: TYPE_VERIFY_FAILURE,
  error,
})

export const typesClear = () => ({
  type: TYPES_CLEAR,
})
