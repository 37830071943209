import { takeLatest, call, put } from 'redux-saga/effects'
import * as actions from './actions'

import Api from '../../lib/api'
import { toCamelCase } from '../../lib/converter'

import notifications from '../../lib/notifications'

export default function* watcherSaga() {
  yield takeLatest(actions.CASH_TURNOVER_GET_REQUEST, watchResourcesCashTurnoverGetRequest)
  yield takeLatest(actions.TOTAL_ORDERS_GET_REQUEST, watchResourcesTotalOrdersGetRequest)
  yield takeLatest(actions.SALES_BY_CATEGORY_GET_REQUEST, watchResourcesSalesByCategoryGetRequest)
  yield takeLatest(actions.BARISTA_EFFICIENCY_GET_REQUEST, watchResourcesBaristaEfficienciesGetRequest)
  yield takeLatest(actions.VENDOR_COMPARISON_GET_REQUEST, watchResourcesVendorComparisonGetRequest)
}

function* watchResourcesCashTurnoverGetRequest({ payload }) {
  try {
    const response = yield call(Api.Statistics.CashTurnover.get, payload.networkId, payload.params)

    yield put(actions.cashTurnoverGetSuccess(toCamelCase(response.data)))
  } catch (error) {
    yield put(actions.cashTurnoverGetFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}

function* watchResourcesTotalOrdersGetRequest({ payload }) {
  try {
    const response = yield call(Api.Statistics.TotalOrders.get, payload.networkId, payload.params)

    yield put(actions.totalOrdersGetSuccess(toCamelCase(response.data)))
  } catch (error) {
    yield put(actions.totalOrdersGetFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}

function* watchResourcesSalesByCategoryGetRequest({ payload }) {
  try {
    const response = yield call(Api.Statistics.SelesByCategory.get, payload.networkId, payload.params)

    yield put(actions.selesByCategoryGetSuccess(toCamelCase(response.data)))
  } catch (error) {
    yield put(actions.selesByCategoryGetFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}

function* watchResourcesBaristaEfficienciesGetRequest({ payload }) {
  try {
    const response = yield call(Api.Statistics.BaristaEfficiencies.get, payload.networkId, payload.params)

    yield put(actions.baristaEfficiencyGetSuccess(toCamelCase(response.data)))
  } catch (error) {
    yield put(actions.baristaEfficiencyGetFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}

function* watchResourcesVendorComparisonGetRequest({ payload }) {
  try {
    const response = yield call(Api.Statistics.VendorComparison.get, payload.networkId, payload.params)

    yield put(actions.vendorComparisonGetSuccess(toCamelCase(response.data)))
  } catch (error) {
    yield put(actions.vendorComparisonGetFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}
