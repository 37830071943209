import React, { useCallback, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

import { RouteNames } from '../../../../router'
import useOnClickOutside from '../../../../hooks/useOnClickOutside'
import { colors, TableActions, TableCell, TableRow, Text } from '../../../UIkit'
import { Shown, VerticalDots } from '../../../UIkit/svgs'
import TableText from '../../components/TableText'

const NetworkRow = ({ network }) => {
  const actionsRef = useRef()
  const history = useNavigate()
  const [showActions, setShowActions] = useState(false)

  const toggleActions = useCallback(() => {
    setShowActions((prev) => !prev)
  }, [setShowActions])

  useOnClickOutside(actionsRef, () => setShowActions(false))

  const navigateToShow = useCallback(() => {
    history(`${RouteNames.STAFF_NETWORKS}/${network.id}`)
  }, [history, RouteNames, network])

  return (
    <TableRow>
      <TableCell>
        <TableText text={network.name} />
      </TableCell>
      <TableCell>
        <TableText text={network.url} />
      </TableCell>
      <TableCell>
        <TableText text={moment(network.createdAt).format('DD-MM-YYYY')} />
      </TableCell>
      <TableCell>
        <div ref={actionsRef}>
          <VerticalDots className='employees__dots-icon' onClick={toggleActions} />
          {showActions && (
            <TableActions elemRef={actionsRef.current}>
              <div className='employees__action' onClick={navigateToShow}>
                <Shown color={colors.primary[500]} />
                <Text variant='body-main2'>Show network</Text>
              </div>
            </TableActions>
          )}
        </div>
      </TableCell>
    </TableRow>
  )
}

export default NetworkRow
