import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { VendorContext } from '../../../vendorContext'
import { Pagination, Text } from '../../../../../../UIkit'
import { staffVendorSupplementsFetchRequest } from '../../../../../../../store/staff/vendors/supplements/actions'
import SupplementsTable from './SupplementsTable'

const VendorSupplements = () => {
  const { vendorId } = useContext(VendorContext)

  const dispatch = useDispatch()
  const { data: supplements, pages: totalPages, fetching } = useSelector(
    state => state.staffVendorSupplements
  )

  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const getSupplements = useCallback((params) => {
    dispatch(staffVendorSupplementsFetchRequest({ vendorId: vendorId, pagyParams: params }))
  }, [dispatch, staffVendorSupplementsFetchRequest, vendorId])

  const onChangePage = useCallback((page = 1) => {
    if (page > totalPages) return

    setPage(page)
    getSupplements({ page: page, per: rowsPerPage })
  }, [totalPages, rowsPerPage, vendorId])

  useEffect(() => {
    onChangePage(1)
  }, [rowsPerPage, vendorId])

  const isSupplementsExists = !!supplements.length

  if (fetching) return null

  return (
    <div className='d-flex flex-column'>
      <Text variant='h3' className='mb-3'>Supplements</Text>

      {isSupplementsExists ? (
        <SupplementsTable supplements={supplements} />
      ) : (
        <Text variant='subheading'>Supplements list is empty</Text>
      )}

      <div className='w-100'>
        <Pagination
          activePage={page}
          perPage={rowsPerPage}
          handleChangePage={onChangePage}
          handleChangePerPage={setRowsPerPage}
          totalPages={totalPages}
          isShow={isSupplementsExists}
        />
      </div>
    </div>
  )
}

export default VendorSupplements
