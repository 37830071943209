import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

import { RouteNames } from '../../../router/index'
import {
  AddCategoryModal,
  RemoveCategoryModal,
  CreateUpdateProductModal,
  CreateUpdateSupplementModal,
  DeleteProductModal,
} from './Modals'
import { Text, Button, EmptyPageMessage, colors } from '../../UIkit'
import { RightArrow, Plus } from '../../UIkit/svgs'
import Loader from '../../Loader'
import BoardComponent from './Board/BoardComponent'
import './styles.scss'
import AddButton from '../../UIkit/AddButton'

const ProductsComponent = ({
  vendorFilter,
  categories,
  productsClusters,
  supplementsClusters,
  addCategory,
  removeCategory,
  fetchProducts,
  createProduct,
  updateProduct,
  deleteProduct,
  fetchSupplements,
  createSupplement,
  updateSupplement,
  deleteSupplement,
  fetching,
}) => {
  const { t } = useTranslation()

  const { type } = useParams()
  const history = useNavigate()
  const itsSupplements = type === 'supplements'

  const [categoryId, setCategoryId] = useState(null)
  const [productId, setProductId] = useState(null)
  const [supplementId, setSupplementId] = useState(null)
  const [showAddCategoryModal, setAddCategoryModal] = useState(false)
  const [showRemoveCategoryModal, setRemoveCategoryModal] = useState(false)
  const [showCreateUpdateProductModal, setCreateUpdateProductModal] = useState(false)
  const [showDeleteProductModal, setDeleteProductModal] = useState(false)
  const [showCreateUpdateSupplementModal, setCreateUpdateSupplementModal] = useState(false)
  const [showDeleteSupplementModal, setDeleteSupplementModal] = useState(false)

  const handleAddCategoryModal = () => {
    setAddCategoryModal((prev) => !prev)
  }

  const handleRemoveCategoryModal = (id) => {
    if (!showRemoveCategoryModal) setCategoryId(id)
    setRemoveCategoryModal((prev) => !prev)
  }

  const handleCreateProductModal = (id) => {
    if (!showCreateUpdateProductModal) setCategoryId(id)
    setCreateUpdateProductModal((prev) => !prev)
    setProductId(null)
  }

  const handleEditProductModal = (id) => {
    if (!showCreateUpdateProductModal) setProductId(id)
    setCreateUpdateProductModal((prev) => !prev)
    setCategoryId(null)
  }

  const handleDeleteProductModal = (id) => {
    if (!showDeleteProductModal) setProductId(id)
    setDeleteProductModal((prev) => !prev)
  }

  const handleCreateSupplementModal = (id) => {
    if (!showCreateUpdateSupplementModal) setCategoryId(id)
    setCreateUpdateSupplementModal((prev) => !prev)
    setSupplementId(null)
  }

  const handleEditSupplementModal = (id) => {
    if (!showCreateUpdateSupplementModal) setSupplementId(id)
    setCreateUpdateSupplementModal((prev) => !prev)
    setCategoryId(null)
  }

  const handleDeleteSupplementModal = (id) => {
    if (!showDeleteSupplementModal) setSupplementId(id)
    setDeleteSupplementModal((prev) => !prev)
  }

  const handleBack = () => {
    history(RouteNames.MENU_TYPES)
  }

  return (
    <div className='menuProducts'>
      <div className='menuProducts__header'>
        <div className='menuProducts__bc'>
          <Text variant={'h3-medium'} onClick={handleBack} style='menuProducts__bcParent' color={colors.gray[400]}>
            {t('menu:title')}
          </Text>
          <RightArrow />
          <Text variant={'h3-medium'} color={colors.gray[800]}>
            {t(`dashboard:${type.toLowerCase()}`)}
          </Text>
        </div>
        {!isMobile ?
          <div>
            <Button onClick={handleAddCategoryModal}>
              <Plus />
              {t('menu:addCategory')}
            </Button>
          </div> :
          <AddButton onClick={handleAddCategoryModal}>
            <Plus color='white' />
          </AddButton>
        }
      </div>
      <div className='menuProducts__wrapper'>
        <Loader size={54} isLoading={fetching} />
        {!fetching && categories.length > 0 && (
          <BoardComponent
            itsSupplements={itsSupplements}
            vendorFilter={vendorFilter}
            categories={categories}
            products={productsClusters}
            supplements={supplementsClusters}
            fetchProducts={fetchProducts}
            updateProduct={updateProduct}
            fetchSupplements={fetchSupplements}
            updateSupplement={updateSupplement}
            handleRemoveCategoryModal={handleRemoveCategoryModal}
            handleCreateProductModal={handleCreateProductModal}
            handleEditProductModal={handleEditProductModal}
            handleDeleteProductModal={handleDeleteProductModal}
            handleCreateSupplementModal={handleCreateSupplementModal}
            handleEditSupplementModal={handleEditSupplementModal}
            handleDeleteSupplementModal={handleDeleteSupplementModal}
          />
        )}
        {!fetching && categories.length < 1 && <EmptyPageMessage text={t('menu:emptyCategories')} />}
      </div>
      {showAddCategoryModal && (
        <AddCategoryModal
          isShown={showAddCategoryModal}
          closeModal={handleAddCategoryModal}
          addCategory={addCategory}
        />
      )}
      {showRemoveCategoryModal && (
        <RemoveCategoryModal
          isShown={showRemoveCategoryModal}
          closeModal={handleRemoveCategoryModal}
          categoryId={categoryId}
          removeCategory={removeCategory}
        />
      )}
      {showCreateUpdateProductModal && (
        <CreateUpdateProductModal
          isShown={showCreateUpdateProductModal}
          closeModal={handleCreateProductModal}
          vendorFilter={vendorFilter}
          categoryId={categoryId}
          productId={productId}
          createProduct={createProduct}
          updateProduct={updateProduct}
        />
      )}
      {showDeleteProductModal && (
        <DeleteProductModal
          isShown={showDeleteProductModal}
          closeModal={handleDeleteProductModal}
          productId={productId}
          deleteProduct={deleteProduct}
        />
      )}
      {showCreateUpdateSupplementModal && (
        <CreateUpdateSupplementModal
          isShown={showCreateUpdateSupplementModal}
          closeModal={handleCreateSupplementModal}
          vendorFilter={vendorFilter}
          categoryId={categoryId}
          supplementId={supplementId}
          createSupplement={createSupplement}
          updateSupplement={updateSupplement}
        />
      )}
      {showDeleteSupplementModal && (
        <DeleteProductModal
          isShown={showDeleteSupplementModal}
          closeModal={handleDeleteSupplementModal}
          supplementId={supplementId}
          deleteProduct={deleteSupplement}
        />
      )}
    </div>
  )
}

ProductsComponent.propTypes = {
  vendorFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  categories: PropTypes.array,
  productsClusters: PropTypes.object,
  addCategory: PropTypes.func,
  removeCategory: PropTypes.func,
  fetchProducts: PropTypes.func,
  createProduct: PropTypes.func,
  updateProduct: PropTypes.func,
  deleteProduct: PropTypes.func,
  fetchSupplements: PropTypes.func,
  createSupplement: PropTypes.func,
  updateSupplement: PropTypes.func,
  deleteSupplement: PropTypes.func,
  fetching: PropTypes.bool,
}

export default ProductsComponent
