import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Chart from 'chart.js/auto'
import { sumBy } from 'lodash'

import { colors } from '../../UIkit'

const DoughnutChart = ({ data, labels }) => {
  const chartRef = useRef()

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d')

    const chartData = {
      labels: labels,
      datasets: [
        {
          data: data,
          backgroundColor: [
            colors.secondary[400],
            colors.primary[500],
            colors.base.green,
            colors.base.violet,
            colors.base.yellow
          ],
        },
      ]
    }

    const renderPrice = (chart, numberLabel) => {
      const { ctx, chartArea: { top, width, height } } = chart

      return (
        ctx.font = 'bold 14px Montserrat',
        ctx.fillText(`₴ ${numberLabel.toLocaleString()}`, width / 2, (height - 20) / 2 + top)

      )
    }

    const hoverLabel = {
      id: 'hoverLabel',
      afterDraw(chart) {
        const { ctx, chartArea: { top, width, height } } = chart
        ctx.save()
        if (chart._active.length > 0) {
          const numberLabel = chart.config.data.datasets[chart._active[0].datasetIndex]
            .data[chart._active[0].index]
          const color = chart.config.data.datasets[chart._active[0].datasetIndex]
            .backgroundColor[chart._active[0].index]
          const total = sumBy(chart.config.data.datasets[0].data)
          const percentLabel = total == 0 ? 0 : (numberLabel / total) * 100

          ctx.font = 'normal 13px Montserrat'
          ctx.textAlign = 'center'
          ctx.fillText(`(${Math.round(percentLabel)} %)`, width / 2, (height + 20) / 2 + top)

          renderPrice(chart, numberLabel)

          ctx.beginPath()
          ctx.moveTo((width - 80) / 2, (height - 40) / 2 + top)
          ctx.lineTo((width - 80)/ 2, (height + 40) / 2 + top)
          ctx.lineWidth = 5
          ctx.strokeStyle = color
          ctx.stroke()
          ctx.restore()
        }
        ctx.restore()
      }
    }

    new Chart(ctx, {
      type: 'doughnut',
      data: chartData,
      spacing: 2,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          customCanvasBackgroundColor: {
            color: colors.base.white,
          },

          plugins: {
            emptyDoughnut: {
              color: 'rgba(255, 128, 0, 0.5)',
              width: 2,
              radiusDecrease: 20
            }
          },

          tooltip: {
            enabled: false,
          },

          legend: {
            display: true,
            position: 'bottom',
            labels: {
              boxWidth: 12,
              usePointStyle: true,
              pointStyle: 'circle',
              padding: 32
            }
          },
        },
      },

      plugins: [hoverLabel]
    })
  }, [])

  Chart.defaults.font.size = 13
  Chart.defaults.color = colors.gray[800]
  Chart.defaults.font.family = 'Montserrat'
  Chart.defaults.font.weight = 500

  return (
    <canvas id='myChart' ref={chartRef} width='100%'></canvas>
  )
}

DoughnutChart.propTypes = {
  labels: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired
}

export default DoughnutChart
