import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import './styles.scss'
import { Validator, formatPhoneNumber } from '../../lib'
import { handleValidate } from '../../lib/validationHelper'
import { Text, Button, Input, TextArea } from '../UIkit'

const LiqpayForm = ({ createLiqpayAccount }) => {
  const { t } = useTranslation()

  const [form, setForm] = useState({
    name: '',
    url: 'https://pinka.coffee',
    description: '',
    phone: '+380',
    email: '',
    iban: '',
    okpo: '',
    company: ''
  })

  const [formErrors, setFormErrors] = useState({
    name: '',
    description: '',
    phone: '',
    email: '',
    iban: '',
    okpo: '',
    company: ''
  })

  const validationFields = [
    ['name', Validator.concepts.isFilled, [form.name]],
    ['description', Validator.concepts.isFilled, [form.description]],
    ['phone', Validator.concepts.phoneRegex, [form.phone]],
    ['email', Validator.concepts.isFilled, [form.email]],
    ['iban', Validator.concepts.isFilled, [form.iban]],
    ['okpo', Validator.concepts.edrpouRegex, [form.okpo]],
    ['company', Validator.concepts.isFilled, [form.company]],
  ]

  const handleUpdateField = ({ target }) => {
    setForm((prev) => ({ ...prev, [target.name]: target.value }))
  }
  const handleUpdatePhone = ({ target }) => {
    setForm((prev) => ({ ...prev, [target.name]: formatPhoneNumber(target.value) }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const data = {
      liqpayAccount: {
        phone: form.phone,
        email: form.email,
        iban: form.iban,
        okpo: form.okpo,
        company: form.company,
        description: form.description,
        site: form.url,
        name: form.name
      }
    }

    handleValidate(validationFields)
      .then(() => createLiqpayAccount(data))
      .catch((errors) => setFormErrors(errors))
  }

  return (
    <form className='liqpay-account-page__form' onSubmit={handleSubmit}>
      <div className='liqpay-account-page__form__container'>
        <div className='liqpay-account-page__form__headline'>
          <Text variant='subheading'>{t('liqpayAccount:title')}</Text>
        </div>
        <Input
          id='network-name'
          name='name'
          placeholder={t('input:networkName')}
          value={form.name}
          onChange={handleUpdateField}
        />
        <Input
          id='email'
          name='email'
          placeholder={t('input:email')}
          value={form.email}
          onChange={handleUpdateField}
          error={formErrors.email}
        />
        <Input
          id='phone'
          name='phone'
          placeholder={t('input:phone')}
          value={form.phone}
          onChange={handleUpdatePhone}
          error={formErrors.phone}
        />
        <Input
          id='iban'
          name='iban'
          placeholder={t('input:iban')}
          value={form.iban}
          onChange={handleUpdateField}
          error={formErrors.iban}
        />
        <Input
          id='company'
          name='company'
          placeholder={t('input:recipientBank')}
          value={form.company}
          onChange={handleUpdateField}
          error={formErrors.company}
        />
        <Input
          id='okpo'
          name='okpo'
          placeholder={t('input:edrpou')}
          value={form.okpo}
          onChange={handleUpdateField}
          error={formErrors.okpo}
        />
        <TextArea
          id='about'
          name='description'
          placeholder={t('input:about')}
          value={form.description}
          onChange={handleUpdateField}
          rows={8}
        />
        <Button type='submit' disabled={form.name == ''}>
          {t('btn:signup')}
        </Button>
      </div>
    </form>
  )
}

export default LiqpayForm
