import React from 'react'

import VendorProducts from './Products/VendorProducts'
import VendorSupplements from './Supplements/VendorSupplements'

const MenuTab = () => {
  return (
    <div className='page-content-container mt-3'>
      <VendorProducts />
      <VendorSupplements />
    </div>
  )
}

export default MenuTab
