import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { posterCreateRequest } from '../../../store/networks/actions'

const PosterContainer = () => {
  const dispatch = useDispatch()
  const history = useNavigate()

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const codeValue = queryParams.get('code')
    const projectName = queryParams.get('account')
    const data = { code: codeValue, projectName: projectName }
    dispatch(posterCreateRequest({ data, history }))
  }, [])

  return null
}

export default PosterContainer
