import React from 'react'

import TableText from '../../../../../components/TableText'
import { TableCell, TableRow } from '../../../../../../UIkit'

const SupplementRow = ({ supplement }) => {
  return (
    <TableRow>
      <TableCell>
        <TableText text={supplement.name} />
      </TableCell>
      <TableCell>
        <TableText text={supplement.category} />
      </TableCell>
      <TableCell>
        <TableText text={`${supplement.price} UAH`} />
      </TableCell>
      <TableCell>
        <TableText text={supplement.availability} />
      </TableCell>
    </TableRow>
  )
}

export default SupplementRow
