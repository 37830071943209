import { takeLatest, call, put } from 'redux-saga/effects'
import * as actions from './actions'
import { productsClusterDeleteRequest } from '../products/actions'
import { supplementsClusterDeleteRequest } from '../supplements/actions'

import Api from '../../lib/api'
import notifications from '../../lib/notifications'
import { toCamelCase } from '../../lib/converter'

export default function* watcherSaga() {
  yield takeLatest(actions.CATEGORIES_FETCH_REQUEST, watchCategoriesFetchRequest)
  yield takeLatest(actions.CATEGORY_ADD_REQUEST, watchCategoryAddRequest)
  yield takeLatest(actions.CATEGORY_DELETE_REQUEST, watchCategoryDeleteRequest)

  function* watchCategoriesFetchRequest({ payload, history }) {
    try {
      const response = yield call(Api.Categories.fetch, payload.networkId, payload.params)

      yield put(actions.categoriesFetchSuccess(toCamelCase(response.data)))
    } catch (error) {
      yield put(actions.categoriesFetchFailure(error))
      notifications.createNotification('error', error.response?.data.errors)
      history('/menu')
    }
  }

  function* watchCategoryAddRequest({ payload }) {
    try {
      const response = yield call(Api.Categories.add, payload.networkId, payload.id)

      yield put(actions.categoryAddSuccess(toCamelCase(response.data)))
      notifications.createNotification('create-success')
    } catch (error) {
      yield put(actions.categoryAddFailure(error))
      notifications.createNotification('error', error.response?.data?.errors)
    }
  }

  function* watchCategoryDeleteRequest({ payload }) {
    try {
      yield call(Api.Categories.remove, payload.networkId, payload.id, payload.vendorId)

      yield put(actions.categoryDeleteSuccess(payload.id))
      yield put(productsClusterDeleteRequest(payload.id))
      yield put(supplementsClusterDeleteRequest(payload.id))
      notifications.createNotification('delete-success')
    } catch (error) {
      yield put(actions.categoryDeleteFailure(error))
      notifications.createNotification('error', error.response?.data.errors)
    }
  }
}
