import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'

import { Input, Checkbox, Button } from '../UIkit'
import Validator from '../../lib/validator'

const BankAccountForm = ({ createBankAccount }) => {
  const { t } = useTranslation()
  const emptyForm = {
    recipient: '',
    iban: '',
    code: '',
    mfo: '',
    default: false,
  }

  const [form, setForm] = useState(emptyForm)

  const [formErrors, setFormErrors] = useState({
    recipient: '',
    iban: '',
    mfo: '',
    code: ''
  })

  const [saveDisabled, setSaveDisabled] = useState(true)

  useEffect(() => {
    if(!isEmpty(form.recipient) && !isEmpty(form.iban) && !isEmpty(form.code) && isEmpty(formErrors.code)) {
      setSaveDisabled(false)
    } else {
      setSaveDisabled(true)
    }
  }, [form, formErrors])

  const handleValidateFields = () =>
    new Promise((resolve, reject) => {
      const errors = Validator.validate([
        ['recipient', Validator.concepts.isFilled, [form.recipient]],
        ['iban', Validator.concepts.isLongerThan, [28, [form.iban]]],
        ['code', Validator.concepts.edrpouRegex, [form.code]],
        ['mfo', Validator.concepts.mfoRegex, [form.mfo]]
      ])

      Validator.clear()

      isEmpty(errors) && isEmpty(formErrors.code) ? resolve() : reject(errors)
    })

  const handleValidate = () => {
    handleValidateFields().then(() => handleSubmit()).catch((errors => setFormErrors(errors)))
  }

  const handleSubmit = () => {
    createBankAccount({ bankAccount: form })
    setForm(emptyForm)
  }

  const handleChange = ({ target }) => {
    if (target.type == 'checkbox') {
      setForm((prev) => ({ ...prev, [target.name]: target.checked }))
    } else {
      setFormErrors((prev) => ({ ...prev, [target.name]: '' }))
      setForm((prev) => ({ ...prev, [target.name]: target.value }))
    }
  }

  return (
    <div className='withdrawal-form'>
      <div className='withdrawal-form__inputs'>
        <Input
          id='recipient'
          name='recipient'
          error={formErrors.recipient}
          placeholder={t('input:recipientName')}
          value={form.recipient}
          onChange={handleChange}
        />
        <Input
          id='iban'
          name='iban'
          error={formErrors.iban}
          placeholder={t('input:iban')}
          value={form.iban}
          onChange={handleChange}
        />
        <div className='withdrawal-form__inputs-code'>
          <Input
            id='code'
            name='code'
            error={formErrors.code}
            placeholder={t('input:ipnEdrpou')}
            value={form.code}
            onChange={handleChange}
          />
          <Input
            id='mfo'
            name='mfo'
            error={formErrors.mfo}
            placeholder={t('input:mfo')}
            value={form.mfo}
            onChange={handleChange}
          />
        </div>
      </div>

      <div>
        <Checkbox
          id='default'
          name='default'
          label={t('settings:withdrawalMethod:setAsDefault')}
          onChange={handleChange}
          checked={form.default}
        />
      </div>

      <div className='withdrawal-form__save'>
        <Button
          onClick={handleValidate}
          disabled={saveDisabled}
        >
          {t('btn:save')}
        </Button>
      </div>
    </div>
  )
}

BankAccountForm.propTypes = {
  createBankAccount: PropTypes.func.isRequired,
}

export default BankAccountForm
