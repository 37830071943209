import {
  PRODUCTS_GET_REQUEST,
  PRODUCTS_GET_SUCCESS,
  PRODUCTS_GET_FAILURE,
  PRODUCT_CREATE_REQUEST,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_CREATE_FAILURE,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAILURE,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_FAILURE,
  BOARD_PRODUCTS_FETCH_REQUEST,
  BOARD_PRODUCTS_FETCH_SUCCESS,
  BOARD_PRODUCTS_FETCH_FAILURE,
  BOARD_PRODUCT_CREATE_REQUEST,
  BOARD_PRODUCT_CREATE_SUCCESS,
  BOARD_PRODUCT_CREATE_FAILURE,
  BOARD_PRODUCT_UPDATE_REQUEST,
  BOARD_PRODUCT_UPDATE_SUCCESS,
  BOARD_PRODUCT_UPDATE_FAILURE,
  BOARD_PRODUCT_DELETE_REQUEST,
  BOARD_PRODUCT_DELETE_SUCCESS,
  BOARD_PRODUCT_DELETE_FAILURE,
  PRODUCTS_CLUSTER_DELETE_REQUEST,
  PRODUCTS_CLEAR,
} from './actions'

import _ from 'lodash'

const initialState = {
  items: [],
  item: {},
  clusters: {},
  fetching: false,
}

export default (state = initialState, { type, payload }) => {
  let totalPages
  let categoryId
  let prevItems
  let prevCluster
  let newClusters

  switch (type) {
    case PRODUCTS_GET_REQUEST:
      return { ...state, fetching: true }
    case PRODUCTS_GET_SUCCESS:
      return { ...state, items: payload, fetching: false }
    case PRODUCTS_GET_FAILURE:
      return { ...state, fetching: false }
    case PRODUCT_UPDATE_REQUEST:
      return { ...state, fetching: true }
    case PRODUCT_UPDATE_SUCCESS:
      return {
        ...state,
        items: {
          data: state.items.data.map((i) => (i.id == payload.data.id ? payload.data : i)),
        },
        fetching: false,
      }
    case PRODUCT_UPDATE_FAILURE:
      return { ...state, fetching: false }
    case PRODUCT_CREATE_REQUEST:
      return { ...state, fetching: true }
    case PRODUCT_CREATE_SUCCESS:
      return {
        ...state,
        items: { data: [payload.data, ...state.items.data] },
        fetching: false,
      }
    case PRODUCT_CREATE_FAILURE:
      return { ...state, fetching: false }
    case PRODUCT_DELETE_REQUEST:
      return {
        ...state,
        items: {
          ...state.items,
          data: state.items.data.filter((_, l) => l !== payload.index),
        },
        fetching: true,
      }
    case PRODUCT_DELETE_SUCCESS:
      return { ...state, fetching: false }
    case PRODUCT_DELETE_FAILURE:
      return { ...state, fetching: false }

      // BOARD

    case BOARD_PRODUCTS_FETCH_REQUEST:
      return { ...state, fetching: true }

    case BOARD_PRODUCTS_FETCH_SUCCESS:
      categoryId = payload.params.categoryId.toString()
      prevCluster = _.isUndefined(state.clusters[categoryId]) ? [] : state.clusters[categoryId]
      newClusters = {
        ...state.clusters,
        [categoryId]: [...prevCluster, ...payload.data],
      }
      return {
        ...state,
        items: payload.data,
        clusters: newClusters,
        fetching: false,
      }
    case BOARD_PRODUCTS_FETCH_FAILURE:
      return { ...state, error: payload, fetching: false }

    case BOARD_PRODUCT_CREATE_REQUEST:
      return { ...state }

    case BOARD_PRODUCT_CREATE_SUCCESS:
      categoryId = payload.productCategoryId.toString()
      prevCluster = _.isUndefined(state.clusters[categoryId]) ? [] : state.clusters[categoryId]
      newClusters = {
        ...state.clusters,
        [categoryId]: [payload, ...prevCluster],
      }
      return { ...state, items: [payload, ...state.items], clusters: newClusters }

    case BOARD_PRODUCT_CREATE_FAILURE:
      return { ...state, error: payload }

    case BOARD_PRODUCT_UPDATE_REQUEST:
      return { ...state }

    case BOARD_PRODUCT_UPDATE_SUCCESS:
      categoryId = payload.productCategoryId.toString()
      totalPages = state.clusters[categoryId][0].pages
      prevItems = state.items.filter((el) => el.id != payload.id)
      newClusters = {
        ...state.clusters,
        [categoryId]: state.clusters[categoryId].map((el) =>
          el.id == payload.id ? { ...payload, pages: totalPages } : el
        ),
      }
      return {
        ...state,
        items: [{ ...payload, pages: totalPages }, ...prevItems],
        clusters: newClusters,
        fetching: false,
      }

    case BOARD_PRODUCT_UPDATE_FAILURE:
      return { ...state, error: payload }

    case BOARD_PRODUCT_DELETE_REQUEST:
      return { ...state }

    case BOARD_PRODUCT_DELETE_SUCCESS: {
      categoryId = payload.categoryId
      prevItems = state.items.filter((el) => el.id != payload.id)
      prevCluster = _.isUndefined(state.clusters[categoryId])
        ? []
        : state.clusters[categoryId].filter((el) => el.id != payload.id)
      newClusters = {
        ...state.clusters,
        [categoryId]: prevCluster,
      }
      return {
        ...state,
        items: prevItems,
        clusters: newClusters,
      }
    }
    case BOARD_PRODUCT_DELETE_FAILURE:
      return { ...state, error: payload }

      // CLEAR

    case PRODUCTS_CLUSTER_DELETE_REQUEST:
      categoryId = payload
      newClusters = _.omit(state.clusters, [categoryId])
      return { ...state, clusters: newClusters }

    case PRODUCTS_CLEAR:
      return initialState

    default:
      return state
  }
}
