import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import NotificationsComponent from '../../components/Notifications/NotificationsComponent'
import UnopenedList from '../../components/Notifications/List/UnopenedList'
import OpenedList from '../../components/Notifications/List/OpenedList'
import { colors, Text } from '../../components/UIkit'
import { subscriptionsGetRequest } from '../../store/subscriptions/actions'
import {
  notificationsBulkDeleteRequest,
  notificationsGetOpenedRequest,
  notificationsGetRequest,
  notificationsOpenAllRequest,
  notificationsOpenOneRequest,
  reduceNotifications,
} from '../../store/notifications/actions'
import { NOTIFICATIONS_TABS } from '../../constants'
import { renderNotificationIcon } from '../../lib'
import './styles.scss'

const NotificationsContainer = ({ handleCloseNotifications }) => {
  const [tab, setTab] = useState(NOTIFICATIONS_TABS.new)
  const [checkedIds, setCheckedIds] = useState([])

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const networkId = localStorage.getItem('networkId')

  const navigateTo = useNavigate()

  const { id: userId } = useSelector((state) => state.authentication.currentSession)
  const { unopenedCount, items: unopenedItems, openedItems } = useSelector((state) => state.notifications)

  const isNew = tab === NOTIFICATIONS_TABS.new
  const isArchive = tab === NOTIFICATIONS_TABS.archive
  const isAnyUnopened = unopenedItems.length > 0
  const isAnyOpened = openedItems.length > 0
  const isAnyChecked = checkedIds.length > 0

  const handleSetNewTab = () => {
    if (tab != NOTIFICATIONS_TABS.new) setTab(NOTIFICATIONS_TABS.new)
  }

  const handleSetArchiveTab = () => {
    if (tab != NOTIFICATIONS_TABS.archive) setTab(NOTIFICATIONS_TABS.archive)
  }

  const notificationChecked = (id) => checkedIds.includes(id)

  const handleUpdateCheckedIds = ({ target }) => {
    if (target.checked) {
      setCheckedIds((prev) => [...prev, Number(target.id)])
    } else {
      setCheckedIds((prev) => prev.filter((checkedId) => checkedId != Number(target.id)))
    }
  }

  useEffect(() => {
    dispatch(notificationsGetRequest({ networkId, userId, params: { page: 1, filter: 'unopened' } }))
    dispatch(notificationsGetOpenedRequest({ networkId, userId, params: { page: 1, filter: 'opened' } }))
    dispatch(subscriptionsGetRequest({ networkId, userId }))
  }, [networkId, userId])

  const fetchUnopenedNotifications = (page, callback) => {
    dispatch(notificationsGetRequest({ networkId, userId, params: { page: page, filter: 'unopened' } }, callback))
  }

  const fetchOpenedNotifications = (page, callback) => {
    dispatch(notificationsGetOpenedRequest({ networkId, userId, params: { page: page, filter: 'opened' } }, callback))
  }

  const handleOpenAll = () => {
    dispatch(notificationsOpenAllRequest({ networkId, userId }))
  }

  const handleOpenOne = (id) => {
    dispatch(notificationsOpenOneRequest({ networkId, userId, id, page: 1 }))
  }

  const handleDeleteSelected = () => {
    const data = { ids: checkedIds }

    dispatch(notificationsBulkDeleteRequest({ networkId, userId, params: data }))
    setCheckedIds([])
  }

  const handleMoveToSettings = () => {
    handleCloseNotifications()

    navigateTo('/settings', {
      state: {
        tab: 'notifications',
      },
    })
  }

  const handleNavigateToTarget = (notification) => {
    handleCloseNotifications()
    handleOpenOne(notification.id)

    if (notification.key === 'feedback.create') {
      navigateTo('/network', {
        state: {
          id: notification.notifiable.vendorId,
          openModal: true,
        },
      })
    } else {
      navigateTo('/orders', {
        state: {
          id: notification.notifiableId,
          openModal: true,
        },
      })
    }
  }

  const unopenedPageRef = useRef(1)
  const openedPageRef = useRef(1)

  useEffect(() => {
    dispatch(reduceNotifications())
  }, [])

  const renderTab = () => {
    switch (tab) {
      case 'new':
        return isAnyUnopened ? (
          <UnopenedList
            pageRef={unopenedPageRef}
            isArchive={isArchive}
            unopenedItems={unopenedItems}
            fetchNotifications={fetchUnopenedNotifications}
            notificationChecked={notificationChecked}
            renderNotificationIcon={renderNotificationIcon}
            handleNavigateToTarget={handleNavigateToTarget}
            handleUpdateCheckedIds={handleUpdateCheckedIds}
          />
        ) : (
          <div className='notifications__empty-message'>
            <Text variant={'body-secondary1'} color={colors.gray[500]}>
              {t('notifications:noNew')}
            </Text>
          </div>
        )
      case 'archive':
        return isAnyOpened ? (
          <OpenedList
            pageRef={openedPageRef}
            isArchive={isArchive}
            openedItems={openedItems}
            fetchNotifications={fetchOpenedNotifications}
            notificationChecked={notificationChecked}
            renderNotificationIcon={renderNotificationIcon}
            handleNavigateToTarget={handleNavigateToTarget}
            handleUpdateCheckedIds={handleUpdateCheckedIds}
          />
        ) : (
          <div className='notifications__empty-message'>
            <Text variant={'body-secondary1'} color={colors.gray[500]}>
              {t('notifications:noOpened')}
            </Text>
          </div>
        )
    }
  }

  return (
    <NotificationsComponent
      isNew={isNew}
      isArchive={isArchive}
      isAnyUnopened={isAnyUnopened}
      isAnyChecked={isAnyChecked}
      unopenedCount={unopenedCount}
      renderTab={renderTab}
      handleOpenAll={handleOpenAll}
      handleMoveToSettings={handleMoveToSettings}
      handleDeleteSelected={handleDeleteSelected}
      handleSetNewTab={handleSetNewTab}
      handleSetArchiveTab={handleSetArchiveTab}
    />
  )
}

export default NotificationsContainer
