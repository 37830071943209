import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Text, colors, Checkbox } from '../../UIkit'

const NotificationElement = ({
  isArchive,
  notification,
  handleNavigateToTarget,
  notificationChecked,
  handleUpdateCheckedIds,
  renderIcon,
}) => {
  const { t, i18n } = useTranslation()

  moment.locale(i18n.language)

  return (
    <div className='notifications__item'>
      {isArchive && (
        <Checkbox
          id={notification.id}
          checked={notificationChecked(notification.id)}
          onChange={handleUpdateCheckedIds}
          className='notifications__checkbox'
        />
      )}
      {renderIcon(notification)}
      <div className='notifications__item-body'>
        <div className='notifications__item-body-title' onClick={() => handleNavigateToTarget(notification)}>
          <Text variant={'body-secondary1'}>{notification.text}</Text>
        </div>
        <div className='notifications__item-body-details'>
          <Text variant={'label-regular'} color={colors.gray[400]}>
            {moment(Date.parse(notification.createdAt)).fromNow()}
          </Text>
          <div className='notifications__item-body-dot' />
          <Text variant={'label-regular'} color={colors.gray[400]}>
            {t(`notifications:${notification.printableNotifiableName?.toLowerCase()}`)}
          </Text>
        </div>
      </div>
    </div>
  )
}

NotificationElement.propTypes = {
  isArchive: PropTypes.bool,
  notification: PropTypes.object,
  handleNavigateToTarget: PropTypes.func,
  notificationChecked: PropTypes.func,
  handleUpdateCheckedIds: PropTypes.func,
  renderIcon: PropTypes.func,
}

export default NotificationElement
