import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
  sessionCreateRequest,
  sessionCreateRequestPhone,
  sessionSmsCodeRequest
} from '../../store/authentication/actions'
import { RouteNames } from '../../router'
import { SignInForm } from '../../components/Authentication/Forms'
import { colors, Text } from '../../components/UIkit'
import { AuthenticationImage } from '../../components/UIkit/svgs'

import './styles.scss'

const SignIpContainer = () => {
  const dispatch = useDispatch()
  const history = useNavigate()
  const { authenticated } = useSelector((state) => state.authentication)
  const { t } = useTranslation()

  useEffect(() => {
    if (authenticated) {
      history(RouteNames.DASHBOARD)
    }
  })

  const handleUserSignIn = (data) => {
    dispatch(sessionCreateRequest({ data, history }))
  }

  const handlePhoneUserSignIn = (data) => {
    dispatch(sessionCreateRequestPhone({ data, history }))
  }

  return (
    <>
      {!authenticated && (
        <div className='auth-page'>
          <div className='auth-page__container'>
            <div className='auth-page__image-wrapper'>
              <Text className='auth-page__welcome-text'>{t('signin:title')}</Text>
              <div className='auth-page__additional-text'>
                <Text variant={'h4'} color={colors.gray[500]}>
                  {t('signin:description')}
                </Text>
              </div>
              <AuthenticationImage />
            </div>
            <SignInForm
              signInUser={handleUserSignIn}
              signInUserPhone={handlePhoneUserSignIn}
              sendSmsCodeRequest={sessionSmsCodeRequest}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default SignIpContainer
