import React from 'react'
import { useState, useMemo } from 'react'
import colors from '../colors'
import './styles.scss'
import { Arrow } from '../svgs'
import { buildTimeOptions } from '../../../lib/timeOptionsBuilder'

export const TimeSelector = ({ error, value, onChange, startFrom, startAfter, upTo, before, ...props }) => {
  const [isListOpen, setIsListOpen] = useState(false)

  const timeOptions = useMemo(() => {
    return buildTimeOptions({ startFrom, startAfter, upTo, before })
  }, [startFrom, startAfter, upTo, before])

  const inputStyle = {
    borderColor: !!error && colors.alerts.error,
  }

  const toggle = () => {
    setIsListOpen(!isListOpen)
  }

  const selectItem = (value) => () => {
    onChange(props.id, props.name, value)
  }

  return (
    <div
      className={`time-input unselectable ${isListOpen && 'with-input-shadow opened'}`}
      style={inputStyle}
      onClick={toggle}
    >
      <span>{value}</span>
      <Arrow className={`time-input-toggle ${isListOpen && 'reflect'}`} />
      {isListOpen && (
        <div className='time-input-selectors'>
          {timeOptions.map((option) => {
            return (
              <div
                className='time-input-selectors-opt'
                key={`selector-opt-${option.value}`}
                onClick={selectItem(option.value)}
              >
                {option.label}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default TimeSelector
