import {
  NOTIFICATIONS_GET_REQUEST,
  NOTIFICATIONS_GET_SUCCESS,
  NOTIFICATIONS_GET_FAILURE,
  NOTIFICATIONS_GET_OPENED_REQUEST,
  NOTIFICATIONS_GET_OPENED_SUCCESS,
  NOTIFICATIONS_GET_OPENED_FAILURE,
  NOTIFICATIONS_OPEN_ALL_REQUEST,
  NOTIFICATIONS_OPEN_ALL_SUCCESS,
  NOTIFICATIONS_OPEN_ALL_FAILURE,
  NOTIFICATIONS_OPEN_ONE_REQUEST,
  NOTIFICATIONS_OPEN_ONE_SUCCESS,
  NOTIFICATIONS_OPEN_ONE_FAILURE,
  NOTIFICATIONS_BULK_DELETE_REQUEST,
  NOTIFICATIONS_BULK_DELETE_SUCCESS,
  NOTIFICATIONS_BULK_DELETE_FAILURE,
  UPDATE_NOTIFICATIONS_LIST,
  REDUCE_NOTIFICATIONS,
  CLEAR_NOTIFICATIONS,
} from './actions'

const initialState = {
  items: [],
  openedItems: [],
  unopenedCount: 0,
  fetching: false,
  fetchingOpened: false,
  error: null,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case NOTIFICATIONS_GET_REQUEST:
      return { ...state, fetching: true }
    case NOTIFICATIONS_GET_SUCCESS:
      return {
        ...state,
        items: [...state.items, ...payload],
        unopenedCount: payload[0] ? payload[0].unopenedCount : 0,
        fetching: false,
      }
    case NOTIFICATIONS_GET_FAILURE:
      return { ...state, error: payload, fetching: false }
    case NOTIFICATIONS_GET_OPENED_REQUEST:
      return { ...state, fetchingOpened: true }
    case NOTIFICATIONS_GET_OPENED_SUCCESS:
      return { ...state, openedItems: [...state.openedItems, ...payload], fetchingOpened: false }
    case NOTIFICATIONS_GET_OPENED_FAILURE:
      return { ...state, error: payload, fetchingOpened: false }
    case NOTIFICATIONS_OPEN_ALL_REQUEST:
      return { ...state, fetchingOpened: true }
    case NOTIFICATIONS_OPEN_ALL_SUCCESS:
      return {
        ...state,
        items: [],
        openedItems: payload,
        unopenedCount: 0,
        fetchingOpened: false,
      }
    case NOTIFICATIONS_OPEN_ALL_FAILURE:
      return { ...state, error: payload, fetchingOpened: false }
    case NOTIFICATIONS_OPEN_ONE_REQUEST:
      return { ...state, fetching: true }
    case NOTIFICATIONS_OPEN_ONE_SUCCESS:
      return {
        ...state,
        items: payload.filter(n => !n.openedAt),
        openedItems: payload.filter(n => !!n.openedAt),
        unopenedCount: payload[0] ? payload[0].unopenedCount : 0,
        fetching: false,
      }
    case NOTIFICATIONS_OPEN_ONE_FAILURE:
      return { ...state, error: payload, fetching: false }
    case NOTIFICATIONS_BULK_DELETE_REQUEST:
      return { ...state }
    case NOTIFICATIONS_BULK_DELETE_SUCCESS:
      return { ...state, openedItems: state.openedItems.filter((item) => payload.ids.indexOf(item.id) === -1) }
    case NOTIFICATIONS_BULK_DELETE_FAILURE:
      return { ...state, error: payload }
    case UPDATE_NOTIFICATIONS_LIST:
      return {
        ...state,
        items: [payload, ...state.items],
        unopenedCount: state.unopenedCount + 1,
      }
    case REDUCE_NOTIFICATIONS:
      return {
        ...state,
        items: [],
        openedItems: [],
      }
    case CLEAR_NOTIFICATIONS:
      return initialState
    default:
      return state
  }
}
