import { takeLatest, call, put } from 'redux-saga/effects'
import * as actions from './actions'

import Api from '../../lib/api'
import notifications from '../../lib/notifications'

export default function* watcherSaga() {
  yield takeLatest(actions.EMAIL_CONFIRMATION_REQUEST, watchEmailConfirmationRequest)
}

function* watchEmailConfirmationRequest({ token, history }) {
  try {
    yield call(Api.EmailConfirmation.create, { token })

    yield put(actions.emailConfirmationSuccess())
    history('/admin')
    notifications.createNotification('email-confirmation-success')
  } catch (error) {
    yield put(actions.emailConfirmationFailure(error))
    notifications.createNotification('email-confirmation-error', error.response.data.error)
  }
}
