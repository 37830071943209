import { takeLatest, takeEvery, call, put } from 'redux-saga/effects'
import * as actions from './actions'

import Api from '../../lib/api'
import notifications from '../../lib/notifications'
import { toCamelCase } from '../../lib/converter'

export default function* watcherSaga() {
  yield takeLatest(actions.PRODUCTS_GET_REQUEST, watchResourcesGetRequest),
  yield takeLatest(actions.PRODUCT_CREATE_REQUEST, watchResourceCreateRequest),
  yield takeLatest(actions.PRODUCT_DELETE_REQUEST, watchResourceDeleteRequest),
  yield takeLatest(actions.PRODUCT_UPDATE_REQUEST, watchResourceUpdateRequest),
  yield takeLatest(actions.BOARD_PRODUCT_CREATE_REQUEST, watchBoardProductCreateRequest)
  yield takeLatest(actions.BOARD_PRODUCT_UPDATE_REQUEST, watchBoardProductUpdateRequest)
  yield takeLatest(actions.BOARD_PRODUCT_DELETE_REQUEST, watchBoardProductDeleteRequest)
  yield takeEvery(actions.BOARD_PRODUCTS_FETCH_REQUEST, watchBoardProductsFetchRequest)
}

function* watchResourcesGetRequest({ params }) {
  try {
    const response = yield call(Api.Product.get, params)

    yield put(actions.productsGetSuccess(response))
  } catch (error) {
    yield put(actions.productsGetFailure(error))
    notifications.createNotification('error')
  }
}

function* watchResourceCreateRequest({ payload }) {
  try {
    const response = yield call(Api.Product.create, payload)

    yield put(actions.productCreateSuccess(response))
    notifications.createNotification('create-success')
  } catch (error) {
    yield put(actions.productCreateFailure(error))
    notifications.createNotification('error')
  }
}

function* watchResourceUpdateRequest({ payload }) {
  try {
    const response = yield call(Api.Product.update, payload.product)

    yield put(actions.productUpdateSuccess(response))
    notifications.createNotification('edit-success')
  } catch (error) {
    yield put(actions.productUpdateFailure(error))
    notifications.createNotification('error')
  }
}

function* watchResourceDeleteRequest({ payload }) {
  try {
    const response = yield call(Api.Product.delete, payload.id)

    yield put(actions.productDeleteSuccess(response))
    notifications.createNotification('delete-success')
  } catch (error) {
    yield put(actions.productDeleteFailure(error))
    notifications.createNotification('error')
  }
}

// BOARD PRODUCTS

function* watchBoardProductsFetchRequest({ payload, callback }) {
  try {
    const response = yield call(Api.Products.fetch, payload.networkId, payload.params)

    yield put(actions.boardProductsFetchSuccess(toCamelCase({ data: response.data, params: payload.params })))
    callback()
  } catch (error) {
    yield put(actions.boardProductsFetchFailure(error))
    notifications.createNotification('error', error.response?.data.errors)
  }
}

function* watchBoardProductCreateRequest({ payload }) {
  try {
    const response = yield call(Api.Products.create, payload.networkId, payload.params)

    yield put(actions.boardProductCreateSuccess(toCamelCase(response.data)))
    notifications.createNotification('create-success')
  } catch (error) {
    yield put(actions.boardProductCreateFailure(error))
    notifications.createNotification('error', error.response?.data.errors)
  }
}

function* watchBoardProductUpdateRequest({ payload }) {
  try {
    const response = yield call(Api.Products.update, payload.networkId, payload.id, payload.params)

    yield put(actions.boardProductUpdateSuccess(toCamelCase(response.data)))
    notifications.createNotification('edit-success')
  } catch (error) {
    yield put(actions.boardProductUpdateFailure(error))
    notifications.createNotification('error', error.response?.data.errors)
  }
}

function* watchBoardProductDeleteRequest({ payload }) {
  try {
    yield call(Api.Products.delete, payload.networkId, payload.id)
    yield put(actions.boardProductDeleteSuccess(payload))
    notifications.createNotification('delete-success')
  } catch (error) {
    yield put(actions.boardProductDeleteFailure(error))
    notifications.createNotification('error', error.response?.data.errors)
  }
}
