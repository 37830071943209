import React from 'react'
import { Link } from 'react-router-dom'
import { RouteNames } from '../../../../router'
import './styles.scss'
import './responsiveStyles.scss'

function Intro () {
  return (
    <section id='intro-business' name='intro'>
      <div className='content'>
        <h1 className='title'>
          <b>Автоматизація</b> <br/> замовлень і <b>збільшення</b> <br/><b>продажів</b> в кав&apos;ярнях
        </h1>
        <p className='description'>
        Ми приклали максимум зусиль, щоб власники кав&apos;ярень надавали найкраще
        обслуговування для своїх відвідувачів і підвищували імідж свого закладу
        </p>

        <div className='join-us-container'>
          <div className='two-buttons-business'>
            <Link className={'btn-landing btn-sign-up'} to={RouteNames.SIGNUP}>
              Зареєструватись
            </Link>
            <Link className={'btn-landing btn-login'} to={RouteNames.ADMIN}>
              Увійти
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Intro
