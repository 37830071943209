import {
  TYPES_FETCH_REQUEST,
  TYPES_FETCH_SUCCESS,
  TYPES_FETCH_FAILURE,
  TYPE_VERIFY_REQUEST,
  TYPE_VERIFY_SUCCESS,
  TYPE_VERIFY_FAILURE,
  TYPES_CLEAR,
} from './actions'

const initialState = {
  items: [],
  item: {},
  fetching: true,
  error: null,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case TYPES_FETCH_REQUEST: {
      return { ...state, fetching: true }
    }
    case TYPES_FETCH_SUCCESS: {
      return {
        ...state,
        items: payload,
        fetching: false,
      }
    }
    case TYPES_FETCH_FAILURE:
      return { ...state, fetching: false, error: payload }

    case TYPE_VERIFY_REQUEST: {
      return { ...state, fetching: true }
    }
    case TYPE_VERIFY_SUCCESS: {
      return {
        ...state,
        item: payload,
        fetching: false,
      }
    }
    case TYPE_VERIFY_FAILURE:
      return { ...state, fetching: false, error: payload }

    case TYPES_CLEAR:
      return initialState

    default:
      return state
  }
}
