import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import ProductElement from './ProductElement'

import '../Layout/Table/index.css'

class ProductsPage extends PureComponent {
  render() {
    const { products, deleteProduct, updateProduct } = this.props

    return (
      <>
        <div className='table-responsive'>
          <table className='table'>
            <thead className='t-b-color'>
              <tr>
                <th className='th-lg'>Photo</th>
                <th className='th-lg'>Name</th>
                <th className='th-lg'>Type</th>
                <th className='th-lg'>Edit/Delete</th>
              </tr>
            </thead>
            {products.data.map((product, index) => (
              <ProductElement
                key={product.id}
                deleteProduct={deleteProduct}
                updateProduct={updateProduct}
                index={index}
                product={product}
              />
            ))}
          </table>
        </div>
      </>
    )
  }
}

ProductsPage.propTypes = {
  products: PropTypes.shape({
    data: PropTypes.array,
  }),
  createProduct: PropTypes.func,
  deleteProduct: PropTypes.func,
  updateProduct: PropTypes.func,
}

export default ProductsPage
