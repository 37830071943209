import moment from 'moment'

const calendarDateRangesList = (customStart, customEnd) => {
  moment.updateLocale(localStorage.getItem('i18nextLng') || 'uk', {
    week: {
      dow: 1,
      doy: 7,
    },
  })

  const formatDateRange = (start, end) => {
    if (start.isSame(end, 'day')) return end.format('DD MMM YYYY')

    return `${start.format('DD MMM')} - ${end.format('DD MMM YYYY')}`
  }

  return [
    {
      title: 'today',
      label: formatDateRange(moment(), moment()),
      value: [moment().startOf('day').toDate(), moment().endOf('day').toDate()],
    },
    {
      title: 'week',
      label: formatDateRange(moment().startOf('week'), moment().endOf('week')),
      value: [moment().startOf('week').toDate(), moment().endOf('week').toDate()],
    },
    {
      title: 'month',
      label: formatDateRange(moment().startOf('month'), moment().endOf('month')),
      value: [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
    },
    {
      title: 'last7',
      label: formatDateRange(moment().subtract(6, 'day').startOf('day'), moment().endOf('day')),
      value: [moment().subtract(6, 'day').startOf('day').toDate(), moment().endOf('day').toDate()],
    },
    {
      title: 'last30',
      label: formatDateRange(moment().subtract(29, 'day').startOf('day'), moment().endOf('day')),
      value: [moment().subtract(29, 'day').startOf('day').toDate(), moment().endOf('day').toDate()],
    },
    {
      title: 'custom',
      label: customStart && customEnd ? formatDateRange(moment(customStart), moment(customEnd)) : 'notSelected',
      value: customStart && customEnd ? [moment(customStart).toDate(), moment(customEnd).toDate()] : [null, null],
    },
  ]
}

export default calendarDateRangesList
