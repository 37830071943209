import React from 'react'

import TableText from '../../../../../components/TableText'
import { TableCell, TableRow, Text } from '../../../../../../UIkit'

const ProductRow = ({ product }) => {
  return (
    <TableRow>
      <TableCell>
        <div style={{ backgroundImage: `url(${product.image.url})` }} className='vendor-image' />
      </TableCell>
      <TableCell>
        <TableText text={product.name} />
      </TableCell>
      <TableCell>
        <TableText text={product.type} />
      </TableCell>
      <TableCell>
        <TableText text={product.category} />
      </TableCell>
      <TableCell>
        <Text variant='body-secondary1'>
          {product.sizes} {product.unit}
        </Text>
        <Text shouldBeUppercase variant='body-secondary1'>
          {product.prices} UAH
        </Text>
      </TableCell>
      <TableCell>
        <TableText text={product.availability} />
      </TableCell>
      <TableCell>
        <TableText text={product.discount} />
      </TableCell>
    </TableRow>
  )
}

export default ProductRow
