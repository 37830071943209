import React from 'react'
import moment from 'moment/moment'

import InfoRow from '../../components/InfoRow'

const VendorInfo = ({ vendor }) => {
  return (
    <div className='staff-info-block'>
      <InfoRow title='Address' text={vendor.address} />
      <InfoRow title='Phone number' text={vendor.phone} />
      <InfoRow title='Email' text={vendor.email} />
      <InfoRow title='Rating' text={vendor.rating} />
      <InfoRow title='Status' text={vendor.status} />
      <InfoRow title='Creation date' text={moment(vendor.created_at).format('DD-MM-YYYY')} />
    </div>
  )
}

export default VendorInfo
