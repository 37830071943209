import { groupBy, sumBy } from 'lodash'
import moment from 'moment'

const DAY_IN_WEEK = 7

export const getOrdersByWeek = (orders) => {
  const groupByDate = groupBy(orders, 'date')
  const dates = Object.keys(groupByDate)

  const getTotalOrdersByDate = () => {
    const array = []

    dates.map((date) => {
      array.push({
        date: date,
        canceledOrders: sumBy(groupByDate[date], 'canceledOrders'),
        finishedOrders: sumBy(groupByDate[date], 'finishedOrders') })
    })

    return array
  }

  const ordersByDate = getTotalOrdersByDate()

  const groupByWeek = () => {
    const array = []
    for (let i = 0; i < Math.ceil(ordersByDate.length / DAY_IN_WEEK); i++){
      array[i] = ordersByDate.slice((i * DAY_IN_WEEK), (i * DAY_IN_WEEK) + DAY_IN_WEEK)
    }

    return array
  }

  const byWeek = groupByWeek()

  const getTotalOrdersByWeek = () => {
    const array = []

    byWeek.map((arr) => {
      array.push({
        date: `${moment(arr[0].date.split(' ')[0]).format('DD.MM')} - ${moment(arr[arr.length-1].date.split(' ')[0]).format('DD.MM')}`,
        canceledOrders: sumBy(arr, 'canceledOrders'),
        finishedOrders: sumBy(arr, 'finishedOrders')
      })
    })

    return array
  }

  return getTotalOrdersByWeek()
}

export const getRevenueByDate = (cashTurnover) => {
  const groupByDate = groupBy(cashTurnover, 'date')
  const dates = Object.keys(groupByDate)

  const getRevenue = () => {
    const array = []

    dates.map((date) => {
      array.push({ date: date, revenue: sumBy(groupByDate[date], 'revenue') })
    })

    return array
  }

  return getRevenue()
}

export const getBaristaStatistics = (baristaEfficiency) => {
  const groupByBaristaId = groupBy(baristaEfficiency, 'baristaId')
  const baristaIds = Object.keys(groupByBaristaId)
  const getBaristaEfficiency = () => {
    const array = []

    baristaIds.map((id) => {
      array.push({
        barista: groupByBaristaId[id]?.[0].barista,
        canceledOrders: sumBy(groupByBaristaId[id], 'canceledOrders'),
        finishedOrders: sumBy(groupByBaristaId[id], 'finishedOrders'),
        revenue: sumBy(groupByBaristaId[id], 'revenue'),
        totalOrders: sumBy(groupByBaristaId[id], 'totalOrders'),
      })
    })

    return array
  }

  return getBaristaEfficiency()
}

export const getVendorStatistics = (vendorComparison) => {
  const groupByVendorId = groupBy(vendorComparison, 'vendorId')
  const vendorIds = Object.keys(groupByVendorId)

  const getBaristaEfficiency = () => {
    const array = []

    vendorIds.map((id) => {
      array.push({
        vendor: groupByVendorId[id]?.[0].vendor,
        drinks: sumBy(groupByVendorId[id], 'drinks'),
        desserts: sumBy(groupByVendorId[id], 'desserts'),
        food: sumBy(groupByVendorId[id], 'food'),
        goods: sumBy(groupByVendorId[id], 'goods'),
        supplements: sumBy(groupByVendorId[id], 'supplements'),
      })
    })

    return array
  }

  return getBaristaEfficiency()
}
