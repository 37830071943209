import React from 'react'

import { parseUah } from '../../../../lib/currencyParser'
import { Text } from '../../../UIkit'
import { Logo } from '../../../UIkit/svgs'
import './styles.scss'
import './../../styles.scss'

const NetworkHeader = ({ network }) => {
  return (
    <div className='page-content-header'>
      <Text variant='h2'>{network.name}</Text>

      <div className='page-content-header__balance'>
        <Logo width='40px' height='40px' />
        <div className='page-content-header__balance__item-right'>
          <Text variant='body-tertiary1'>Balance</Text>
          <Text variant='body-main2'>₴ {parseUah(network.balance)}</Text>
        </div>
      </div>
    </div>
  )
}

export default NetworkHeader
