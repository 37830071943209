import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'

import { StaffRootContext } from '../../../contexts/staff/rootContext'
import { colors, Text } from '../../UIkit'
import { links } from './data'
import '../../../containers/SideBar/styles.scss'
import './styles.scss'

const StaffSidebar = () => {
  const { currentSession } = useContext(StaffRootContext)

  return (
    <div className='side-bar sidebar-sticky'>
      <Text variant='h4' className='overflow-text'>{currentSession.full_name}</Text>

      <div className='side-bar__menu'>
        <div className='side-bar__menu-section'>
          {links.map(link => (
            <NavLink
              key={link.text}
              className='side-bar__menu-item'
              to={link.path}
              children={() => {
                const isActive = window.location.pathname.includes(link.path)
                return (
                  <>
                    {isActive ? link.filledSvg : link.svg}
                    <Text variant='button-large' color={isActive ? colors.primary[500] : colors.gray[800]}>
                      {link.text}
                    </Text>
                  </>
                )
              }}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default StaffSidebar
