import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { emailConfirmationRequest } from '../../store/emailConfirmation/actions'
import Loader from '../../components/Loader'

const EmailConfirmationContainer = () => {
  const { token } = useParams()
  const { loading } = useSelector((state) => state.emailConfirmation)
  const history = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(emailConfirmationRequest(token, history))
  }, [token])

  return (
    <div>
      <Loader isLoading={loading} />
    </div>
  )
}

export default EmailConfirmationContainer
