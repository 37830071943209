import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import Validator from '../../lib/validator'
import { Text, Input, Button } from '../UIkit'

const LiqpayActivationForm = ({ sendActivationCode }) => {
  const { t } = useTranslation()

  const [form, setForm] = useState({
    code: ''
  })

  const [formErrors, setFormErrors] = useState({
    code: ''
  })

  const handleValidate = () =>
    new Promise((resolve, reject) => {
      const errors = Validator.validate([
        ['code', Validator.concepts.liqpayActivationCodeRegex, [form.code]]
      ])

      Validator.clear()

      isEmpty(errors) ? resolve() : reject(errors)
    })

  const handleUpdateField = ({ target }) => {
    setForm((prev) => ({ ...prev, [target.name]: target.value }))
    setFormErrors((prev) => ({ ...prev, [target.name]: '' }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const data = {
      code: form.code
    }

    handleValidate()
      .then(() => {
        sendActivationCode(data)
      })
      .catch((errors) => setFormErrors(errors))
  }

  return (
    <div className='liqpay-activation-form'>
      <form onSubmit={handleSubmit}>
        <Text className='liqpay-activation-form-text' variant='body-main1'>
          {t('network:activationCode')}
        </Text>
        <Input
          id='code'
          name='code'
          placeholder={t('input:code')}
          autoFocus
          value={form.code}
          onChange={handleUpdateField}
          error={formErrors.code}
        />
        <Button type='submit' style={{ marginTop: 24 }}>
          {t('btn:send')}
        </Button>
      </form>
    </div>
  )
}

export default LiqpayActivationForm
