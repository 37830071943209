import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { staffBaristasClear, staffBaristasFetchRequest } from '../../../../../store/staff/baristas/actions'
import { BaristasModalContext } from './baristasModalContext'
import { Text } from '../../../../UIkit'
import BaristasModal from './BaristasModal/BaristasModal'
import VendorsTable from './List/VendorsTable'

const VendorsInfo = ({ vendors }) => {
  const dispatch = useDispatch()
  const [isModalOpened, setModalOpened] = useState(false)

  const openModal = useCallback((vendorId) => {
    setModalOpened(true)
    dispatch(staffBaristasFetchRequest({ vendorId: vendorId }))
  }, [setModalOpened, dispatch])

  const closeModal = useCallback(() => {
    setModalOpened(false)
    dispatch(staffBaristasClear())
  }, [setModalOpened, dispatch])

  const modalContextValue = useMemo(() => ({
    openModal,
    closeModal
  }), [isModalOpened, setModalOpened])

  const emptyCondition = !vendors || !vendors.length

  return (
    <BaristasModalContext.Provider value={modalContextValue}>
      <div className='staff-info-block'>
        <Text variant='h2'>Vendors</Text>

        {emptyCondition ? (
          <Text variant='subheading'>Vendors list is empty</Text>
        ) : (
          <VendorsTable vendors={vendors} />
        )}

        <BaristasModal
          isOpened={isModalOpened}
          handleClose={closeModal}
        />
      </div>
    </BaristasModalContext.Provider>
  )
}

export default VendorsInfo
