import React, { useState, useRef } from 'react'
import PropType from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Text, Actions, colors } from '../UIkit'
import { Cup } from '../UIkit/svgs'
import { VendorDelete, WorkingHours } from '.'
import { VendorUpdateContainer, FeedbacksContainer } from '../../containers/Network'

import { Location, Clock, Star, VerticalDots, Edit, Trash } from '../UIkit/svgs'
import useOnClickOutside from '../../hooks/useOnClickOutside'

const Vendor = ({ vendor, setVendorCoordinates, deleteVendor }) => {
  const { t } = useTranslation()

  const [showActions, setShowActions] = useState(false)
  const [showWorkingHours, setShowWorkingHours] = useState(false)
  const [isOpenDelete, setIsOpenDelete] = useState(false)
  const [isOpenUpdate, setIsOpenUpdate] = useState(false)
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false)

  const actionRef = useRef()
  const workingHourRef = useRef()

  const toggleActions = () => setShowActions((prev) => !prev)
  const toggleWorkingHours = () => setShowWorkingHours((prev) => !prev)

  const handleOpenDelete = () => setIsOpenDelete((prev) => !prev)
  const handleOpenUpdate = () => setIsOpenUpdate((prev) => !prev)
  const handleOpenFeedback = () => setIsFeedbackOpen((prev) => !prev)

  const handleDelete = () => {
    const data = {
      networkId: Number(localStorage.getItem('networkId')),
      id: vendor.id,
    }

    deleteVendor(data)
    handleOpenDelete()
  }

  useOnClickOutside(workingHourRef, () => setShowWorkingHours(false))
  useOnClickOutside(actionRef, () => setShowActions(false))

  const handleVendorClick = () => {
    setVendorCoordinates({
      latitude: vendor.latitude,
      longitude: vendor.longitude,
      zoom: 18,
    })
  }

  return (
    <div className='vendor'>
      <div className='vendor__logo'>
        {!!vendor.imageSmUrl && <img src={vendor.imageSmUrl} />}

        {!vendor.imageSmUrl && <Cup width={45} height={45} />}
      </div>
      <div className='vendor__details'>
        <Text
          wrapper
          onClick={handleVendorClick}
          style='vendor__details__address'
          variant='body-main1'
          color={colors.gray[800]}
        >
          <Location />
          &nbsp;{vendor.fullAddress}
        </Text>

        <div ref={workingHourRef} className='vendor__details__working-hours'>
          <Text variant='body-main1' color={colors.gray[800]} onClick={toggleWorkingHours}>
            <Clock color={colors.primary[500]} />
            &nbsp;{vendor.openingHours}
          </Text>
        </div>

        {showWorkingHours && <WorkingHours elemRef={workingHourRef.current} workTimes={vendor.workTimes} />}

        <Text
          wrapper
          onClick={handleOpenFeedback}
          className='vendor__rating'
          variant='body-main1'
          color={colors.gray[500]}
        >
          {t('network:rating')}: &nbsp;
          <Star />
          <Text color={colors.gray[800]} variant='body-main2'>
            &nbsp;{vendor.rating}
          </Text>
        </Text>
      </div>

      <div ref={actionRef}>
        <div>
          <VerticalDots style={{ userSelect: 'none', cursor: 'pointer' }} onClick={toggleActions} />
        </div>

        <div className='vendor__actions'>
          {showActions && (
            <Actions elemRef={actionRef.current}>
              <div className='employees__action' onClick={handleOpenUpdate}>
                <Edit />
                <Text variant='body-main2'>{t('actions:edit')}</Text>
              </div>
              <div className='employees__action' onClick={handleOpenDelete}>
                <Trash />
                <Text variant='body-main2'>{t('actions:delete')}</Text>
              </div>
            </Actions>
          )}
        </div>
      </div>

      <VendorDelete
        isShown={isOpenDelete}
        handleDelete={handleDelete}
        closeModal={handleOpenDelete}
      />

      {isOpenUpdate && <VendorUpdateContainer id={vendor.id} isShown={isOpenUpdate} closeModal={handleOpenUpdate} />}

      {isFeedbackOpen && <FeedbacksContainer id={vendor.id} isShown={isFeedbackOpen} closeModal={handleOpenFeedback} />}
    </div>
  )
}

Vendor.propTypes = {
  vendor: PropType.object.isRequired,
  setVendorCoordinates: PropType.func.isRequired,
  deleteVendor: PropType.func.isRequired,
}
export default Vendor
