import { takeLatest, call, put } from 'redux-saga/effects'
import * as actions from './actions'

import Api from '../../lib/api'

export default function* watchSaga() {
  yield takeLatest(actions.CHECK_CREDENTIALS_REQUEST, watchResourceFetchRequest)
}

function* watchResourceFetchRequest({ payload }) {
  try {
    const response = yield call(Api.Credentials.get, payload)

    yield put(actions.checkCredentialsSuccess(response.data.message))
  } catch (error) {
    yield put(actions.checkCredentialsFailure(error))
  }
}
