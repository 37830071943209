import React from 'react'

import { Exit, Plus } from '../UIkit/svgs'
import { colors } from '../UIkit'

const MAX_IMAGES = 7

const Gallery = ({ imageIndex, galleryImages, handleChangeImage, mobile, handleDelete }) => {
  return (
    <div className='gallery'>
      {!!galleryImages.length &&
        galleryImages.map((image, index) => {
          const isSelected = imageIndex == index
          return (
            <div
              key={index}
              onClick={handleChangeImage(image, index)}
              className={`gallery__item ${isSelected && 'gallery__selected'}`}
            >
              {mobile && 
                <div className='gallery__exit-btn' onClick={
                  handleDelete(image.signedId)
                }>
                  <Exit color='#fff' width={24} height={24} />
                </div>}
              <img className='gallery__image' src={image.url && image.url} />
            </div>
          )
        })}

      {(mobile && galleryImages.length >= 1) && Array(MAX_IMAGES - galleryImages.length).fill(null).map((_, index) => (
        <div key={index} className={`${!mobile && 'gallery__item'}`}>
          {!mobile &&
            <button onClick={handleClick('new')}>
              <Plus color={colors.gray[400]} width={18} height={18} />
            </button>
          }
        </div>
      ))}
    </div>
  )
}

export default Gallery
