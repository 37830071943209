import config from 'config'

export const getPosterAuthURL = (companyName) => {
  const queryParams = new URLSearchParams({
    application_id: config.posterAppId,
    redirect_uri: config.posterRedirectUri,
    response_type: 'code',
  })

  return `https://${companyName}.joinposter.com/api/auth?${queryParams.toString()}`
}
