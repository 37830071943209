import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'

import { Modal, Button, Dropdown, Text, colors, Input } from '../../UIkit'
import { Email, Phone } from '../../UIkit/svgs'
import { Validator, formatPhoneNumber } from '../../../lib'
import formatedPhoneNumber from '../../../lib/formatedPhoneNumber'

const EmployeeEdit = ({ isShown, closeModal, employee, updateEmployee, vendorsList }) => {
  const { t } = useTranslation()

  const [form, setForm] = useState({
    jobRole: employee.role,
    vendorId: employee.vendor?.id,
    firstName: employee.profile?.firstName,
    lastName: employee.profile?.lastName,
    email: employee.email,
    phoneNumber: employee.phoneNumber,
  })

  const [formErrors, setFormErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  })

  const handleValidate = () =>
    new Promise((resolve, reject) => {
      const errors = Validator.validate([
        ['firstName', Validator.concepts.isFilled, [form.firstName]],
        ['lastName', Validator.concepts.isFilled, [form.lastName]],
        ['email', Validator.concepts.emailRegex, [form.email]],
        ['phoneNumber', Validator.concepts.phoneRegex, [formatedPhoneNumber(form.phoneNumber)]],
      ])

      Validator.clear()

      isEmpty(errors) ? resolve() : reject(errors)
    })

  const onUpdateField = (e) => {
    const nextFormState = {
      ...form,
      [e.target.name]: e.target.name == 'phoneNumber' ? formatPhoneNumber(e.target.value) : e.target.value,
    }
    setForm(nextFormState)
  }

  const handleUpdate = () => {
    const data = {
      id: employee.id,
      params: {
        networkEmployee: {
          role: form.jobRole,
          vendorId: form.vendorId,
          email: form.email,
          phoneNumber: form.phoneNumber,
          profileAttributes: {
            firstName: form.firstName,
            lastName: form.lastName,
          }
        },
      },
    }

    handleValidate()
      .then(() => {
        updateEmployee(data)
        closeModal()
      })
      .catch((errors) => setFormErrors(errors))
  }

  const onUpdateVendor = useCallback((item) => setForm((prev) => ({ ...prev, vendorId: item })), [setForm])

  return (
    <Modal show={isShown} handleClose={closeModal}>
      <div className='edit-employee'>
        <div className='edit-employee__header'>
          <div style={{ backgroundImage: `url(${employee.profile.avatarUrl})` }} className='edit-employee__avatar' />
          <Text variant='subheading'>{employee.profile.fullName}</Text>
        </div>

        <div className='edit-employee__info-block'>
          <div className='edit-employee__info-block'>
            <Text shouldBeUppercase variant='body-tertiary1' color={colors.gray[500]}>
              {t('employees:contacts')}
            </Text>
            <div className='edit-employee__info'>
              <Email />
              <Text variant='body-main1'>{employee.email}</Text>
            </div>
            <div className='edit-employee__info'>
              <Phone />
              <Text variant='body-main1'>{employee.phoneNumber}</Text>
            </div>
          </div>

          <div className='edit-employee__info-block'>
            <Text shouldBeUppercase variant='body-tertiary1' color={colors.gray[500]}>
              {t('employees:position')}
            </Text>
            <div className='edit-employee__selects'>
              <Text wrapper variant='body-main1'>
                <Dropdown
                  placeholder={t('input:vendor')}
                  value={form.vendorId}
                  onChange={onUpdateVendor}
                  list={vendorsList}
                />
              </Text>
            </div>
          </div>

          <div className='edit-employee__info-block'>
            <Text shouldBeUppercase variant='body-tertiary1' color={colors.gray[500]}>
              {t('employees:generalInfo')}
            </Text>

            <div>
              <Input
                id='first-name'
                name='firstName'
                placeholder={t('input:firstName')}
                value={form.firstName}
                onChange={onUpdateField}
                error={formErrors.firstName}
              />
              <Input
                id='last-name'
                name='lastName'
                placeholder={t('input:secondName')}
                value={form.lastName}
                onChange={onUpdateField}
                error={formErrors.lastName}
              />
              <Input
                id='email'
                name='email'
                placeholder={t('input:email')}
                value={form.email}
                onChange={onUpdateField}
                error={formErrors.email}
              />
              <Input
                id='phoneNumber'
                type='tel'
                value={form.phoneNumber}
                name='phoneNumber'
                onChange={onUpdateField}
                placeholder={t('input:phone')}
                error={formErrors.phoneNumber}
              />
            </div>
          </div>
        </div>
        <div className='edit-employee__buttons'>
          <Button variant='secondary' onClick={closeModal}>
            {t('btn:cancel')}
          </Button>
          <Button onClick={handleUpdate}>{t('btn:save')}</Button>
        </div>
      </div>
    </Modal>
  )
}

export default EmployeeEdit
