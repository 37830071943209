import React, { useCallback, useContext, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { BaristasModalContext } from '../baristasModalContext'
import { RouteNames } from '../../../../../../router'
import useOnClickOutside from '../../../../../../hooks/useOnClickOutside'
import { colors, TableActions, TableCell, TableRow, Text } from '../../../../../UIkit'
import { Cup, Shown, VerticalDots } from '../../../../../UIkit/svgs'
import TableText from '../../../../components/TableText'

const VendorRow = ({ vendor }) => {
  const actionsRef = useRef()
  const history = useNavigate()
  const { openModal } = useContext(BaristasModalContext)
  const [showActions, setShowActions] = useState(false)

  useOnClickOutside(actionsRef, () => setShowActions(false))

  const toggleActions = useCallback(() => {
    setShowActions((prev) => !prev)
  }, [setShowActions])

  const openBaristasModal = useCallback(() => {
    openModal(vendor.id)
  }, [openModal, vendor])

  const navigateToVendor = useCallback(() => {
    history(`${RouteNames.STAFF_VENDORS}/${vendor.id}`)
  }, [history, RouteNames, vendor])
  

  return (
    <TableRow>
      <TableCell>
        <div style={{ backgroundImage: `url(${vendor.imageUrl})` }} className='vendor-image' />
      </TableCell>
      <TableCell>
        <TableText text={vendor.city} />
      </TableCell>
      <TableCell>
        <TableText text={vendor.address} />
      </TableCell>
      <TableCell>
        <TableText text={vendor.rating} />
      </TableCell>
      <TableCell>
        <div ref={actionsRef}>
          <VerticalDots className='employees__dots-icon' onClick={toggleActions} />
          {showActions && (
            <TableActions elemRef={actionsRef.current}>
              <div className='employees__action' onClick={navigateToVendor}>
                <Cup color={colors.primary[500]} />
                <Text variant='body-main2'>Show vendor</Text>
              </div>
              <div className='employees__action' onClick={openBaristasModal}>
                <Shown color={colors.primary[500]} />
                <Text variant='body-main2'>Show baristas</Text>
              </div>
            </TableActions>
          )}
        </div>
      </TableCell>
    </TableRow>
  )
}

export default VendorRow
