import React from 'react'
import { useTranslation } from 'react-i18next'

import { UploadingFail } from '../UIkit/svgs'
import { Button, Text, colors } from '../UIkit'

const ValidationError = ({ handleClick }) => {
  const { t } = useTranslation()

  return (
    <>
      <UploadingFail />

      <Text variant='body-main1' color={colors.gray[800]}>
        {t('uploader:uploadingFailed')}
      </Text>

      <Text variant='body-tertiary1' color={colors.gray[800]}>
        {t('uploader:fileIsLargerThan5MB')}
      </Text>

      <Button onClick={handleClick('new')} variant='tertiary' size='small'>
        {t('btn:retry')}
      </Button>
    </>
  )
}

export default ValidationError
