import React, { useEffect, useState } from 'react'
import { CheckEmailForm, FinishForm, ForgotPasswordForm, CreatePasswordForm } from './Forms'

const ResetPassword = ({ token, isBarista, history, sendEmail, handleResetPassword, handleCancel }) => {
  const [step, setStep] = useState(1)
  const [email, setEmail] = useState('')

  const handleChangeEmail = ({ target }) => setEmail(target.value)

  const toFinish = () => {
    history(`${isBarista ? '/barista' : ''}/forgot_password`)
    setStep(4)
  }

  useEffect(() => {
    if (token) {
      setStep(3)
    }
  }, [token])

  const renderForm = () => {
    switch (step) {
      case 1:
        return (
          <ForgotPasswordForm
            email={email}
            handleChangeEmail={handleChangeEmail}
            sendEmail={sendEmail}
            handleCancel={handleCancel}
            toNextStep={() => setStep(2)}
          />
        )
      case 2:
        return <CheckEmailForm email={email} handleCancel={handleCancel} toPrevStep={() => setStep(1)} />
      case 3:
        return (
          <CreatePasswordForm
            handleResetPassword={handleResetPassword}
            handleCancel={handleCancel}
            toFinish={toFinish}
          />
        )
      case 4:
        return <FinishForm isBarista={isBarista} toSignIn={() => history('/admin')}/>
    }
  }

  return renderForm()
}

export default ResetPassword
