import { takeLatest, takeEvery, call, put } from 'redux-saga/effects'
import * as actions from './actions'

import Api from '../../lib/api'
import notifications from '../../lib/notifications'
import { toCamelCase } from '../../lib/converter'

export default function* watcherSaga() {
  yield takeLatest(actions.SUPPLEMENT_CREATE_REQUEST, watchSupplementCreateRequest)
  yield takeLatest(actions.SUPPLEMENT_UPDATE_REQUEST, watchSupplementUpdateRequest)
  yield takeLatest(actions.SUPPLEMENT_DELETE_REQUEST, watchSupplementDeleteRequest)
  yield takeEvery(actions.SUPPLEMENTS_FETCH_REQUEST, watchSupplementsFetchRequest)
}

function* watchSupplementsFetchRequest({ payload, callback }) {
  try {
    const response = yield call(Api.Supplements.fetch, payload.networkId, payload.params)

    yield put(actions.supplementsFetchSuccess(toCamelCase({ data: response.data, params: payload.params })))
    callback()
  } catch (error) {
    yield put(actions.supplementsFetchFailure(error))
    notifications.createNotification('error', error.response?.data.errors)
  }
}

function* watchSupplementCreateRequest({ payload }) {
  try {
    const response = yield call(Api.Supplements.create, payload.networkId, payload.params)

    yield put(actions.supplementCreateSuccess(toCamelCase(response.data)))
    notifications.createNotification('create-success')
  } catch (error) {
    yield put(actions.supplementCreateFailure(error))
    notifications.createNotification('error', error.response?.data.errors)
  }
}

function* watchSupplementUpdateRequest({ payload }) {
  try {
    const response = yield call(Api.Supplements.update, payload.networkId, payload.id, payload.params)

    yield put(actions.supplementUpdateSuccess(toCamelCase(response.data)))
    notifications.createNotification('edit-success')
  } catch (error) {
    yield put(actions.supplementUpdateFailure(error))
    notifications.createNotification('error', error.response?.data.errors)
  }
}

function* watchSupplementDeleteRequest({ payload }) {
  try {
    yield call(Api.Supplements.delete, payload.networkId, payload.id)
    yield put(actions.supplementDeleteSuccess(payload))
    notifications.createNotification('delete-success')
  } catch (error) {
    yield put(actions.supplementDeleteFailure(error))
    notifications.createNotification('error', error.response?.data.errors)
  }
}
