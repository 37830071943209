import { consumer } from '../services/cable'

export const consumerConnect = (channelName, targetId, callback) => {
  global.addEventListener = () => {}
  global.removeEventListener = () => {}

  consumer.subscriptions.create({ channel: channelName, user_id: targetId }, {
    received: (data) => callback(data),
    initialized: () => {
      console.log('useChannel - INFO: Init ' + channelName)
    },
    connected: () => {
      console.log('useChannel - INFO: Connected to ' + channelName)
    },
    disconnected: () => {
      console.log('useChannel - INFO: Disconnected')
    }
  })
}
