import {
  SUBSCRIPTIONS_GET_REQUEST,
  SUBSCRIPTIONS_GET_SUCCESS,
  SUBSCRIPTIONS_GET_FAILURE,
  SUBSCRIPTION_SUBSCRIBE_REQUEST,
  SUBSCRIPTION_SUBSCRIBE_SUCCESS,
  SUBSCRIPTION_SUBSCRIBE_FAILURE,
  SUBSCRIPTION_UNSUBSCRIBE_REQUEST,
  SUBSCRIPTION_UNSUBSCRIBE_SUCCESS,
  SUBSCRIPTION_UNSUBSCRIBE_FAILURE,
} from './actions'

const initialState = {
  items: [],
  fetching: false,
  error: null,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SUBSCRIPTIONS_GET_REQUEST:
      return { ...state, fetching: true }
    case SUBSCRIPTIONS_GET_SUCCESS:
      return { ...state, items: payload, fetching: false }
    case SUBSCRIPTIONS_GET_FAILURE:
      return { ...state, error: payload, fetching: false }
    case SUBSCRIPTION_SUBSCRIBE_REQUEST:
      return { ...state, fetching: true }
    case SUBSCRIPTION_SUBSCRIBE_SUCCESS:
      return { ...state, items: state.items.map((i) => (i.id == payload.id ? payload : i)), fetching: false }
    case SUBSCRIPTION_SUBSCRIBE_FAILURE:
      return { ...state, error: payload, fetching: false }
    case SUBSCRIPTION_UNSUBSCRIBE_REQUEST:
      return { ...state, fetching: true }
    case SUBSCRIPTION_UNSUBSCRIBE_SUCCESS:
      return { ...state, items: state.items.map((i) => (i.id == payload.id ? payload : i)), fetching: false }
    case SUBSCRIPTION_UNSUBSCRIBE_FAILURE:
      return { ...state, error: payload, fetching: false }
    default:
      return state
  }
}
