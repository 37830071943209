import React from 'react'
import PropTypes from 'prop-types'
import ClipLoader from 'react-spinners/ClipLoader'
import style from './style.js'
import './styles.scss'

const Loader = (props) => {
  const clipLoader = (
    <ClipLoader css={style.css} sizeUnit={'px'} size={props.size} color={props.color} loading={props.isLoading} />
  )

  return props.blocker ? (
    <div className={`clip-loader-with-blocker ${!props.isLoading && 'inactive'}`}>{clipLoader}</div>
  ) : (
    clipLoader
  )
}

Loader.defaultProps = {
  isLoading: true,
  size: style.size,
  color: style.color,
}

Loader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
  blocker: PropTypes.bool,
}

export default Loader
