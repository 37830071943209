const formatPhoneNumber = (value) => {
  const input = value.replace(/\D/g, '').substring(0, 12)
  const countryCode = '+380'
  const areaCode = input.substring(3, 5)
  const middle = input.substring(5, 8)
  const prelast = input.substring(8, 10)
  const last = input.substring(10, 12)

  if (input.length > 10) {
    return `${countryCode} (${areaCode}) ${middle} ${prelast} ${last}`
  } else if (input.length > 8) {
    return `${countryCode} (${areaCode}) ${middle} ${prelast}`
  } else if (input.length > 5) {
    return `${countryCode} (${areaCode}) ${middle}`
  } else if (input.length > 1) {
    return `${countryCode} (${areaCode}`
  } else {
    return `${countryCode} (${input.substring(0)}`
  }
}

export default formatPhoneNumber
