import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

import EmployeeCreate from './Create/EmployeeCreate'
import { Button, colors } from '../UIkit'
import { Plus } from '../UIkit/svgs'
import EmployeeNoVendors from './Create/EmployeeNoVendors'
import AddButton from '../UIkit/AddButton'

const EmployeesOptions = ({ createEmployee, vendorsList, navigateToNetwork }) => {
  const { t } = useTranslation()

  const [showCreateEmployee, setShowCreateEmployee] = useState(false)
  const [showNoVendor, setShowNoVendor] = useState(false)

  const handleShowModal = () => {
    if (vendorsList.length > 0) {
      setShowCreateEmployee(true)
    } else {
      setShowNoVendor(true)
    }
  }

  const handleCloseCreateEmployee = () => {
    setShowCreateEmployee(false)
  }

  const handleCloseNoVendor = () => {
    setShowNoVendor(false)
  }

  return (
    <div className='employees__options'>
      {!isMobile ? (
        <Button onClick={handleShowModal}>
          <Plus />
          {t('employees:addEmployee')}
        </Button>
      ) : (
        <AddButton onClick={handleShowModal}>
          <Plus color={colors.base.white} />
        </AddButton>
      )}
      {showCreateEmployee && (
        <EmployeeCreate
          createEmployee={createEmployee}
          vendorsList={vendorsList}
          isShown={showCreateEmployee}
          closeModal={handleCloseCreateEmployee}
        />
      )}
      {showNoVendor && (
        <EmployeeNoVendors
          isShown={showNoVendor}
          closeModal={handleCloseNoVendor}
          navigateToNetwork={navigateToNetwork}
        />
      )}
    </div>
  )
}

export default React.memo(EmployeesOptions)
