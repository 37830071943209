import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Text, colors } from './../UIkit'
import { MarkAsRead, Settings, Trash } from '../UIkit/svgs'

const NotificationsTopbar = ({
  isNew,
  isArchive,
  isAnyUnopened,
  isAnyChecked,
  unopenedCount,
  handleOpenAll,
  handleMoveToSettings,
  handleDeleteSelected,
  handleSetNewTab,
  handleSetArchiveTab,
}) => {
  const { t } = useTranslation()

  return (
    <div className='notifications__topbar'>
      <div className='notifications__topbar__tabs'>
        <div className={`notifications__topbar__tabs-new ${isNew ? 'active' : ''}`} onClick={handleSetNewTab}>
          <Text variant={'body-tertiary2'} color={isNew ? colors.gray[800] : colors.gray[400]}>
            {t('notifications:new')}
          </Text>
          <div className='notifications__topbar__tabs-new-counter'>
            <Text variant={'label-medium'} color={isNew ? colors.base.white : colors.gray[400]}>
              {unopenedCount < 100 ? unopenedCount : '99+'}
            </Text>
          </div>
        </div>
        <div
          className={`notifications__topbar__tabs-archive ${isArchive ? 'active' : ''}`}
          onClick={handleSetArchiveTab}
        >
          <Text variant={'body-tertiary2'} color={isArchive ? colors.gray[800] : colors.gray[400]}>
            {t('notifications:archive')}
          </Text>
        </div>
      </div>
      <div className='notifications__topbar__options'>
        {isNew && isAnyUnopened && (
          <div data-title={t('notifications:markAllAsRead')}>
            <MarkAsRead color={colors.primary[500]} onClick={handleOpenAll} />
          </div>
        )}
        {isArchive && isAnyChecked && (
          <div data-title={t('notifications:delete')}>
            <Trash color={colors.primary[500]} onClick={handleDeleteSelected} />
          </div>
        )}
        <div data-title={t('notifications:settings')}>
          <Settings color={colors.primary[500]} onClick={handleMoveToSettings} />
        </div>
      </div>
    </div>
  )
}

NotificationsTopbar.propTypes = {
  isNew: PropTypes.bool,
  isArchive: PropTypes.bool,
  unopenedCount: PropTypes.number,
  handleOpenAll: PropTypes.func,
  handleSetNewTab: PropTypes.func,
  handleSetArchiveTab: PropTypes.func,
}

export default NotificationsTopbar
