import { takeLatest, call, put } from 'redux-saga/effects'
import * as actions from './actions'
import notifications from '../../lib/notifications'
import { toCamelCase } from '../../lib/converter'

import Api from '../../lib/api'

export default function* watcherSaga() {
  yield takeLatest(actions.SUBSCRIPTIONS_GET_REQUEST, watchResourceGetRequest)
  yield takeLatest(actions.SUBSCRIPTION_SUBSCRIBE_REQUEST, watchResourceSubscribeRequest)
  yield takeLatest(actions.SUBSCRIPTION_UNSUBSCRIBE_REQUEST, watchResourceUnsubscribeRequest)
}

function* watchResourceGetRequest({ payload }) {
  try {
    const response = yield call(Api.Subscriptions.get, payload.networkId, payload.userId, payload.params)

    yield put(actions.subscriptionsGetSuccess(toCamelCase(response.data)))
  } catch (error) {
    yield put(actions.subscriptionsGetFailure(error))
    notifications.createNotification('error')
  }
}

function* watchResourceSubscribeRequest({ payload }) {
  try {
    const response = yield call(Api.Subscriptions.subscribe, payload.networkId, payload.userId, payload.id)

    yield put(actions.subscriptionSubscribeSuccess(toCamelCase(response.data)))
    notifications.createNotification('settings-changed-successfully')
  } catch (error) {
    yield put(actions.subscriptionSubscribeFailure(error))
    notifications.createNotification('error')
  }
}

function* watchResourceUnsubscribeRequest({ payload }) {
  try {
    const response = yield call(Api.Subscriptions.unsubscribe, payload.networkId, payload.userId, payload.id, payload)

    yield put(actions.subscriptionUnsubscribeSuccess(toCamelCase(response.data)))
    notifications.createNotification('settings-changed-successfully')
  } catch (error) {
    yield put(actions.subscriptionUnsubscribeFailure(error))
    notifications.createNotification('error')
  }
}
