// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/landing/Intro/green-cat.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../assets/images/landing/Intro/red-cat.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../../assets/images/landing/Intro/polygons.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&family=Unbounded:wght@300;500&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "#intro{flex:1;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");background-repeat:no-repeat;background-size:20%,15%,55% 90%;background-position:bottom -20px left,top right,center;align-items:center;padding-top:128px;min-height:calc(100vh - 144px)}#intro .content{flex-direction:column;align-items:center;width:1013px}#intro .content .title{font-family:\"Unbounded\",cursive;font-weight:300;font-size:64px;line-height:90px;color:#3f3947;margin-bottom:.25em;text-align:center;text-transform:uppercase}#intro .content .title strong{display:contents}#intro .content .description{color:#71667f;font-size:16px;line-height:20px;margin-bottom:2.5em;width:530px}#intro .content .join-us-container{flex-direction:column}#intro .content .join-us-container .label{font-size:24px;color:rgba(0,0,0,.8);font-weight:bold;margin-bottom:1em}#intro .content .join-us-container .two-buttons{display:flex;flex-wrap:wrap;zoom:1.2}#intro .content .join-us-container .two-buttons img{max-width:140px;cursor:pointer;background:none;margin-bottom:15px;transition:.3s}#intro .content .join-us-container .two-buttons img:first-child{margin-right:15px}#intro .content .join-us-container .two-buttons img:hover{opacity:.7;transition:.3s}", ""]);
// Exports
module.exports = exports;
