import { NotificationManager } from 'react-notifications'
import i18n from '../services/i18n'

class Notifications {
  createNotification = (type, message) => {
    switch (type) {
      case 'create-success':
        NotificationManager.success(i18n.t('notifications:succesfullyCreated'))
        break

      case 'code-sent':
        NotificationManager.success(i18n.t('notifications:codeSent'))
        break

      case 'delete-success':
        NotificationManager.success(i18n.t('notifications:succesfullyDeleted'))
        break

      case 'edit-success':
        NotificationManager.success(message || i18n.t('notifications:succesfullyUpdated'))
        break

      case 'login-success':
        NotificationManager.success(i18n.t('notifications:loginSuccess'))
        break

      case 'error':
        NotificationManager.error(message || i18n.t('notifications:error'))
        break

      case '404':
        NotificationManager.error(message || i18n.t('notifications:notFound'))
        break

      case 'session-expired':
        NotificationManager.error(i18n.t('notifications:sessionExpired'))
        break

      case 'subscriber-created':
        NotificationManager.success(i18n.t('notifications:subscriberCreated'))
        break

      case 'subscriber-email-duplicate':
        NotificationManager.error(i18n.t('notifications:subscriberEmailDuplicate'))
        break

      case 'name-empty':
        NotificationManager.error(i18n.t('notifications:nameEmpty'))
        break

      case 'text-empty':
        NotificationManager.error(i18n.t('notifications:textEmpty'))
        break

      case 'name-only-letters':
        NotificationManager.error(i18n.t('notifications:nameOnlyLetters'))
        break

      case 'email-empty':
        NotificationManager.error(i18n.t('notifications:emailEmpty'))
        break

      case 'email-invalid':
        NotificationManager.error(i18n.t('notifications:emailInvalid'))
        break

      case 'mail-sent':
        NotificationManager.success(i18n.t('notifications:mailSent'))
        break

      case 'max-5':
        NotificationManager.error(i18n.t('notifications:maxFive'))
        break

      case 'updated-map':
        NotificationManager.warning(i18n.t('notifications:updatedMap'))
        break

      case 'mail-backend-error':
        NotificationManager.error(i18n.t('notifications:mailBackendError'))
        break

      case 'password-reset-success':
        NotificationManager.success(i18n.t('notifications:passwordResetSuccess'))
        break

      case 'password-reset-error':
        NotificationManager.error(`${i18n.t('notifications:passwordResetError')} ${message}`)
        break

      case 'email-confirmation-success':
        NotificationManager.success(i18n.t('notifications:emailConfirmationSuccess'))
        break

      case 'email-confirmation-required':
        NotificationManager.success(i18n.t('notifications:emailConfirmationRequired'))
        break

      case 'email-confirmation-error':
        NotificationManager.error(`${i18n.t('notifications:emailConfirmationError')} ${message}`)
        break

      case 'passwords-does-not-match':
        NotificationManager.error(i18n.t('notifications:passwordDoesNotMatch'))
        break

      case 'settings-changed-successfully':
        NotificationManager.success(i18n.t('notifications:settingsChangedSuccessfully'))
        break

      default:
        NotificationManager.error(message || i18n.t('notifications:somethingWentWrok'))
        break
    }
  }
}

export default new Notifications()
