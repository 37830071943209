import { EMAIL_CONFIRMATION_REQUEST, EMAIL_CONFIRMATION_SUCCESS, EMAIL_CONFIRMATION_FAILURE } from './actions'

const initialState = {
  loading: true,
}

export default (state = initialState, { type }) => {
  switch (type) {
    case EMAIL_CONFIRMATION_REQUEST:
      return { ...state }
    case EMAIL_CONFIRMATION_SUCCESS:
      return { ...state, loading: false }
    case EMAIL_CONFIRMATION_FAILURE:
      return { ...state, loading: false }
    default:
      return state
  }
}
