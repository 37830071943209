export const SESSION_CREATE_REQUEST = 'STAFF_SESSION_CREATE_REQUEST'
export const SESSION_CREATE_SUCCESS = 'STAFF_SESSION_CREATE_SUCCESS'
export const SESSION_CREATE_FAILURE = 'STAFF_SESSION_CREATE_FAILURE'
export const SESSION_DELETE_REQUEST = 'STAFF_SESSION_DELETE_REQUEST'
export const SESSION_DELETE_SUCCESS = 'STAFF_SESSION_DELETE_SUCCESS'
export const SESSION_DELETE_FAILURE = 'STAFF_SESSION_DELETE_FAILURE'

export const sessionCreateRequest = (payload) => ({
  type: SESSION_CREATE_REQUEST,
  payload,
})

export const sessionCreateSuccess = (item) => ({
  type: SESSION_CREATE_SUCCESS,
  payload: item,
})

export const sessionCreateFailure = (error) => ({
  type: SESSION_CREATE_FAILURE,
  payload: error,
})

export const sessionDeleteRequest = (payload) => ({
  type: SESSION_DELETE_REQUEST,
  payload,
})

export const sessionDeleteSuccess = (payload) => ({
  type: SESSION_DELETE_SUCCESS,
  payload,
})

export const sessionDeleteFailure = (error) => ({
  type: SESSION_DELETE_FAILURE,
  payload: error,
})
