import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'

import { BankAccountForm, Control, WithdrawalMethodInfo } from '../'
import { Text, colors } from '../../UIkit'
import { Plus, DefaultWithdrawal, Bank } from '../../UIkit/svgs'
import { capitalize } from '../../../lib'
import { WITHDRAWAL_METHOD_TYPE } from '../../../constants'

import './styles.scss'

const WITHDRAWAL_METHOD = {
  new: 'new',
  created: 'created',
}

const WithdrawalMethod = ({ withdrawalMethods, createBankAccount, deleteBankAccount, updateBankAccount }) => {
  const { t } = useTranslation()

  const [activeMethodType, setActiveMethodType] = useState(WITHDRAWAL_METHOD.new)
  const [activeMethod, setActiveMethod] = useState({})

  const [isStartScroll, setIsStartScroll] = useState(true)
  const [isEndScroll, setIsEndScroll] = useState(false)

  const handleSelectMethod = (type, method = {}) => () => {
    setActiveMethodType(type)
    setActiveMethod(method)
  }

  const handleDeleteMethod = () => {
    deleteBankAccount(activeMethod.id)

    setActiveMethodType(WITHDRAWAL_METHOD.new)
    setActiveMethod({})
  }

  const handleUpdateMethod = () => {
    updateBankAccount({
      id: activeMethod.id,
      default: activeMethod.default,
    })
  }

  const handleChangeMethod = ({ target }) => {
    setActiveMethod((prev) => ({ ...prev, [target.name]: target.checked }))
  }

  const isActive = (method = {}) => activeMethod == method

  const methodsRef = useRef()

  const handleScroll = () => {
    const { scrollLeft, scrollWidth, clientWidth } = methodsRef.current
    const scrollHeight = scrollWidth - clientWidth

    setIsStartScroll(scrollLeft == 0)
    setIsEndScroll(scrollHeight === scrollLeft)
  }

  return (
    <div className='withdrawal'>
      <div className='withdrawal__header'>
        <Text shouldBeUppercase variant='body-tertiary1' color={colors.gray[500]}>
          {t('settings:withdrawalMethod:myMethods')}
        </Text>

        <Control
          methodsRef={methodsRef}
          isStartScroll={isStartScroll}
          isEndScroll={isEndScroll}
          enabled={withdrawalMethods?.length > 1}
        />
      </div>

      <div className='withdrawal__method-list' ref={methodsRef} onScroll={handleScroll}>
        <div
          className={`withdrawal__method-list__${isEmpty(activeMethod) ? 'active-method' : 'method'}`}
          onClick={handleSelectMethod(WITHDRAWAL_METHOD.new)}
        >
          <div className='withdrawal__method-list__new'>
            <Plus width={24} height={24} color={colors.primary[500]} />
            <Text variant='body-main1'>{t('settings:withdrawalMethod:newMethod')}</Text>
          </div>
        </div>

        {withdrawalMethods.map((method, i) => (
          <div
            key={i}
            className={`withdrawal__method-list__${isActive(method) ? 'active-method' : 'method'}`}
            onClick={handleSelectMethod(WITHDRAWAL_METHOD.created, method)}
          >
            <div className='withdrawal__method-list__row'>
              <div className='withdrawal__method-list__method-name'>
                {method.type === WITHDRAWAL_METHOD_TYPE.bank && <Bank color={colors.primary[500]} />}
                <Text variant='body-main1'>{capitalize(method.name)}</Text>
              </div>

              {isActive(method) && <DefaultWithdrawal />}
            </div>

            <div className='withdrawal__method-list__method-code'>
              <Text variant='body-tertiary1' color={colors.gray[500]}>
                {method.number}
              </Text>
            </div>
          </div>
        ))}
      </div>

      {activeMethodType == WITHDRAWAL_METHOD.new && (
        <div className='withdrawal__type'>
          <Text variant='body-tertiary1' color={colors.gray[500]}>
            {t('settings:withdrawalMethod:bankAccount')}
          </Text>
        </div>
      )}

      {activeMethodType == WITHDRAWAL_METHOD.new && <BankAccountForm createBankAccount={createBankAccount} />}

      {activeMethodType == WITHDRAWAL_METHOD.created && (
        <WithdrawalMethodInfo
          method={activeMethod}
          handleChangeMethod={handleChangeMethod}
          handleDeleteMethod={handleDeleteMethod}
          handleUpdateMethod={handleUpdateMethod}
        />
      )}
    </div>
  )
}

WithdrawalMethod.propTypes = {
  withdrawalMethods: PropTypes.array.isRequired,
  createBankAccount: PropTypes.func.isRequired,
  deleteBankAccount: PropTypes.func.isRequired,
  updateBankAccount: PropTypes.func.isRequired,
}

export default WithdrawalMethod
