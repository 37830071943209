import { takeLatest, call, put } from 'redux-saga/effects'
import * as actions from './actions'

import Api from '../../../../lib/api'
import notifications from '../../../../lib/notifications'
import { toCamelCase } from '../../../../lib/converter'

export default function* watcherSaga() {
  yield takeLatest(actions.STAFF_VENDOR_SUPPLEMENTS_FETCH_REQUEST, watchResourcesFetchRequest)
}

function* watchResourcesFetchRequest({ payload }) {
  try {
    const response = yield call(Api.Staff.Vendors.Supplements.fetch, payload)

    yield put(actions.staffVendorSupplementsFetchSuccess(toCamelCase(response?.data)))
  } catch (error) {
    yield put(actions.staffVendorSupplementsFetchFailure(error))
    notifications.createNotification('error', error.response?.data)
  }
}
