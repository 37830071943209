import { takeLatest, call, put } from 'redux-saga/effects'
import * as actions from './actions'

import Api from '../../../lib/api'
import notifications from '../../../lib/notifications'
import i18n from '../../../services/i18n'
import { RouteNames } from '../../../router'

export default function* watcherSaga() {
  yield takeLatest(actions.SESSION_CREATE_REQUEST, watchResourceCreateRequest),
  yield takeLatest(actions.SESSION_DELETE_REQUEST, watchResourceDeleteRequest)
}

function* watchResourceCreateRequest({ payload: { data, history } }) {
  try {
    const response = yield call(Api.Staff.Session.create, data)

    yield put(actions.sessionCreateSuccess(response.data))
    localStorage.setItem('staffSession', JSON.stringify(response.data))
    localStorage.setItem('i18nextLng', 'uk')
    i18n.changeLanguage('uk')
    history(RouteNames.STAFF_OWNERS)

    notifications.createNotification('login-success')
  } catch (error) {
    yield put(actions.sessionCreateFailure(error.response.data.errors))
    notifications.createNotification('error', error.response.data.errors)
  }
}

function* watchResourceDeleteRequest({ payload: { history } }) {
  try {
    yield call(Api.Staff.Session.delete)

    yield put(actions.sessionDeleteSuccess())

    localStorage.removeItem('staffSession')
    localStorage.setItem('i18nextLng', 'uk')
    history(RouteNames.STAFF_SIGNIN)
  } catch (error) {
    yield put(actions.sessionDeleteFailure(error))
    notifications.createNotification('error')
  }
}
