import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { SignUp } from '../../components/Authentication'
import { colors, Text } from '../../components/UIkit'
import { AuthenticationImage } from '../../components/UIkit/svgs'
import './styles.scss'
import { leadTrackEvent } from '../../services/fbqEvents'
import { checkCredentialsRequest } from '../../store/checkCredentials/actions'
import { userCreateRequest, userPhoneRegistrationRequest } from '../../store/users/actions'
import { RouteNames } from '../../router'

const SignUpContainer = () => {
  const dispatch = useDispatch()
  const history = useNavigate()
  const { t } = useTranslation()

  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    phoneNumber: '+380 (',
    smsCode: '',
  })

  const checkCredentials = (data) => dispatch(checkCredentialsRequest(data))
  const createOwnerByEmail = (data) => dispatch(userCreateRequest(data, history))
  const createOwnerByPhone = (data) => dispatch(userPhoneRegistrationRequest(data, history))

  const session = useSelector((state) => state.authentication)

  useEffect(() => {
    if (session?.authenticated) {
      leadTrackEvent()
      history(RouteNames.DASHBOARD)
    }
  })

  const createOwner = (data) => {
    userData.smsCode.length > 0 ? createOwnerByPhone(data) : createOwnerByEmail(data)
  }

  return (
    <div className='auth-page'>
      <div className='auth-page__container'>
        <div className='auth-page__image-wrapper'>
          <Text className='auth-page__welcome-text'>{t('signup:title')}</Text>
          <div className='auth-page__additional-text'>
            <Text variant={'h4'} color={colors.gray[500]}>
              {t('signup:description')}
            </Text>
          </div>
          <AuthenticationImage />
        </div>
        <div>
          <SignUp
            checkCredentials={checkCredentials}
            userData={userData}
            setUserData={setUserData}
            createOwner={createOwner}
            history={history}
          />
        </div>
      </div>
    </div>
  )
}

export default SignUpContainer
