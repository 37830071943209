import { takeLatest, call, put } from 'redux-saga/effects'
import * as actions from './actions'
import notifications from '../../lib/notifications'

import Api from '../../lib/api'
import { toCamelCase } from '../../lib/converter'

export default function* watcherSaga() {
  yield takeLatest(actions.ORDERS_GET_REQUEST, watchResourcesGetRequest)
  yield takeLatest(actions.ORDER_GET_REQUEST, watchResourceGetRequest)
}

function* watchResourcesGetRequest({ payload }) {
  try {
    const response = yield call(Api.Orders.get, payload.networkId, payload.params)

    yield put(actions.ordersGetSuccess(toCamelCase(response.data)))
  } catch (error) {
    yield put(actions.ordersGetFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}

function* watchResourceGetRequest({ payload }) {
  try {
    const response = yield call(Api.Order.get, payload.networkId, payload.id)

    yield put(actions.orderGetSuccess(toCamelCase(response.data)))
  } catch (error) {
    yield put(actions.orderGetFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}
