export const QUESTION_CREATE_REQUEST = 'QUESTION_CREATE_REQUEST'
export const QUESTION_CREATE_SUCCESS = 'QUESTION_CREATE_SUCCESS'
export const QUESTION_CREATE_FAILURE = 'QUESTION_CREATE_FAILURE'

export const questionCreateRequest = (payload) => ({
  type: QUESTION_CREATE_REQUEST,
  payload,
})

export const questionCreateSuccess = (payload) => ({
  type: QUESTION_CREATE_SUCCESS,
  payload,
})

export const questionCreateFailure = (payload) => ({
  type: QUESTION_CREATE_FAILURE,
  payload,
})
