import React, { useState } from 'react'
import './styles.scss'

const Tooltip = ({ content, delay, direction, disableHoverListener, children }) => {
  let timeout
  const [active, setActive] = useState(false)

  const showTip = () => {
    if(disableHoverListener) return

    timeout = setTimeout(() => {
      setActive(true)
    }, delay)
  }

  const hideTip = () => {
    if(disableHoverListener) return

    clearInterval(timeout)
    setActive(false)
  }

  return (
    <div
      className='tooltip-wrapper'
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {children}
      {active && (
        <div className={`tooltip-tip ${direction}`}>
          {content}
        </div>
      )}
    </div>
  )
}

Tooltip.defaultProps = {
  delay: 400,
  direction: 'top',
}

export default Tooltip
