import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import NotificationsContainer from '../../containers/Notifications'
import { colors, RightModal } from '../UIkit'
import { Bell } from '../UIkit/svgs'
import ProfileMenu from './ProfileMenu'
import ChangePasswordModal from './ChangePassword'
import { reduceNotifications } from '../../store/notifications/actions'

const Profile = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const [showMenu, setShowMenu] = useState(false)
  const [showNotifications, setShowNotifications] = useState(false)
  const [showChangePassword, setShowChangePassword] = useState(false)
  
  const currentUser = useSelector((state) => state.authentication.currentSession)
  const { unopenedCount } = useSelector((state) => state.notifications)

  const handleOpenNotifications = () => {
    setShowNotifications(true)
  }

  const handleCloseNotifications = () => {
    setShowNotifications(false)
  }

  const handleCloseProfileMenu = () => {
    setShowMenu(false)
  }

  useEffect(() => {
    if (showMenu) dispatch(reduceNotifications())
  }, [showMenu])

  const styles = {
    backgroundImage: `url(${currentUser?.profile?.avatar_url})`,
    borderColor: showMenu && colors.primary[500],
  }

  return (
    <>
      <div className='header__profile'>
        <div
          className={`header__notifications-icon ${unopenedCount ? 'has-notifications' : ''}`}
          onClick={handleOpenNotifications}
        >
          <Bell />
        </div>
        <div style={styles} className='header__avatar' onClick={() => setShowMenu(!showMenu)}></div>
      </div>
      {showMenu && 
        <ProfileMenu 
          currentUser={currentUser}
          handleClose={handleCloseProfileMenu}
          showChangePassword={setShowChangePassword} 
        />
      }
      {showChangePassword && <ChangePasswordModal show={showChangePassword} setShow={setShowChangePassword} />}
      <RightModal title={t('notifications:title')} show={showNotifications} handleClose={handleCloseNotifications}>
        <NotificationsContainer handleCloseNotifications={handleCloseNotifications}/>
      </RightModal>
    </>
  )
}

export default React.memo(Profile)
