import {
  NETWORK_CREATE_REQUEST,
  NETWORK_CREATE_SUCCESS,
  NETWORK_CREATE_FAILURE,

  NETWORK_GET_REQUEST,
  NETWORK_GET_SUCCESS,
  NETWORK_GET_FAILURE,

  NETWORK_UPDATE_REQUEST,
  NETWORK_UPDATE_SUCCESS,
  NETWORK_UPDATE_FAILURE,

  POSTER_CREATE_FAILURE,
  POSTER_CREATE_REQUEST,
  POSTER_CREATE_SUCCESS,
  POSTER_DELETE_REQUEST,
  POSTER_DELETE_SUCCESS,
  POSTER_DELETE_FAILURE
} from './actions'

const initialState = {
  network:  {},
  fetching: false,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case NETWORK_CREATE_REQUEST:
      return { ...state, fetching: true }
    case NETWORK_CREATE_SUCCESS:
      return { ...state, network: payload, fetching: false }
    case NETWORK_CREATE_FAILURE:
      return { ...state, fetching: false }

    case NETWORK_UPDATE_REQUEST:
      return { ...state, fetching: true }
    case NETWORK_UPDATE_SUCCESS:
      return { ...state, network: payload, fetching: false }
    case NETWORK_UPDATE_FAILURE:
      return { ...state, fetching: false }

    case NETWORK_GET_REQUEST:
      return { ...state, fetching: true }
    case NETWORK_GET_SUCCESS:
      return { ...state, network: payload, fetching: false }
    case NETWORK_GET_FAILURE:
      return { ...state, fetching: false }

    case POSTER_CREATE_REQUEST: {
      return { ...state, fetching: true }
    }
    case POSTER_CREATE_SUCCESS: {
      return { ...state, network: { ...state.network, ...payload }, fetching: false }
    }
    case POSTER_CREATE_FAILURE:
      return { ...state, fetching: false, errors: payload }
    case POSTER_DELETE_REQUEST: {
      return { ...state, fetching: true }
    }
    case POSTER_DELETE_SUCCESS: {
      return {
        ...state,
        network: { ...state.network, ...payload },
        fetching: false,
      }
    }
    case POSTER_DELETE_FAILURE:
      return { ...state, fetching: false, errors: payload }
    default:
      return state
  }
}
