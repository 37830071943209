import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { en, ua } from '../constants/localizations'

i18n.use(initReactI18next).init({
  lng: localStorage.getItem('i18nextLng') || 'uk',
  fallbackLng: localStorage.getItem('i18nextLng') || ['en', 'uk'],
  supportedLngs: ['en', 'uk'],
  debug: true,
  resources: {
    en: en,
    uk: ua,
  },
  ns: ['translation'],
  defaultNS: 'translation',
  saveUserLanguagePreference: true,
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
