import React from 'react'
import moment from 'moment'

import InfoRow from '../../components/InfoRow'

const NetworkInfo = ({ network }) => {
  return (
    <div className='staff-info-block'>
      <InfoRow title='Description' text={network.description} />
      <InfoRow title='Url' text={network.url} />
      <InfoRow title='Creation date' text={moment(network.createdAt).format('DD-MM-YYYY')} />
    </div>
  )
}

export default NetworkInfo
