import React from 'react'

import './styles.scss'
import './responsiveStyles.scss'
import { AppStoreButton, GooglePlayButton } from '../../../../assets/svgs/landing'
import Links from '../../../Landing/constants'

function AppLinks() {
  return (
    <section id='links'>
      <div className='section-wrapper'>
        <div className='description'>
          <p>Бог створив кота, щоб людина змогла погладити тигра. Ми створили цей додаток, щоб повсякденне життя
            бізнесу в сфері обслуговування було наповнене приємною рутиною і сердечками,такими як роблять бариста</p>
        </div>
        <div className='join-us'>
          <span>Завантажити додаток для Бариста</span>
          <div className='two-buttons'>
            <a href={Links.linkToBaristaGooglePlay}>
              <img src={GooglePlayButton} alt='Google play' />
            </a>
            <a href={Links.linkToBaristaAppStore}>
              <img src={AppStoreButton} alt='App store' />
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AppLinks
