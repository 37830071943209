import React, { useCallback, useMemo, useState } from 'react'

import OrdersTab from './OrdersTab/OrdersTab'
import MenuTab from './MenuTab/MenuTab'
import { Tabs } from '../../../../UIkit'

const TABS = ['Menu', 'Orders History']

const VendorTabs = () => {
  const [tab, setTab] = useState(TABS[0])

  const onTabChange = useCallback((tabLabel) => {
    setTab(tabLabel)
  }, [setTab])

  const Component = useMemo(() => (tab === 'Menu') ? <MenuTab /> : <OrdersTab />, [tab])

  return (
    <div className='w-100'>
      <Tabs
        tabs={TABS}
        currentTab={tab}
        onTabChange={onTabChange}
      />
      {Component}
    </div>
  )
}

export default VendorTabs
