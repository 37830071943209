import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Modal } from '../../components/UIkit'
import { Feedbacks } from '../../components/Network'
import { vendorGetRequest, vendorRatingGetRequest, vendorFeedbacksGetRequest } from '../../store/vendors/actions'

const FeedbacksContainer = ({ isShown, closeModal, id }) => {
  const { t } = useTranslation()
  const networkId = localStorage.getItem('networkId')
  const dispatch = useDispatch()

  const { item: vendor, rating, feedbacks, itemFetching: fetching } = useSelector((state) => state.vendors)


  const getRaiting = () => dispatch(vendorRatingGetRequest({ networkId, id }))
  const getFeedbacks = () => dispatch(vendorFeedbacksGetRequest({ networkId, id }))
  const getVendor = () => dispatch(vendorGetRequest({ networkId, id }))

  useEffect(() => { getRaiting() }, [])
  useEffect(() => { getFeedbacks() }, [])
  useEffect(() => { getVendor() }, [])


  return (
    <Modal
      show={isShown}
      handleClose={closeModal}
      fetching={fetching}
      title={t('network:customerFeedback')}
    >
      <Feedbacks rating={rating} vendor={vendor} feedbacks={feedbacks} />
    </Modal>
  )
}

export default FeedbacksContainer
