import React from 'react'

import { Feature1, Feature2, Feature3, Feature4 } from '../../../../assets/images/landing/Features'
import './styles.scss'
import './responsiveStyles.scss'

function Features() {
  return (
    <section id='features' className='business-section'>
      <h1>Основні можливості додатку</h1>
      <div className='section-wrapper'>
        <div className='feature'>
          <div className='description'>
            <span className='number'>01</span>
            <div className='info'>
              <h6>Інформація про замовлення</h6>
              <p>Відстежуйте опрацювання замовлень працівниками протягом потрібного вам періоду: по днях, по тижнях чи
                 місяцях</p>
            </div>
          </div>
          <img src={Feature1} alt='Feature 1' />
        </div>
        <div className='feature'>
          <img src={Feature2} alt='Feature 2' />
          <div className='description'>
            <span className='number'>02</span>
            <div className='info'>
              <h6>Мережа ваших кафе та ресторанів</h6>
              <p>Додавайте адреси, години роботи та завантажуйте фото всіх ваших закладів</p>
            </div>
          </div>
        </div>
        <div className='feature'>
          <div className='description'>
            <span className='number'>03</span>
            <div className='info'>
              <h6>Управління персоналом</h6>
              <p>Надавайте доступ до інформації про замовлення для ваших бариста і статистичні дані для вашого
                аналітичного відділу за допомогою email запрошень</p>
            </div>
          </div>
          <img src={Feature3} alt='Feature 3' />
        </div>
        <div className='feature'>
          <img src={Feature4} alt='Feature 4' />
          <div className='description'>
            <span className='number'>04</span>
            <div className='info'>
              <h6>Інтерактивне меню</h6>
              <p>Заповнюйте інформацію та додавайте фото кожного напою, десертів, їжі та товарів, які є у вашому меню</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Features
