import React from 'react'

import './styles.scss'

const Actions = ({ children, elemRef }) => {
  const viewHeight = window.innerHeight
  const viewSpaceBelow = viewHeight - elemRef.getBoundingClientRect()?.top

  const styles = {
    bottom: viewSpaceBelow <= 180 ? '100px' : 'auto',
  }

  return (
    <div style={styles} className='actions-tip with-input-shadow'>
      {children}
    </div>
  )
}

export default Actions
