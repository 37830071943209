import React, { useMemo } from 'react'

import { colors, Text } from '../../UIkit'

const TableText = ({ text }) => {
  const textEmptyCondition = useMemo(() => {
    if (text === 0) return false

    return !text || (text === ' ')
  }, [text])

  return textEmptyCondition ? (
    <Text variant='body-tertiary2' color={colors.primary[500]}>Empty</Text>
  ) : (
    <Text variant='body-tertiary2' color={colors.gray[400]}>{text}</Text>
  )
}
export default TableText
