import {
  WITHDRAWALS_GET_REQUEST,
  WITHDRAWALS_GET_SUCCESS,
  WITHDRAWALS_GET_FAILURE,
  WITHDRAWAL_CREATE_REQUEST,
  WITHDRAWAL_CREATE_SUCCESS,
  WITHDRAWAL_CREATE_FAILURE,
  WITHDRAWAL_GET_REQUEST,
  WITHDRAWAL_GET_SUCCESS,
  WITHDRAWAL_GET_FAILURE,
  WITHDRAWALS_CLEAR
} from './actions'

const initialState = {
  withdrawals: [],
  withdrawal: {},
  activeWithdrawalId: '',
  pages: 0,
  fetching: false
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case WITHDRAWALS_GET_REQUEST:
      return { ...state, fetching: true }
    case WITHDRAWALS_GET_SUCCESS:
      return {
        ...state,
        withdrawals: payload,
        activeWithdrawalId: payload.length ? payload[0].active_withdrawal_id : '',
        pages: payload.length ? payload[0].pages : 0,
        fetching: false
      }
    case WITHDRAWALS_GET_FAILURE:
      return { ...state, fetching: false }

    case WITHDRAWAL_GET_REQUEST:
      return { ...state, fetching: true }
    case WITHDRAWAL_GET_SUCCESS:
      return { ...state, withdrawal: payload, fetching: false }
    case WITHDRAWAL_GET_FAILURE:
      return { ...state, fetching: false }

    case WITHDRAWAL_CREATE_REQUEST:
      return { ...state, fetching: true }
    case WITHDRAWAL_CREATE_SUCCESS:
      return {
        ...state,
        withdrawals: [ payload, ...state.withdrawals ],
        activeWithdrawalId: payload.id,
        fetching: false
      }
    case WITHDRAWAL_CREATE_FAILURE:
      return { ...state, fetching: false }

    case WITHDRAWALS_CLEAR:
      return initialState

    default:
      return state
  }
}
