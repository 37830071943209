import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Text, Button, IconButton, colors } from '../UIkit'
import { Globe, Edit, Camera } from '../UIkit/svgs'

const Details = ({ network, handleToggleModal }) => {
  const { t } = useTranslation()

  const logo = network.logo && network.logo.url

  return (
    <div className='details'>
      <div className='logo' style={{ backgroundImage: `url(${logo})` }}>
        {!logo && (
          <>
            <Camera />
            <Text color={colors.gray[500]} variant='h4'>
              {network.name}
            </Text>
          </>
        )}
      </div>

      <Text variant='h4-bold'>{network.name}</Text>
      {network.url && (
        <a href={network.url} className='url'>
          <Globe />
          <Text variant='body-main1'>&nbsp;{network.url}</Text>
        </a>
      )}

      <div className='actions'>
        <Link className='menuLink' to='/menu'>
          <Button variant='secondary'>{t('network:viewMenu')}</Button>
        </Link>
        <IconButton onClick={handleToggleModal}>
          <Edit color={colors.gray[800]} />
        </IconButton>
      </div>

      <Text shouldBeUppercase variant='body-tertiary1'>
        {t('network:about')}
      </Text>
      {network.description && <Text variant='body-main1'>{network.description}</Text>}

      {!network.description && (
        <Text color={colors.gray[300]} variant='body-main1'>
          {t('network:emptyDescription')}
        </Text>
      )}
    </div>
  )
}

Details.propTypes = {
  network: PropTypes.object.isRequired,
  handleToggleModal: PropTypes.func.isRequired,
}

export default Details
