import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Validator } from '../../../lib'
import { sessionCreateRequest } from '../../../store/staff/authentication/actions'
import { Shown, Hidden } from '../../UIkit/svgs'
import { Input, Button, Text } from '../../UIkit'
import { RouteNames } from '../../../router'
import AppStaff from '../AppStaff'
import './styles.scss'

const SignInView = () => {
  const dispatch = useDispatch()
  const history = useNavigate()

  const { authenticated } = useSelector((state) => state.staffAuthentication)

  useEffect(() => {
    if (authenticated) {
      history(RouteNames.STAFF_OWNERS)
    }
  })

  const [form, setForm] = useState({
    email: '',
    password: '',
  })

  const [errors, setErrors] = useState({
    email: '',
    password: '',
  })

  const [showPassword, setShowPassword] = useState(false)
  const { t } = useTranslation()

  const onHandleClick = () => {
    setShowPassword(!showPassword)
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target

    const validationList = [
      ['email', Validator.concepts.emailRegex, [value]],
      ['password', Validator.concepts.isFilled, [value]],
    ]

    Validator.clear()
    const validationErrors = Validator.validate(validationList)

    setErrors((prev) => ({
      ...prev,
      [name]: validationErrors[name] || '',
    }))

    setForm((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleSubmit = () => {
    dispatch(sessionCreateRequest({ data: form, history }))
  }

  const isDisabled = !form.email || !form.password

  return (
    <AppStaff>
      <div className='sign-in'>
        <Text variant='subheading'>{t('signInAdm:form:title')}</Text>
        <div className='sign-in__form'>
          <div className='input-box'>
            <Input
              type='email'
              name='email'
              value={form.email}
              placeholder={t('input:emailAdm')}
              onChange={handleInputChange}
              error={errors.email}
            />
          </div>
          <div className='input-box'>
            <Input
              type={showPassword ? 'text' : 'password'}
              name='password'
              value={form.password}
              placeholder={t('input:passwordAdm')}
              onChange={handleInputChange}
              error={errors.password}
            />
            <span className='password-icon' onClick={onHandleClick}>
              {showPassword ? <Shown /> : <Hidden />}
            </span>
          </div>
          <Button className='sign-in_btn' type='submit' disabled={isDisabled} onClick={handleSubmit}>
            <p className='sign-in_text'>{t('btn:signInAdm')}</p>
          </Button>
        </div>
      </div>
    </AppStaff>
  )
}

export default SignInView
