import { takeLatest, call, put } from 'redux-saga/effects'
import * as actions from './actions'

import Api from '../../../lib/api'
import notifications from '../../../lib/notifications'
import { toCamelCase } from '../../../lib/converter'

export default function* watcherSaga() {
  yield takeLatest(actions.STAFF_BARISTAS_FETCH_REQUEST, watchResourcesFetchRequest)
}

function* watchResourcesFetchRequest({ payload }) {
  try {
    const response = yield call(Api.Staff.Baristas.fetch, payload.vendorId)

    yield put(actions.staffBaristasFetchSuccess(toCamelCase(response?.data)))
  } catch (error) {
    yield put(actions.staffBaristasFetchFailure(error))
    notifications.createNotification('error', error.response?.data)
  }
}
