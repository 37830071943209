import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { NotificationContainer } from 'react-notifications'

import { sessionRestoreRequest } from '../store/authentication/actions'
import AppRouter from './AppRouter'

const App = () => {
  const dispatch = useDispatch()

  const restoreSession = () => {
    dispatch(sessionRestoreRequest())
  }

  useEffect(() => {
    restoreSession()
  }, [])

  return (
    <BrowserRouter>
      <NotificationContainer />
      <AppRouter />
    </BrowserRouter>
  )
}

export default App
