export const SUPPLEMENTS_CLEAR = 'SUPPLEMENTS_CLEAR'
export const SUPPLEMENTS_CLUSTER_DELETE_REQUEST = 'SUPPLEMENTS_CLUSTER_DELETE_REQUEST'

export const SUPPLEMENTS_FETCH_REQUEST = 'SUPPLEMENTS_FETCH_REQUEST'
export const SUPPLEMENTS_FETCH_SUCCESS = 'SUPPLEMENTS_FETCH_SUCCESS'
export const SUPPLEMENTS_FETCH_FAILURE = 'SUPPLEMENTS_FETCH_FAILURE'
export const SUPPLEMENT_CREATE_REQUEST = 'SUPPLEMENT_CREATE_REQUEST'
export const SUPPLEMENT_CREATE_SUCCESS = 'SUPPLEMENT_CREATE_SUCCESS'
export const SUPPLEMENT_CREATE_FAILURE = 'SUPPLEMENT_CREATE_FAILURE'
export const SUPPLEMENT_UPDATE_REQUEST = 'SUPPLEMENT_UPDATE_REQUEST'
export const SUPPLEMENT_UPDATE_SUCCESS = 'SUPPLEMENT_UPDATE_SUCCESS'
export const SUPPLEMENT_UPDATE_FAILURE = 'SUPPLEMENT_UPDATE_FAILURE'
export const SUPPLEMENT_DELETE_REQUEST = 'SUPPLEMENT_DELETE_REQUEST'
export const SUPPLEMENT_DELETE_SUCCESS = 'SUPPLEMENT_DELETE_SUCCESS'
export const SUPPLEMENT_DELETE_FAILURE = 'SUPPLEMENT_DELETE_FAILURE'

export const supplementsFetchRequest = (payload, callback) => ({
  type: SUPPLEMENTS_FETCH_REQUEST,
  payload,
  callback,
})

export const supplementsFetchSuccess = (payload) => ({
  type: SUPPLEMENTS_FETCH_SUCCESS,
  payload,
})

export const supplementsFetchFailure = (error) => ({
  type: SUPPLEMENTS_FETCH_FAILURE,
  error,
})

export const supplementCreateRequest = (payload) => ({
  type: SUPPLEMENT_CREATE_REQUEST,
  payload,
})

export const supplementCreateSuccess = (payload) => ({
  type: SUPPLEMENT_CREATE_SUCCESS,
  payload,
})

export const supplementCreateFailure = (error) => ({
  type: SUPPLEMENT_CREATE_FAILURE,
  error,
})

export const supplementUpdateRequest = (payload) => ({
  type: SUPPLEMENT_UPDATE_REQUEST,
  payload,
})

export const supplementUpdateSuccess = (payload) => ({
  type: SUPPLEMENT_UPDATE_SUCCESS,
  payload,
})

export const supplementUpdateFailure = (error) => ({
  type: SUPPLEMENT_UPDATE_FAILURE,
  error,
})

export const supplementDeleteRequest = (payload) => ({
  type: SUPPLEMENT_DELETE_REQUEST,
  payload,
})

export const supplementDeleteSuccess = (payload) => ({
  type: SUPPLEMENT_DELETE_SUCCESS,
  payload,
})

export const supplementDeleteFailure = (error) => ({
  type: SUPPLEMENT_DELETE_FAILURE,
  error,
})

// CLEAR

export const supplementsClusterDeleteRequest = (payload) => ({
  type: SUPPLEMENTS_CLUSTER_DELETE_REQUEST,
  payload,
})

export const supplementsClear = () => ({
  type: SUPPLEMENTS_CLEAR,
})
