export const STAFF_VENDOR_PRODUCTS_FETCH_REQUEST = 'STAFF_VENDOR_PRODUCTS_FETCH_REQUEST'
export const STAFF_VENDOR_PRODUCTS_FETCH_SUCCESS = 'STAFF_VENDOR_PRODUCTS_FETCH_SUCCESS'
export const STAFF_VENDOR_PRODUCTS_FETCH_FAILURE = 'STAFF_VENDOR_PRODUCTS_FETCH_FAILURE'

export const staffVendorProductsFetchRequest = (payload) => ({
  type: STAFF_VENDOR_PRODUCTS_FETCH_REQUEST,
  payload
})

export const staffVendorProductsFetchSuccess = (payload) => ({
  type: STAFF_VENDOR_PRODUCTS_FETCH_SUCCESS,
  payload,
})

export const staffVendorProductsFetchFailure = (error) => ({
  type: STAFF_VENDOR_PRODUCTS_FETCH_FAILURE,
  error,
})
