import React from 'react'
import PropTypes from 'prop-types'

import { Rate, Reviews } from './'

const Feedbacks = ({ rating, vendor, feedbacks }) => {
  return (
    <div className='feedbacks'>
      <Rate rating={rating} vendor={vendor} />

      {!!feedbacks.length &&
        <Reviews feedbacks={feedbacks} />
      }
    </div>
  )
}
Feedbacks.propTypes = {
  rating: PropTypes.object.isRequired,
  vendor: PropTypes.object.isRequired,
  feedbacks: PropTypes.array.isRequired
}

export default Feedbacks
