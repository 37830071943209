import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Modal } from '../UIkit'
import { VendorForm } from './'

const VendorCreate = ({ isShown, closeModal, createVendor }) => {
  const { t } = useTranslation()

  return (
    <Modal show={isShown} handleClose={closeModal} title={t('network:addVendor')}>
      <VendorForm
        isNewRecord
        createVendor={createVendor}
        closeModal={closeModal}
      />
    </Modal>
  )
}

VendorCreate.propTypes = {
  isShown: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  createVendor: PropTypes.func.isRequired
}

export default VendorCreate
