import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { EditProfile, Notifications, Settings, WithdrawalMethod } from '../../components/Settings'
import { subscriptionUnsubscribeRequest } from '../../store/subscriptions/actions'

import {
  bankAccountsGetRequest,
  bankAccountCreateRequest,
  bankAccountDeleteRequest,
  bankAccountUpdateRequest,
} from '../../store/bankAccounts/actions'
import { SETTINGS_SECTIONS } from '../../constants'
import { combineWithdrawalMethods } from '../../lib'
import { profileGetRequest, profileUpdateRequest } from '../../store/profiles/actions'
import { adminDeleteRequest } from '../../store/admin/actions'
import { Modal } from '../../components/UIkit'

const SettingsContainer = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const networkId = localStorage.getItem('networkId')
  const location = useLocation()
  const history = useNavigate()

  const [activeSection, setActiveSection] = useState(!isMobile && (location.state?.tab || SETTINGS_SECTIONS.profile))
  const [isOpen, setIsOpen] = useState(false)

  const { items: bankAccounts } = useSelector((state) => state.bankAccounts)
  const { items: subscriptions } = useSelector((state) => state.subscriptions)
  const {
    profile: profile,
    fetching: profileFetching,
    updating: profileUpdating
  } = useSelector((state) => state.profiles)

  const createBankAccount = (data) => dispatch(bankAccountCreateRequest({ networkId, data }))
  const deleteBankAccount = (id) => dispatch(bankAccountDeleteRequest({ networkId, id }))
  const updateBankAccount = (data) => dispatch(bankAccountUpdateRequest({ networkId, data }))
  const getBankAccounts = () => dispatch(bankAccountsGetRequest(networkId))

  const getProfile = () => dispatch(profileGetRequest())
  const updateProfile = (data) => dispatch(profileUpdateRequest(data))

  const deleteProfile = () => dispatch(adminDeleteRequest({ history }))

  const withdrawalMethods = combineWithdrawalMethods(bankAccounts)

  const isSubscribing = (setting) => subscriptions.find((sub) => setting.key === sub.key)?.subscribing

  const handleUpdateSwitch = ({ target }) => {
    const subscription = subscriptions.find((sub) => target.id === sub.key)
    const userId = subscription.targetId
    const id = subscription.id
    const subscribing = !subscription.subscribing

    dispatch(subscriptionUnsubscribeRequest({ networkId, userId, id, subscribing }))
  }

  useEffect(() => {
    getProfile()
  }, [])

  useEffect(() => {
    getBankAccounts()
  }, [])

  const handleClose = () => {
    setIsOpen(false)
  }

  const renderSection = () => {
    switch (activeSection) {
      case SETTINGS_SECTIONS.profile:
        return (
          !profileFetching && <EditProfile
            deleteAdminProfile={deleteProfile}
            profile={profile}
            updating={profileUpdating}
            updateProfile={updateProfile}
          />
        )
      case SETTINGS_SECTIONS.withdrawal:
        return (
          <WithdrawalMethod
            withdrawalMethods={withdrawalMethods}
            createBankAccount={createBankAccount}
            deleteBankAccount={deleteBankAccount}
            updateBankAccount={updateBankAccount}
          />
        )
      case SETTINGS_SECTIONS.notifcations:
        return <Notifications isSubscribing={isSubscribing} handleUpdateSwitch={handleUpdateSwitch} />
    }
  }

  const renderMobileSection = () => {
    switch (activeSection) {
      case SETTINGS_SECTIONS.profile:
        return (
          !profileFetching && <Modal mobileModal title={t('settings:editProfile:title')} show={isOpen} handleClose={handleClose} >
            <EditProfile
              deleteAdminProfile={deleteProfile}
              profile={profile}
              updating={profileUpdating}
              updateProfile={updateProfile}
            />
          </Modal>
        )
      case SETTINGS_SECTIONS.withdrawal:
        return (
          <Modal title={t('withdrawalMethod:title')} show={isOpen} handleClose={handleClose} mobileModal >
            <WithdrawalMethod
              withdrawalMethods={withdrawalMethods}
              createBankAccount={createBankAccount}
              deleteBankAccount={deleteBankAccount}
              updateBankAccount={updateBankAccount}
            />
          </Modal>
        )
      case SETTINGS_SECTIONS.notifcations:
        return (
          <Modal title={t('notifications:title')} show={isOpen} handleClose={handleClose} mobileModal >
            <Notifications isSubscribing={isSubscribing} handleUpdateSwitch={handleUpdateSwitch} />
          </Modal>
        )
    }
  }

  return <Settings 
    activeSection={activeSection} 
    setActiveSection={setActiveSection} 
    renderSection={renderSection} 
    renderMobileSection={renderMobileSection} 
    setOpen={setIsOpen} />
}

export default SettingsContainer
