import React from 'react'
import { useSelector } from 'react-redux'

import { Modal, Text } from '../../../../../UIkit'
import BaristasTable from './BaristasTable'

const BaristasModal = ({ isOpened, handleClose }) => {
  const baristas = useSelector(state => state.staffBaristas)

  const emptyCondition = !baristas.data || !baristas.data.length

  return (
    <Modal show={isOpened} fetching={baristas.fetching} handleClose={handleClose}>
      <div className='p-4'>
        {emptyCondition ? (
          <Text variant='h3'>This vendor has no employees</Text>
        ) : (
          <BaristasTable baristas={baristas.data} />
        )}
      </div>
    </Modal>
  )
}

export default BaristasModal
