import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Chart from 'chart.js/auto'

import { colors } from '../../UIkit'

const BarChart = ({ data, labels }) => {
  const chartRef = useRef()

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d')

    const chartData = {
      labels: labels,
      datasets: [
        {
          data: data.map((obj) => obj.finishedOrders),
          backgroundColor: colors.secondary[400],
          borderRadius: 3,
          minBarLength: 7
        },
        {
          data: data.map((obj) => obj.canceledOrders),
          backgroundColor: colors.gray[100],
          borderRadius: 3,
          minBarLength: 7
        }
      ]
    }

    new Chart(ctx, {
      type: 'bar',
      data: chartData,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 4,
        scales: {
          x: {
            ticks: {
              autoSkip: false,
            },
            grid: {
              display: false
            }
          },
        },

        plugins: {
          customCanvasBackgroundColor: {
            color: colors.base.white,
          },

          tooltip: {
            enabled: false,
            position: 'nearest',
            external: externalTooltipHandler
          },
          
          legend: {
            display: false,
          },
        },
      },
    })
  }, [])

  const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div')
    let triangle = chart.canvas.parentNode.querySelector('div')

    if (!triangle) {
      triangle = document.createElement('div')

      triangle.style.width = '12px'
      triangle.style.borderLeft = '12px solid transparent'
      triangle.style.borderRight = '12px solid transparent'
      triangle.style.borderTop = '12px solid white'
    }

    if (!tooltipEl) {
      tooltipEl = document.createElement('div')
      tooltipEl.style.background = 'white'
      tooltipEl.style.filter = 'drop-shadow(0px -2px 10px rgba(44, 40, 40, 0.04))' +
                               ' drop-shadow(0px 0px 4px rgba(44, 40, 40, 0.06))' +
                               ' drop-shadow(0px 4px 8px rgba(44, 40, 40, 0.08))'
      tooltipEl.style.borderRadius = '8px'

      tooltipEl.style.width = '74px'
      tooltipEl.style.height = '38px'
      tooltipEl.style.padding = '8px'
      tooltipEl.style.marginBottom = '15px'

      tooltipEl.style.display = 'flex'
      tooltipEl.style.flexDirection = 'column'
      tooltipEl.style.alignItems = 'center'

      tooltipEl.style.opacity = 1
      tooltipEl.style.pointerEvents = 'none'
      tooltipEl.style.position = 'absolute'
      tooltipEl.style.transform = 'translate(-50%, -100%)'
      tooltipEl.style.transition = 'all .1s ease'


      const table = document.createElement('table')
      table.style.margin = '0px'
      table.style.display = 'flex'
      table.style.alignItems = 'center'
      table.style.flexDirection = 'column'

      tooltipEl.appendChild(table)
      tooltipEl.appendChild(triangle)

      chart.canvas.parentNode.appendChild(tooltipEl)
    }

    return tooltipEl
  }

  const externalTooltipHandler = (context) => {
    // Tooltip Element
    const { chart, tooltip } = context
    const tooltipEl = getOrCreateTooltip(chart, context)

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0
      return
    }

    // Set Text
    if (tooltip.body) {
      const bodyLines = tooltip.body.map(b => b.lines)

      const tableBody = document.createElement('tbody')
      bodyLines.forEach((body) => {

        const tr = document.createElement('tr')
        tr.style.backgroundColor = 'inherit'
        tr.style.borderWidth = 0

        const td = document.createElement('td')
        td.style.borderWidth = 0
        td.style.fontSize = '15px'
        td.style.fontWeight = 'normal'
        td.style.color = colors.gray[500]

        const text = document.createTextNode(body)

        td.appendChild(text)
        tr.appendChild(td)
        tableBody.appendChild(tr)
      })

      const tableRoot = tooltipEl.querySelector('table')

      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove()
      }

      // Add new children
      tableRoot.appendChild(tableBody)
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1
    tooltipEl.style.left = positionX + tooltip.caretX + 'px'
    tooltipEl.style.top = positionY + (tooltip.caretY - 16) + 'px'
    tooltipEl.style.font = tooltip.options.bodyFont.string
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px'
  }


  Chart.defaults.font.size = 13
  Chart.defaults.color = colors.gray[500]
  Chart.defaults.font.family = 'Montserrat'
  Chart.defaults.font.weight = 500

  return (
    <canvas 
      id='myChart'
      height={376}
      ref={chartRef} />
  )
}

BarChart.propTypes = {
  labels: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired
}

export default BarChart
