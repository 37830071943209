export const BANK_ACCOUNT_CREATE_REQUEST = 'BANK_ACCOUNT_CREATE_REQUEST'
export const BANK_ACCOUNT_CREATE_SUCCESS = 'BANK_ACCOUNT_CREATE_SUCCESS'
export const BANK_ACCOUNT_CREATE_FAILURE = 'BANK_ACCOUNT_CREATE_FAILURE'

export const BANK_ACCOUNTS_GET_REQUEST = 'BANK_ACCOUNTS_GET_REQUEST'
export const BANK_ACCOUNTS_GET_SUCCESS = 'BANK_ACCOUNTS_GET_SUCCESS'
export const BANK_ACCOUNTS_GET_FAILURE = 'BANK_ACCOUNTS_GET_FAILURE'

export const BANK_ACCOUNT_DELETE_REQUEST = 'BANK_ACCOUNT_DELETE_REQUEST'
export const BANK_ACCOUNT_DELETE_SUCCESS = 'BANK_ACCOUNT_DELETE_SUCCESS'
export const BANK_ACCOUNT_DELETE_FAILURE = 'BANK_ACCOUNT_DELETE_FAILURE'

export const BANK_ACCOUNT_UPDATE_REQUEST = 'BANK_ACCOUNT_UPDATE_REQUEST'
export const BANK_ACCOUNT_UPDATE_SUCCESS = 'BANK_ACCOUNT_UPDATE_SUCCESS'
export const BANK_ACCOUNT_UPDATE_FAILURE = 'BANK_ACCOUNT_UPDATE_FAILURE'

export const bankAccountCreateRequest = (payload) => ({
  type: BANK_ACCOUNT_CREATE_REQUEST,
  payload,
})

export const bankAccountCreateSuccess = (payload) => ({
  type: BANK_ACCOUNT_CREATE_SUCCESS,
  payload,
})

export const bankAccountCreateFailure = (error) => ({
  type: BANK_ACCOUNT_CREATE_FAILURE,
  error,
})

export const bankAccountsGetRequest = (payload) => ({
  type: BANK_ACCOUNTS_GET_REQUEST,
  payload,
})

export const bankAccountsGetSuccess = (payload) => ({
  type: BANK_ACCOUNTS_GET_SUCCESS,
  payload,
})

export const bankAccountsGetFailure = (error) => ({
  type: BANK_ACCOUNTS_GET_FAILURE,
  error,
})

export const bankAccountDeleteRequest = (payload) => ({
  type: BANK_ACCOUNT_DELETE_REQUEST,
  payload,
})

export const bankAccountDeleteSuccess = (payload) => ({
  type: BANK_ACCOUNT_DELETE_SUCCESS,
  payload,
})

export const bankAccountDeleteFailure = (error) => ({
  type: BANK_ACCOUNT_DELETE_FAILURE,
  error,
})

export const bankAccountUpdateRequest = (payload) => ({
  type: BANK_ACCOUNT_UPDATE_REQUEST,
  payload,
})

export const bankAccountUpdateSuccess = (payload) => ({
  type: BANK_ACCOUNT_UPDATE_SUCCESS,
  payload,
})

export const bankAccountUpdateFailure = (error) => ({
  type: BANK_ACCOUNT_UPDATE_FAILURE,
  error,
})
