export const NOTIFICATIONS_GET_REQUEST = 'NOTIFICATIONS_GET_REQUEST'
export const NOTIFICATIONS_GET_SUCCESS = 'NOTIFICATIONS_GET_SUCCESS'
export const NOTIFICATIONS_GET_FAILURE = 'NOTIFICATIONS_GET_FAILURE'
export const NOTIFICATIONS_GET_OPENED_REQUEST = 'NOTIFICATIONS_GET_OPENED_REQUEST'
export const NOTIFICATIONS_GET_OPENED_SUCCESS = 'NOTIFICATIONS_GET_OPENED_SUCCESS'
export const NOTIFICATIONS_GET_OPENED_FAILURE = 'NOTIFICATIONS_GET_OPENED_FAILURE'
export const NOTIFICATIONS_OPEN_ALL_REQUEST = 'NOTIFICATIONS_OPEN_ALL_REQUEST'
export const NOTIFICATIONS_OPEN_ALL_SUCCESS = 'NOTIFICATIONS_OPEN_ALL_SUCCESS'
export const NOTIFICATIONS_OPEN_ALL_FAILURE = 'NOTIFICATIONS_OPEN_ALL_FAILURE'
export const NOTIFICATIONS_OPEN_ONE_REQUEST = 'NOTIFICATIONS_OPEN_ONE_REQUEST'
export const NOTIFICATIONS_OPEN_ONE_SUCCESS = 'NOTIFICATIONS_OPEN_ONE_SUCCESS'
export const NOTIFICATIONS_OPEN_ONE_FAILURE = 'NOTIFICATIONS_OPEN_ONE_FAILURE'
export const NOTIFICATIONS_BULK_DELETE_REQUEST = 'NOTIFICATIONS_BULK_DELETE_REQUEST'
export const NOTIFICATIONS_BULK_DELETE_SUCCESS = 'NOTIFICATIONS_BULK_DELETE_SUCCESS'
export const NOTIFICATIONS_BULK_DELETE_FAILURE = 'NOTIFICATIONS_BULK_DELETE_FAILURE'
export const UPDATE_NOTIFICATIONS_LIST = 'UPDATE_NOTIFICATIONS_LIST'
export const REDUCE_NOTIFICATIONS = 'REDUCE_NOTIFICATIONS'
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS'

export const notificationsGetRequest = (payload, callback) => ({
  type: NOTIFICATIONS_GET_REQUEST,
  payload,
  callback,
})

export const notificationsGetSuccess = (payload) => ({
  type: NOTIFICATIONS_GET_SUCCESS,
  payload,
})

export const notificationsGetFailure = (error) => ({
  type: NOTIFICATIONS_GET_FAILURE,
  error,
})

export const notificationsGetOpenedRequest = (payload, callback) => ({
  type: NOTIFICATIONS_GET_OPENED_REQUEST,
  payload,
  callback,
})

export const notificationsGetOpenedSuccess = (payload) => ({
  type: NOTIFICATIONS_GET_OPENED_SUCCESS,
  payload,
})

export const notificationsGetOpenedFailure = (error) => ({
  type: NOTIFICATIONS_GET_OPENED_FAILURE,
  error,
})

export const notificationsOpenAllRequest = (payload) => ({
  type: NOTIFICATIONS_OPEN_ALL_REQUEST,
  payload,
})

export const notificationsOpenAllSuccess = (payload) => ({
  type: NOTIFICATIONS_OPEN_ALL_SUCCESS,
  payload,
})

export const notificationsOpenAllFailure = (error) => ({
  type: NOTIFICATIONS_OPEN_ALL_FAILURE,
  error,
})

export const notificationsOpenOneRequest = (payload) => ({
  type: NOTIFICATIONS_OPEN_ONE_REQUEST,
  payload,
})

export const notificationsOpenOneSuccess = (payload) => ({
  type: NOTIFICATIONS_OPEN_ONE_SUCCESS,
  payload,
})

export const notificationsOpenOneFailure = (error) => ({
  type: NOTIFICATIONS_OPEN_ONE_FAILURE,
  error,
})

export const notificationsBulkDeleteRequest = (payload) => ({
  type: NOTIFICATIONS_BULK_DELETE_REQUEST,
  payload,
})

export const notificationsBulkDeleteSuccess = (payload) => ({
  type: NOTIFICATIONS_BULK_DELETE_SUCCESS,
  payload,
})

export const notificationsBulkDeleteFailure = (error) => ({
  type: NOTIFICATIONS_BULK_DELETE_FAILURE,
  error,
})

export const updateNotificationsList = (payload) => ({
  type: UPDATE_NOTIFICATIONS_LIST,
  payload,
})

export const reduceNotifications = () => ({
  type: REDUCE_NOTIFICATIONS,
})

export const clearNotifications = () => ({
  type: CLEAR_NOTIFICATIONS,
})
