import { takeLatest, call, put } from 'redux-saga/effects'
import * as actions from './actions'

import Api from '../../lib/api'
import notifications from '../../lib/notifications'
import { sessionCreateSuccess } from '../authentication/actions'

export default function* watcherSaga() {
  yield takeLatest(actions.USER_CREATE_REQUEST, watchResourceCreateRequest)
  yield takeLatest(actions.USERS_GET_REQUEST, watchResourcesGetRequest)
  yield takeLatest(actions.USER_SMS_CODE_REQUEST, watchResourceSmsCodeRequest)
  yield takeLatest(actions.USER_PHONE_REGISTRATION_REQUEST, watchResourcePhoneRegistrationRequest)
}

function* watchResourceCreateRequest({ payload, history }) {
  try {
    yield call(Api.Users.create, payload)

    yield put(actions.userCreateSuccess())
    history('/admin')
    notifications.createNotification('email-confirmation-required')
  } catch (error) {
    yield put(actions.userCreateFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}

function* watchResourcesGetRequest({ params }) {
  try {
    const response = yield call(Api.Users.get, params)

    yield put(actions.usersGetSuccess(response))
  } catch (error) {
    yield put(actions.usersGetFailure(error))
    notifications.createNotification('error')
  }
}

function* watchResourceSmsCodeRequest({ payload, callback }) {
  try {
    yield call(Api.SmsCodes.requestCode, payload)
    yield put(actions.userSmsCodeSuccess())
    if (callback) callback()
  } catch (error) {
    yield put(actions.userSmsCodeFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}

function* watchResourcePhoneRegistrationRequest({ payload, history }) {
  try {
    const response = yield call(Api.Users.createPhone, payload)
    const user = {
      ...response.data,
      employee: { role: 'owner', network_id: response.data.network_id, network_name: response.data.network_name }
    }

    localStorage.setItem('networkId', response.data.network_id)
    yield put(actions.userCreateSuccess(user))
    yield put(sessionCreateSuccess(user))
    history('/dashboard')
  } catch (error) {
    yield put(actions.userCreateFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}
