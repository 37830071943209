import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { VendorContext } from '../../vendorContext'
import { Pagination, Text } from '../../../../../UIkit'
import { staffVendorOrdersFetchRequest } from '../../../../../../store/staff/vendors/orders/actions'
import OrdersTable from './OrdersTable'

const OrdersTab = () => {
  const { vendorId } = useContext(VendorContext)

  const dispatch = useDispatch()
  const { data: orders, pages: totalPages, fetching } = useSelector(
    state => state.staffVendorOrders
  )

  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const getOrders = useCallback((params) => {
    dispatch(staffVendorOrdersFetchRequest({ vendorId: vendorId, pagyParams: params }))
  }, [dispatch, staffVendorOrdersFetchRequest, vendorId])

  const onChangePage = useCallback((page = 1) => {
    if (page > totalPages) return

    setPage(page)
    getOrders({ page: page, per: rowsPerPage })
  }, [totalPages, rowsPerPage])

  useEffect(() => {
    onChangePage(1)
  }, [rowsPerPage, vendorId])

  const isOrdersExists = !!orders.length

  if (fetching) return null

  return (
    <div className='d-flex flex-column pt-3'>
      {isOrdersExists ? (
        <OrdersTable orders={orders} />
      ) : (
        <Text variant='subheading'>Orders list is empty</Text>
      )}

      <div className='w-100'>
        <Pagination
          activePage={page}
          perPage={rowsPerPage}
          handleChangePage={onChangePage}
          handleChangePerPage={setRowsPerPage}
          totalPages={totalPages}
          isShow={isOrdersExists}
        />
      </div>
    </div>
  )
}

export default OrdersTab
