import React, { useEffect, useMemo, useState } from 'react'
import { calendarDateRangesList } from '../../lib'
import ReceiptsFilters from '../../components/Receipts/ReceiptsFilters'

const ReceiptsFiltersContainer = ({ filters, handleChangeFilters, handleResetFilters, handleCloseFilters }) => {
  const [dateFilter, setDateFilter] = useState([filters.dateFrom, filters.dateTo])

  const calendarDateRanges = useMemo(() => calendarDateRangesList(dateFilter[0], dateFilter[1]), [dateFilter])

  const handleApplyFilters = () => {
    const data = {
      dateFrom: dateFilter[0],
      dateTo: dateFilter[1],
    }

    handleChangeFilters(data)
    handleCloseFilters()
  }

  useEffect(() => {
    setDateFilter([filters.dateFrom, filters.dateTo])
  }, [filters])

  return (
    <ReceiptsFilters
      dateFilter={dateFilter}
      dateRangesList={calendarDateRanges}
      handleChangeDate={setDateFilter}
      handleResetFilters={handleResetFilters}
      handleApplyFilters={handleApplyFilters}
    />
  )
}

export default ReceiptsFiltersContainer
