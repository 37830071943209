import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import LandingComponent from '../../components/Landing'
import Loader from '../../components/Loader'
import { questionCreateRequest } from '../../store/contactUs/actions'

class LandingContainer extends PureComponent {
  render() {
    return (
      <>
        <Loader blocker isLoading={this.props.isLoadingJoinUs || this.props.isLoadingContactUs} />
        <LandingComponent handleContacUs={this.props.questionCreate} handleJoinToClub={this.props.subscribeCreate} />
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  subscribeCreate: (userParams) => dispatch(subscriberCreateRequest({ userParams })),
  questionCreate: (mail) => dispatch(questionCreateRequest({ mail })),
})

const mapStateToProps = (state) => ({
  isLoadingContactUs: state.contactUs.fetching,
})

LandingContainer.propTypes = {
  subscribeCreate: PropTypes.func.isRequired,
  questionCreate: PropTypes.func.isRequired,
  isLoadingContactUs: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingContainer)
