import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'

import { Text, colors } from '../UIkit'
import { Star, Cup } from '../UIkit/svgs'

const Rate = ({ rating, vendor }) => {
  const { t } = useTranslation()

  const hasFeedback = rating.totalFeedbacks !== 0

  return (
    <div className='rate'>
      <div className='rate__vendor'>
        {vendor.imageUrls && <img src={vendor.imageUrls[0].url} />}

        {!vendor.imageUrls && (
          <div className='rate__vendor__default-pic'>
            <Cup width={20} height={20} />
          </div>
        )}
        <Text variant='body-main1'>{vendor.fullAddress}</Text>
      </div>
      <div className='rate__reviews_amount'>
        <div className='rate__reviews_amount__count'>
          <Star color={colors.primary[500]} width={30} height={30} />
          <Text color={colors.gray[800]} variant='h1-bold'>
            {rating.rating}
          </Text>
          <Text variant='body-main1'>/</Text>
          <Text variant='body-main1'>5</Text>
        </div>

        {hasFeedback && (
          <Text color={colors.gray[500]} variant='body-secondary1'>
            {t('network:basedOn')} {rating.totalFeedbacks} {t('network:reviews')}
          </Text>
        )}

        {!hasFeedback && (
          <Text color={colors.gray[500]} variant='body-secondary1'>
            {t('network:noReviews')}
          </Text>
        )}
      </div>

      <div className='rate__details'>
        {!isEmpty(rating) &&
          rating.statistics.map((statistic, index) => {
            const procentRate = hasFeedback ? ((statistic.count / rating.totalFeedbacks) * 100).toFixed(0) : 0
            return (
              <div key={index} className='rate__details__row'>
                <div className='rate__details-stars'>
                  <Text variant='body-main2'>
                    {statistic.rating} {t('network:stars', { count: statistic.rating })}
                  </Text>
                </div>

                <div className='rate__details__row__scale'>
                  <div style={{ width: `${procentRate}%` }} className='rate__details__row__scale__filled'></div>
                </div>
                <Text variant='body-main2'>{procentRate}%</Text>
              </div>
            )
          })}
      </div>
    </div>
  )
}

Rate.propTypes = {
  rating: PropTypes.object.isRequired,
  vendor: PropTypes.object.isRequired,
}

export default Rate
