import React, { useEffect, useRef } from 'react'

import SideBarMenu from '../../components/SideBar/SideBarMenu'
import { Exit, Logo } from '../../components/UIkit/svgs'
import './styles.scss'
import { useDispatch, useSelector } from 'react-redux'
import { networkGetRequest } from '../../store/networks/actions'
import { isMobile } from 'react-device-detect'

const SideBarContainer = ({ isOpen, onClose }) => {
  const dispatch = useDispatch()
  const networkId = localStorage.getItem('networkId')

  const getNetwork = () => dispatch(networkGetRequest(networkId))
  const network = useSelector((state) => state.networks.network)
  const sidebarRef = useRef()

  useEffect(() => {
    getNetwork()
  }, [])

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isOpen && sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        onClose()
      }
    }

    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [isOpen, onClose])

  return (
    <>
      {!isMobile ? (
        <div className='side-bar'>
          <Logo className='side-bar__logo' />
          <SideBarMenu network={network} />
        </div>
      ) : (
        <>
          <div ref={sidebarRef} className={`side-bar mobile ${isOpen ? 'open' : ''}`}>
            <div className='side-bar__header'>
              <div className='side-bar__header_left'>
                <Logo className='side-bar__logo' width={40} height={40} />
                <div className='side-bar__title'>
                  <p>Pinka</p>
                  <p>Admin</p>
                </div>
              </div>
              <div onClick={onClose}><Exit /></div>
            </div>
            <SideBarMenu network={network} onClose={onClose} />
          </div>
          {isOpen && <div className='side-bar__overlay' onClick={onClose}></div>}
        </>
      )}
    </>
  )
}

export default React.memo(SideBarContainer)
