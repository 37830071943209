import React from 'react'

import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  Text
} from '../../../UIkit'
import { VerticalDots } from '../../../UIkit/svgs'
import NetworkRow from './NetworkRow'

const tableHeaders = [
  'Name',
  'Url',
  'Created At'
]

const NetworksTable = ({ networks }) => {
  return (
    <Table>
      <TableHead>
        {tableHeaders.map(title => (
          <TableHeader key={title}>
            <Text variant='subheading'>{title}</Text>
          </TableHeader>
        ))}
        <TableHeader>
          <VerticalDots />
        </TableHeader>
      </TableHead>
      <TableBody>
        {networks.map(network => <NetworkRow key={network.id} network={network} />)}
      </TableBody>
    </Table>
  )
}

export default NetworksTable
