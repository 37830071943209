export const EMPLOYEES_GET_REQUEST = 'EMPLOYEES_GET_REQUEST'
export const EMPLOYEES_GET_SUCCESS = 'EMPLOYEES_GET_SUCCESS'
export const EMPLOYEES_GET_FAILURE = 'EMPLOYEES_GET_FAILURE'
export const EMPLOYEE_CREATE_REQUEST = 'EMPLOYEE_CREATE_REQUEST'
export const EMPLOYEE_CREATE_SUCCESS = 'EMPLOYEE_CREATE_SUCCESS'
export const EMPLOYEE_CREATE_FAILURE = 'EMPLOYEE_CREATE_FAILURE'
export const EMPLOYEE_UPDATE_REQUEST = 'EMPLOYEE_UPDATE_REQUEST'
export const EMPLOYEE_UPDATE_SUCCESS = 'EMPLOYEE_UPDATE_SUCCESS'
export const EMPLOYEE_UPDATE_FAILURE = 'EMPLOYEE_UPDATE_FAILURE'
export const EMPLOYEES_CLEAR = 'EMPLOYEES_CLEAR'
export const EMPLOYEES_GET_BARISTAS_REQUEST = 'EMPLOYEES_GET_BARISTAS_REQUEST'
export const EMPLOYEES_GET_BARISTAS_SUCCESS = 'EMPLOYEES_GET_BARISTAS_SUCCESS'
export const EMPLOYEES_GET_BARISTAS_FAILURE = 'EMPLOYEES_GET_BARISTAS_FAILURE'
export const EMPLOYEES_CLEAR_BARISTAS = 'EMPLOYEES_CLEAR_BARISTAS'

export const employeesGetRequest = (payload) => ({
  type: EMPLOYEES_GET_REQUEST,
  payload,
})

export const employeesGetSuccess = (payload) => ({
  type: EMPLOYEES_GET_SUCCESS,
  payload,
})

export const employeesGetFailure = (error) => ({
  type: EMPLOYEES_GET_FAILURE,
  error,
})

export const employeeCreateRequest = (payload) => ({
  type: EMPLOYEE_CREATE_REQUEST,
  payload,
})

export const employeeCreateSuccess = (payload) => ({
  type: EMPLOYEE_CREATE_SUCCESS,
  payload,
})

export const employeeCreateFailure = (payload) => ({
  type: EMPLOYEE_CREATE_FAILURE,
  payload,
})

export const employeeUpdateRequest = (payload) => ({
  type: EMPLOYEE_UPDATE_REQUEST,
  payload,
})

export const employeeUpdateSuccess = (payload) => ({
  type: EMPLOYEE_UPDATE_SUCCESS,
  payload,
})

export const employeeUpdateFailure = (payload) => ({
  type: EMPLOYEE_UPDATE_FAILURE,
  payload,
})

export const employeesClear = () => ({
  type: EMPLOYEES_CLEAR,
})

export const employeesGetBaristasRequest = (payload) => ({
  type: EMPLOYEES_GET_BARISTAS_REQUEST,
  payload,
})

export const employeesGetBaristasSuccess = (payload) => ({
  type: EMPLOYEES_GET_BARISTAS_SUCCESS,
  payload,
})

export const employeesGetBaristasFailure = (error) => ({
  type: EMPLOYEES_GET_BARISTAS_FAILURE,
  error,
})

export const employeesClearBaristas = () => ({
  type: EMPLOYEES_CLEAR_BARISTAS,
})
