import { takeLatest, call, put } from 'redux-saga/effects'
import * as actions from './actions'
import notifications from '../../lib/notifications'
import { toCamelCase } from '../../lib/converter'

import Api from '../../lib/api'

export default function* watcherSaga() {
  yield takeLatest(actions.NOTIFICATIONS_GET_REQUEST, watchResourceGetRequest)
  yield takeLatest(actions.NOTIFICATIONS_GET_OPENED_REQUEST, watchResourceGetOpenedRequest)
  yield takeLatest(actions.NOTIFICATIONS_OPEN_ALL_REQUEST, watchResourceOpenAllRequest)
  yield takeLatest(actions.NOTIFICATIONS_OPEN_ONE_REQUEST, watchResourceOpenOneRequest)
  yield takeLatest(actions.NOTIFICATIONS_BULK_DELETE_REQUEST, watchResourceBulkDeleteRequest)
}

function* watchResourceGetRequest({ payload, callback = () => {} }) {
  try {
    const response = yield call(Api.Notifications.get, payload.networkId, payload.userId, payload.params)

    yield put(actions.notificationsGetSuccess(toCamelCase(response.data)))
    callback()
  } catch (error) {
    yield put(actions.notificationsGetFailure(error))
    notifications.createNotification('error')
  }
}

function* watchResourceGetOpenedRequest({ payload, callback = () => {} }) {
  try {
    const response = yield call(Api.Notifications.get, payload.networkId, payload.userId, payload.params)

    yield put(actions.notificationsGetOpenedSuccess(toCamelCase(response.data)))
    callback()
  } catch (error) {
    yield put(actions.notificationsGetOpenedFailure(error))
    notifications.createNotification('error')
  }
}

function* watchResourceOpenAllRequest({ payload }) {
  try {
    const response = yield call(Api.Notifications.openAll, payload.networkId, payload.userId)

    yield put(actions.notificationsOpenAllSuccess(toCamelCase(response.data)))
  } catch (error) {
    yield put(actions.notificationsOpenAllFailure(error))
    notifications.createNotification('error')
  }
}

function* watchResourceOpenOneRequest({ payload: { networkId, userId, id, ...rest } }) {
  try {
    const response = yield call(Api.Notifications.openOne, networkId, userId, id, rest)

    yield put(actions.notificationsOpenOneSuccess(toCamelCase(response.data)))
  } catch (error) {
    yield put(actions.notificationsOpenOneFailure(error))
    notifications.createNotification('error')
  }
}

function* watchResourceBulkDeleteRequest({ payload }) {
  try {
    yield call(Api.Notifications.bulkDelete, payload.networkId, payload.userId, payload.params)

    yield put(actions.notificationsBulkDeleteSuccess(payload.params))
  } catch (error) {
    yield put(actions.notificationsBulkDeleteFailure(error))
    notifications.createNotification('error')
  }
}
