const colors = {
  base: {
    white: '#FFFFFF',
    black: '#000000',
    green: '#6BCB77',
    violet: '#8D72E1',
    yellow: '#FFD365'
  },
  alerts: {
    success: '#20A549',
    warning: '#FFAB00',
    error: '#E52A34',
  },
  gray: {
    50: '#F2F1F4',
    100: '#D8D5DD',
    200: '#BEB8C6',
    300: '#A49CB0',
    400: '#8A8099',
    500: '#71667F',
    600: '#584F63',
    700: '#3F3947',
    800: '#26222A',
    900: '#0D0B0E',
  },
  primary: {
    50: '#FFF0F3',
    100: '#FFE3E8',
    200: '#FFCAD7',
    300: '#FF9FB5',
    400: '#FF6990',
    500: '#FE3C72',
    600: '#EC1259',
    700: '#C7094B',
    800: '#A70A47',
    900: '#8E0D43',
  },
  secondary: {
    50: '#EFFCFC',
    100: '#D7F5F6',
    200: '#B4EBED',
    300: '#80DCE0',
    400: '#3FC1C9',
    500: '#2AA7B0',
    600: '#258795',
    700: '#246D7A',
    800: '#265A64',
    900: '#234C56',
  },
}

export default colors
