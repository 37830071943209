import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

import {
  DashboardFilled,
  Dashboard,
  CartFilled,
  Cart,
  Coffee,
  CoffeeFilled,
  UsersFilled,
  Users,
  MenuFilled,
  Menu,
  SettingsFilled,
  Settings,
  QuestionFilled,
  Question,
  TransactionsFilled,
  Transactions,
} from '../UIkit/svgs'
import { Text, colors } from '../UIkit'
import SideBarHelpModal from './Modal/SideBarHelpModal'

const SideBarMenu = ({ onClose }) => {
  const { t } = useTranslation()

  const [showMenu, setShowMenu] = useState(false)

  const handleCloseMenu = () => {
    setShowMenu(false)
  }

  const handleModalMenu = () => {
    setShowMenu(!showMenu)
    isMobile && onClose()
  }

  return (
    <div className='side-bar__menu'>
      <NavLink
        to='receipts'
        className='side-bar__menu-section'
      >
        {!isMobile &&
        <>
          <NavLink
            className='side-bar__menu-item'
            to='dashboard'
            children={({ isActive }) => {
              return (
                <>
                  {isActive ? <DashboardFilled color={colors.primary[500]} /> : <Dashboard />}
                  <Text variant='button-large' color={isActive ? colors.primary[500] : colors.gray[800]}>
                    {t('sidebar:dashboard')}
                  </Text>
                </>
              )
            }}
          />
          <NavLink
            className='side-bar__menu-item'
            to='receipts'
            children={({ isActive }) => {
              return (
                <>
                  {isActive ? <TransactionsFilled color={colors.primary[500]} /> : <Transactions />}
                  <Text variant='button-large' color={isActive ? colors.primary[500] : colors.gray[800]}>
                    {t('sidebar:transactions')}
                  </Text>
                </>
              )
            }}
          />
          <NavLink
            className='side-bar__menu-item'
            to='orders'
            children={({ isActive }) => {
              return (
                <>
                  {isActive ? <CartFilled color={colors.primary[500]} /> : <Cart />}
                  <Text variant='button-large' color={isActive ? colors.primary[500] : colors.gray[800]}>
                    {t('sidebar:orders')}
                  </Text>
                </>
              )
            }}
          />
          <NavLink
            className='side-bar__menu-item'
            to='network'
            children={({ isActive }) => {
              return (
                <>
                  {isActive ? <CoffeeFilled color={colors.primary[500]} /> : <Coffee />}
                  <Text variant='button-large' color={isActive ? colors.primary[500] : colors.gray[800]}>
                    {t('sidebar:network')}
                  </Text>
                </>
              )
            }}
          />
          <NavLink
            className='side-bar__menu-item'
            to='employees'
            children={({ isActive }) => {
              return (
                <>
                  {isActive ? <UsersFilled color={colors.primary[500]} /> : <Users />}
                  <Text variant='button-large' color={isActive ? colors.primary[500] : colors.gray[800]}>
                    {t('sidebar:employees')}
                  </Text>
                </>
              )
            }}
          />
          <NavLink
            className='side-bar__menu-item'
            to='menu'
            children={({ isActive }) => {
              return (
                <>
                  {isActive ? <MenuFilled color={colors.primary[500]} /> : <Menu />}
                  <Text variant='button-large' color={isActive ? colors.primary[500] : colors.gray[800]}>
                    {t('sidebar:menu')}
                  </Text>
                </>
              )
            }}
          />
        </>}
        {/* <NavLink
          className='side-bar__menu-item'
          to='withdraw_funds'
          onClick={isMobile && onClose}
          children={({ isActive }) => {
            return (
              <>
                {<MoneyWithdraw color={isActive ? colors.primary[500] : null} />}
                <Text variant='button-large' color={isActive ? colors.primary[500] : colors.gray[800]}>
                  {t('sidebar:withdrawFunds')}
                </Text>
              </>
            )
          }}
        /> */}
      </NavLink>
      <div className='side-bar__menu-bottom'>
        <NavLink
          className='side-bar__menu-item'
          to='settings'
          onClick={isMobile && onClose}
          children={({ isActive }) => {
            return (
              <>
                {isActive ? <SettingsFilled color={colors.primary[500]} /> : <Settings />}
                <Text variant='button-large' color={isActive ? colors.primary[500] : colors.gray[800]}>
                  {t('sidebar:settings')}
                </Text>
              </>
            )
          }}
        />
        <div className={showMenu ? 'side-bar__menu-item side-bar__background-active' : 'side-bar__menu-item'}
          onClick={handleModalMenu}
        >
          {showMenu ? <QuestionFilled color={colors.primary[500]} /> : <Question />}
          <Text variant='button-large' color={showMenu ? colors.primary[500] : colors.gray[800]}>
            {t('sidebar:help')}
          </Text>
        </div>
        {showMenu && <SideBarHelpModal handleClose={handleCloseMenu} showMenu={showMenu}/>}
      </div>
    </div>
  )
}

export default React.memo(SideBarMenu)
