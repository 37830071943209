import { takeLatest, call, put } from 'redux-saga/effects'
import * as actions from './actions'

import Api from '../../lib/api'
import notifications from '../../lib/notifications'
import { toCamelCase } from '../../lib/converter'

export default function* watcherSaga() {
  yield takeLatest(actions.TYPES_FETCH_REQUEST, watchTypesFetchRequest)
  yield takeLatest(actions.TYPE_VERIFY_REQUEST, watchTypeVerifyRequest)

  function* watchTypesFetchRequest({ payload }) {
    try {
      const response = yield call(Api.ProductTypes.fetch, payload.networkId)
      yield put(actions.typesFetchSuccess(toCamelCase(response.data)))
    } catch (error) {
      yield put(actions.typesFetchFailure(error))
      notifications.createNotification('error', error.response?.data.errors)
    }
  }

  function* watchTypeVerifyRequest({ history, payload }) {
    try {
      const response = yield call(Api.ProductTypes.get, payload.networkId, payload.id)
      yield put(actions.typeVerifySuccess(toCamelCase(response.data)))
    } catch (error) {
      yield put(actions.typeVerifyFailure(error))
      history('/menu')
    }
  }
}
