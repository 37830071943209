import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { orderBy } from 'lodash'
import { isMobile } from 'react-device-detect'

import { EmptyNotification, ResultsFor } from '../'
import { getVendorStatistics } from '../../../lib/statisticHelper'
import { Text, Dropdown, Loader } from '../../UIkit'
import { Cup, Sort } from '../../UIkit/svgs'
import { Table, TableHead, TableBody, TableHeader, TableRow, TableCell } from '../Table'

import './styles.scss'

const VendorComparison = ({ vendorComparison, loading }) => {
  const { t } = useTranslation()

  const [vendorStatistics, setVendorStatistics] = useState([])

  const [activeSort, setActiveSort] = useState({
    field: 'totalOrders',
    order: 'asc',
  })

  const defaultValues = Array(5)
    .fill(null)
    .map((_, index) => ({
      vendor: {
        fullAddress: `${t('dashboard:vendor')} #${index + 1}`,
      },
      drinks: 0,
      desserts: 0,
      food: 0,
      goods: 0,
      supplements: 0,
    }))

  useEffect(() => {
    const data = orderBy(getVendorStatistics(vendorComparison), activeSort.field, activeSort.order)
    setVendorStatistics(!vendorComparison.length ? defaultValues : data)
  }, [vendorComparison, activeSort])

  const headers = [
    t('dashboard:vendor'),
    t('dashboard:drinks'),
    t('dashboard:desserts'),
    t('dashboard:food'),
    t('dashboard:goods'),
    t('dashboard:supplements'),
  ]

  const fieldsOptions = [
    { value: 'drinks', label: t('dashboard:drinks') },
    { value: 'desserts', label: t('dashboard:desserts') },
    { value: 'food', label: t('dashboard:food') },
    { value: 'goods', label: t('dashboard:goods') },
    { value: 'supplements', label: t('dashboard:supplements') },
  ]

  const orderOptions = [
    { value: 'asc', label: t('dashboard:ascending') },
    { value: 'desc', label: t('dashboard:descending') },
  ]

  const handleChangeFieldSort = (param) => {
    setActiveSort((prev) => ({ ...prev, field: param }))
  }

  const handleChangeOrderSort = (param) => {
    setActiveSort((prev) => ({ ...prev, order: param }))
  }

  return (
    <div className='vendor-comparison'>
      <div className='vendor-comparison__header'>
        <Text variant='subheading-bold'>{t('dashboard:vendorComparison')}</Text>

        <div className='vendor-comparison__header__sorts'>
          {!isMobile && <Text variant='body-tertiary1'>{t('dashboard:sortBy')}</Text>}
          <Dropdown list={fieldsOptions} value={activeSort.field} onChange={handleChangeFieldSort} />
          <Dropdown iconHeader={isMobile && <Sort/>} value={activeSort.order} list={orderOptions} onChange={handleChangeOrderSort} />
        </div>
      </div>

      <div className='vendor-comparison__content'>
        <ResultsFor />

        {!vendorComparison.length && !loading && <EmptyNotification subject='vendor' />}

        {loading && <Loader size={50} inBlock />}

        {!loading && (
          <div className='vendor-comparison__table'>
            <Table>
              <TableHead>
                <TableRow>
                  {headers.map((header, i) => (
                    <TableHeader key={i}>
                      <Text variant='body-main2'>{header}</Text>
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {vendorStatistics.map((statistic, i) => (
                  <TableRow key={i}>
                    <TableCell flex>
                      {statistic.vendor?.imageSmUrl && (
                        <img className='vendor-comparison__logo' src={statistic.vendor.imageSmUrl} />
                      )}

                      {!statistic.vendor?.imageSmUrl && (
                        <div className='vendor-comparison__default-pic'>
                          <Cup width={20} height={20} />
                        </div>
                      )}
                      <Text variant='body-main1'>{statistic.vendor?.fullAddress}</Text>
                    </TableCell>

                    <TableCell>
                      <Text variant='body-main1'>{statistic.drinks}</Text>
                    </TableCell>

                    <TableCell>
                      <Text variant='body-main1'>{statistic.desserts}</Text>
                    </TableCell>

                    <TableCell>
                      <Text variant='body-main1'>{statistic.food}</Text>
                    </TableCell>

                    <TableCell>
                      <Text variant='body-main1'>{statistic.goods}</Text>
                    </TableCell>

                    <TableCell>
                      <Text variant='body-main1'>{statistic.supplements}</Text>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      </div>
    </div>
  )
}
VendorComparison.propTypes = {
  vendorComparison: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default VendorComparison
