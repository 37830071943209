import {
  CATEGORIES_FETCH_REQUEST,
  CATEGORIES_FETCH_SUCCESS,
  CATEGORIES_FETCH_FAILURE,
  CATEGORY_ADD_SUCCESS,
  CATEGORY_ADD_FAILURE,
  CATEGORY_DELETE_REQUEST,
  CATEGORY_DELETE_SUCCESS,
  CATEGORY_DELETE_FAILURE,
  CATEGORIES_CLEAR,
} from './actions'

const initialState = {
  items: [],
  item: {},
  fetching: true,
  error: null,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CATEGORIES_FETCH_REQUEST: {
      return { ...state, fetching: true }
    }
    case CATEGORIES_FETCH_SUCCESS: {
      return {
        ...state,
        items: payload,
        fetching: false,
      }
    }
    case CATEGORIES_FETCH_FAILURE:
      return { ...state, fetching: false, error: payload }

    case CATEGORY_ADD_SUCCESS: {
      return {
        ...state,
        items: [...state.items, payload],
      }
    }
    case CATEGORY_ADD_FAILURE:
      return { ...state, error: payload }

    case CATEGORY_DELETE_REQUEST:
      return { ...state }

    case CATEGORY_DELETE_SUCCESS: {
      return {
        ...state,
        items: state.items.filter((el) => el.id != payload)
      }
    }
    case CATEGORY_DELETE_FAILURE:
      return { ...state, error: payload }

    case CATEGORIES_CLEAR:
      return initialState

    default:
      return state
  }
}
