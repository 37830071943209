import React, { useState, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

import useOnClickOutside from '../../../hooks/useOnClickOutside'
import { Text, colors } from '../'
import { Arrow, CreditCard, Info, Plus } from '../svgs'
import Loader from '../Loader'
import capitalize from '../../../lib/capitalize'

import './styles.scss'

const PaymentDropdown = ({ value, placeholder, list, onChange, loading }) => {
  const { t } = useTranslation()

  const [isListOpen, setIsListOpen] = useState(false)
  const dropdownRef = useRef()

  const handleClose = () => {
    setIsListOpen(false)
  }

  const handleToggle = () => {
    setIsListOpen((prev) => !prev)
  }

  const selectItem = (item) => {
    setIsListOpen(false)
    onChange(item.id, item.recipient)
  }

  useOnClickOutside(dropdownRef, handleClose)

  const memoizedList = useMemo(() => {
    if (!list.length) {
      return (
        <div className='payment-dropdown__empty_message'>
          <Text variant='body-secondary1' color={colors.gray[500]}>
            {t('withdrawFunds:create:notPaymentMethod')}
          </Text>
        </div>
      )
    } else {
      return list.map((item, index) => (
        <option className='payment-dropdown__list-item' key={index} onClick={() => selectItem(item)}>
          {item.recipient}
        </option>
      ))
    }
  }, [list])

  return (
    <div className='payment-dropdown' ref={dropdownRef}>
      {!!placeholder && <span className='payment-dropdown__header-placeholder'>{placeholder}</span>}
      <div className={`payment-dropdown__header ${isListOpen && 'with-shadow'}`} onClick={handleToggle}>
        {isMobile && <CreditCard color={colors.primary[500]} />}
        <div className='payment-dropdown__header-title'>
          <Text variant='body-main1'>{capitalize(value || t('withdrawFunds:create:notFound'))}</Text>
        </div>
        {!isMobile ? 
          <Arrow className={`payment-dropdown__header-toggle ${isListOpen ? 'reflect' : ''}`} /> : 
          <Info />}
      </div>
      {isListOpen && (
        <div className='payment-dropdown__list' role='list'>
          {loading ? (
            <Loader size={36} inBlock={true} />
          ) : (
            <div>
              <div className='payment-dropdown__list-scrollable'>{memoizedList}</div>
              <Link to='/settings' state={{ tab: 'withdraw' }} style={{ textDecoration: 'none' }}>
                <div className='payment-dropdown__create-payment'>
                  <Plus color={colors.primary[500]} width={18} height={18} />
                  <Text variant='body-main2' color={colors.primary[500]}>
                    {t('withdrawFunds:create:addPaymentMethod')}
                  </Text>
                </div>
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

PaymentDropdown.propTypes = {
  value: PropTypes.any,
  list: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
}

export default React.memo(PaymentDropdown)
