import React, { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useOnClickOutside from '../../../hooks/useOnClickOutside'
import colors from '../colors'
import { Arrow } from '../svgs'
import Text from '../Text'
import './styles.scss'

const DateRangeDropdown = ({ value, list, onChange }) => {
  const { t } = useTranslation()
  const [isListOpen, setIsListOpen] = useState(false)
  const dropdownRef = useRef()

  const memoizedValue = useMemo(
    () =>
      list.find(
        (item) => item.value[0]?.toString() === value[0]?.toString() && item.value[1]?.toString() === value[1]?.toString()
      ),
    [value, list]
  )

  const handleClose = () => {
    setIsListOpen(false)
  }

  const handleToggle = () => {
    setIsListOpen((prev) => !prev)
  }

  const selectItem = (item) => {
    setIsListOpen(false)
    onChange(item.value)
  }

  const memoizedList = useMemo(() => {
    return list.map((item, index) => (
      <div className='date-range-dropdown__list-item' key={index} value={item.value} onClick={() => selectItem(item)}>
        <Text variant='body-main1'>{t(`filters:${item.title}`)}</Text>
        <Text variant='body-main1' color={colors.gray[300]}>
          {item.label == 'notSelected' ? t(`filters:${item.label}`) : item.label }
        </Text>
      </div>
    ))
  }, [list])

  useOnClickOutside(dropdownRef, handleClose)

  return (
    <div className='date-range-dropdown' ref={dropdownRef}>
      <div className={`date-range-dropdown__header ${isListOpen && 'with-shadow'}`} onClick={handleToggle}>
        <div className='date-range-dropdown__header-title'>
          <Text variant='body-main1'>{t(`filters:${memoizedValue?.title || list[list.length - 1].title}`)}</Text>
          <Text variant='body-main1' color={colors.gray[300]}>
            {memoizedValue?.label == 'notSelected' 
              ? t(`filters:${memoizedValue?.label}`) : memoizedValue?.label || list[list.length - 1].label}
          </Text>
        </div>
        <Arrow className={`date-range-dropdown__header-toggle ${isListOpen ? 'reflect' : ''}`} />
      </div>
      {isListOpen && (
        <div className='date-range-dropdown__list' role='list'>
          {memoizedList}
        </div>
      )}
    </div>
  )
}

export default DateRangeDropdown
