import { useState, useLayoutEffect } from 'react'
import { createPortal } from 'react-dom'

const PortalModal = ({ wrapperId, children }) => {
  const [portalElement, setPortalElement] = useState(null)

  useLayoutEffect(() => {
    let element = document.getElementById(wrapperId)
    let portalCreated = false

    if (!element) {
      element = createWrapperAndAppendToBody(wrapperId)
      portalCreated = true
    }

    setPortalElement(element)

    return () => {
      if (portalCreated && element.parentNode) {
        element.parentNode.removeChild(element)
      }
    }
  }, [wrapperId])

  const createWrapperAndAppendToBody = (elementId) => {
    const element = document.createElement('div')
    element.setAttribute('id', elementId)
    document.body.appendChild(element)
    return element
  }

  if (!portalElement) return null

  return createPortal(children, portalElement)
}

export default PortalModal
