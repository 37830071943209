import React, { useState } from 'react'
import './styles.scss'
import { Arrow } from '../svgs'


const ExpandSection = ({ children, title, icon }) => {
  const [isExpanded, setExpanded] = useState(false)

  const toggleExpand = () => {
    setExpanded(!isExpanded)
  }

  return (
    <>
      <div className='expand-btn' onClick={toggleExpand}>
        <div className='expand-btn__left-side'>
          {icon}
          {title}
        </div>
        {!isExpanded ? <Arrow /> : <div style={{ transform: 'rotate(180deg)', width: '20px' }}><Arrow /></div>}
      </div>
      {isExpanded && (
        <div>
          {children}
        </div>
      )}
    </>
  )
}

export default React.memo(ExpandSection)
