import { Validator } from '.'
import { isEmpty } from 'lodash'

export const handleValidate = (validationFields) =>
  new Promise((resolve, reject) => {
    const errors = Validator.validate(validationFields)

    Validator.clear()

    isEmpty(errors) ? resolve() : reject(errors)
  })
