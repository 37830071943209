import React from 'react'
import cn from 'classnames'

import './styles.scss'
import { Text } from '../../UIkit'

export const Table = ({ children }) => {
  return <table className='statistic-table'>{children}</table>
}

export const TableHead = ({ children }) => {
  return <thead className='statistic-table__head'>{children}</thead>
}

export const TableBody = ({ children }) => {
  return <tbody>{children}</tbody>
}

export const TableHeader = ({ children }) => {
  return <th className='statistic-table__header'>{children}</th>
}

export const TableRow = ({ children }) => {
  return <tr className='statistic-table__row'>{children}</tr>
}

export const TableCell = ({ children, flex = false }) => {
  return <td className={cn('statistic-table__cell', { 'statistic-table__cell-flex': flex })}>{children}</td>
}

export const ExpandedTableRow = ({ children, expandedRow, key, tableHeaders }) => {
  return <>
    {expandedRow === key && (
      <tr>
        <td colSpan={tableHeaders.length}>
          {children}
        </td>
      </tr>
    )}
  </>
}

export const ExpandedItem = ({ title, text }) => {
  return (
    <div className='custom-table__expanded-item'>
      <span>{title}</span>
      <Text variant='body-main1'>{text}</Text>
    </div>
  )
}
