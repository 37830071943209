import React from 'react'
import { Text, colors } from '../'
import './styles.scss'

const RadioButton = ({ name, label, value, isChecked, handleChange, disabled }) => {
  return (
    <div className='radio'>
      <input
        className='radio__input'
        type='radio'
        id={name}
        name={name}
        value={value}
        disabled={disabled}
        checked={isChecked}
        onChange={handleChange}
      />
      <label htmlFor={name} className='radio__label'>
        <Text
          color={disabled ? colors.gray[300] : colors.gray[800]}
          variant='body-main1' 
          htmlFor={name}
        >
          {label}
        </Text>
      </label>
    </div>
  )
}

export default RadioButton
