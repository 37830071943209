import React from 'react'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

import { RouteNames } from '../../../../router'
import { Logo, LogoWithoutName } from '../../../../assets/svgs/landing'
import './styles.scss'
import './responsiveStyles.scss'

function Header () {
  const location = useLocation()
  const { t } = useTranslation()
  
  return (
    <header>
      <a href='/' className='logo'>
        <img src={isMobile ? LogoWithoutName : Logo} alt='Pinka logo' />
      </a>

      <nav className={'header-nav'} >
        {/*<Link to='step-by-step'>
          Про Нас
        </Link>*/}
        {
          location.pathname === RouteNames.FOR_BUSINESS ? (
            <Link className='btn-business' to={RouteNames.ROOT}>
              {t('btn:forUsers')}
            </Link>
          ) : (
            <Link className='btn-business' to={RouteNames.FOR_BUSINESS}>
              {t('btn:forCoffeeShops')}
            </Link>
          )
        }
      </nav>
    </header>
  )
}

export default Header
