import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { RouteNames } from '../../router'
import AppStaff from './AppStaff'

const ProtectedStaffRoute = ({ session }) => {
  return session.authenticated ? (
    <AppStaff>
      <Outlet />
    </AppStaff>
  ) : (
    <Navigate to={RouteNames.STAFF_SIGNIN} />
  )
}

export default ProtectedStaffRoute
