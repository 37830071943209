import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

import { Modal, Button, Text, Input, TextArea, Checkbox, colors } from '../../../UIkit'
import { Plus, Erase } from '../../../UIkit/svgs'
import { findBoardProduct, findCategory } from '../../../../lib/store'
import { Uploader } from '../../../Uploader'
import Validator from '../../../../lib/validator'
import './styles.scss'

const MenuProductCreateUpdateComponent = ({
  isShown,
  closeModal,
  vendorFilter,
  productId,
  categoryId,
  updateProduct,
  createProduct,
}) => {
  const { t } = useTranslation()

  const product = findBoardProduct(productId)
  const category = product ? findCategory(product.productCategoryId) : findCategory(categoryId)

  if (!category) closeModal()

  const [form, setForm] = useState({
    categoryId: category.id,
    name: '',
    description: '',
    coffeeTypes: [],
    productOptions: [{ size: '', price: '', unit: category.type.unit }],
  })

  useEffect(() => {
    if (product) {
      setForm({
        categoryId: product.productCategoryId,
        name: product.name,
        description: product.description,
        productOptions: product.options,
        coffeeTypes: product.coffeeTypes.map((t) => ({ id: t.id, name: t.name.toLowerCase() })),
      })
    } else {
      setForm((prev) => ({ ...prev }))
    }
  }, [product, vendorFilter])

  const [optionsToDestroy, setOptionsToDestroy] = useState([])
  const [coffeeTypesToDestroy, setCoffeeTypesToDestroy] = useState([])
  const [formErrors, setFormErrors] = useState({})

  const handleValidate = () => {
    const data = [['name', Validator.concepts.isFilled, [form.name]]]

    let optionsToValidate = []

    form.productOptions.map((o, index) => {
      optionsToValidate = [
        ...optionsToValidate,
        [`size${+index}`, Validator.concepts.isFilledShort, [form.productOptions[index].size]],
        [`price${+index}`, Validator.concepts.isFilledShort, [form.productOptions[index].price]],
      ]
    })

    const errors = Validator.validate([...data, ...optionsToValidate])

    Validator.clear()

    return errors
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const data = {
      networkProduct: {
        productCategoryId: form.categoryId,
        image: form.image,
        name: form.name,
        description: form.description,
        coffeeTypesAttributes: [...form.coffeeTypes, ...coffeeTypesToDestroy],
        productOptionsAttributes: [...form.productOptions, ...optionsToDestroy],
      },
    }

    const errors = handleValidate()
    const hasErrors = Object.keys(errors).length > 0

    if (hasErrors) return setFormErrors(errors)

    if (product) {
      updateProduct(product.id, data)
    } else {
      createProduct(data)
    }
    closeModal()
  }

  const handleUploadImage = (signedId) => {
    setForm((prev) => ({ ...prev, image: signedId }))
  }

  const handleDeleteImage = () => {
    setForm((prev) => ({ ...prev, image: null }))
  }

  const onUpdateField = ({ target }) => setForm((prev) => ({ ...prev, [target.name]: target.value }))

  const onUpdateCoffeeType = ({ target }) => {
    if (target.checked) {
      const newCoffeeTypes = [...form.coffeeTypes, { name: target.name }]
      setForm((prev) => ({ ...prev, coffeeTypes: newCoffeeTypes }))
      const newDestroyArray = coffeeTypesToDestroy.filter((el) => el.name != target.name)
      setCoffeeTypesToDestroy(newDestroyArray)
    } else {
      const newCoffeeTypes = form.coffeeTypes.filter((el) => el.name != target.name)
      setForm((prev) => ({ ...prev, coffeeTypes: newCoffeeTypes }))
      const typeToDelete = form.coffeeTypes.find((el) => el.name === target.name)
      const newDestroyArray = [...coffeeTypesToDestroy, { ...typeToDelete, _destroy: true }]
      setCoffeeTypesToDestroy(newDestroyArray)
    }
  }

  const onUpdateOption = (target, index) => {
    setForm((prev) => {
      let data = [...prev.productOptions]
      data[index][target.name] = target.value
      return {
        ...prev,
        productOptions: data,
      }
    })
  }

  const addOption = () => {
    setForm((prev) => ({
      ...prev,
      productOptions: [...prev.productOptions, { size: '', price: '', unit: category.type.unit }],
    }))
  }

  const removeOption = (item) => {
    setForm((prev) => ({ ...prev, productOptions: prev.productOptions.filter((o) => o != item) }))
    if (form.productOptions.includes(item)) {
      setOptionsToDestroy([...optionsToDestroy, { ...item, _destroy: true }])
    }
  }

  const itsCoffee = category.slug === 'coffee'
  const addOptionAvaliable = form.productOptions.length < 3
  const coffeeTypeChecked = (name) => (form.coffeeTypes.find((obj) => obj.name === name) ? true : false)

  return (
    <Modal
      show={isShown}
      handleClose={closeModal}
      title={product ? `${t('actions:edit')} ${product.name}` : t('menu:addProduct')}
      scrollable={isMobile}
    >
      <div className='menuProducts__createProduct'>
        <div className='menuProducts__createProduct_wrapper'>
          <form id='create-product-form' className='menuProducts__createProduct_form' onSubmit={handleSubmit}>
            <Input
              id='name'
              name='name'
              placeholder={t('input:productName')}
              value={form.name}
              onChange={onUpdateField}
              error={formErrors.name}
            />
            <TextArea
              id='description'
              placeholder={t('input:description')}
              name='description'
              rows={3}
              value={form.description || ''}
              onChange={onUpdateField}
            />
            {itsCoffee && (
              <div className='menuProducts__createProduct_coffeeType'>
                <Text variant={'body-main2'}>{t('menu:coffeeType')}:</Text>
                <div className='menuProducts__createProduct_checkboxes'>
                  <Checkbox
                    id='arabica-type'
                    name='arabica'
                    checked={coffeeTypeChecked('arabica')}
                    onChange={onUpdateCoffeeType}
                    label={t('menu:arabica')}
                    className='menuProducts__createProduct_checkbox'
                  />
                  <Checkbox
                    id='robusta-type'
                    name='robusta'
                    checked={coffeeTypeChecked('robusta')}
                    onChange={onUpdateCoffeeType}
                    label={t('menu:robusta')}
                    className='menuProducts__createProduct_checkbox'
                  />
                  <Checkbox
                    id='mix-type'
                    name='mix'
                    checked={coffeeTypeChecked('mix')}
                    onChange={onUpdateCoffeeType}
                    label={t('menu:mix')}
                    className='menuProducts__createProduct_checkbox'
                  />
                </div>
              </div>
            )}
            <div className='menuProducts__createProduct_options'>
              {form.productOptions.map((item, index) => (
                <div key={index} className='menuProducts__createProduct_option'>
                  <div className='menuProducts__createProduct_optionInput'>
                    <Input
                      id={`size-${index}`}
                      name='size'
                      placeholder={t('input:size')}
                      type='number'
                      min='1'
                      value={item.size}
                      onChange={(e) => onUpdateOption(e.target, index)}
                      error={formErrors[`size${+index}`]}
                    />
                  </div>
                  <Text className='menuProducts__createProduct_optionUnit' variant='body-secondary1'>
                    {category.type.unit}
                  </Text>
                  <div className='menuProducts__createProduct_optionInput'>
                    <Input
                      id={`price-${index}`}
                      name='price'
                      placeholder={t('input:price')}
                      type='number'
                      min='0'
                      value={item.price}
                      onChange={(e) => onUpdateOption(e.target, index)}
                      error={formErrors[`price${+index}`]}
                    />
                  </div>
                  <Text className='menuProducts__createProduct_optionUnit' variant='body-secondary1'>
                    {t('menu:uah')}
                  </Text>
                  {index < 1 ? (
                    <button
                      disabled={!addOptionAvaliable}
                      onClick={addOption}
                      className='menuProducts__createProduct_optionAction'
                      type='button'
                    >
                      <Plus color={addOptionAvaliable ? colors.primary[500] : colors.gray[200]} />
                      <Text variant='body-main2' color={addOptionAvaliable ? colors.primary[500] : colors.gray[200]}>
                        {t('btn:add')}
                      </Text>
                    </button>
                  ) : (
                    <button
                      onClick={() => removeOption(item)}
                      className='menuProducts__createProduct_optionAction'
                      type='button'
                    >
                      <Erase color={colors.primary[500]} />
                    </button>
                  )}
                </div>
              ))}
            </div>
          </form>
          <div className='menuProducts__createProduct_image'>
            <Uploader name='image' onChange={handleUploadImage} onDelete={handleDeleteImage} image={product?.image} />
          </div>
        </div>
        <div className='menuProducts__createProduct_actions'>
          <Button form='create-product-form' type='submit' variant='primary' size='large'>
            {t('btn:save')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default MenuProductCreateUpdateComponent
