import React, { PureComponent } from 'react'
import LandingBusinessComponent from '../../components/LandingBusiness'

class LandingBusinessContainer extends PureComponent {
  render() {
    return (
      <LandingBusinessComponent />
    )
  }
}

export default LandingBusinessContainer
