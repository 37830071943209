import React, { useRef } from 'react'
import { Button, Modal, Text, colors } from '../../UIkit'
import { useTranslation } from 'react-i18next'
import config from 'config'

import useOnClickOutside from '../../../hooks/useOnClickOutside'
import '../../../containers/SideBar/styles.scss'
import { isMobile } from 'react-device-detect'
import { Email, Phone } from '../../UIkit/svgs'

const SideBarHelpModal = ({ handleClose, showMenu }) => {
  const { t } = useTranslation()

  const modalRef = useRef()

  const mailToSupport = () => {
    window.location.href = `mailto:${config.mailToEmail}`
  }

  useOnClickOutside(modalRef, handleClose)

  return (
    <>
      {!isMobile ?       
        <div className='side-bar__help-menu' ref={modalRef}>
          <div className='header__user-info'>
            <div>
              <Text variant='button-large' color={colors.gray[800]}>
                {t('help:contact')}
              </Text>
              <div className='side-bar__help-phone'>{config.supportNumber}</div>
            </div>
          </div>
          <div>
            <div className='side-bar__help-button' onClick={mailToSupport}>
              <Button style={{ minWidth: 200 }}>
                {t('btn:support')}
              </Button>
            </div>
          </div>
        </div> :
        <Modal title='Contact Us' handleClose={handleClose} show={showMenu} mobileModal subModal withoutExit >
          <div className='employees__action'>
            <Phone />
            <Text variant='body-main2'>+38 (090) 000 00 00</Text>
          </div>
          <div className='employees__action' onClick={mailToSupport}>
            <Email color={colors.primary[500]} />
            <Text variant='body-main2'>{t('help:contact')}</Text>
          </div>
        </Modal>}    
    </>
  )
}

export default React.memo(SideBarHelpModal)
