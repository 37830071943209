import {
  SUPPLEMENTS_FETCH_REQUEST,
  SUPPLEMENTS_FETCH_SUCCESS,
  SUPPLEMENTS_FETCH_FAILURE,
  SUPPLEMENT_CREATE_REQUEST,
  SUPPLEMENT_CREATE_SUCCESS,
  SUPPLEMENT_CREATE_FAILURE,
  SUPPLEMENT_UPDATE_REQUEST,
  SUPPLEMENT_UPDATE_SUCCESS,
  SUPPLEMENT_UPDATE_FAILURE,
  SUPPLEMENT_DELETE_REQUEST,
  SUPPLEMENT_DELETE_SUCCESS,
  SUPPLEMENT_DELETE_FAILURE,
  SUPPLEMENTS_CLUSTER_DELETE_REQUEST,
  SUPPLEMENTS_CLEAR,
} from './actions'

import _ from 'lodash'

const initialState = {
  items: [],
  item: {},
  clusters: {},
  fetching: false,
}

export default (state = initialState, { type, payload }) => {
  let totalPages
  let categoryId
  let prevItems
  let prevCluster
  let newClusters

  switch (type) {
    case SUPPLEMENTS_FETCH_REQUEST:
      return { ...state, fetching: true }

    case SUPPLEMENTS_FETCH_SUCCESS:
      categoryId = payload.params.categoryId.toString()
      prevCluster = _.isUndefined(state.clusters[categoryId]) ? [] : state.clusters[categoryId]
      newClusters = {
        ...state.clusters,
        [categoryId]: [...prevCluster, ...payload.data],
      }
      return {
        ...state,
        items: payload.data,
        clusters: newClusters,
        fetching: false,
      }
    case SUPPLEMENTS_FETCH_FAILURE:
      return { ...state, error: payload, fetching: false }

    case SUPPLEMENT_CREATE_REQUEST:
      return { ...state }

    case SUPPLEMENT_CREATE_SUCCESS:
      categoryId = payload.productCategoryId.toString()
      prevCluster = _.isUndefined(state.clusters[categoryId]) ? [] : state.clusters[categoryId]
      newClusters = {
        ...state.clusters,
        [categoryId]: [payload, ...prevCluster],
      }
      return { ...state, items: [payload, ...state.items], clusters: newClusters }

    case SUPPLEMENT_CREATE_FAILURE:
      return { ...state, error: payload }

    case SUPPLEMENT_UPDATE_REQUEST:
      return { ...state }

    case SUPPLEMENT_UPDATE_SUCCESS:
      categoryId = payload.productCategoryId.toString()
      totalPages = state.clusters[categoryId][0].pages
      prevItems = state.items.filter((el) => el.id != payload.id)
        ? []
        : state.clusters[categoryId].filter((el) => el.id != payload.id)
      newClusters = {
        ...state.clusters,
        [categoryId]: state.clusters[categoryId].map((el) =>
          el.id == payload.id ? { ...payload, pages: totalPages } : el
        ),
      }
      return {
        ...state,
        items: [{ ...payload, pages: totalPages }, ...prevItems],
        clusters: newClusters,
        fetching: false,
      }

    case SUPPLEMENT_UPDATE_FAILURE:
      return { ...state, error: payload }

    case SUPPLEMENT_DELETE_REQUEST:
      return { ...state }

    case SUPPLEMENT_DELETE_SUCCESS: {
      categoryId = payload.categoryId
      prevItems = state.items.filter((el) => el.id != payload.id)
      prevCluster = _.isUndefined(state.clusters[categoryId])
        ? []
        : state.clusters[categoryId].filter((el) => el.id != payload.id)
      newClusters = {
        ...state.clusters,
        [categoryId]: prevCluster,
      }
      return {
        ...state,
        items: prevItems,
        clusters: newClusters,
      }
    }
    case SUPPLEMENT_DELETE_FAILURE:
      return { ...state, error: payload }

      // CLEAR

    case SUPPLEMENTS_CLUSTER_DELETE_REQUEST:
      categoryId = payload
      newClusters = _.omit(state.clusters, [categoryId])
      return { ...state, clusters: newClusters }

    case SUPPLEMENTS_CLEAR:
      return initialState

    default:
      return state
  }
}
