import React from 'react'

import { Table, TableBody, TableHead, TableHeader, Text } from '../../../../../UIkit'
import { VerticalDots } from '../../../../../UIkit/svgs'
import VendorRow from './VendorRow'

const tableHeaders = [
  'Image',
  'City',
  'Address',
  'Rating'
]

const VendorsTable = ({ vendors }) => {
  return (
    <div className='overflow-auto w-100'>
      <Table>
        <TableHead>
          {tableHeaders.map(title => (
            <TableHeader key={title}>
              <Text variant='subheading'>{title}</Text>
            </TableHeader>
          ))}
          <TableHeader>
            <VerticalDots />
          </TableHeader>
        </TableHead>
        <TableBody>
          {vendors.map(vendor => <VendorRow key={vendor.id} vendor={vendor} />)}
        </TableBody>
      </Table>
    </div>
  )
}

export default VendorsTable
