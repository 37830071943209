/* eslint-disable */

import React, { Component } from 'react'
import { Footer, Header } from '../../components/Landing/Sections'

import './styles.css'

class PrivacyContainer extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <Header/>
        <div className='container'>
          <br />
          <strong>УМОВИ КОНФІДЕНЦІЙНОСТІ</strong>
          <div>
            <br />
            Цей публічний договір та умови конфіденційності (далі в тому числі – Політика конфіденційності, Положення)
            сервісу Pinka.coffe (далі – Сервіс) визначає умови та правила використання веб-сайту https://pinka.coffee/
            (надалі «Сайт») та Прикладних програм (англ. Application), для використання сайту та доступу до
            персонального кабінету (надалі «Програм»). Він розроблений відповідно до Закону України «Про захист персональних даних»,
            Закону України «Про інформацію», постанови Кабінету Міністрів України «Про перелік відомостей,
            що не становлять комерційної таємниці» № 61 від 09.08.1993 р., положень Цивільного та Господарського кодексів України.
          </div>
          <br />
          <div>
            Дану Політику конфіденційності Сервісу затверджено з метою охорони і захисту персональних даних Клієнтів Сервісу
            (включаючи користувачів сайту), конфіденційної інформації, що містить персональні данні; а також конфіденційної інформації,
            власниками якої є власник Сервісу;  – з метою регулювання доступу до даної інформації, її надання та передачі.
          </div>
          <br />
          <div>
            Політика конфіденційності Сервісу поширюються на всіх користувачів Сайту або Програм (Клієнтів) – як на користувачів,
            які не мають облікового запису на Сайті або у Програмі (незареєстрованний користувач) так і на користувачів,
            які мають будь-який обліковий запис, кожного з доступних видів. Під обліковим записом розуміється сукупність
            інформації про Клієнта й даних авторизації (логін, пароль).
          </div>
          <br />
          <strong>1. ВИЗНАЧЕННЯ ТЕРМІНІВ</strong>
          <div>
            <br />
            Терміни, що вживаються в даному Положенні, мають таке значення:
          </div>
          <br />
          <div>
            1.1. Персональні дані - відомості чи сукупність відомостей про фізичну особу, яка ідентифікована або може
            бути конкретно ідентифікована.
          </div>
          <br />
          <div>
            1.2. Конфіденційна інформація – це відомості, які знаходяться у володінні, користуванні або
            розпорядженні окремих фізичних чи юридичних осіб і поширюються за їх бажанням відповідно до передбачених ними умов.
          </div>
          <br />
          <div>
            1.3.Конфіденційність інформації – властивість інформації бути захищеною від несанкціонованого ознайомлення.
          </div>
          <br />
          <div>
            1.4. Режим конфіденційності інформації – правові, організаційні, технічні й інші заходи щодо захисту персональних даних.
          </div>
          <br />
          <div>
            1.5. Власник інформації, що є конфіденційною, – особа, що володіє інформацією, яка є конфіденційною, на
            законній підставі, обмежила доступ до цієї інформації та установила у відношенні до неї
            режим конфіденційності інформації.
          </div>
          <br />
          <div>
            1.6. Доступ до інформації, що є конфіденційною, – ознайомлення певних осіб з інформацією, що є
            конфіденційною, за згодою її власника або на іншій законній підставі надання доступу до персональних даних.
          </div>
          <br />
          <div>
            1.7. Клієнт – фізична або юридична дієздатна особа, що досягла 18-літного віку, яка повністю прийняла
            усі умови даної політики конфіденційності, через реєстрацію на Сайті або у Програмі і володіє індивідуальним
            логіном і паролем, а також Обліковим записом клієнта задля використання послуг даного сайту та Програм.
          </div>
          <br />
          <div>
            1.8. Контрагент – сторона цивільно-правового договору, якій власник інформації, що є конфіденційною,
            передав цю інформацію, за умови збереження конфіденційності цієї інформації.
          </div>
          <br />
          <div>
            1.9. Сайт - інтернет-сайт https://pinka.coffee/, включаючи усі його субдомени,
            який знаходиться під організаційним контролем власника Сервісу .
          </div>
          <br />
          <div>
            1.10. Прикладна програма – комп’ютерна програма або програмне забезпечення, яке можливо використовувати з
            планшетів, смартфонів та на інших мобільних пристроях, яка розповсюджується на умовах договору
            приєднання (вільної публічної оферти), що надає особі, яка приєдналася до такого договору,
            безоплатний дозвіл на: використання програми за її призначенням, яка знаходиться під
            організаційним контролем власника Сервісу.
          </div>
          <br />
          <div>
            1.11. Передача інформації, що є конфіденційною, – передача інформації, що є конфіденційною та
            зафіксована на сайті Сервісу, її власником або власником сайту на підставі договору в обсязі й на
            умовах, які передбачені договором, включаючи умову про прийняття контрагентом встановлених договором
            заходів щодо охорони її конфіденційності.
          </div>
          <br />
          <div>
            1.12. Надання інформації, що є конфіденційною, – передача інформації, що є конфіденційною та
            зафіксована на Сайті Сервісу, її власником органам державної влади, іншим державним органам,
            органам місцевого самоврядування з метою виконання їх функцій.
          </div>
          <br />
          <div>
            1.13. Розголошення інформації, що є конфіденційною, – дія або бездіяльність, у результаті яких інформація,
            що є конфіденційною, у будь-якій можливій формі (усній, письмовій, іншій формі, у тому числі з
              використанням технічних засобів) стає відомою третім особам без згоди власника такої інформації або
              всупереч цивільно-правовому договору.
          </div>
          <br />
          <strong>2. ІНФОРМАЦІЯ, ЩО Є КОНФІДЕНЦІЙНОЮ</strong>
          <div>
            <br />
            2.1. До відомостей (інформації), що є конфіденційними, належать:
          </div>
          <br />
          <div>
            2.1.1. Персональна інформація, яку надає Клієнт при реєстрації або під час залишення коментарів на Сайті:
            телефон, e-mail, ім'я та прізвище.
          </div>
          <br />
          <div>
            2.1.2. Дані, що передаються автоматично при відвідуванні сайту: IP-адреса, інформація про браузер,
            тривалості перебування на сайті, адреса сайту, з якого був здійснений перехід і т.д.
          </div>
          <br />
          <div>
            2.1.3. Дані щодо деталей звернення Клієнта за послугами Сервісу, а також інша інформація, яка
            відповідно до чинного законодавства є конфіденційною інформацією.
          </div>
          <br />
          <strong>3. ВСТАНОВЛЕННЯ РЕЖИМУ КОНФІДЕНЦІЙНОСТІ ІНФОРМАЦІЇ</strong>
          <div>
            <br />
            3.1. Режим конфіденційності інформації вважається встановленим після надання згоди Клієнтом на збір,
            обробку, поширення і доступ до персональних даних під час його реєстрації на Сайті та вжиттям Сервісом
            заходів щодо охорони конфіденційності інформації.
          </div>
          <br />
          <div>
            3.2. Використовуючи будь-яку частину Сайту та Програм, Клієнт надає свою згоду на:
          </div>
          <br />
          <div>
            3.2.1. обробку своїх персональних даних з первинних джерел у такому обсязі: особисті відомості
            (вік, стать, родинний стан, склад сім’ї тощо), відомості щодо місця проживання: фактичне та за
            державною реєстрацією, відомості про перебування на військовому обліку, дані, що стосуються стану
            здоров’я в межах, визначених чинним законодавством, дані про присвоєння ідентифікаційного номера
            платника податків, електронні ідентифікаційні дані (біографічні довідки, номери телефонів), запис зображення (фото);
          </div>
          <br />
          <div>
            3.2.2. використання персональних даних, що передбачає дії володільця бази щодо обробки цих даних,
            а також дії щодо надання часткового або повного права обробки персональних даних іншим суб'єктам відносин,
            пов'язаних із персональними даними (стаття 10 Закону України «Про захист персональних даних»);
          </div>
          <br />
          <div>
            3.2.3. поширення персональних даних, що передбачає дії володільця бази персональних даних щодо передачі
            відомостей про фізичну особу з бази персональних даних (стаття 14 Закону України «Про захист персональних даних»);
          </div>
          <br />
          <div>
            3.2.4. доступ до персональних даних третіх осіб, що визначає дії володільця бази персональних даних
            у разі отримання запиту від третьої особи щодо доступу до персональних даних, у тому числі порядок доступу
            суб'єкта персональних даних до відомостей про себе (стаття 16 Закону України «Про захист персональних даних»).
          </div>
          <br />
          <strong>4. ПРАВА ТА ОБОВЯЗКИ КЛІЄНТА (КОРИСТУВАЧА САЙТУ)</strong>
          <div>
            <br />
            4.1. Клієнт (Користувач сайту) має право:
          </div>
          <br />
          <div>
            4.1.1. користуватися усіма технічними можливостями Сайту та Програм Сервісу;
          </div>
          <br />
          <div>
            4.1.2. мати доступ до будь якої публічної інформації, розміщеної на Сайті або у Програмах;
          </div>
          <br />
          <div>
            4.1.3. на збереження його персональних даних від третіх осіб на умовах визначених чинним законодавством
            України та положеннями даної Політики конфіденційності;
          </div>
          <br />
          <div>
            4.1.4. використовувати інформацію з Сайту в особистих та некомерційних цілях.
          </div>
          <br />
          <div>
            4.2. Клієнт (Користувач сайту) зобов’язується:
          </div>
          <br />
          <div>
            4.2.1. при здійсненні реєстрації вказувати та розміщувати публічні достовірні особисті дані;
          </div>
          <br />
          <div>
            4.2.2. використовувати Сайт та Програми лише в цілях, що не суперечать угодам між Клієнтом (Користувачем)
            та Сервісом, чинному законодавству України та принципам розумності і моралі;
          </div>
          <br />
          <div>
            4.2.3. нести відповідальність перед третіми особами за свої дії або бездіяльність при використанні Сайту та/або Програм;
          </div>
          <br />
          <div>
            4.2.4. самостійно та за свій власний рахунок врегулювати всі претензії третіх осіб,
            що пов’язані з дією або бездіяльністю Клієнта при використанні Сайту та/або Програм.
          </div>
          <br />
          <div>
            4.3. Клієнту (Користувачу сайту) забороняється:
          </div>
          <br />
          <div>
            4.3.1. розпалювати релігійні, расові або міжнаціональної ворожнечі;
          </div>
          <br />
          <div>
            4.3.2. вчиняти дії, спрямовані на введення інших користувачів в оману;
          </div>
          <br />
          <div>
            4.3.3. вчиняти дії, що порушують честь і гідність, права і свободу будь-якої особи;
          </div>
          <br />
          <div>
            4.3.4. реєструвати обліковий запис від імені третьої особи або замість іншої особи;
          </div>
          <br />
          <div>
            4.3.5. надавати в користування свій обліковий запис та/або логін і пароль від свого облікового запису третім особам;
          </div>
          <br />
          <div>
            4.3.6. розміщувати матеріали рекламного, еротичного, порнографічного або образливого характеру;
          </div>
          <br />
          <div>
            4.3.7. розміщувати інформацію, що порушує права й законні інтереси третіх осіб
            (у тому числі, розміщення фотографій і відеороликів, основним об’єктом яких є людина, якщо ця людина
              не давала згоди на розміщення на Сайті фотографії або відео з її участю);
          </div>
          <br />
          <div>
            4.3.8. розміщувати комп’ютерні віруси або програми, здатні перервати або порушити нормальну
            функціональність комп’ютерного обладнання та програмного забезпечення, а також
            засобів телекомунікації будь-яких осіб;
          </div>
          <br />
          <div>
            4.3.9. будь-яке використання інформації із Сайту в комерційних цілях.
          </div>
          <br />
          <strong>5. ПРАВА ТА ОБОВЯЗКИ ВЛАСНИКА СЕРВІСУ</strong>
          <div>
            <br />
            5.1. Власник Сервісу має право:
          </div>
          <br />
          <div>
            5.1.1. встановлювати, змінювати й скасовувати в письмовій формі режим конфіденційної
            інформації відповідно до чинного законодавства;
          </div>
          <br />
          <div>
            5.1.2. використовувати інформацію, що становить комерційну таємницю та/або конфіденційну інформацію,
            для власних потреб у порядку, що не суперечить законодавству;
          </div>
          <br />
          <div>
            5.1.3. дозволяти або забороняти доступ до інформації, що є конфіденційною інформацією, визначати порядок і
            умови доступу до цієї інформації;
          </div>
          <br />
          <div>
            5.1.4. вводити в цивільний оборот інформацію, що є конфіденційною, на підставі договорів, що передбачають
            включення в них умов про охорону конфіденційності цієї інформації;
          </div>
          <br />
          <div>
            5.1.5. вимагати від юридичних і фізичних осіб, що одержали доступ до інформації, що є конфіденційною,
            органів державної влади, інших державних органів, органів місцевого самоврядування, яким надана інформація,
            що є конфіденційною, дотримання обов’язків по охороні її конфіденційності;
          </div>
          <br />
          <div>
            5.1.6. вимагати від осіб, що одержали доступ до інформації, що є конфіденційною, у результаті дій,
            здійснених випадково або помилково, охорони конфіденційності цієї інформації;
          </div>
          <br />
          <div>
            5.1.7. у випадку розміщення Клієнтом на Сайті або за допомогою Програм інформації або
            здійснення інших дій, що не відповідають положенням чинного законодавства України та даної Політики
            конфіденційності, -  без повідомлення, за власним розсудом видалити повністю або частково розміщену
            Клієнтом інформацію, включаючи ту інформацію, у відношенні якої важко визначити її відповідність
            законодавству, що застосовується у випадках отримання від інших Клієнтів обґрунтованих скарг стосовно
            некоректної поведінки Клієнта на Сайті Сервісу, залишає за собою право заблокувати сторінку Клієнта;
          </div>
          <br />
          <div>
            5.1.8. на зміни Політики конфіденційності для подальшого удосконалення системи безпеки у
            відповідності до діючого законодавства;
          </div>
          <br />
          <div>
            5.1.9. надсилати Клієнту рекламні інформаційні повідомлення -  у випадку, коли Клієнт
            хоче відмовитися від отримання розсилок від Сервісу, він повинен повідомити про це
            Сервіс будь-яким зручним для нього способом.
          </div>
          <br />
          <div>
            5.2. Власник Сервісу зобов’язується:
          </div>
          <br />
          <div>
            5.2.1. захищати у встановленому чинним законодавством, даною Політикою, трудовими та цивільно-правовими
            договорами порядку свої права у випадку розголошення, незаконного одержання або незаконного використання
            третіми особами інформації, що є конфіденційною інформацію, у тому числі вимагати відшкодування збитків,
            заподіяних у зв’язку з порушенням його прав.
          </div>
          <br />
          <strong>6. ОХОРОНА КОНФІДЕНЦІЙНОСТІ ІНФОРМАЦІЇ</strong>
          <div>
            <br />
            6.1. З метою охорони конфіденційності інформації, працівники, залучені до роботи Сервісу,
            які відповідно до особливостей виконуваних трудових функцій мають доступ до неї, зобов’язані:
          </div>
          <br />
          <div>
            6.1.1. взяти на себе письмове Зобов’язання про нерозголошення інформації, що становить конфіденційну
            інформацію або підписати договір про конфіденційність і нерозголошення інформації;
          </div>
          <br />
          <div>
            6.1.2. виконувати встановлений Сервісом режим конфіденційної інформації;
          </div>
          <br />
          <div>
            6.1.3. не розголошувати інформацію, що становить конфіденційну інформацію, що містить персональні дані Клієнта,
            а також власниками якої є Сервіс, і без їх згоди не використовувати цю інформацію в особистих цілях;
          </div>
          <br />
          <div>
            6.1.4. не розголошувати вище вказану інформацію, що є конфіденційною після припинення трудового договору (контракту);
          </div>
          <br />
          <div>
            6.1.5. відшкодувати заподіяний Клієнту збиток, якщо працівник винний у розголошенні інформації,
            що становить конфіденційну інформацію та яка стала йому відома у зв’язку з виконанням ним трудових обов’язків;
          </div>
          <br />
          <div>
            6.2. Відносини між Сервісом  і його контрагентами у частині, що стосується нерозголошення конфіденційної
            інформації, регулюються законодавством, договорами та даною Політикою конфіденційності.
            В договорах Сервісу з контрагентами зазначається пункт про нерозголошення контрагентом інформації,
            яка становить комерційну таємницю та/або конфіденційну інформацію.
          </div>
          <br />
          <div>
            6.3. Власник Сервісу приймає всі необхідні організаційні і технічні міри для захисту персональних
            даних від випадкового та шахрайського доступу, знищення, зміни або інших неправомірних дій.
          </div>
          <br />
          <strong>7. ПОРЯДОК ЗБЕРІГАННЯ, ВИКОРИСТАННЯ, НАДАННЯ ТА ОБЛІКУ КОНФІДЕНЦІЙНОЇ ІНФОРМАЦІЇ</strong>
          <div>
            <br />
            7.1. Паперові та електронні носії, що містять конфіденційну інформацію Сервісу, зберігаються в
            неробочий час тільки в сейфах або скриньках, що зачиняються;  електронні бази
            зберігання інформації - закодовані відповідними паролями безпеки.
          </div>
          <br />
          <div>
            7.2. При роботі з електронними носіями без попередньої згоди з Сервісу працівникам,
            залученим до роботи Сервісу не дозволено:
          </div>
          <br />
          <div>
            7.2.1. знімати копії з електронних носіїв, що містять конфіденційну інформацію,
            виносити копії цих носіїв за межі офісу, передавати їх іншим особам, що не мають доступу до комерційної
            таємниці та/або конфіденційної інформації;
          </div>
          <br />
          <div>
            7.2.2. ознайомлювати співробітників Сервісу та інших осіб, що не мають доступу до конфіденційної інформації
            з відомостями, що містять конфіденційну інформацію.
          </div>
          <br />
          <div>
            7.4. Відомості, що становлять конфіденційну інформацію Сервісу можуть бути надані контрагентам
            при укладанні угод, за їхньою вимогою з попереднього погодження з власниками персональних даних.
          </div>
          <br />
          <div>
            7.5. Відомості, що містять конфіденційну інформацію можуть надаватися виключно власником Сервісу
            або особами, що його заміняють лише на письмову вимогу представників органів державної виконавчої влади,
            контролюючих та правоохоронних органів.
          </div>
          <br />
          <div>
            7.6. Облік електронних носіїв, що містять конфіденційну інформацію веде відповідальна особа.
          </div>
          <br />
          <br />
          <br />
        </div>
        <Footer />
      </div>
    )
  }
}

export default PrivacyContainer
