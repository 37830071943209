import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import NotificationsTopbar from './NotificationsTopbar'

const NotificationsComponent = ({
  isNew,
  isArchive,
  isAnyUnopened,
  isAnyChecked,
  unopenedCount,
  renderTab,
  handleOpenAll,
  handleMoveToSettings,
  handleDeleteSelected,
  handleSetNewTab,
  handleSetArchiveTab,
}) => {
  const scrollRef = useRef(null)

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo(0, 0)
    }
  }, [isNew])

  return (
    <div className='notifications'>
      <NotificationsTopbar
        isNew={isNew}
        isArchive={isArchive}
        isAnyUnopened={isAnyUnopened}
        isAnyChecked={isAnyChecked}
        unopenedCount={unopenedCount}
        handleOpenAll={handleOpenAll}
        handleMoveToSettings={handleMoveToSettings}
        handleDeleteSelected={handleDeleteSelected}
        handleSetNewTab={handleSetNewTab}
        handleSetArchiveTab={handleSetArchiveTab}
      />
      <div className='notifications__list' ref={scrollRef}>
        {renderTab()}
      </div>
    </div>
  )
}

NotificationsComponent.propTypes = {
  isNew: PropTypes.bool,
  isArchive: PropTypes.bool,
  isAnyUnopened: PropTypes.bool,
  isAnyChecked: PropTypes.bool,
  unopenedCount: PropTypes.number,
  handleMoveToSettings: PropTypes.func,
  renderTab: PropTypes.func,
  handleOpenAll: PropTypes.func,
  handleDeleteSelected: PropTypes.func,
  handleSetNewTab: PropTypes.func,
  handleSetArchiveTab: PropTypes.func,
}

export default NotificationsComponent
