// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/landing/IntroBusiness/money.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@media screen and (max-width: 1650px){#how-it-works .section-users-wrapper h6{font-size:24px;line-height:30px}#how-it-works .section-users-wrapper p{font-size:14px;line-height:18px}#how-it-works .section-users-wrapper .description{flex-direction:column}}@media screen and (max-width: 1280px){#how-it-works .section-users-wrapper .step{max-width:267px;margin:15px 0px}#how-it-works .section-users-wrapper .step h6{font-size:26px;line-height:32px}#how-it-works .section-users-wrapper .step .description{flex-direction:column}}@media only screen and (max-width: 768px){#how-it-works .section-users-wrapper{flex-wrap:wrap;justify-content:center}#how-it-works .section-users-wrapper .step{max-width:unset;flex-direction:row;align-items:center;margin-bottom:60px}}@media only screen and (max-width: 480px){#how-it-works{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:top 15px center;background-size:95%;background-repeat:no-repeat}#how-it-works .section-users-wrapper{flex-wrap:wrap;flex-direction:column;justify-content:center}#how-it-works .section-users-wrapper .section-subtitle{width:unset}#how-it-works .section-users-wrapper .step{width:100%;margin-bottom:35px}#how-it-works .section-users-wrapper .step .number{font-size:18px;line-height:22px;padding:6px;height:34px;width:34px}#how-it-works .section-users-wrapper .step p{font-size:16px}#how-it-works .section-users-wrapper .step h6{font-size:18px;line-height:22px}#how-it-works .section-users-wrapper .step img{max-width:130px}}", ""]);
// Exports
module.exports = exports;
