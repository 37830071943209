export const SEND_PASSWORD_RESET_EMAIL_REQUEST = 'SEND_PASSWORD_RESET_EMAIL_REQUEST'
export const SEND_PASSWORD_RESET_EMAIL_SUCCESS = 'SEND_PASSWORD_RESET_EMAIL_SUCCESS'
export const SEND_PASSWORD_RESET_EMAIL_FAILURE = 'SEND_PASSWORD_RESET_EMAIL_FAILURE'
export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST'
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS'
export const PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE'

export const BARISTA_PASSWORD_RESET_REQUEST = 'BARISTA_PASSWORD_RESET_REQUEST'
export const BARISTA_PASSWORD_RESET_SUCCESS = 'BARISTA_PASSWORD_RESET_SUCCESS'
export const BARISTA_PASSWORD_RESET_FAILURE = 'BARISTA_PASSWORD_RESET_FAILURE'

export const sendPasswordResetEmailRequest = (payload) => ({
  type: SEND_PASSWORD_RESET_EMAIL_REQUEST,
  payload,
})

export const sendPasswordResetEmailSuccess = () => ({
  type: SEND_PASSWORD_RESET_EMAIL_SUCCESS,
})

export const sendPasswordResetEmailFailure = (error) => ({
  type: SEND_PASSWORD_RESET_EMAIL_FAILURE,
  payload: error,
})

export const passwordResetRequest = (payload) => ({
  type: PASSWORD_RESET_REQUEST,
  payload,
})

export const passwordResetSuccess = () => ({
  type: PASSWORD_RESET_SUCCESS,
})

export const passwordResetFailure = (error) => ({
  type: PASSWORD_RESET_FAILURE,
  payload: error,
})

export const baristaPasswordResetRequest = (payload) => ({
  type: BARISTA_PASSWORD_RESET_REQUEST,
  payload,
})

export const baristaPasswordResetSuccess = () => ({
  type: BARISTA_PASSWORD_RESET_SUCCESS,
})

export const baristaPasswordResetFailure = (error) => ({
  type: BARISTA_PASSWORD_RESET_FAILURE,
  payload: error,
})
