/* eslint-disable no-irregular-whitespace */
import { DirectUpload } from 'activestorage'
import config from 'config'

import { transliterate } from './' 
import { toCamelCase } from './converter'

class Upload {
  constructor(file, options) {
    this.options = options
    this.status = {
      ERROR:    'error',
      FINISHED: 'finished',
      PROGRESS: 'uploading',
      WAITING:  'waiting'
    }
    this.file = this.changeFilename(file)

    this.directUpload = new DirectUpload(this.file, `${config.apiUrl}/api/v2/attachments`, this)

    this.handleChangeFile({ progress: 0, state: this.status.WAITING })
  }

  changeFilename = (file) => {
    const blob = file.slice(0, file.size, file.type)
    const newFilename = transliterate(file.name.replace(/[- )(]/g,''))

    return new File([blob], newFilename, { type: file.type })
  }

  handleChangeFile = (upload) => {
    const fileInfo = {
      id:   this.id,
      filename: this.filename,
      size: this.size,
      type: this.type
    }

    this.options.onChangeFile({ ...fileInfo, ...upload })
  }

  handleSuccess = (blob) => {
    const response = toCamelCase(blob)

    this.handleChangeFile({
      attachmentId: response.id,
      progress:     100,
      serviceUrl:   response.url,
      signedId:     response.signedId,
      state:        this.status.FINISHED,
      url:          response.serviceUrl
    })

    return response
  };

  handleError = (error) => {
    this.handleChangeFile({
      error,
      progress: 0,
      state:    this.status.ERROR
    })

    throw error
  };

  handleProgress = ({ loaded, total }) => {
    const progress = (loaded / total) * 100

    this.handleChangeFile({
      progress: Math.floor(progress),
      state:    this.status.PROGRESS
    })
  }

  get id() {
    return this.directUpload.id
  }

  get filename() {
    return `${this.file.name}`
  }

  get size() {
    return this.file.size
  }

  get type() {
    return `${this.file.type}`
  }

  static get getStatuses() {
    return {
      ERROR:    'error',
      FINISHED: 'finished',
      PROGRESS: 'uploading',
      WAITING:  'waiting'
    }
  }

  start() {
    const promise = new Promise((resolve, reject) => {
      this.directUpload.create((error, blob) => {
        if (error) {
          reject(error)
        } else {
          resolve(blob)
        }
      })
    })

    return promise.then(this.handleSuccess).catch(this.handleError)
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener('progress', this.handleProgress)
  }
}

export default Upload
