import React from 'react'
import './styles.scss'

const sizesMapping = { large: 'large', largeWide: 'largeWide', small: 'small' }

const variantsMapping = {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
  gray: 'gray'

}

const Button = ({ variant = variantsMapping.primary, size = sizesMapping.large, children, ...props }) => {
  return (
    <button className={`button--${variant} ${size}`} {...props}>
      {children}
    </button>
  )
}

export default React.memo(Button)
