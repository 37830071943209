import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { staffOwnersFetchRequest } from '../../../store/staff/owners/actions'
import { Pagination, Text } from '../../UIkit'
import OwnersTable from './List/OwnersTable'

const OwnersView = () => {
  const dispatch = useDispatch()
  const { data: owners, pages: totalPages, fetching: fetching } = useSelector(state => state.staffOwners)

  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(20)

  const getOwners = useCallback((params) => {
    dispatch(staffOwnersFetchRequest({ params: params }))
  }, [dispatch, staffOwnersFetchRequest])

  const onChangePage = useCallback((page = 1) => {
    if (page > totalPages) return

    setPage(page)
    getOwners({ page: page, per: rowsPerPage })
  }, [totalPages, rowsPerPage])

  useEffect(() => {
    onChangePage(page)
  }, [rowsPerPage])

  const emptyCondition = !owners.length

  if (fetching) return null  

  return (
    <>
      <Text className='mb-3' variant='h2'>Owners</Text>

      {emptyCondition ? (
        <Text variant='subheading'>Owners list is empty</Text>
      ) : (
        <OwnersTable owners={owners} />
      )}

      <div className='w-100'>
        <Pagination
          activePage={page}
          perPage={rowsPerPage}
          handleChangePage={onChangePage}
          handleChangePerPage={setRowsPerPage}
          totalPages={totalPages}
          isShow={owners.length}
        />
      </div>
    </>
  )
}

export default OwnersView
