import {
  VENDORS_GET_REQUEST,
  VENDORS_GET_SUCCESS,
  VENDORS_GET_FAILURE,
  VENDOR_CREATE_REQUEST,
  VENDOR_CREATE_SUCCESS,
  VENDOR_CREATE_FAILURE,
  VENDOR_DELETE_REQUEST,
  VENDOR_DELETE_SUCCESS,
  VENDOR_DELETE_FAILURE,
  VENDOR_GET_REQUEST,
  VENDOR_GET_SUCCESS,
  VENDOR_GET_FAILURE,
  VENDOR_UPDATE_REQUEST,
  VENDOR_UPDATE_SUCCESS,
  VENDOR_UPDATE_FAILURE,
  VENDORS_CITIES_GET_REQUEST,
  VENDORS_CITIES_GET_SUCCESS,
  VENDORS_CITIES_GET_FAILURE,
  VENDOR_RATING_GET_REQUEST,
  VENDOR_RATING_GET_SUCCESS,
  VENDOR_RATING_GET_FAILURE,
  VENDOR_FEEDBACKS_GET_REQUEST,
  VENDOR_FEEDBACKS_GET_SUCCESS,
  VENDOR_FEEDBACKS_GET_FAILURE,
} from './actions'

const initialState = {
  items: [],
  cities: [],
  item: {},
  addresses: [],
  rating: {},
  feedbacks: [],
  fetching: false,
  itemFetching: false,
  cityFetching: false,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case VENDORS_GET_REQUEST:
      return { ...state, fetching: true }
    case VENDORS_GET_SUCCESS:
      return {
        ...state,
        items: payload,
        addresses: [...payload.map((v) => ({ value: v.id, label: v.fullAddress }))],
        fetching: false,
      }
    case VENDORS_GET_FAILURE:
      return { ...state, fetching: false }

    case VENDOR_CREATE_REQUEST:
      return { ...state }
    case VENDOR_CREATE_SUCCESS:
      return {
        ...state,
        items: [payload, ...state.items],
        addresses: [{ value: payload.id, label: payload.fullAddress }, ...state.addresses],
        cities: state.cities.find(el => el === payload.city) ? state.cities : [payload.city, ...state.cities],
      }
    case VENDOR_CREATE_FAILURE:
      return { ...state, error: payload }

    case VENDOR_DELETE_REQUEST:
      return { ...state }
    case VENDOR_DELETE_SUCCESS:
      return {
        ...state,
        items: state.items.filter((item) => item.id !== payload),
        addresses: state.addresses.filter((address) => address.value !== payload),
      }
    case VENDOR_DELETE_FAILURE:
      return { ...state }

    case VENDOR_GET_REQUEST:
      return { ...state, itemFetching: true }
    case VENDOR_GET_SUCCESS:
      return { ...state, item: payload, itemFetching: false }
    case VENDOR_GET_FAILURE:
      return { ...state, itemFetching: false }

    case VENDOR_UPDATE_REQUEST:
      return { ...state }
    case VENDOR_UPDATE_SUCCESS:
      return {
        ...state,
        items: state.items.map((i) => (i.id == payload.id ? payload : i)),
      }
    case VENDOR_UPDATE_FAILURE:
      return { ...state }

    case VENDORS_CITIES_GET_REQUEST:
      return { ...state, cityFetching: true }
    case VENDORS_CITIES_GET_SUCCESS:
      return { ...state, cities: payload, cityFetching: false }
    case VENDORS_CITIES_GET_FAILURE:
      return { ...state, cityFetching: false }

    case VENDOR_RATING_GET_REQUEST:
      return { ...state }
    case VENDOR_RATING_GET_SUCCESS:
      return { ...state, rating: payload }
    case VENDOR_RATING_GET_FAILURE:
      return { ...state }

    case VENDOR_FEEDBACKS_GET_REQUEST:
      return { ...state }
    case VENDOR_FEEDBACKS_GET_SUCCESS:
      return { ...state, feedbacks: payload }
    case VENDOR_FEEDBACKS_GET_FAILURE:
      return { ...state }

    default:
      return state
  }
}
