import React from 'react'
import createSagaMiddleware from 'redux-saga'
import 'bootstrap/dist/css/bootstrap.min.css'
import { render } from 'react-dom'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import { createLogger } from 'redux-logger'
import { I18nextProvider } from 'react-i18next'
import i18n from './services/i18n'
import reducer from './store/combineReducers'
import App from './components/App'
import { rootWatcherSaga } from './store/combineSagas'
import './index.css'
import './assets/scss/index.js'

const sagaMiddleware = createSagaMiddleware()
const logger = createLogger({ diff: true, collapsed: true, duration: true })

const store = createStore(reducer, applyMiddleware(sagaMiddleware, logger))

sagaMiddleware.run(rootWatcherSaga)

render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </Provider>,
  document.getElementById('root')
)
