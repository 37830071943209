import React from 'react'

import { Text } from '../../../UIkit'
import InfoRow from '../../components/InfoRow'

const OwnerInfo = ({ owner }) => {
  if (!owner) return null

  return (
    <div className='staff-info-block'>
      <Text variant='h2'>Owner</Text>

      <div className='staff-info-block__block'>
        <div style={{ backgroundImage: `url(${owner.profile?.avatarUrl})` }} className='edit-employee__avatar' />
        <Text variant='h3'>{owner.profile?.fullName}</Text>
      </div>

      <InfoRow title='Email' text={owner.email || ''} />
      <InfoRow title='Phone number' text={owner.phoneNumber || ''} />
    </div>
  )
}

export default OwnerInfo
