export const NETWORK_CREATE_REQUEST = 'NETWORK_CREATE_REQUEST'
export const NETWORK_CREATE_SUCCESS = 'NETWORK_CREATE_SUCCESS'
export const NETWORK_CREATE_FAILURE = 'NETWORK_CREATE_FAILURE'

export const NETWORK_GET_REQUEST = 'NETWORK_GET_REQUEST'
export const NETWORK_GET_SUCCESS = 'NETWORK_GET_SUCCESS'
export const NETWORK_GET_FAILURE = 'NETWORK_GET_FAILURE'

export const NETWORK_UPDATE_REQUEST = 'NETWORK_UPDATE_REQUEST'
export const NETWORK_UPDATE_SUCCESS = 'NETWORK_UPDATE_SUCCESS'
export const NETWORK_UPDATE_FAILURE = 'NETWORK_UPDATE_FAILURE'

export const POSTER_CREATE_REQUEST = 'POSTER_CREATE_REQUEST'
export const POSTER_CREATE_SUCCESS = 'POSTER_CREATE_SUCCESS'
export const POSTER_CREATE_FAILURE = 'POSTER_CREATE_FAILURE'

export const POSTER_DELETE_REQUEST = 'POSTER_DELETE_REQUEST'
export const POSTER_DELETE_SUCCESS = 'POSTER_DELETE_SUCCESS'
export const POSTER_DELETE_FAILURE = 'POSTER_DELETE_FAILURE'

export const networkCreateRequest = (payload, history) => ({
  type: NETWORK_CREATE_REQUEST,
  payload,
  history
})

export const networkCreateSuccess = (payload) => ({
  type: NETWORK_CREATE_SUCCESS,
  payload
})

export const networkCreateFailure = (error) => ({
  type: NETWORK_CREATE_FAILURE,
  error
})

export const networkGetRequest = (payload) => ({
  type: NETWORK_GET_REQUEST,
  payload
})

export const networkGetSuccess = (payload) => ({
  type: NETWORK_GET_SUCCESS,
  payload
})

export const networkGetFailure = (error) => ({
  type: NETWORK_GET_FAILURE,
  error
})

export const networkUpdateRequest = (payload) => ({
  type: NETWORK_UPDATE_REQUEST,
  payload
})

export const networkUpdateSuccess = (payload) => ({
  type: NETWORK_UPDATE_SUCCESS,
  payload
})

export const networkUpdateFailure = (payload) => ({
  type: NETWORK_UPDATE_FAILURE,
  payload
})

export const posterCreateRequest = (payload) => ({
  type: POSTER_CREATE_REQUEST,
  payload
})

export const posterCreateSuccess = (payload) => ({
  type: POSTER_CREATE_SUCCESS,
  payload
})

export const posterCreateFailure = (error) => ({
  type: POSTER_CREATE_FAILURE,
  error
})

export const posterDeleteRequest = (payload) => ({
  type: POSTER_DELETE_REQUEST,
  payload
})

export const posterDeleteSuccess = (payload) => ({
  type: POSTER_DELETE_SUCCESS,
  payload
})

export const posterDeleteFailure = (error) => ({
  type: POSTER_DELETE_FAILURE,
  error
})
