import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import GoogleMap from 'google-map-react'
import config from 'config'

import { MapPointer } from '../UIkit/svgs'

const COUNTRY_ZOOM = 5.5
const VENDOR_ZOOM = 18

const Map = ({ vendors, vendorCoordinates }) => {
  const [mapCenter, setMapCenter] = useState({})
  const [map, setMap] = useState({})

  const centerOfUkraine = {
    latitude: 50.4500336,
    longitude: 30.5241361,
    zoom: COUNTRY_ZOOM
  }

  useEffect(() => {
    const hasVendorCoordinates = !isEmpty(vendorCoordinates)
    const bounds = new window.google.maps.LatLngBounds()

    if (hasVendorCoordinates && !isEmpty(map)) {
      bounds.extend({
        lat: vendorCoordinates.latitude, 
        lng: vendorCoordinates.longitude
      }),
      map.fitBounds(bounds),
      map.setZoom(VENDOR_ZOOM)
    }

    !hasVendorCoordinates && setMapCenter(centerOfUkraine)
    
  }, [vendorCoordinates])

  useEffect(() => {
    const bounds = new window.google.maps.LatLngBounds()

    if (!!vendors.length && !isEmpty(map)) {
      vendors.forEach((vendor) => {
        bounds.extend({ lat: vendor.latitude,lng: vendor.longitude })
        map.fitBounds(bounds)
        vendors.length == 1 && map.setZoom(VENDOR_ZOOM)
      })
    }

    if (!vendors.length && !isEmpty(map)) {
      bounds.extend({
        lat: centerOfUkraine.latitude,lng: 
        centerOfUkraine.longitude
      })
      map.fitBounds(bounds)
      map.setZoom(COUNTRY_ZOOM)
    }
  }, [vendors, map])

  const handleApiLoaded = ({ map }) => {
    setMap(map)
  }

  return (
    <div className='map'>
      <GoogleMap
        apiKey={config.googleApiKey}
        center={[mapCenter.latitude, mapCenter.longitude]}
        zoom={mapCenter.zoom}
        onGoogleApiLoaded={handleApiLoaded}
        yesIWantToUseGoogleMapApiInternals
      >
        {vendors.map((vendor, index) => (
          <MapPointer 
            key={index} 
            lat={vendor.latitude} 
            lng={vendor.longitude} 
          />
        ))}
      </GoogleMap>
    </div>
  )
}

Map.propTypes = {
  vendors: PropTypes.array.isRequired
}

export default Map
