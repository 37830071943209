import {
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAILURE,
  PROFILE_GET_REQUEST,
  PROFILE_GET_SUCCESS,
  PROFILE_GET_FAILURE
} from './actions'

const initialState = {
  profile: {},
  fetching: false,
  updating: false
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case PROFILE_GET_REQUEST:
      return { ...state, fetching: true }
    case PROFILE_GET_SUCCESS:
      return { ...state, profile: payload, fetching: false }
    case PROFILE_GET_FAILURE:
      return { ...state, fetching: false }

    case PROFILE_UPDATE_REQUEST:
      return { ...state, updating: true }
    case PROFILE_UPDATE_SUCCESS:
      return { ...state, profile: payload, updating: false }
    case PROFILE_UPDATE_FAILURE:
      return { ...state, updating: false }

    default:
      return state
  }
}
