import React from 'react'

import { TableCell, TableRow } from '../../../../../UIkit'
import TableText from '../../../../components/TableText'

const BaristaRow = ({ barista }) => {
  return (
    <TableRow>
      <TableCell>
        <div className='staff-info-block__block'>
          <div style={{ backgroundImage: `url(${barista.profile?.avatarUrl})` }} className='vendor-image' />
          <TableText text={barista.profile?.fullName} />
        </div>
      </TableCell>
      <TableCell>
        <TableText text={barista.email} />
      </TableCell>
      <TableCell>
        <TableText text={barista.phoneNumber} />
      </TableCell>
    </TableRow>
  )
}

export default BaristaRow
