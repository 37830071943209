import { takeLatest, call, put, select } from 'redux-saga/effects'
import * as actions from './actions'

import Api from '../../lib/api'
import { toCamelCase } from '../../lib/converter'
import notifications from '../../lib/notifications'

export default function* watcherSaga() {
  yield takeLatest(actions.BANK_ACCOUNT_CREATE_REQUEST, watchResourceCreateRequest)
  yield takeLatest(actions.BANK_ACCOUNTS_GET_REQUEST, watchResourcesGetRequest)
  yield takeLatest(actions.BANK_ACCOUNT_DELETE_REQUEST, watchResourceDeleteRequest)
  yield takeLatest(actions.BANK_ACCOUNT_UPDATE_REQUEST, watchResourceUpdateRequest)
}

function* watchResourceCreateRequest({ payload }) {
  try {
    const response = yield call(Api.BankAccount.create, payload.networkId, payload.data)
    const data = toCamelCase(response.data)
    const bankAccounts = yield select((state) => state.bankAccounts.items)

    if (data.default) {
      yield put(
        actions.bankAccountUpdateSuccess([
          ...bankAccounts
            .filter((account) => account.id !== data.id)
            .map((account) => ({ ...account, default: false })),
          data,
        ])
      )
    } else {
      yield put(actions.bankAccountCreateSuccess([ ...bankAccounts, data ]))
    }

    notifications.createNotification('create-success')
  } catch (error) {
    yield put(actions.bankAccountCreateFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}

function* watchResourcesGetRequest({ payload }) {
  try {
    const response = yield call(Api.BankAccounts.get, payload)

    yield put(actions.bankAccountsGetSuccess(toCamelCase(response.data)))
  } catch (error) {
    yield put(actions.bankAccountsGetFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}

function* watchResourceDeleteRequest({ payload }) {
  try {
    yield call(Api.BankAccount.delete, payload.networkId, payload.id)

    yield put(actions.bankAccountDeleteSuccess(payload.id))
    notifications.createNotification('delete-success')
  } catch (error) {
    yield put(actions.bankAccountDeleteFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}

function* watchResourceUpdateRequest({ payload }) {
  try {
    const response = yield call(Api.BankAccount.update, payload.networkId, payload.data.id, payload.data)
    const data = toCamelCase(response.data)

    const bankAccounts = yield select((state) => state.bankAccounts.items)

    yield put(
      actions.bankAccountUpdateSuccess([
        ...bankAccounts
          .filter((account) => account.id !== data.id)
          .map((account) => ({ ...account, default: false })),
        data,
      ])
    )

    notifications.createNotification('edit-success')
  } catch (error) {
    yield put(actions.bankAccountUpdateFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}
