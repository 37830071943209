import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  sendPasswordResetEmailRequest,
  passwordResetRequest,
  baristaPasswordResetRequest
} from './../../store/passwordReset/actions'
import { RouteNames } from '../../router'
import { ResetPassword } from '../../components/Authentication'
import { colors, Text } from '../../components/UIkit'
import { AuthenticationImage } from '../../components/UIkit/svgs'

import './styles.scss'

const ResetPasswordContainer = () => {
  const history = useNavigate()
  const dispatch = useDispatch()
  const session = useSelector((state) => state.authentication)
  const { token } = useParams()
  const { t } = useTranslation()

  const isBarista = window.location.pathname.includes('barista')
  const handleCancel = () => {
    history('/admin')
  }

  const sendEmail = (email, nextStep) => {
    const params = {
      email: email,
    }

    dispatch(sendPasswordResetEmailRequest({ params, nextStep }))
  }

  const handleResetPassword = (password, passwordConfirmation) => {
    const params = isBarista
      ? {
        token: token,
        barista: {
          password: password,
          passwordConfirmation: passwordConfirmation,
        },
      }
      : {
        token: token,
        owner: {
          password: password,
          passwordConfirmation: passwordConfirmation,
        },
      }

    isBarista ? dispatch(baristaPasswordResetRequest(params)) : dispatch(passwordResetRequest(params))
  }

  useEffect(() => {
    if (session?.authenticated) {
      history(RouteNames.DASHBOARD)
    }
  })

  return (
    <div className='auth-page'>
      <div className='auth-page__container'>
        <div className='auth-page__image-wrapper'>
          <Text className='auth-page__welcome-text'>{t('signin:title')}</Text>
          <div className='auth-page__additional-text'>
            <Text variant={'h4'} color={colors.gray[500]}>
              {t('signin:description')}
            </Text>
          </div>
          <AuthenticationImage />
        </div>
        <ResetPassword
          isBarista={isBarista}
          token={token}
          history={history}
          sendEmail={sendEmail}
          handleResetPassword={handleResetPassword}
          handleCancel={handleCancel}
        />
      </div>
    </div>
  )
}

export default ResetPasswordContainer
