import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { useDispatch, useSelector } from 'react-redux'
import { typesFetchRequest } from '../../store/types/actions'
import { categoriesClear } from '../../store/categories/actions'
import { productsClear } from '../../store/products/actions'
import { supplementsClear } from '../../store/supplements/actions'

import TypesComponent from '../../components/Menu/Types/TypesComponent'

const TypesContainer = () => {
  const dispatch = useDispatch()
  const networkId = localStorage.getItem('networkId')
  const { items, fetching } = useSelector((state) => state.types)
  const clearCategories = () => dispatch(categoriesClear())
  const clearProducts = () => dispatch(productsClear())
  const clearSupplements = () => dispatch(supplementsClear())
  const fetchTypes = () => dispatch(typesFetchRequest({ networkId }))

  useEffect(() => {
    fetchTypes()
    clearCategories()
    clearProducts()
    clearSupplements()
  }, [])

  return <TypesComponent types={items} fetching={fetching} />
}

TypesContainer.propTypes = {
  items: PropTypes.array,
  fetching: PropTypes.bool,
}

export default TypesContainer
