import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import PropTypes from 'prop-types'
import config from 'config'

const CaptchaForm = ({ processCaptcha }) => {
  return config.isDev ? (
    <div className='mb-3'>
      Потрібна перевірка Recaptcha.
      <button type='button' onClick={() => processCaptcha('captcha')}>
        Я не робот
      </button>
    </div>
  ) : (
    <ReCAPTCHA sitekey={config.captchaKey} onChange={processCaptcha} />
  )
}

CaptchaForm.propTypes = {
  processCaptcha: PropTypes.func.isRequired,
}

export default CaptchaForm
