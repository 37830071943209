import {
  CASH_TURNOVER_GET_REQUEST,
  CASH_TURNOVER_GET_SUCCESS,
  CASH_TURNOVER_GET_FAILURE,

  TOTAL_ORDERS_GET_REQUEST,
  TOTAL_ORDERS_GET_SUCCESS,
  TOTAL_ORDERS_GET_FAILURE,

  SALES_BY_CATEGORY_GET_REQUEST,
  SALES_BY_CATEGORY_GET_SUCCESS,
  SALES_BY_CATEGORY_GET_FAILURE,

  BARISTA_EFFICIENCY_GET_REQUEST,
  BARISTA_EFFICIENCY_GET_SUCCESS,
  BARISTA_EFFICIENCY_GET_FAILURE,

  VENDOR_COMPARISON_GET_REQUEST,
  VENDOR_COMPARISON_GET_SUCCESS,
  VENDOR_COMPARISON_GET_FAILURE,
} from './actions'

const initialState = {
  cashTurnover: [],
  cashTurnoverFecthing: false,

  totalOrders: [],
  totalOrdersFetching: false,

  salesByCategory:[],
  salesByCategoryFetching: false,

  baristaEfficiency: [],
  baristaEfficiencyFetching: false,

  vendorComparison: [],
  vendorComparisonFetching: false
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CASH_TURNOVER_GET_REQUEST:
      return { ...state, cashTurnoverFecthing: true }
    case CASH_TURNOVER_GET_SUCCESS:
      return { ...state, cashTurnover: payload, cashTurnoverFecthing: false }
    case CASH_TURNOVER_GET_FAILURE:
      return { ...state, cashTurnoverFecthing: false }
    
    case TOTAL_ORDERS_GET_REQUEST:
      return { ...state, totalOrdersFetching: true }
    case TOTAL_ORDERS_GET_SUCCESS:
      return { ...state, totalOrders: payload, totalOrdersFetching: false }
    case TOTAL_ORDERS_GET_FAILURE:
      return { ...state, totalOrdersFetching: false }

    case SALES_BY_CATEGORY_GET_REQUEST:
      return { ...state, salesByCategoryFetching: true }
    case SALES_BY_CATEGORY_GET_SUCCESS:
      return { ...state, salesByCategory: payload, salesByCategoryFetching: false }
    case SALES_BY_CATEGORY_GET_FAILURE:
      return { ...state, salesByCategoryFetching: false }

    case BARISTA_EFFICIENCY_GET_REQUEST:
      return { ...state, baristaEfficiencyFetching: true }
    case BARISTA_EFFICIENCY_GET_SUCCESS:
      return { ...state, baristaEfficiency: payload, baristaEfficiencyFetching: false }
    case BARISTA_EFFICIENCY_GET_FAILURE:
      return { ...state, baristaEfficiencyFetching: false }

    case VENDOR_COMPARISON_GET_REQUEST:
      return { ...state, vendorComparisonFetching: true }
    case VENDOR_COMPARISON_GET_SUCCESS:
      return { ...state, vendorComparison: payload, vendorComparisonFetching: false }
    case VENDOR_COMPARISON_GET_FAILURE:
      return { ...state, vendorComparisonFetching: false }
  
    default:
      return state
  }
}
  
  
