import { useEffect } from 'react'
import config from 'config'

function DetectDevice() {
  useEffect(() => {
    const { userAgent } = navigator
    const isIOS = /iPhone|iPad|iPod/i.test(userAgent)

    window.location.assign(isIOS ? config.appStoreLink : config.googlePlayLink)
  }, [])

  return null
}
export default DetectDevice
