export const USER_CREATE_REQUEST = 'USER_CREATE_REQUEST'
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS'
export const USER_CREATE_FAILURE = 'USER_CREATE_FAILURE'

export const USERS_GET_REQUEST = 'USERS_GET_REQUEST'
export const USERS_GET_SUCCESS = 'USERS_GET_SUCCESS'
export const USERS_GET_FAILURE = 'USERS_GET_FAILURE'

export const USER_SMS_CODE_REQUEST = 'USER_SMS_CODE_REQUEST'
export const USER_SMS_CODE_SUCCESS = 'USER_SMS_CODE_SUCCESS'
export const USER_SMS_CODE_FAILURE = 'USER_SMS_CODE_FAILURE'
export const USER_SMS_CODE_CLEAR = 'USER_SMS_CODE_CLEAR'
export const USER_PHONE_REGISTRATION_REQUEST = 'USER_PHONE_REGISTRATION_REQUEST'

export const userCreateRequest = (payload, history) => ({
  type: USER_CREATE_REQUEST,
  payload,
  history
})

export const userCreateSuccess = () => ({
  type: USER_CREATE_SUCCESS,
})

export const userCreateFailure = (error) => ({
  type: USER_CREATE_FAILURE,
  error
})

export const usersGetRequest = (params) => ({
  type: USERS_GET_REQUEST,
  params
})

export const usersGetSuccess = (payload) => ({
  type: USERS_GET_SUCCESS,
  payload
})

export const usersGetFailure = (error) => ({
  type: USERS_GET_FAILURE,
  error
})

export const userSmsCodeRequest = (payload, callback) => ({
  type: USER_SMS_CODE_REQUEST,
  payload,
  callback
})

export const userSmsCodeSuccess = () => ({
  type: USER_SMS_CODE_SUCCESS,
})

export const userSmsCodeFailure = (error) => ({
  type: USER_SMS_CODE_FAILURE,
  error
})

export const userSmsCodeClear = () => ({
  type: USER_SMS_CODE_CLEAR
})

export const userPhoneRegistrationRequest = (payload, history) => ({
  type: USER_PHONE_REGISTRATION_REQUEST,
  payload,
  history
})
