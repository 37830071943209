import React from 'react'

import './styles.scss'
import './responsiveStyles.scss'
import { Section1, Section2 } from '../../../../assets/images/landing/Loyality'

function Loyalty() {
  return (
    <section id='loyalty' className='loyalty-section'>
      <div className='content'>
        <div className='row-1'>
          <img src={Section1} alt='Section 1' />
        </div>
        <div className='row-2'>
          <img src={Section2} alt='Section 2' />
        </div>
      </div>
    </section>
  )
}

export default Loyalty
