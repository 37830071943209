import { takeLatest, call, put } from 'redux-saga/effects'
import * as actions from './actions'

import Api from '../../lib/api'
import notifications from '../../lib/notifications'

export default function* watcherSaga() {
  yield takeLatest(actions.SEND_PASSWORD_RESET_EMAIL_REQUEST, watchSendPasswordResetEmailRequest)
  yield takeLatest(actions.PASSWORD_RESET_REQUEST, watchPasswordResetRequest)
  yield takeLatest(actions.BARISTA_PASSWORD_RESET_REQUEST, watchBaristaPasswordResetRequest)
}

function* watchSendPasswordResetEmailRequest({ payload: { params, nextStep } }) {
  try {
    yield call(Api.PasswordReset.create, params)
    yield nextStep()
    yield put(actions.sendPasswordResetEmailSuccess())
  } catch (error) {
    yield put(actions.sendPasswordResetEmailFailure(error))
    notifications.createNotification('password-reset-error', error.response.data.errors)
  }
}

function* watchPasswordResetRequest({ payload }) {
  try {
    yield call(Api.PasswordReset.update, payload)

    yield put(actions.passwordResetSuccess())
  } catch (error) {
    yield put(actions.passwordResetFailure(error))
    notifications.createNotification('password-reset-error', error.response.data.errors)
  }
}

function* watchBaristaPasswordResetRequest({ payload }) {
  try {
    yield call(Api.BaristaPasswordReset.update, payload)

    yield put(actions.passwordResetSuccess())
  } catch (error) {
    yield put(actions.passwordResetFailure(error))
    notifications.createNotification('password-reset-error', error.response.data.error)
  }
}
