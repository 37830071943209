import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'

import { adminUpdateRequest } from '../../store/admin/actions'
import { Modal, Text, Button, PasswordInput, colors } from '../UIkit'
import Validator from '../../lib/validator'

const ChangePassword = ({ show, setShow }) => {
  const { t } = useTranslation()

  const [form, setForm] = useState({
    oldPassword: '',
    password: '',
    confirmPassword: '',
  })

  const [formErrors, setFormErrors] = useState({
    oldPassword: '',
    password: '',
    confirmPassword: '',
  })

  const dispatch = useDispatch()

  const changePassword = (data) => dispatch(adminUpdateRequest(data))

  const handleValidate = () =>
    new Promise((resolve, reject) => {
      const errors = Validator.validate([
        ['oldPassword', Validator.concepts.isFilled, [form.oldPassword]],
        ['password', Validator.concepts.isLongerThan, [6, form.password]],
        ['confirmPassword', Validator.concepts.isMatching, [form.password, form.confirmPassword]],
      ])

      Validator.clear()

      isEmpty(errors) ? resolve() : reject(errors)
    })

  const handleSubmit = (e) => {
    e.preventDefault()

    const data = {
      owner: {
        oldPassword: form.oldPassword,
        password: form.password,
        passwordConfirmation: form.confirmPassword,
      },
    }

    handleValidate()
      .then(() => {
        changePassword(data)
        setShow(false)
      })
      .catch((errors) => setFormErrors(errors))
  }

  const onUpdateField = (e) => {
    const nextFormState = {
      ...form,
      [e.target.name]: e.target.value,
    }
    setForm(nextFormState)
  }

  return (
    <Modal show={show} handleClose={() => setShow(false)} title={t('header:changePassword:title')}>
      <form className='change-password' autoComplete='off' onSubmit={handleSubmit}>
        <Text variant='body-secondary1' color={colors.gray[500]}>
          {t('header:changePassword:description')}
        </Text>
        <div className='change-password__inputs'>
          <Text wrapper variant='body-main1'>
            <PasswordInput
              id='old-password'
              name='oldPassword'
              placeholder={t('input:oldPassword')}
              autoFocus
              value={form.oldPassword}
              onChange={onUpdateField}
              error={formErrors.oldPassword}
            />
          </Text>
          <Text wrapper variant='body-main1'>
            <PasswordInput
              id='new-password'
              name='password'
              placeholder={t('input:password')}
              value={form.password}
              onChange={onUpdateField}
              error={formErrors.password}
            />
          </Text>
          <Text wrapper variant='body-main1'>
            <PasswordInput
              id='new-password_confirmation'
              name='confirmPassword'
              placeholder={t('input:confirmPassword')}
              value={form.confirmPassword}
              onChange={onUpdateField}
              error={formErrors.confirmPassword}
            />
          </Text>
        </div>
        <Button type='submit' disabled={!(form.password && form.confirmPassword)}>
          {t('btn:save')}
        </Button>
      </form>
    </Modal>
  )
}

export default React.memo(ChangePassword)
