export const textShortener = (text) => {
  if (text.length > 8) {
    const beginOfText = text.substr(0, 8)
    const endOfText = text.substr(-10, 10)

    return `${beginOfText}...${endOfText}`
  }

  return text
}
  
export default textShortener
