import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'

import { ordersGetRequest, ordersClear } from '../../store/orders/actions'
import { useRowsPerPage } from '../../contexts/perPageContext'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow, Text, Pagination, colors } from '../../components/UIkit'

import './styles.scss'
import ReceiptsOptions from '../../components/Receipts/ReceiptsOptions'

const ReceiptsContainer = () => {
  const { t } = useTranslation()

  const date = new Date()
  const firstDayInMonth = new Date(date.getFullYear(), date.getMonth(), 1)

  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState({
    dateFrom: firstDayInMonth,
    dateTo: date,
  })

  const { rowsPerPage, setRowsPerPage } = useRowsPerPage()

  const headers = [t('receipts:date'), t('receipts:order'), t('receipts:uah'), t('receipts:receipts') ]

  const dispatch = useDispatch()
  const networkId = localStorage.getItem('networkId')

  const { items: orders, fetching, pages: totalPages } = useSelector((state) => state.orders)

  const handleChangePage = (page) => {
    if (page <= totalPages) {
      setPage(page)
      if (page) getOrders(networkId, { page: page, per: rowsPerPage, status: 'finished' })
    }
  }

  const handleChangeFilters = (value) => {
    setFilters({
      dateFrom: value.dateFrom,
      dateTo: value.dateTo,
    })
  }

  const handleResetFilters = () => {
    setFilters({
      dateFrom: null,
      dateTo: null,
    })
  }

  const getOrders = (id, params) => {
    dispatch(ordersGetRequest({ networkId: id, params: params }))
  }

  const clearOrders = () => dispatch(ordersClear())

  const renderOrderTotal = () => {
    let orderTotal = 0

    orders.map(order => {
      orderTotal += order.customerPrice
    })

    return Number(orderTotal).toFixed(2)
  }

  useEffect(() => {
    setPage(1)
  }, [networkId, rowsPerPage])

  useEffect(() => {
    getOrders(networkId, { page: 1, per: rowsPerPage, status: 'finished', ...filters })

    return () => {
      clearOrders()
    }
  }, [networkId, rowsPerPage, filters])

  return (
    <div className='receipts'>
      <div className='title'>
        <Text variant='h3-medium'>{t('receipts:title')}</Text>
        <ReceiptsOptions
          filters={filters}
          handleResetFilters={handleResetFilters}
          handleChangeFilters={handleChangeFilters}
        />
      </div>

      {fetching ? null : (
        <div>
          <Table className='receipts__table'>
            <TableHead>
              <TableRow>
                {isMobile && (
                  <TableHeader>
                    <Text variant='subheading'></Text>
                  </TableHeader>
                )}
                {headers.map((header) => (
                  <TableHeader key={header}>
                    <Text variant='subheading'>{header}</Text>
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((receipt, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <div className='orders__order-date'>
                      <Text variant='body-main2'>
                        {receipt.date}
                      </Text>
                    </div>
                    {(index == orders.length - 1) && <div className='orders__order-total-br'><br /></div>}
                  </TableCell>

                  <TableCell>
                    <div className='orders__order-id'>
                      <Text variant='body-main2' color={colors.secondary[500]}>
                        #{receipt.id}
                      </Text>
                    </div>
                    <div>
                      <Text variant='body-main2' color={colors.primary[700]}>
                        {t('receipts:loyalty')}
                      </Text>
                    </div>
                    <div>
                      <Text variant='body-main2' color={colors.primary[700]}>
                        {t('receipts:commission')}
                      </Text>
                    </div>
                    {(index == orders.length - 1) && <div className='orders__order-total-br'><br /></div>}
                  </TableCell>

                  <TableCell>
                    <div>
                      <br />
                    </div>
                    <div>
                      <Text>
                        {receipt?.discountPrice || 0} ({(receipt.discount * 100) || 0}%)
                      </Text>
                    </div>
                    <div>
                      <Text>
                        {receipt.commissionPrice}
                      </Text>
                    </div>
                    {(index == orders.length - 1) &&
                    <div className='orders__order-total-title'>
                      <Text variant='h4-bold'>
                        {t('receipts:total')}
                      </Text>
                    </div>
                    }
                  </TableCell>

                  <TableCell>
                    <div>
                      <Text>
                        {receipt.totalWithoutDiscount || receipt.total}
                      </Text>
                    </div>
                    <div>
                      <Text>
                        {receipt.total}
                      </Text>
                    </div>
                    <div>
                      <Text>
                        {receipt.customerPrice}
                      </Text>
                    </div>
                    {(index == orders.length - 1) &&
                      <div className='orders__order-total-value'>
                        <Text variant='h4-bold'>
                          {renderOrderTotal()}
                        </Text>
                      </div>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
      <div className='orders__pagination-section' >
        <Pagination
          activePage={page}
          perPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangePerPage={setRowsPerPage}
          totalPages={totalPages}
          isShow={orders.length}
        />
      </div>
    </div>
  )
}

export default ReceiptsContainer
