import React, { Component } from 'react'
import { connect } from 'react-redux'
import Pagination from 'react-js-pagination'
import PropTypes from 'prop-types'

import {
  productsGetRequest,
  productDeleteRequest,
  productCreateRequest,
  productUpdateRequest,
} from '../../store/products/actions'
import debounce from 'lodash.debounce'
import Loader from '../../components/Loader'
import { Button, Modal, ModalBody, FormGroup, Input, Form, Label } from 'reactstrap'
import ProductsPage from '../../components/Products/ProductsPage'
import addProduct from '../../assets/svgs/plus-vendor.svg'
import './pagination.scss'

class ProductsContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activePage: 1,
      productsModalOpened: false,
      productName: '',
      productType: 'drink',
      errors: false,
      isFetching: true,
      fetchParams: {
        name: '',
        page: 1,
      },
    }

    this.getProducts = debounce(() => this.props.getProducts(this.state.fetchParams), 500)
  }

  componentDidMount() {
    this.props.getProducts()
  }

  createProduct = (name, image, productType) => {
    const data = {
      product: {
        name,
        image,
        product_type: productType,
      },
    }

    this.props.createProduct(data)
    this.toggleProductsModal()
  }

  handleChooseImage = (e) => {
    const file = e.target.files[0]
    const reader = new FileReader()

    reader.readAsDataURL(file)
    reader.onload = (event) => this.setState({ productImage: event.target.result })
  }

  onInputChange = ({ target }) => {
    this.setState({ [target.name]: target.value })
  }

  handleSubmit = (e) => {
    if (this.state.productName !== '') {
      e.preventDefault()
      const { productName, productImage, productType } = this.state

      this.createProduct(productName, productImage, productType)
    } else {
      this.setState({ errors: true })
    }
  }

  deleteProduct = (id, index) => () => {
    if (confirm('Delete this item?')) this.props.deleteProduct({ id, index })
  }

  updateProduct = (id, name, image, productType) => {
    const product = {
      product: {
        id,
        name,
        image: image.url,
        product_type: productType,
        page: this.state.activePage,
      },
    }
    this.setState({ isFetching: true }, () => this.props.updateProduct(product))
  }

  toggleProductsModal = () => {
    this.setState({
      productName: '',
      productsModalOpened: !this.state.productsModalOpened,
      errors: false,
    })
  }

  isFetching = () => {
    if (this.state.isFetching && this.props.fetching) {
      this.state.isFetching = false

      return this.props.fetching
    } else {
      return this.state.isFetching
    }
  }

  handleChange = ({ target }) =>
    this.setState(
      {
        fetchParams: { ...this.state.fetchParams, [target.name]: target.value },
        isFetching: true,
      },
      this.getProducts
    )

  handlePageChange = (page) => {
    this.setState(
      {
        isFetching: true,
        activePage: page,
        fetchParams: { ...this.state.fetchParams, page },
      },
      () => this.props.getProducts(this.state.fetchParams)
    )
  }

  render() {
    const { products } = this.props

    return (
      <div className='main-container h-75'>
        <div className='header'>
          <span className='header-title'>Products</span>
          <div>
            <span className='header-search-span'>Name:</span>
            <input className='header-search-input' placeholder='All' name='name' onChange={this.handleChange} />
            <img
              src={addProduct}
              onClick={this.toggleProductsModal}
              className='width-40 m-l-60'
              style={{ cursor: 'pointer' }}
            />
          </div>
        </div>
        <div className='white-b-color'>
          <Loader blocker isLoading={this.isFetching()} />
          {products.data && (
            <ProductsPage
              handlePageChange={this.handlePageChange}
              createProduct={this.createProduct}
              updateProduct={this.updateProduct}
              deleteProduct={this.deleteProduct}
              activePage={this.state.activePage}
              products={products}
            />
          )}
          <Pagination
            innerClass='products-pagination'
            activePage={this.state.activePage}
            itemsCountPerPage={1}
            totalItemsCount={(products.data && products.data[0].pages) || 1}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange}
          />
        </div>
        <Modal
          centered
          id='edit-products-modal'
          isOpen={this.state.productsModalOpened}
          toggle={this.toggleProductsModal}
        >
          <ModalBody>
            <h1 className='title'>Add New Product</h1>
            <Form>
              <FormGroup className='flex f-column'>
                <Label for='productName'>Name: </Label>
                <Input
                  name='productName'
                  invalid={this.state.errors}
                  value={this.state.productName}
                  onChange={this.onInputChange}
                />
              </FormGroup>
              <FormGroup className='flex f-column'>
                <Label for='productType'>Type: </Label>
                <Input
                  type='select'
                  name='productType'
                  id='productType'
                  value={this.state.productType}
                  onChange={this.onInputChange}
                >
                  <option value='drink'>Drink</option>
                  <option value='extra'>Extra</option>
                  <option value='snack'>Snack</option>
                </Input>
              </FormGroup>
              <FormGroup className='flex f-column'>
                <Label for='product_name'>Choose a file: </Label>
                <Input className='file' type='file' id='file' multiple onChange={this.handleChooseImage} />
              </FormGroup>
              <div className='flex a-items-center j-c-center'>
                <Button size={'lg'} onClick={this.handleSubmit}>
                  Save
                </Button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  fetching: state.products.fetching,
  products: state.products.items,
})

const mapDispatchToProps = (dispatch) => ({
  createProduct: (data) => dispatch(productCreateRequest(data)),
  updateProduct: (product) => dispatch(productUpdateRequest(product)),
  deleteProduct: (data) => dispatch(productDeleteRequest(data)),
  getProducts: (params) => dispatch(productsGetRequest(params)),
})

ProductsContainer.propTypes = {
  createProduct: PropTypes.func,
  deleteProduct: PropTypes.func,
  getProducts: PropTypes.func,
  updateProduct: PropTypes.func,
  products: PropTypes.any,
  fetching: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsContainer)
