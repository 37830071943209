import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { orderBy } from 'lodash'
import { isMobile } from 'react-device-detect'

import { EmptyNotification, ResultsFor } from '../'
import { getBaristaStatistics } from '../../../lib/statisticHelper'
import { Text, Dropdown, Loader } from '../../UIkit'
import { Cat, Sort } from '../../UIkit/svgs'
import { Table, TableHead, TableBody, TableHeader, TableRow, TableCell } from '../Table'

import './styles.scss'

const BaristaEfficiency = ({ baristaEfficiency, loading }) => {
  const { t } = useTranslation()

  const [baristaStatistics, setBaristaStatistics] = useState([])

  const [activeSort, setActiveSort] = useState({
    field: 'totalOrders',
    order: 'asc',
  })

  const defaultValues = Array(5)
    .fill(null)
    .map((_, index) => ({
      barista: {
        fullName: `${t('dashboard:barista')} #${index + 1}`,
      },
      totalOrders: 0,
      finishedOrders: 0,
      canceledOrders: 0,
      revenue: 0,
    }))

  useEffect(() => {
    const data = orderBy(getBaristaStatistics(baristaEfficiency), activeSort.field, activeSort.order)
    setBaristaStatistics(!baristaEfficiency.length ? defaultValues : data)
  }, [baristaEfficiency, activeSort])

  const headers = [
    t('dashboard:barista'),
    t('dashboard:totalOrders'),
    t('dashboard:finished'),
    t('dashboard:canceled'),
    t('dashboard:revenueUAH'),
  ]

  const fieldsOptions = [
    { value: 'totalOrders', label: t('dashboard:totalOrders') },
    { value: 'finishedOrders', label: t('dashboard:finished') },
    { value: 'canceledOrders', label: t('dashboard:canceled') },
    { value: 'revenue', label: t('dashboard:revenue') },
  ]

  const orderOptions = [
    { value: 'asc', label: t('dashboard:ascending') },
    { value: 'desc', label: t('dashboard:descending') },
  ]

  const handleChangeFieldSort = (param) => {
    setActiveSort((prev) => ({ ...prev, field: param }))
  }

  const handleChangeOrderSort = (param) => {
    setActiveSort((prev) => ({ ...prev, order: param }))
  }

  return (
    <div className='barista-efficiency'>
      <div className='barista-efficiency__header'>
        <Text variant='subheading-bold'>{t('dashboard:baristaEfficiency')}</Text>

        <div className='barista-efficiency__header__sorts'>
          {!isMobile && <Text variant='body-tertiary1'>{t('dashboard:sortBy')}</Text>}
          <Dropdown list={fieldsOptions} value={activeSort.field} onChange={handleChangeFieldSort} />
          <Dropdown iconHeader={isMobile && <Sort/>} value={activeSort.order} list={orderOptions} onChange={handleChangeOrderSort} />
        </div>
      </div>

      <div className='barista-efficiency__content'>
        <ResultsFor />

        {!baristaEfficiency.length && !loading && <EmptyNotification subject='baristas' />}

        {loading && <Loader size={50} inBlock />}

        {!loading && (
          <div className='barista-efficiency__table'>
            <Table>
              <TableHead>
                <TableRow>
                  {headers.map((header, i) => (
                    <TableHeader key={i}>
                      <Text variant='body-main2'>{header}</Text>
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {baristaStatistics.map((statistic, i) => (
                  <TableRow key={i}>
                    <TableCell flex>
                      {statistic.vendor?.imageSmUrl && (
                        <img style={{ height: '32px', width: '32px' }} src={statistic.barista?.avatarUrl} />
                      )}

                      {!statistic.vendor?.imageSmUrl && (
                        <div className='vendor-comparison__default-pic'>
                          <Cat width={20} height={20} />
                        </div>
                      )}
                      <Text variant='body-main1'>
                        {statistic.barista?.fullName || 'Not Found'}
                      </Text>
                    </TableCell>

                    <TableCell>
                      <Text variant='body-main1'>{statistic.totalOrders}</Text>
                    </TableCell>

                    <TableCell>
                      <Text variant='body-main1'>{statistic.finishedOrders}</Text>
                    </TableCell>

                    <TableCell>
                      <Text variant='body-main1'>{statistic.canceledOrders}</Text>
                    </TableCell>

                    <TableCell>
                      <Text variant='body-main1'>{statistic.revenue.toLocaleString()}</Text>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      </div>
    </div>
  )
}
BaristaEfficiency.propTypes = {
  baristaEfficiency: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default BaristaEfficiency
