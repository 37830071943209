import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Calendar, colors, Text } from '../UIkit'
import DateRangeDropdown from '../UIkit/Calendar/DateRangeDropdown'

const ReceiptsFilters = ({
  dateFilter,
  dateRangesList,
  handleChangeDate,
  handleResetFilters,
  handleApplyFilters,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <div className='receipts-filters__content'>
        <div className='receipts-filters-scrollable'>
          <div className='receipts-filters__block'>
            <Text shouldBeUppercase variant='body-tertiary1' color={colors.gray[500]}>
              {t('withdrawFunds:filters:timePeriod')}
            </Text>
            <DateRangeDropdown value={dateFilter} list={dateRangesList} onChange={handleChangeDate} />
            <Calendar className={'with-shadow'} date={dateFilter} onChange={handleChangeDate} />
          </div>
        </div>
      </div>
      <div className='receipts-filters__buttons'>
        <Button variant='secondary' onClick={handleResetFilters}>
          {t('btn:reset')}
        </Button>
        <Button variant='primary' onClick={handleApplyFilters}>
          {t('btn:apply')}
        </Button>
      </div>
    </>
  )
}

export default ReceiptsFilters
