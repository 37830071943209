import React from 'react'
import { TableCell, Text } from '../components/UIkit'

export const renderTableCellWithText = (content, textProps = {}) => (
  <TableCell>
    <Text variant='body-main1' {...textProps}>
      {content}
    </Text>
  </TableCell>
)
