import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { orderGetRequest } from '../../store/orders/actions'
import OrderInfoModal from '../../components/Orders/Modal/OrderInfoModal'

const OrderContainer = ({ id, isShown, closeModal }, props) => {
  const { item: order, itemFetching: fetching } = useSelector((state) => state.orders)

  const networkId = props.networkId || localStorage.getItem('networkId')

  const dispatch = useDispatch()

  const getOrder = () => dispatch(orderGetRequest({ networkId, id }))

  useEffect(() => {
    getOrder()
  }, [])

  return <OrderInfoModal order={order} fetching={fetching} isShown={isShown} closeModal={closeModal} />
}

export default OrderContainer
