import React, { useState } from 'react'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Validator } from '../../../../lib'
import { Button, colors, Input, Text } from '../../../UIkit'
import { Password } from '../../../UIkit/svgs'

const ForgotPasswordForm = ({ email, handleChangeEmail, sendEmail, handleCancel, toNextStep }) => {
  const { t } = useTranslation()

  const [formErrors, setFormErrors] = useState({
    email: '',
  })

  const handleValidate = () =>
    new Promise((resolve, reject) => {
      const errors = Validator.validate([['email', Validator.concepts.emailRegex, [email]]])

      Validator.clear()

      isEmpty(errors) ? resolve() : reject(errors)
    })

  const handleSubmit = (e) => {
    e.preventDefault()

    handleValidate()
      .then(() => {
        sendEmail(email, toNextStep)
      })
      .catch((errors) => setFormErrors(errors))
  }

  return (
    <form className='auth-page__form' onSubmit={handleSubmit}>
      <div className='auth-page__form__headline'>
        <div className='auth-page__form__headline-icon'>
          <Password />
        </div>
        <Text variant='subheading'>{t('forgotPassword:title')}</Text>
      </div>
      <Text variant={'body-secondary1'} color={colors.gray[500]}>
        {t('forgotPassword:description')}
      </Text>
      <div className='auth-page__form__inputs'>
        <Input
          id='email'
          name='email'
          placeholder={t('input:email')}
          value={email}
          onChange={handleChangeEmail}
          error={formErrors.email}
          autoFocus={true}
        />
      </div>
      <div className='auth-page__form__buttons'>
        <Button type='submit' disabled={email === ''}>
          {t('btn:send')}
        </Button>
        <Button variant={'secondary'} onClick={handleCancel}>
          {t('btn:cancel')}
        </Button>
      </div>
    </form>
  )
}

export default ForgotPasswordForm
