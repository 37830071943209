import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { findBoardSupplement, findCategory } from '../../../../lib/store'
import { Modal, Text, Input, Button } from '../../../UIkit'
import Validator from '../../../../lib/validator'
import './styles.scss'

const MenuSupplementCreateUpdateComponent = ({
  isShown,
  closeModal,
  vendorFilter,
  supplementId,
  categoryId,
  createSupplement,
  updateSupplement,
}) => {
  const { t } = useTranslation()

  const supplement = findBoardSupplement(supplementId)
  const category = supplement ? findCategory(supplement.productCategoryId) : findCategory(categoryId)

  if (!category) return closeModal()

  const [form, setForm] = useState({
    categoryId: category.id,
    name: '',
    price: '',
  })
  const [formErrors, setFormErrors] = useState({})

  useEffect(() => {
    if (supplement) {
      setForm({
        categoryId: supplement.productCategoryId,
        name: supplement.name,
        price: supplement.price,
      })
    } else {
      setForm((prev) => ({ ...prev }))
    }
  }, [supplement, vendorFilter])

  const handleValidate = () => {
    const data = [
      ['name', Validator.concepts.isFilled, [form.name]],
      ['price', Validator.concepts.isFilledShort, [form.price]],
    ]

    const errors = Validator.validate(data)
    Validator.clear()

    return errors
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const data = {
      networkSupplement: {
        productCategoryId: form.categoryId,
        name: form.name,
        price: form.price,
      },
    }

    const errors = handleValidate()
    const hasErrors = Object.keys(errors).length > 0
    if (hasErrors) return setFormErrors(errors)

    if (supplement) {
      updateSupplement(supplement.id, data)
    } else {
      createSupplement(data)
    }
    closeModal()
  }

  const onUpdateField = ({ target }) => setForm((prev) => ({ ...prev, [target.name]: target.value }))

  return (
    <Modal
      show={isShown}
      handleClose={closeModal}
      title={`${supplement ? `${t('actions:edit')} ${supplement.name}` : t('menu:addSupplement')}`}
    >
      <div className='menuProducts__createSupplement'>
        <div className='menuProducts__createSupplement_wrapper'>
          <form id='create-supplement-form' className='menuProducts__createSupplement_form' onSubmit={handleSubmit}>
            <div className='menuProducts__createSupplement_name'>
              <Input
                id='name'
                name='name'
                placeholder={t('input:supplementName')}
                value={form.name}
                onChange={onUpdateField}
                error={formErrors.name}
              />
            </div>
            <div className='menuProducts__createSupplement_price'>
              <Input
                id='price'
                name='price'
                placeholder={t('input:price')}
                type='number'
                value={form.price}
                onChange={onUpdateField}
                error={formErrors.price}
              />
              <Text className='menuProducts__createSupplement_unit' variant='body-secondary1'>
                {t('menu:uah')}
              </Text>
            </div>
            <div className='menuProducts__createSupplement_actions'>
              <Button type='submit' variant='primary' size='large'>
                {t('btn:save')}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  )
}

export default MenuSupplementCreateUpdateComponent
