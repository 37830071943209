import React from 'react'
import { isMobile } from 'react-device-detect'

import './styles.scss'

export const Board = ({ children }) => {
  return (
    <div className='customBoard'>
      <div className='customBoard__scroll'>
        <div className='customBoard__columns'>{children}</div>
      </div>
    </div>
  )
}

export const BoardColumn = ({ renderHeader, children, renderFooter }) => {
  return (
    <div className='customBoard__column'>
      <div className='customBoard__columnHeader'>{renderHeader()}</div>
      <div className='customBoard__columnCards'>{children}</div>
      {!isMobile && <div className='customBoard__columnFooter'>{renderFooter()}</div>}
    </div>
  )
}

export const BoardCard = ({ children }) => {
  return <div className='customBoard__card'>{children}</div>
}

export const BoardActions = ({ children, elemRef }) => {
  return (
    <div ref={elemRef} className='customBoard__actions with-input-shadow'>
      {children}
    </div>
  )
}
