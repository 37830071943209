import React from 'react'
import { Text } from '../UIkit'

const OrderStatus = ({ className, textColor, statusIcon, text }) => {
  return (
    <div className={className}>
      {statusIcon}
      <Text variant='body-tertiary2' color={textColor}>
        {text}
      </Text>
    </div>
  )
}

export default React.memo(OrderStatus)
