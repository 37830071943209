import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Chart from 'chart.js/auto'

import { colors } from '../../UIkit'

const EmptyDoughnutChart = () => {
  const chartRef = useRef()

  const { t, i18n } = useTranslation()

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d')

    const chartData = {
      labels: [
        t('dashboard:drinks'),
        t('dashboard:desserts'),
        t('dashboard:food'),
        t('dashboard:goods'),
        t('dashboard:supplements'),
      ],
      datasets: [
        {
          data: [1, 1, 1, 1, 1, 90],
          backgroundColor: [
            colors.secondary[400],
            colors.primary[500],
            colors.base.green,
            colors.base.violet,
            colors.base.yellow,
            colors.gray[50],
          ],
        },
      ],
    }

    const renderPrice = (chart) => {
      const {
        ctx,
        chartArea: { top, width, height },
      } = chart

      return (ctx.font = 'bold 14px Montserrat'), ctx.fillText('₴ 0', width / 2, (height - 20) / 2 + top)
    }

    const hoverLabel = {
      id: 'hoverLabel',
      afterDraw(chart) {
        const {
          ctx,
          chartArea: { top, width, height },
        } = chart
        ctx.save()
        if (chart._active.length > 0) {
          const color =
            chart.config.data.datasets[chart._active[0].datasetIndex].backgroundColor[chart._active[0].index]

          ctx.font = 'normal 13px Montserrat'
          ctx.textAlign = 'center'
          ctx.fillText('(0 %)', width / 2, (height + 20) / 2 + top)

          renderPrice(chart)

          ctx.beginPath()
          ctx.moveTo((width - 80) / 2, (height - 40) / 2 + top)
          ctx.lineTo((width - 80) / 2, (height + 40) / 2 + top)
          ctx.lineWidth = 5
          ctx.strokeStyle = color
          ctx.stroke()
          ctx.restore()
        }
        ctx.restore()
      },
    }

    new Chart(ctx, {
      type: 'doughnut',
      data: chartData,
      spacing: 2,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          customCanvasBackgroundColor: {
            color: colors.base.white,
          },

          tooltip: {
            enabled: false,
          },

          legend: {
            display: true,
            position: 'bottom',
            labels: {
              boxWidth: 12,
              usePointStyle: true,
              pointStyle: 'circle',
              padding: 32,
            },
          },
        },
      },
      plugins: [hoverLabel],
    })
  }, [i18n.language])

  Chart.defaults.font.size = 13
  Chart.defaults.color = colors.gray[500]
  Chart.defaults.font.family = 'Montserrat'
  Chart.defaults.font.weight = 500

  return <canvas id='myChart' ref={chartRef} width='100%'></canvas>
}

EmptyDoughnutChart.propTypes = {
  labels: PropTypes.array,
  data: PropTypes.array,
}

export default EmptyDoughnutChart
