export const WITHDRAWALS_GET_REQUEST = 'WITHDRAWALS_GET_REQUEST'
export const WITHDRAWALS_GET_SUCCESS = 'WITHDRAWALS_GET_SUCCESS'
export const WITHDRAWALS_GET_FAILURE = 'WITHDRAWALS_GET_FAILURE'

export const WITHDRAWAL_GET_REQUEST = 'WITHDRAWAL_GET_REQUEST'
export const WITHDRAWAL_GET_SUCCESS = 'WITHDRAWAL_GET_SUCCESS'
export const WITHDRAWAL_GET_FAILURE = 'WITHDRAWAL_GET_FAILURE'

export const WITHDRAWAL_CREATE_REQUEST = 'WITHDRAWAL_CREATE_REQUEST'
export const WITHDRAWAL_CREATE_SUCCESS = 'WITHDRAWAL_CREATE_SUCCESS'
export const WITHDRAWAL_CREATE_FAILURE = 'WITHDRAWAL_CREATE_FAILURE'

export const WITHDRAWALS_CLEAR = 'WITHDRAWALS_CLEAR'

export const withdrawalsGetRequest = (payload) => ({
  type: WITHDRAWALS_GET_REQUEST,
  payload
})

export const withdrawalsGetSuccess = (payload) => ({
  type: WITHDRAWALS_GET_SUCCESS,
  payload
})

export const withdrawalsGetFailure = (error) => ({
  type: WITHDRAWALS_GET_FAILURE,
  error
})

export const withdrawalGetRequest = (payload) => ({
  type: WITHDRAWAL_GET_REQUEST,
  payload
})

export const withdrawalGetSuccess = (payload) => ({
  type: WITHDRAWAL_GET_SUCCESS,
  payload
})

export const withdrawalGetFailure = (error) => ({
  type: WITHDRAWAL_GET_FAILURE,
  error
})

export const withdrawalCreateRequest = (payload) => ({
  type: WITHDRAWAL_CREATE_REQUEST,
  payload
})

export const withdrawalCreateSuccess = (payload) => ({
  type: WITHDRAWAL_CREATE_SUCCESS,
  payload
})

export const withdrawalCreateFailure = (error) => ({
  type: WITHDRAWAL_CREATE_FAILURE,
  error
})

export const withdrawalsClear = () => ({
  type: WITHDRAWALS_CLEAR
})
