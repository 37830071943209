// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/landing/IntroBusiness/money.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@media screen and (max-width: 1650px){#how-it-works .section-wrapper .step{max-width:252px}}@media screen and (max-width: 1280px){#how-it-works .section-wrapper .step{max-width:260px;margin:15px 0px}#how-it-works .section-wrapper .step h6{font-size:24px;line-height:30px}#how-it-works .section-wrapper .step p{font-size:14px;line-height:18px}}@media only screen and (max-width: 768px){#how-it-works .section-wrapper{flex-wrap:wrap;justify-content:center}#how-it-works .section-wrapper .step{max-width:304px;margin-bottom:60px}}@media only screen and (max-width: 480px){#how-it-works{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:top 15px center;background-size:95%;background-repeat:no-repeat}#how-it-works .section-wrapper{flex-wrap:wrap;justify-content:space-between}#how-it-works .section-wrapper .step{max-width:156px;margin-bottom:35px}#how-it-works .section-wrapper .step .number{font-size:18px;line-height:22px;padding:6px;height:34px;width:34px}#how-it-works .section-wrapper .step h6{font-size:18px;line-height:22px}}@media screen and (max-width: 360px){#how-it-works .section-wrapper{justify-content:center}}", ""]);
// Exports
module.exports = exports;
