export const LIQPAY_ACCOUNT_CREATE_REQUEST = 'LIQPAY_ACCOUNT_CREATE_REQUEST'
export const LIQPAY_ACCOUNT_CREATE_SUCCESS = 'LIQPAY_ACCOUNT_CREATE_SUCCESS'
export const LIQPAY_ACCOUNT_CREATE_FAILURE = 'LIQPAY_ACCOUNT_CREATE_FAILURE'

export const LIQPAY_ACTIVATION_CREATE_REQUEST = 'LIQPAY_ACTIVATION_CREATE_REQUEST'
export const LIQPAY_ACTIVATION_CREATE_SUCCESS = 'LIQPAY_ACTIVATION_CREATE_SUCCESS'
export const LIQPAY_ACTIVATION_CREATE_FAILURE = 'LIQPAY_ACTIVATION_CREATE_FAILURE'

export const liqpayAccountCreateRequest = (payload, id, callback) => ({
  type: LIQPAY_ACCOUNT_CREATE_REQUEST,
  payload,
  id,
  callback
})

export const liqpayAccountCreateSuccess = (payload) => ({
  type: LIQPAY_ACCOUNT_CREATE_SUCCESS,
  payload
})

export const liqpayAccountCreateFailure = (error) => ({
  type: LIQPAY_ACCOUNT_CREATE_FAILURE,
  error
})


export const liqpayActivationCreateRequest = (payload, id, callback) => ({
  type: LIQPAY_ACTIVATION_CREATE_REQUEST,
  payload,
  id,
  callback
})

export const liqpayActivationCreateSuccess = (payload) => ({
  type: LIQPAY_ACTIVATION_CREATE_SUCCESS,
  payload
})

export const liqpayActivationCreateFailure = (error) => ({
  type: LIQPAY_ACTIVATION_CREATE_FAILURE,
  error
})
