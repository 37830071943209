import { capitalize } from './'

export const createWorkingHours = (workTimes) => {
  const workingHours = []

  const sortedWorkTimes = workTimes.sort((a, b) => a.id - b.id).map((object) => ({
    day: capitalize(object.weekday.slice(0, 3)),
    time: object.opened ? `${object.from} - ${object.to}` : 'Closed'
  }))
  
  const fillWorkingHour = () => (
    sortedWorkTimes.map((object) => {
      const lastElement = workingHours[workingHours.length - 1]

      if (lastElement?.time == object.time) {
        const combineDays = lastElement.day.slice(0, 3) + ` - ${object.day}`
        return lastElement.day = combineDays
      } else {
        return workingHours.push(object)
      }
    })
  )

  fillWorkingHour()

  return workingHours
}

export default createWorkingHours
