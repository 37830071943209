import React from 'react'

import { Table, TableBody, TableHead, TableHeader, Text } from '../../../../../UIkit'
import OrdersElement from '../../../../../Orders/List/OrdersElement'

const tableHeaders = ['Order ID', 'Date', 'Vendor', 'Barista', 'Payment', 'Status']

const OrdersTable = ({ orders }) => {
  return (
    <div className='overflow-auto w-100'>
      <Table>
        <TableHead>
          {tableHeaders.map(title => (
            <TableHeader key={title}>
              <Text variant='subheading'>{title}</Text>
            </TableHeader>
          ))}
        </TableHead>
        <TableBody>
          {orders.map((order, index) => (
            <OrdersElement key={index} order={order} headers={tableHeaders} />
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default OrdersTable
