import { createContext, useContext } from 'react'

const defaultValue = {
  rowsPerPage: 0,
  setCityFilter: () => {},
}

export const PerPageContext = createContext(defaultValue)

export const useRowsPerPage = () => {
  return useContext(PerPageContext)
}
