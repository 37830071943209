import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, colors, Text } from '../../../UIkit'
import { Check } from '../../../UIkit/svgs'

const FinishForm = ({ isBarista, toSignIn }) => {
  const { t } = useTranslation()

  return (
    <div className='auth-page__form'>
      <div className='auth-page__form__headline'>
        <div className='auth-page__form__headline-icon-done'>
          <Check color={colors.secondary[500]}/>
        </div>
        <Text variant='subheading'>{t('passwordReset:title')}</Text>
      </div>
      <div className='auth-page__form__info'>
        <Text variant={'body-main1'}>{t('passwordReset:description1')}</Text>
        <Text variant={'body-main1'}>
          {isBarista ? t('passwordReset:descriptionBarista') : t('passwordReset:description2')}
        </Text>
      </div>
      {!isBarista && <div className='auth-page__form__buttons'>
        <Button onClick={toSignIn}>{t('btn:continue')}</Button>
      </div>}
    </div>
  )
}

export default FinishForm
