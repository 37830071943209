import React from 'react'
import { colors } from '../components/UIkit'
import { Cart, Chat, MoneyWithdraw } from '../components/UIkit/svgs'

const renderNotificationIcon = (notification) => {
  switch (notification?.key) {
    case 'feedback.create':
      return (
        <div className='notifications__item-icon' style={{ backgroundColor: colors.secondary[100] }}>
          <Chat width={20} height={20} color={colors.secondary[500]} />
        </div>
      )
    case 'order.finished':
      return (
        <div className='notifications__item-icon' style={{ backgroundColor: colors.secondary[100] }}>
          <Cart width={20} height={20} color={colors.secondary[500]} />
        </div>
      )
    case 'order.canceled':
      return (
        <div className='notifications__item-icon' style={{ backgroundColor: colors.primary[100] }}>
          <Cart width={20} height={20} color={colors.primary[400]} />
        </div>
      )

    case 'withdrawal.success':
      return (
        <div className='notifications__item-icon' style={{ backgroundColor: colors.secondary[100] }}>
          <MoneyWithdraw width={20} height={20} color={colors.secondary[500]} />
        </div>
      )
    case 'withdrawal.failed':
      return (
        <div className='notifications__item-icon' style={{ backgroundColor: colors.primary[100] }}>
          <MoneyWithdraw width={20} height={20} color={colors.primary[400]} />
        </div>
      )
    default:
      return <div className='notifications__item-icon' />
  }
}

export default renderNotificationIcon
