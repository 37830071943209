import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, IconButton, RightModal } from '../UIkit'
import { Filter, Reload } from '../UIkit/svgs'
import { isMobile } from 'react-device-detect'
import ReceiptsFiltersContainer from '../../containers/Receipts/ReceiptsFiltersContainer'

const ReceiptsOptions = ({ filters, handleChangeFilters, handleResetFilters }) => {
  const { t } = useTranslation()
  const [showFiltersModal, setShowFiltersModal] = useState(false)

  const handleCloseFilters = () => {
    setShowFiltersModal(false)
  }

  return (
    <>
      <div className='receipts__options'>
        <IconButton onClick={() => setShowFiltersModal(true)}>
          <Filter />
        </IconButton>
        <Button variant='tertiary' size='large' onClick={handleResetFilters}>
          <Reload style={{ transform: 'scale(-1, 1)' }} />
          {!isMobile && t('btn:resetAll')}
        </Button>
      </div>

      <RightModal title='Filters' show={showFiltersModal} handleClose={handleCloseFilters}>
        <ReceiptsFiltersContainer
          filters={filters}
          handleChangeFilters={handleChangeFilters}
          handleResetFilters={handleResetFilters}
          handleCloseFilters={handleCloseFilters}
        />
      </RightModal>
    </>
  )
}

export default ReceiptsOptions
