import {
  BANK_ACCOUNT_CREATE_REQUEST,
  BANK_ACCOUNT_CREATE_SUCCESS,
  BANK_ACCOUNT_CREATE_FAILURE,
  
  BANK_ACCOUNTS_GET_REQUEST,
  BANK_ACCOUNTS_GET_SUCCESS,
  BANK_ACCOUNTS_GET_FAILURE,

  BANK_ACCOUNT_DELETE_REQUEST,
  BANK_ACCOUNT_DELETE_SUCCESS,
  BANK_ACCOUNT_DELETE_FAILURE,

  BANK_ACCOUNT_UPDATE_REQUEST,
  BANK_ACCOUNT_UPDATE_SUCCESS,
  BANK_ACCOUNT_UPDATE_FAILURE,
} from './actions'

const initialState = {
  items: [],
  fetching: false,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case BANK_ACCOUNT_CREATE_REQUEST:
      return { ...state, fetching: true }
    case BANK_ACCOUNT_CREATE_SUCCESS:
      return {
        ...state,
        items: payload,
        fetching: false,
      }
    case BANK_ACCOUNT_CREATE_FAILURE:
      return { ...state, fetching: false }

    case BANK_ACCOUNTS_GET_REQUEST:
      return { ...state, fetching: true }
    case BANK_ACCOUNTS_GET_SUCCESS:
      return { ...state, items: payload, fetching: false }
    case BANK_ACCOUNTS_GET_FAILURE:
      return { ...state, fetching: false }

    case BANK_ACCOUNT_DELETE_REQUEST:
      return { ...state, fetching: true }
    case BANK_ACCOUNT_DELETE_SUCCESS:
      return {
        ...state,
        items: state.items.filter((account) => account.id !== payload),
        fetching: false,
      }
    case BANK_ACCOUNT_DELETE_FAILURE:
      return { ...state, fetching: false }

    case BANK_ACCOUNT_UPDATE_REQUEST:
      return { ...state, fetching: true }
    case BANK_ACCOUNT_UPDATE_SUCCESS:
      return { ...state, items: payload, fetching: false }
    case BANK_ACCOUNT_UPDATE_FAILURE:
      return { ...state, fetching: false }

    default:
      return state
  }
}
