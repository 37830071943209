export const STAFF_NETWORKS_FETCH_REQUEST = 'STAFF_NETWORKS_FETCH_REQUEST'
export const STAFF_NETWORKS_FETCH_SUCCESS = 'STAFF_NETWORKS_FETCH_SUCCESS'
export const STAFF_NETWORKS_FETCH_FAILURE = 'STAFF_NETWORKS_FETCH_FAILURE'
export const STAFF_NETWORKS_GET_REQUEST = 'STAFF_NETWORKS_GET_REQUEST'
export const STAFF_NETWORKS_GET_SUCCESS = 'STAFF_NETWORKS_GET_SUCCESS'
export const STAFF_NETWORKS_GET_FAILURE = 'STAFF_NETWORKS_GET_FAILURE'

export const staffNetworksFetchRequest = (payload) => ({
  type: STAFF_NETWORKS_FETCH_REQUEST,
  payload
})

export const staffNetworksFetchSuccess = (payload) => ({
  type: STAFF_NETWORKS_FETCH_SUCCESS,
  payload,
})

export const staffNetworksFetchFailure = (error) => ({
  type: STAFF_NETWORKS_FETCH_FAILURE,
  error,
})

export const staffNetworksGetRequest = (id) => ({
  type: STAFF_NETWORKS_GET_REQUEST,
  id
})

export const staffNetworksGetSuccess = (payload) => ({
  type: STAFF_NETWORKS_GET_SUCCESS,
  payload,
})

export const staffNetworksGetFailure = (error) => ({
  type: STAFF_NETWORKS_GET_FAILURE,
  error,
})
