import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { sumBy } from 'lodash'

import { getRevenueByDate } from '../../../lib/statisticHelper'
import { EmptyNotification, LineChart, ResultsFor } from '../'
import { Text, Loader, colors } from '../../UIkit'

import './styles.scss'

const CashTurnover = ({ cashTurnover, loading }) => {
  const { t } = useTranslation()

  const revenueByDate = getRevenueByDate(cashTurnover)

  const total = sumBy(revenueByDate, 'revenue').toLocaleString()

  const labels = revenueByDate.map((obj) => moment(obj.date.split(' ')[0]).format('DD.MM'))
  const data = revenueByDate.map((obj) => obj.revenue)

  return (
    <div className='turnover'>
      <div className='turnover__header'>
        <Text variant='subheading-bold'>{t('dashboard:cashTurnover')}</Text>

        <div className='turnover__header__revenue'>
          <Text variant='body-tertiary2' color={colors.gray[500]}>
            {t('dashboard:total')}
          </Text>
          <Text variant='h3-medium'>₴ {total}</Text>
        </div>
      </div>

      <div className='turnover__content'>
        <ResultsFor />

        <div className='turnover__chart'>
          {!cashTurnover.length && !loading && <EmptyNotification subject='vendor' />}

          {loading && <Loader size={50} inBlock />}

          {!loading && <LineChart labels={labels} data={data} loading={loading} />}
        </div>
      </div>
    </div>
  )
}

CashTurnover.propTypes = {
  cashTurnover: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default CashTurnover
