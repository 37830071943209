import React from 'react'
import { useTranslation } from 'react-i18next'

import { NETWORK_TABS } from '../../constants'
import { Text } from '../UIkit'

const Tabs = ({ tab, handleToggle }) => {
  const { t } = useTranslation()

  const isDetailsTab = tab.includes(NETWORK_TABS.details)

  return (
    <div className='tabs'>
      <button className={`buttonTab ${isDetailsTab && 'activeTab'}`} onClick={handleToggle(NETWORK_TABS.details)}>
        <Text variant='button-large'>{t('network:details')}</Text>
      </button>
      <button className={`buttonTab ${!isDetailsTab && 'activeTab'}`} onClick={handleToggle(NETWORK_TABS.vendors)}>
        <Text variant='button-large'>{t('network:vendors')}</Text>
      </button>
    </div>
  )
}

export default Tabs
