import React from 'react'

const VendorHeader = ({ vendor }) => {
  return (
    <div className='page-content-header'>
      <div style={{ backgroundImage: `url(${vendor.imageUrl})` }} className='edit-employee__avatar' />
    </div>
  )
}

export default VendorHeader
