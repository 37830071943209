import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'

import { typeVerifyRequest } from '../../store/types/actions'
import { useFilters } from '../../contexts/filtersContext'
import {
  categoriesFetchRequest,
  categoryAddRequest,
  categoryDeleteRequest,
  categoriesClear,
} from '../../store/categories/actions'
import {
  boardProductsFetchRequest,
  boardProductCreateRequest,
  boardProductUpdateRequest,
  boardProductDeleteRequest,
  productsClear,
} from '../../store/products/actions'
import {
  supplementsFetchRequest,
  supplementCreateRequest,
  supplementUpdateRequest,
  supplementDeleteRequest,
  supplementsClear,
} from '../../store/supplements/actions'

import ProductsComponent from '../../components/Menu/Products/ProductsComponent'

const ProductsContainer = () => {
  const dispatch = useDispatch()
  const history = useNavigate()
  const networkId = localStorage.getItem('networkId')
  const { vendorFilter } = useFilters()

  // TYPE

  const { type } = useParams()

  useEffect(() => {
    dispatch(typeVerifyRequest(history, { networkId, id: type }))
  }, [type])

  // CATRGORIES

  const { items: categories, fetching: categoriesFetching } = useSelector((state) => state.categories)

  const clearCategories = () => dispatch(categoriesClear())

  const fetchCategories = (type) => {
    dispatch(categoriesFetchRequest({ networkId, params: { typeSlug: type } }, history))
  }

  const addCategory = (id) => {
    dispatch(categoryAddRequest({ networkId, id }))
  }

  const removeCategory = (id) => {
    dispatch(categoryDeleteRequest({ networkId, id }))
  }

  // PRODUCTS

  const { clusters: productsClusters } = useSelector((state) => state.products)

  const clearProducts = () => dispatch(productsClear())

  const fetchProducts = (categoryID, page, callback) => {
    dispatch(
      boardProductsFetchRequest(
        { networkId, params: { vendorId: vendorFilter, categoryId: categoryID, page: page } },
        callback
      )
    )
  }

  const createProduct = (params) => {
    dispatch(boardProductCreateRequest({ networkId, params }))
  }

  const updateProduct = (id, params) => {
    dispatch(boardProductUpdateRequest({ networkId, id, params }))
  }

  const deleteProduct = (id, categoryId) => {
    dispatch(boardProductDeleteRequest({ networkId, id, categoryId }))
  }

  // SUPPLEMENTS

  const { clusters: supplementsClusters } = useSelector((state) => state.supplements)

  const clearSupplements = () => dispatch(supplementsClear())

  const fetchSupplements = (categoryID, page, callback) => {
    dispatch(
      supplementsFetchRequest(
        { networkId, params: { vendorId: vendorFilter, categoryId: categoryID, page: page } },
        callback
      )
    )
  }

  const createSupplement = (params) => {
    dispatch(supplementCreateRequest({ networkId, params }))
  }

  const updateSupplement = (id, params) => {
    dispatch(supplementUpdateRequest({ networkId, id, params }))
  }

  const deleteSupplement = (id, categoryId) => {
    dispatch(supplementDeleteRequest({ networkId, id, categoryId }))
  }

  useEffect(() => {
    fetchCategories(type)
    clearCategories()
    clearProducts()
    clearSupplements()
  }, [vendorFilter])

  return (
    <ProductsComponent
      vendorFilter={vendorFilter}
      categories={categories}
      productsClusters={productsClusters}
      supplementsClusters={supplementsClusters}
      fetchCategories={fetchCategories}
      addCategory={addCategory}
      removeCategory={removeCategory}
      fetchProducts={fetchProducts}
      createProduct={createProduct}
      updateProduct={updateProduct}
      deleteProduct={deleteProduct}
      fetchSupplements={fetchSupplements}
      createSupplement={createSupplement}
      updateSupplement={updateSupplement}
      deleteSupplement={deleteSupplement}
      fetching={categoriesFetching}
    />
  )
}

export default ProductsContainer
