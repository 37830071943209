import React from 'react'

import { Table, TableBody, TableHead, TableHeader, Text } from '../../../../../UIkit'
import BaristaRow from './BaristaRow'

const tableHeaders = [
  'Employee',
  'Email',
  'Phone number'
]

const BaristasTable = ({ baristas }) => {
  return (
    <div className='overflow-auto w-100'>
      <Table>
        <TableHead>
          {tableHeaders.map(title => (
            <TableHeader key={title}>
              <Text variant='subheading'>{title}</Text>
            </TableHeader>
          ))}
        </TableHead>
        <TableBody>
          {baristas.map(barista => <BaristaRow key={barista.id} barista={barista} />)}
        </TableBody>
      </Table>
    </div>
  )
}

export default BaristasTable
