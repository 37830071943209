export const STAFF_VENDOR_ORDERS_FETCH_REQUEST = 'STAFF_VENDOR_ORDERS_FETCH_REQUEST'
export const STAFF_VENDOR_ORDERS_FETCH_SUCCESS = 'STAFF_VENDOR_ORDERS_FETCH_SUCCESS'
export const STAFF_VENDOR_ORDERS_FETCH_FAILURE = 'STAFF_VENDOR_ORDERS_FETCH_FAILURE'

export const staffVendorOrdersFetchRequest = (payload) => ({
  type: STAFF_VENDOR_ORDERS_FETCH_REQUEST,
  payload
})

export const staffVendorOrdersFetchSuccess = (payload) => ({
  type: STAFF_VENDOR_ORDERS_FETCH_SUCCESS,
  payload,
})

export const staffVendorOrdersFetchFailure = (error) => ({
  type: STAFF_VENDOR_ORDERS_FETCH_FAILURE,
  error,
})
