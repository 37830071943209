import React from 'react'
import { useTranslation } from 'react-i18next'

import { findBoardProduct, findBoardSupplement } from '../../../../lib/store'
import { Modal, Button, Text, colors } from '../../../UIkit'
import './styles.scss'

const MenuProductDeleteComponent = ({ isShown, closeModal, productId, supplementId, deleteProduct }) => {
  const { t } = useTranslation()

  const product = findBoardProduct(productId)
  const supplement = findBoardSupplement(supplementId)

  if (!product && !supplement) closeModal()

  const handleDelete = () => {
    if (product) {
      deleteProduct(product.id, product.productCategoryId)
    } else {
      deleteProduct(supplement.id, supplement.productCategoryId)
    }
    closeModal()
  }

  return (
    <Modal show={isShown} handleClose={closeModal} withoutExit>
      <div className='menuCategories__deleteModal'>
        <div className='menuCategories__deleteModal__title'>
          <Text variant='subheading'>{`${t('actions:delete')} ${product?.name || supplement?.name}?`}</Text>
        </div>
        <div className='menuCategories__deleteModal__description'>
          <Text variant='body-secondary1' color={colors.gray[500]}>
            {t('menu:areYouSure')}
          </Text>
        </div>
        <div className='menuCategories__deleteModal__buttons'>
          <Button variant='secondary' onClick={closeModal}>
            {t('btn:cancel')}
          </Button>
          <Button onClick={handleDelete}>{t('actions:delete')}</Button>
        </div>
      </div>
    </Modal>
  )
}

export default MenuProductDeleteComponent
