import {
  ADMIN_UPDATE_REQUEST, ADMIN_UPDATE_SUCCESS, ADMIN_UPDATE_FAILURE,
  ADMIN_DELETE_REQUEST, ADMIN_DELETE_SUCCESS, ADMIN_DELETE_FAILURE
} from './actions'

const initialState = {
  item: {},
  fetching: false,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ADMIN_UPDATE_REQUEST:
      return { ...state, fetching: true }
    case ADMIN_UPDATE_SUCCESS:
      return { ...state, item: payload, fetching: false }
    case ADMIN_UPDATE_FAILURE:
      return { ...state, fetching: false }

    case ADMIN_DELETE_REQUEST:
      return { ...state, fetching: true }
    case ADMIN_DELETE_SUCCESS:
      return { ...state, fetching: false }
    case ADMIN_DELETE_FAILURE:
      return { ...state, fetching: false }

    default:
      return state
  }
}
