// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/landing/Intro/green-cat-sm.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../assets/images/landing/Intro/red-cat.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../../assets/images/landing/Intro/polygons.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../../../assets/images/landing/Intro/polygons-sm.png");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../../../assets/images/landing/Intro/green-bubble.png");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../../../../assets/images/landing/Intro/red-bubble.png");
var ___CSS_LOADER_URL_IMPORT_6___ = require("../../../../assets/images/landing/Intro/two-cats.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
// Module
exports.push([module.id, "@media screen and (max-width: 1440px){#intro{background-size:23%,18%,70% 90%;background-position:bottom -20px left -45px,bottom 45px right -30px,center;padding-top:50px}}@media only screen and (max-width: 768px){#intro{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");background-size:30%,22%,80% 75%;background-position:bottom -20px left -15px,top right -20px,center;padding-top:164px}#intro .content .title{font-size:34px;line-height:60px}}@media only screen and (max-width: 480px){#intro{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ");background-position:center,center left -15px,top right;background-size:95%,22%,22%;padding-top:68px;min-height:calc(100vh - 250px)}#intro .content{width:335px}#intro .content .title{font-size:24px;line-height:36px;margin-bottom:18px}#intro .content .description{color:#71667f;font-size:16px;line-height:20px;margin-bottom:1em;text-align:center;width:335px}#intro .content .cats{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ");background-size:contain;height:184px;margin-bottom:1.5em;width:100%}#intro .content .join-us-container .two-buttons{zoom:1.05}}", ""]);
// Exports
module.exports = exports;
