import React from 'react'
import PropTypes from 'prop-types'

import { colors } from '../UIkit'
import { Arrow } from '../UIkit/svgs'

const Control = ({ methodsRef, isStartScroll, isEndScroll, enabled = true }) => {
  const handleChange = (action) => () => {
    const { scrollLeft, scrollWidth, clientWidth } = methodsRef.current
    const scrollHeight = scrollWidth - clientWidth
    const scrollPostion = action == 'plus' ? scrollLeft + 280 : scrollLeft - 280

    if (scrollPostion <= scrollHeight && 0 <= scrollPostion) {
      methodsRef.current.scrollLeft = scrollPostion
    }
  }

  return (
    <div className='control__buttons'>
      <Arrow
        className={`${isStartScroll && !enabled && 'control__disable-button'}`}
        color={(isStartScroll || !enabled) ? colors.gray[200] : colors.gray[500]}
        onClick={handleChange('minus')}
      />

      <Arrow
        className={`${isEndScroll || !enabled && 'control__disable-button'}`}
        color={(isEndScroll || !enabled) ? colors.gray[200] : colors.gray[500]}
        onClick={handleChange('plus')}
      />
    </div>
  )
}

Control.propTypes = {
  methodsRef: PropTypes.object.isRequired,
  isStartScroll: PropTypes.bool.isRequired,
  isEndScroll: PropTypes.bool.isRequired
}

export default Control
