import { QUESTION_CREATE_REQUEST, QUESTION_CREATE_SUCCESS, QUESTION_CREATE_FAILURE } from './actions'

const initialState = {
  fetching: false,
}

export default (state = initialState, { type }) => {
  switch (type) {
    case QUESTION_CREATE_REQUEST:
      return { ...state, fetching: true }
    case QUESTION_CREATE_SUCCESS:
      return { ...state, fetching: false }
    case QUESTION_CREATE_FAILURE:
      return { ...state, fetching: false }
    default:
      return state
  }
}
