import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Button, Checkbox, colors, Text } from '../UIkit'
import WithdrawalMethodDelete from './WithdrawalMethodDeleteModal'

const WithdrawalMethodInfo = ({ method, handleChangeMethod, handleDeleteMethod, handleUpdateMethod }) => {
  const { t } = useTranslation()

  const [isVisibleDelete, setIsVisibleDelete] = useState(false)
  const [canUpdate, setCanUpdate] = useState(false)

  useEffect(() => {
    setCanUpdate(false)
  }, [method.number])

  const handleVisibleDelete = () => setIsVisibleDelete((prev) => !prev)

  const handleDelete = () => {
    handleDeleteMethod()
    handleVisibleDelete()
  }

  const handleChange = (value) => {
    handleChangeMethod(value)
    setCanUpdate(true)
  }

  return (
    <div className='withdrawal-info'>
      <div>
        <Checkbox
          id='default'
          name='default'
          label={t('settings:withdrawalMethod:setAsDefault')}
          onChange={handleChange}
          checked={method.default}
        />
      </div>

      <div className='withdrawal-info__details'>
        <div>
          <Text variant='body-tertiary1' color={colors.gray[500]}>
            {t('settings:withdrawalMethod:recipient')}
          </Text>

          <Text variant='body-main1'>{method.ownerName}</Text>
        </div>

        <div>
          <Text variant='body-tertiary1' color={colors.gray[500]}>
            {t('input:iban')}
          </Text>

          <Text variant='body-main1'>{method.number}</Text>
        </div>

        <div>
          <Text variant='body-tertiary1' color={colors.gray[500]}>
            {t('input:ipnEdrpou')}
          </Text>

          <Text variant='body-main1'>{method.code}</Text>
        </div>
        <div>
          <Text variant='body-tertiary1' color={colors.gray[500]}>
            {t('input:mfo')}
          </Text>

          <Text variant='body-main1'>{method.mfo}</Text>
        </div>
      </div>

      <div className='withdrawal-info__buttons'>
        <Button variant='secondary' onClick={handleVisibleDelete}>
          {t('actions:delete')}
        </Button>
        <Button onClick={handleUpdateMethod} disabled={!canUpdate}>
          {t('btn:save')}
        </Button>
      </div>

      <WithdrawalMethodDelete isShown={isVisibleDelete} handleDelete={handleDelete} closeModal={handleVisibleDelete} />
    </div>
  )
}

WithdrawalMethodInfo.propTypes = {
  method: PropTypes.object,
  handleChangeMethod: PropTypes.func.isRequired,
  handleDeleteMethod: PropTypes.func.isRequired,
  handleUpdateMethod: PropTypes.func.isRequired,
}

export default WithdrawalMethodInfo
