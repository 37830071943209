import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'

import { colors, Input, Loader, Text } from '..'
import './styles.scss'


const LocationSearchInput = ({ onChange, value, error }) => {
  const { t, i18n } = useTranslation()

  const [address, setAddress] = useState('')

  useEffect(() => {
    setAddress(value)
  }, [])

  const handleChange = (address) => {
    setAddress(address)
  }

  const handleFindObjectName = (result, objectName) =>
    result.address_components.find((address) => address.types[0] == objectName)?.long_name

  const formatAddress = (addressObject) => {
    const route = handleFindObjectName(addressObject, 'route')
    const streetNumber = handleFindObjectName(addressObject, 'street_number')

    return `${route}, ${streetNumber}`
  }

  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        const data = {
          city: handleFindObjectName(results[0], 'locality'),
          address: formatAddress(results[0]),
          latitude: results[0].geometry.location.lat(),
          longitude: results[0].geometry.location.lng(),
        }
        setAddress(address)
        onChange(data)
      })
      .catch((error) => console.error('Error', error))
  }

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={{ language: i18n.language }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className='autocomplete'>
          <Input value={address} error={error} {...getInputProps()} placeholder={t('input:address')} />

          {loading && (
            <div className='autocomplete__loading'>
              <Loader inBlock size={20} color={colors.primary[500]} />
              {t('network:loadingResults')}
            </div>
          )}

          {!!suggestions.length && !loading && (
            <div className='autocomplete__suggestions'>
              {suggestions.map((suggestion, index) => (
                <div
                  key={index}
                  {...getSuggestionItemProps(suggestion)}
                  className={
                    suggestion.active ? 'autocomplete__suggestions__item-active' : 'autocomplete__suggestions__item'
                  }
                >
                  <Text variant='body-main1' color={suggestion.active && colors.primary[500]}>
                    {suggestion.description}
                  </Text>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </PlacesAutocomplete>
  )
}

LocationSearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
}

export default LocationSearchInput
