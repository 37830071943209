import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { NetworkForm, UserForm, ProgressBar } from '.'
import { SIGNUP_STEPS } from '../../constants'

const SignUp = ({
  userData,
  setUserData,
  history,
  checkCredentials,
  createOwner }) => {
  const [step, setStep] = useState('')

  const [searchParams] = useSearchParams()
  const stepParam = searchParams.get('step')

  const handleMoveToNextStep = () => {
    history(`/signup?step=${SIGNUP_STEPS.network}`)
  }

  const handleMoveToPrevStep = () => {
    if (step != SIGNUP_STEPS.user) {
      history(`/signup?step=${SIGNUP_STEPS.user}`)
    }
  }

  useEffect(() => {
    const step = stepParam == SIGNUP_STEPS.network ? SIGNUP_STEPS.network : SIGNUP_STEPS.user
    setStep(step)
  }, [stepParam])

  const renderForm = () => {
    switch (step) {
      case SIGNUP_STEPS.user:
        return (
          <UserForm
            userData={userData}
            setUserData={setUserData}
            checkCredentials={checkCredentials}
            handleMoveToNextStep={handleMoveToNextStep}
          />
        )
      case SIGNUP_STEPS.network:
        return (
          <NetworkForm
            userData={userData}
            createOwner={createOwner}
          />
        )
    }
  }

  return (
    <>
      {renderForm()}
      <ProgressBar step={step} handleMoveToPrevStep={handleMoveToPrevStep} />
    </>
  )
}

export default SignUp
