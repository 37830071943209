import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'

import { Modal, Button, Text, PasswordInput } from '../../UIkit'
import { Validator } from '../../../lib'

const EmployeeChangePassword = ({ isShown, closeModal, employeeId, updateEmployee }) => {
  const { t } = useTranslation()

  const [form, setForm] = useState({
    password: '',
    confirmPassword: ''
  })

  const [formErrors, setFormErrors] = useState({
    password: '',
    confirmPassword: '',
  })

  const handleValidate = () =>
    new Promise((resolve, reject) => {
      const errors = Validator.validate([
        ['password', Validator.concepts.isLongerThan, [6, form.password]],
        ['confirmPassword', Validator.concepts.isMatching, [form.password, form.confirmPassword]],
      ])

      Validator.clear()

      isEmpty(errors) ? resolve() : reject(errors)
    })

  const onUpdateField = (e) => {
    const nextFormState = {
      ...form,
      [e.target.name]: e.target.value,
    }
    setForm(nextFormState)
  }

  const handleUpdate = () => {
    const data = {
      id: employeeId,
      params: {
        networkEmployee: {
          password: form.password,
          passwordConfirmation: form.confirmPassword,
        },
      },
    }

    handleValidate()
      .then(() => {
        updateEmployee(data)
        closeModal()
      })
      .catch((errors) => setFormErrors(errors))
  }

  return (
    <Modal show={isShown} handleClose={closeModal}>
      <div className='edit-employee__change-password'>
        <Text variant='subheading'>{t('actions:changePassword')}</Text>
        <div className='w-100'>
          <PasswordInput
            id='password'
            name='password'
            placeholder={t('input:password')}
            value={form.password}
            onChange={onUpdateField}
            error={formErrors.password}
          />
          <PasswordInput
            id='password_confirmation'
            name='confirmPassword'
            placeholder={t('input:confirmPassword')}
            value={form.confirmPassword}
            onChange={onUpdateField}
            error={formErrors.confirmPassword}
          />
        </div>
      </div>
      <div className='edit-employee__buttons'>
        <Button variant='secondary' onClick={closeModal}>
          {t('btn:cancel')}
        </Button>
        <Button onClick={handleUpdate}>{t('btn:save')}</Button>
      </div>
    </Modal>
  )
}

export default EmployeeChangePassword
