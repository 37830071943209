import React from 'react'
import Text from '../Text'
import { ListBullets } from '../svgs'
import colors from '../colors'
import './styles.scss'

const EmptyPageMessage = ({ text }) => {
  return (
    <div className='empty-page'>
      <div className='empty-page__image'>
        <ListBullets />
      </div>
      <Text variant='h4' color={colors.gray[500]}>
        {text}
      </Text>
    </div>
  )
}

export default EmptyPageMessage
