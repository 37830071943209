import { takeLatest, call, put } from 'redux-saga/effects'
import * as actions from './actions'

import Api from '../../lib/api'
import notifications from '../../lib/notifications'

export default function* watcherSaga() {
  yield takeLatest(actions.WITHDRAWALS_GET_REQUEST, watchResourcesGetRequest),
  yield takeLatest(actions.WITHDRAWAL_GET_REQUEST, watchResourceGetRequest),
  yield takeLatest(actions.WITHDRAWAL_CREATE_REQUEST, watchResourceCreateRequest)
}

function* watchResourcesGetRequest({ payload }) {
  try {
    const response = yield call(Api.Withdrawals.get, payload.networkId, payload.params)

    yield put(actions.withdrawalsGetSuccess(response.data))

  } catch (error) {
    yield put(actions.withdrawalsGetFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}

function* watchResourceGetRequest({ payload }) {
  try {
    const response = yield call(Api.Withdrawal.get, payload.networkId, payload.id)

    yield put(actions.withdrawalGetSuccess(response.data))

  } catch (error) {
    yield put(actions.withdrawalGetFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}

function* watchResourceCreateRequest({ payload }) {
  try {
    const response = yield call(Api.Withdrawals.create, payload.networkId, payload.withdrawal)

    yield put(actions.withdrawalCreateSuccess(response.data))
    notifications.createNotification('create-success')

  } catch (error) {
    yield put(actions.withdrawalCreateFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}
