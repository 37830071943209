const Style = {
  css: {
    position: 'fixed',
    left: 'calc(50% + 100px)',
    top: 'calc(50% - 150px)',
    zIndex: '401',
  },
  color: '#BEB8C6',
  size: 50,
}

export default Style
