import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroller'
import NotificationElement from './NotificationElement'
import { Loader } from '../../UIkit'

const UnopenedList = ({
  pageRef,
  isArchive,
  unopenedItems,
  fetchNotifications,
  notificationChecked,
  handleNavigateToTarget,
  handleUpdateCheckedIds,
  renderNotificationIcon,
}) => {
  const [localDataLoading, setLocalDataLoading] = useState(false)

  const totalPages = unopenedItems[0]?.pages || 1
  const hasMorePages = totalPages > pageRef.current

  const setCurrentPage = (page) => (pageRef.current = page)

  const { fetching: storeNewLoading } = useSelector((state) => state.notifications)

  const localLoadingCallback = () => setLocalDataLoading(false)

  const loadMoreData = () => {
    if (!storeNewLoading) {
      setLocalDataLoading(true)
      const fetchPage = pageRef.current + 1
      if (fetchPage <= totalPages) {
        fetchNotifications(fetchPage, localLoadingCallback)
        setCurrentPage(fetchPage)
      }else {
        setLocalDataLoading(false)
      }
    }
  }

  return (
    <InfiniteScroll
      hasMore={hasMorePages}
      threshold={200}
      loadMore={loadMoreData}
      useWindow={false}
      initialLoad={false}
    >
      {unopenedItems.map((notification, index) => (
        <NotificationElement
          key={index}
          isArchive={isArchive}
          notificationChecked={notificationChecked}
          handleNavigateToTarget={handleNavigateToTarget}
          handleUpdateCheckedIds={handleUpdateCheckedIds}
          notification={notification}
          renderIcon={renderNotificationIcon}
        />
      ))}
      {localDataLoading && (
        <div className='notifications__scroll-loader'>
          <Loader size={30} inBlock />
        </div>
      )}
    </InfiniteScroll>
  )
}

UnopenedList.propTypes = {
  pageRef: PropTypes.any,
  isArchive: PropTypes.bool,
  unopenedItems: PropTypes.array,
  fetchNotifications: PropTypes.func,
  notificationChecked: PropTypes.func,
  renderNotificationIcon: PropTypes.func,
  handleNavigateToTarget: PropTypes.func,
  handleUpdateCheckedIds: PropTypes.func,
}

export default UnopenedList
