// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/landing/IntroBusiness/cat-left.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../assets/images/landing/IntroBusiness/cat-right.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../../assets/images/landing/IntroBusiness/cat-sm.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../../../assets/images/landing/IntroBusiness/tea.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, "@media screen and (max-width: 1560px){#intro-business{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-size:27%,20%;background-position:bottom 55px left,top 200px right -50px;padding-top:100px}}@media only screen and (max-width: 1280px){#intro-business{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");background-size:30%,40%;background-position:bottom 170px left,top 300px right;padding-top:80px;min-height:calc(100vh - 83px)}#intro-business .content .title{font-size:34px;line-height:60px}#intro-business .content .description{color:#71667f;font-size:16px;line-height:20px;margin-bottom:40px;width:645px}#intro-business .content .join-us-container .two-buttons-business .btn-landing{font-size:14px;line-height:18px;padding:14px 20px}}@media only screen and (max-width: 768px){#intro-business{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");background-size:45%,60%;background-position:bottom 100px left,top right -20px;padding-top:200px}}@media only screen and (max-width: 480px){#intro-business{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");background-position:top right,center right 25px;background-size:95%,15%;padding-top:0;min-height:calc(100vh - 250px)}#intro-business .content{height:calc(100vh - 250px);justify-content:flex-end;width:100%;padding:0 20px;align-items:flex-start}#intro-business .content .title{font-size:24px;line-height:36px;margin-bottom:18px;width:280px;text-align:left}#intro-business .content .description{margin-bottom:32px;text-align:left;width:335px}#intro-business .content .join-us-container{margin-bottom:32px}#intro-business .content .join-us-container .two-buttons-business .btn-landing{font-size:14px;line-height:18px;padding:12px 16px;min-width:158px}}", ""]);
// Exports
module.exports = exports;
