// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/Trouble-Font/Trouble-Font.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/fonts/Roboto/Robotothin.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&family=Unbounded:wght@300;500&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "@font-face{font-family:Trouble Font;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}@font-face{font-family:Roboto;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");font-weight:100;font-style:normal}.landing-main{background-color:#f9f9f9;font-family:\"Montserrat\",sans-serif;font-style:normal;font-weight:normal;font-size:16px;line-height:20px;box-sizing:border-box;scroll-behavior:smooth;height:auto;display:flex;justify-content:center;align-items:center;flex-direction:column}.landing-main *:not(strong){display:flex}.section{width:100%;flex-direction:column}.section-title{margin-top:60px}.two-buttons a{opacity:.7}@media screen and (min-width: 1280px){.underline-landing{height:1px;width:80%;background-color:#d8d5dd;margin-top:80px;margin-bottom:20px}}", ""]);
// Exports
module.exports = exports;
