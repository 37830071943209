import React, { useState } from 'react'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Validator } from '../../../../lib'
import { Button, colors, PasswordInput, Text } from '../../../UIkit'
import { Password } from '../../../UIkit/svgs'

const CreatePasswordForm = ({ handleResetPassword, handleCancel, toFinish }) => {
  const { t } = useTranslation()

  const [form, setForm] = useState({
    password: '',
    passwordConfirmation: '',
  })
  const [formErrors, setFormErrors] = useState({
    password: '',
    passwordConfirmation: '',
  })

  const handleChangeField = ({ target }) => {
    setForm((prev) => ({ ...prev, [target.name]: target.value }))
  }

  const handleValidate = () =>
    new Promise((resolve, reject) => {
      const errors = Validator.validate([
        ['password', Validator.concepts.isLongerThan, [6, form.password]],
        ['passwordConfirmation', Validator.concepts.isLongerThan, [6, form.password]],
        ['passwordConfirmation', Validator.concepts.isMatching, [form.password, form.passwordConfirmation]],
      ])

      Validator.clear()

      isEmpty(errors) ? resolve() : reject(errors)
    })

  const handleSubmit = (e) => {
    e.preventDefault()

    handleValidate()
      .then(() => {
        handleResetPassword(form.password, form.passwordConfirmation)
        toFinish()
      })
      .catch((errors) => setFormErrors(errors))
  }

  return (
    <form className='auth-page__form' onSubmit={handleSubmit}>
      <div className='auth-page__form__headline'>
        <div className='auth-page__form__headline-icon'>
          <Password />
        </div>
        <Text variant='subheading'>{t('createNewPassword:title')}</Text>
      </div>
      <Text variant={'body-secondary1'} color={colors.gray[500]}>
        {t('createNewPassword:description')}
      </Text>
      <div className='auth-page__form__inputs'>
        <PasswordInput
          id='password'
          name='password'
          placeholder={t('input:password')}
          value={form.password}
          onChange={handleChangeField}
          error={formErrors.password}
          autoFocus={true}
        />
        <PasswordInput
          id='password-confirmation'
          name='passwordConfirmation'
          placeholder={t('input:confirmPassword')}
          value={form.passwordConfirmation}
          onChange={handleChangeField}
          error={formErrors.passwordConfirmation}
        />
      </div>
      <div className='auth-page__form__buttons'>
        <Button type='submit' disabled={Object.values(form).includes('')}>
          {t('btn:save')}
        </Button>
        <Button variant={'secondary'} onClick={handleCancel}>
          {t('btn:cancel')}
        </Button>
      </div>
    </form>
  )
}

export default CreatePasswordForm
