import React from 'react'
import { useTranslation } from 'react-i18next'

import { Modal, Button, Text, colors } from '../../UIkit'

const EmployeeDeactivate = ({ isShown, closeModal, employeeId, updateEmployee }) => {
  const { t } = useTranslation()

  const handleDeactivate = () => {
    const data = {
      id: employeeId,
      params: {
        networkEmployee: {
          active: false,
        },
      },
    }

    updateEmployee(data)
    closeModal()
  }

  return (
    <Modal show={isShown} handleClose={closeModal} withoutExit>
      <div className='deactivate-employee'>
        <div className='deactivate-employee__title'>
          <Text variant='subheading'>{t('employees:deactivateTitle')}</Text>
        </div>
        <div className='deactivate-employee__description'>
          <Text variant='body-secondary1' color={colors.gray[500]}>
            {t('employees:deactivateText')}
          </Text>
        </div>
        <div className='deactivate-employee__buttons'>
          <Button variant='secondary' onClick={closeModal}>
            {t('btn:cancel')}
          </Button>
          <Button onClick={handleDeactivate}>{t('actions:deactivate')}</Button>
        </div>
      </div>
    </Modal>
  )
}

export default EmployeeDeactivate
