import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useFilters } from '../../contexts/filtersContext'
import { calendarDateRangesList } from '../../lib'
import { employeesGetBaristasRequest } from '../../store/employees/actions'
import OrdersFilters from '../../components/Orders/OrdersFilters'

const OrdersFiltersContainer = ({ filters, handleChangeFilters, handleResetFilters, handleCloseFilters }) => {
  const { t, i18n } = useTranslation()

  const [dateFilter, setDateFilter] = useState([filters.dateFrom, filters.dateTo])
  const [baristaFilter, setBaristaFilter] = useState(filters.baristaId)
  const [statusFilter, setStatusFilter] = useState(filters.status)

  const dispatch = useDispatch()

  const { cityFilter, vendorFilter } = useFilters()

  const { baristas, fetching } = useSelector((state) => state.employees)

  const getBaristasForFilter = (params) => {
    dispatch(employeesGetBaristasRequest({ params: params }))
  }

  const baristasFilterList = useMemo(() => [{ value: null, label: t('orders:allBaristas') }, ...baristas], [
    baristas,
    i18n.language,
  ])

  const calendarDateRanges = useMemo(() => calendarDateRangesList(dateFilter[0], dateFilter[1]), [dateFilter])

  const handleApplyFilters = () => {
    const data = {
      dateFrom: dateFilter[0],
      dateTo: dateFilter[1],
      baristaId: baristaFilter,
      status: statusFilter,
    }

    handleChangeFilters(data)
    handleCloseFilters()
  }

  const handleChangeStatus = ({ target }) => setStatusFilter(target.value)

  const handleChangeBarista = (value) => setBaristaFilter(value)

  useEffect(() => {
    setDateFilter([filters.dateFrom, filters.dateTo])
    setBaristaFilter(filters.baristaId)
    setStatusFilter(filters.status)
  }, [filters])

  useEffect(() => {
    getBaristasForFilter({ city: cityFilter, vendorId: vendorFilter, only_baristas: true })
  }, [cityFilter, vendorFilter])

  return (
    <OrdersFilters
      dateFilter={dateFilter}
      baristaFilter={baristaFilter}
      baristasFilterList={baristasFilterList}
      baristasFetching={fetching}
      statusFilter={statusFilter}
      dateRangesList={calendarDateRanges}
      handleChangeDate={setDateFilter}
      handleChangeBarista={handleChangeBarista}
      handleChangeStatus={handleChangeStatus}
      handleResetFilters={handleResetFilters}
      handleApplyFilters={handleApplyFilters}
    />
  )
}

export default OrdersFiltersContainer
