import React from 'react'
import { AppStoreButton, GooglePlayButton } from '../../../../assets/svgs/landing'
import { isMobileOnly } from 'react-device-detect'
import Links from '../../constants'
import './styles.scss'
import './responsiveStyles.scss'

function Intro () {
  return (
    <section id='intro' name='intro'>
      <div className='content'>
        <h1 className='title'>
            Любиш каву?<br />
          <strong>замовляй онлайн, отримуй без черг</strong>
        </h1>
        <p className='description'>
            Завантажуй Pinka, знаходь топові кав’ярні, роби замовлення в додатку, та забирай своє замовлення без черг та очікування
        </p>

        { isMobileOnly && <div className='cats'/>}

        <div className='join-us-container'>
          <div className='two-buttons'>
            <a href={Links.linkToGooglePlay}>
              <img src={GooglePlayButton} alt='Google play' />
            </a>
            <a href={Links.linkToAppStore}>
              <img src={AppStoreButton} alt='App store' />
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Intro
