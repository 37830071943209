import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Text, colors } from '../UIkit'
import { Trash } from '../UIkit/svgs'
import { textShortener } from '../../lib'

const Actions = ({ handleClick, filename = '', handleDelete, signedId = '', isMultiple }) => {
  const { t } = useTranslation()

  const action = isMultiple ? 'change' : 'new'

  return (
    <div className='actions'>
      <div className='actions__delete'>
        <Trash onClick={handleDelete(signedId)} color={colors.base.white} />
      </div>

      <div className='actions__info'>
        <Text color={colors.base.white} variant='body-main1'>
          {textShortener(filename)}
        </Text>

        <Button color={colors.primary[500]} onClick={handleClick(action, signedId)} variant='tertiary' size='large'>
          {t('btn:change')}
        </Button>
      </div>
    </div>
  )
}

export default Actions
