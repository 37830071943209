import {
  ORDERS_GET_REQUEST,
  ORDERS_GET_SUCCESS,
  ORDERS_GET_FAILURE,
  ORDER_GET_REQUEST,
  ORDER_GET_SUCCESS,
  ORDER_GET_FAILURE,
  ORDERS_CLEAR,
} from './actions'

const initialState = {
  items: [],
  item: {},
  fetching: false,
  itemFetching: false,
  errors: {},
  limit: 9,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ORDERS_GET_REQUEST: {
      return { ...state, fetching: true }
    }
    case ORDERS_GET_SUCCESS: {
      return {
        ...state,
        items: payload,
        fetching: false,
        pages: payload.length ? payload[0].pages : 0,
      }
    }
    case ORDERS_GET_FAILURE:
      return { ...state, fetching: false, error: payload }
    case ORDER_GET_REQUEST:
      return { ...state, itemFetching: true }
    case ORDER_GET_SUCCESS:
      return { ...state, item: payload, itemFetching: false }
    case ORDER_GET_FAILURE:
      return { ...state, errors: payload, itemFetching: false }
    case ORDERS_CLEAR:
      return initialState
    default:
      return state
  }
}
