import React, { useEffect, useRef, useState } from 'react'
import propTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Text, Button, Input, PasswordInput, colors, Loader } from '../../UIkit'
import { UserIcon } from '../../UIkit/svgs'
import { Validator, formatPhoneNumber } from '../../../lib'
import { sessionSmsCodeClear } from '../../../store/authentication/actions'
import Captcha from '../../Captcha'

const SignInForm = ({ signInUser, signInUserPhone, sendSmsCodeRequest }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { smsSent, fetching } = useSelector(({ authentication }) => authentication)

  const [form, setForm] = useState({
    email: '',
    password: '',
    phoneNumber: '+380 (',
    smsCode: '',
  })
  const [errors, setErrors] = useState({})
  const [method, setMethod] = useState('email')
  const [timer, setTimer] = useState(30)
  const [smsResent, setSmsResent] = useState(false)
  const [captcha, setCaptcha] = useState('')

  const timerInterval = useRef()

  const handleUpdateField = ({ target: { name, value } }) => {
    setForm((prev) => ({ ...prev, [name]: name === 'phoneNumber' ? formatPhoneNumber(value) : value }))
  }

  useEffect(() => {
    if (method === 'email') dispatch(sessionSmsCodeClear())
  }, [method])

  useEffect(() => {
    if (smsSent || smsResent) {
      timerInterval.current = setInterval(() => {
        setTimer((ps) => ps - 1)
      }, 1000)

      return () => {
        clearInterval(timerInterval.current)
      }
    }
  }, [smsSent, smsResent])

  useEffect(() => {
    if (timer <= 0) {
      clearInterval(timerInterval.current)
      setSmsResent(false)
    }
  }, [timer])

  const requestSendCode = () => {
    if (smsSent) setSmsResent(true)

    dispatch(sendSmsCodeRequest({ phoneNumber: form.phoneNumber, captcha, reason: 'authentication' }))
    setTimer(30)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    handleValidate().then(() => {
      if (method === 'phone') {
        if (smsSent) {
          const data = {
            phoneNumber: form.phoneNumber,
            smsCode: form.smsCode,
          }
          
          signInUserPhone(data)
        } else requestSendCode()

        setCaptcha('')
      } else {
        const data = {
          email: form.email,
          password: form.password,
        }

        signInUser(data)
      }
    })
  }

  const handleValidate = () =>
    new Promise((resolve, reject) => {
      let validationFields = []
      Validator.clear()

      if (method === 'email') validationFields = [
        ['email', Validator.concepts.isFilled, [form.email]],
        ['email', Validator.concepts.emailRegex, [form.email]],
        ['password', Validator.concepts.isFilled, [form.password]],
      ]
      if (method === 'phone') {
        validationFields = [['phoneNumber', Validator.concepts.phoneRegex, [form.phoneNumber]]]
        if (!smsSent) validationFields.push(['captcha', Validator.concepts.isFilled, [captcha]])
      }

      const errors = Validator.validate(validationFields)

      setErrors(errors)
      isEmpty(errors) ? resolve() : reject(errors)
    })

  const isDisabled = () => {
    const { smsCode, phoneNumber, email, password  } = form

    if (smsSent) {
      return smsCode.length < 4
    } else if (method === 'email') {
      return Object.values({ email, password }).includes('')
    } 
    return phoneNumber.length < 19 || !captcha
  }

  const processCaptcha = (newCaptcha) => {
    setCaptcha(newCaptcha)
  }

  if (fetching)
    return (
      <div className='auth-page__form'>
        <div className='auth-page__form__headline'>
          <UserIcon />
          <Text variant='subheading'>{t('signup:userForm:title')}</Text>
        </div>
        <Loader size={48} inBlock />
      </div>
    )

  return (
    <form className='auth-page__form' onSubmit={handleSubmit}>
      <div className='auth-page__form__headline'>
        <UserIcon />
        <Text variant='subheading'>{t('signin:form:title')}</Text>
      </div>

      {smsSent ? (
        <>
          <Input
            id='smsCode'
            name='smsCode'
            placeholder={t('input:smsCode')}
            value={form.smsCode}
            onChange={handleUpdateField}
          />
          <div className='w-100 mb-3 text-center'>
            <div className='mb-2'>
              {timer > 0 && <Text variant='body-main1'>{t('signup:userForm:resendCodeTimer') + ` ${timer}`}</Text>}
            </div>
            <Button type='button' onClick={requestSendCode} disabled={timer > 0}>
              {t('signup:userForm:resendCode')}
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className='w-100 mb-2 text-center'>
            <Text variant='body-main1'>{t('signin:form:chooseMethod')}</Text>
          </div>
          <div className='d-flex justify-content-between align-items-center w-100 mb-4'>
            <Button type='button' onClick={() => setMethod('email')}>
              {t('signup:userForm:email')}
            </Button>
            <div className='mx-3'>
              <Text variant='body-main1'>{t('signup:userForm:or')}</Text>
            </div>
            <Button type='button' onClick={() => setMethod('phone')}>
              {t('signup:userForm:phone')}
            </Button>
          </div>

          {method === 'email' ? (
            <>
              <Input
                id='email'
                name='email'
                placeholder={t('input:email')}
                value={form.email}
                onChange={handleUpdateField}
                error={errors.email}
                autoFocus={true}
              />
              <PasswordInput
                id='password'
                name='password'
                placeholder={t('input:password')}
                value={form.password}
                error={errors.password}
                onChange={handleUpdateField}
              />

              <div className='auth-page__form__forgot_password'>
                <Text wrapper variant={'body-tertiary2'} color={colors.secondary[500]}>
                  <Link className='link' to='/forgot_password'>
                    {t('forgotPassword:title')}
                  </Link>
                </Text>
              </div>
            </>
          ) : (
            <Input
              id='phoneNumber'
              name='phoneNumber'
              type='tel'
              placeholder={t('input:phoneNumber')}
              value={form.phoneNumber}
              onChange={handleUpdateField}
              error={errors.phoneNumber}
            />
          )}
        </>
      )}
      {method === 'phone' && !smsSent && <Captcha processCaptcha={processCaptcha} />}
      <Button type='submit' disabled={isDisabled()}>
        {method === 'email' ? t('btn:signin') : t('btn:next')}
      </Button>
      <div className='auth-page__form__description'>
        <Text variant={'body-secondary1'} color={colors.gray[500]}>
          {t('signin:form:noAccount')}&nbsp;
        </Text>
        <Text wrapper variant={'body-secondary1'}>
          <Link to='/signup'>{t('btn:signup')}</Link>
        </Text>
      </div>
    </form>
  )
}

SignInForm.propTypes = {
  signInUser: propTypes.func.isRequired,
  signInUserPhone: propTypes.func.isRequired,
  sendSmsCodeRequest: propTypes.func.isRequired,
}

export default SignInForm
