import React from 'react'
import PropTypes from 'prop-types'

import { Text, colors } from '../UIkit'
import { textShortener } from '../../lib'

const ProgressBar = ({ progress, filename = '' }) => {
  return (
    <>
      <div
        className='circle-progress'
        style={{
          background: `conic-gradient(${colors.primary[500]} ${progress * 3.6}deg, ${colors.primary[100]} 0deg)`,
        }}
      />
      <Text variant='body-main1'>{textShortener(filename)}</Text>
    </>
  )
}

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  filename: PropTypes.string,
}

export default ProgressBar
