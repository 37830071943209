import { takeLatest, call, put } from 'redux-saga/effects'
import * as actions from './actions'

import Api from '../../lib/api'
import notifications from '../../lib/notifications'

export default function* watcherSaga() {
  yield takeLatest(actions.PROFILE_GET_REQUEST, watchResourceGetRequest),
  yield takeLatest(actions.PROFILE_UPDATE_REQUEST, watchResourceUpdateRequest)
}

function* watchResourceGetRequest() {
  try {
    const response = yield call(Api.Profiles.get)

    yield put(actions.profileGetSuccess(response.data))

  } catch (error) {
    yield put(actions.profileGetFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}

function* watchResourceUpdateRequest({ payload }) {
  try {
    const response = yield call(Api.Profiles.update, payload)

    yield put(actions.profileUpdateSuccess(response.data))
    notifications.createNotification('edit-success')

  } catch (error) {
    yield put(actions.profileUpdateFailure(error))
    notifications.createNotification('error', error.response.data.errors)
  }
}
