import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

import { Button, Switch, Text, TimeSelector, LocationSearchInput, colors } from '../UIkit'
import { Uploader } from '../Uploader'
import { Validator } from '../../lib'
import { WEEK_DAYS } from '../../constants'
import ExpandSection from '../UIkit/ExpandSection'
import { Clock, PictureIcon } from '../UIkit/svgs'

const VendorForm = ({ createVendor, updateVendor, closeModal, vendor, isNewRecord = false }) => {
  const { t } = useTranslation()

  const workTimes = WEEK_DAYS.map((day) => ({ weekday: day, from: '10:00', to: '20:00', opened: true }))
  const [form, setForm] = useState({
    city: '',
    address: '',
    workTimes,
    images: [],
  })
  const [errors, setErrors] = useState({})

  useEffect(() => {
    !isNewRecord &&
      setForm({
        id: vendor.id,
        city: vendor.city,
        address: vendor.address,
        latitude: vendor.latitude,
        longitude: vendor.longitude,
        workTimes: vendor.workTimes,
        fullAddress: vendor.fullAddress,
        images: vendor.imageUrls ? vendor.imageUrls?.map((image) => image.signedId) : [],
      })
  }, [vendor])

  const handleUpdateAddress = (data) => {
    setForm((prev) => ({
      ...prev,
      city: data.city,
      address: data.address,
      latitude: data.latitude,
      longitude: data.longitude,
    }))
  }

  const handleValidate = () =>
    new Promise((resolve) => {
      const errors = Validator.validate([
        ['address', Validator.concepts.correctAddress, [form.address]],
        ...form.workTimes.map((workTime) => {
          return [`workTime${workTime.weekday}`, Validator.concepts.correctTimeRange, [workTime]]
        }),
      ])

      Validator.clear()
      !Object.values(errors).length ? resolve() : setErrors(errors)
    })

  const handleUpdateSwitch = ({ target }) => {
    const weekday = workTimes[target.id].weekday

    const obj = form.workTimes.find((workTime) => workTime.weekday == weekday)
    const newObj = { ...obj, opened: !obj.opened }
    const data = form.workTimes.map((i) => (i.weekday == obj.weekday ? newObj : i))

    setForm((prev) => ({ ...prev, workTimes: data }))
  }

  const handleUpdateTime = (id, name, value) => {
    const obj = form.workTimes.find((workTime) => workTime.weekday == id)
    const newObj = { ...obj, [name]: value }
    const data = form.workTimes.map((i) => (i.weekday == obj.weekday ? newObj : i))
    setForm((prev) => ({ ...prev, workTimes: data }))
  }

  const handleUpload = (signedId) => {
    setForm((prev) => ({ ...prev, images: [...prev.images, signedId] }))
  }

  const handleDeleteImage = (signedId) => {
    setForm((prev) => ({ ...prev, images: prev.images.filter((id) => id !== signedId) }))
  }

  const handleRewriteImage = (oldSignedId, newSignedId) => {
    setForm((prev) => ({
      ...prev,
      images: prev.images.map((signedId) => (signedId == oldSignedId ? newSignedId : signedId)),
    }))
  }

  const handleSubmit = () => {
    const data = {
      networkId: Number(localStorage.getItem('networkId')),
      vendor: {
        city: form.city,
        address: form.address,
        latitude: form.latitude,
        longitude: form.longitude,
        workTimesAttributes: form.workTimes,
        images: form.images,
      },
    }

    handleValidate().then(() => {
      isNewRecord ? createVendor(data) : updateVendor({ id: vendor.id, ...data })
      closeModal()
    })
  }

  const renderWorkDay = (day, index) => {
    const workTime = form.workTimes.find((workTime) => workTime.weekday == day)
    const switchLabel = workTime.opened ? t('network:open') : t('network:closed')
    const error = errors[`workTime${workTime.weekday}`]

    return (
      <>
        <div className='vendor-form__day' key={index}>
          <Text className='vendor-form__day__name' variant='body-main1'>
            {!isMobile ? t(`weekDayShort:${day}`) : t(`weekDayLong:${day}`)}
          </Text>
          <Switch
            index={index}
            name={day}
            label={switchLabel}
            checked={workTime.opened}
            onChange={handleUpdateSwitch}
          />

          <div className='vendor-form__hours'>
            <TimeSelector
              id={workTime.weekday}
              disabled={!workTime.opened}
              name='from'
              value={workTime.from}
              onChange={handleUpdateTime}
              error={error}
              before={workTime.to}
            />
            <Text className='dash'> - </Text>
            <TimeSelector
              id={workTime.weekday}
              disabled={!workTime.opened}
              name='to'
              value={workTime.to}
              onChange={handleUpdateTime}
              error={error}
              startAfter={workTime.from}
            />
          </div>
        </div>
        {!!error && (
          <Text variant='body-tertiary1' color={colors.alerts.error}>
            {error}
          </Text>
        )}
      </>
    )
  }

  return (
    <div className='vendor-scrollable'>
      <div className='vendor-form'>
        <div className='vendor-form__info'>
          <LocationSearchInput onChange={handleUpdateAddress} value={vendor?.fullAddress} error={errors.address} />

          {!isMobile ? (
            <>
              <Text variant='body-main2'>{t('network:openingHours')}:</Text>

              {WEEK_DAYS.map((day, index) => renderWorkDay(day, index))}
            </>
          ) : (
            <ExpandSection
              icon={<Clock color={colors.primary[500]} />}
              title={<Text variant='body-main2'>{t('network:openingHours')}</Text>}
            >
              {WEEK_DAYS.map((day, index) => renderWorkDay(day, index))}
            </ExpandSection>
          )}
        </div>
        {!isMobile ? (
          <Uploader
            isMultiple
            name='images'
            onChange={handleUpload}
            onDelete={handleDeleteImage}
            onRewrite={handleRewriteImage}
            images={vendor?.imageUrls}
          />
        ) : (
          <ExpandSection
            icon={<PictureIcon color={colors.primary[500]} />}
            title={<Text variant='body-main2'>{t('network:photos')}</Text>}
          >
            <Uploader
              isMultiple
              mobile
              name='images'
              onChange={handleUpload}
              onDelete={handleDeleteImage}
              onRewrite={handleRewriteImage}
              images={vendor?.imageUrls}
            />
          </ExpandSection>
        )}
      </div>

      <div className='vendor-form__button'>
        <Button type='submit' onClick={handleSubmit}>
          {t('btn:save')}
        </Button>
      </div>
    </div>
  )
}

VendorForm.propTypes = {
  createVendor: PropTypes.func,
  updateVendor: PropTypes.func,
  closeModal: PropTypes.func.isRequired,
  vendor: PropTypes.object,
  isNewRecord: PropTypes.bool,
}

export default VendorForm
