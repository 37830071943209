import { isEmpty } from 'lodash'
import i18n from '../services/i18n'
import valid from 'card-validator'

class Validator {
  constructor() {
    this.errors = {}
  }

  clear() {
    this.errors = {}
  }

  validate = (validationList = []) => {
    validationList.map((validationItem) => {
      const field = validationItem[0]
      const concept = validationItem[1]
      const args = validationItem[2]
      const shouldSkip = validationItem[3]

      if (shouldSkip) return

      const error = concept(...args)
      if (error) this.errors[field] = error
    })

    return this.errors
  }

  concepts = {
    emailRegex: (email = '') => {
      if (isEmpty(String(email))) {
        return i18n.t('validators:notEmpty')
      }
      if (!/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(email)) {
        return i18n.t('validators:invalidField')
      }
    },

    phoneRegex: (phone = '') => {
      if (isEmpty(String(phone))) {
        return i18n.t('validators:notEmpty')
      }
      if (!/^[\+][0-9]{3}[\s]?[(][0-9]{2}[)][-\s]?[0-9]{3}[-\s]?[0-9]{2}[-\s]?[0-9]{2}$/.test(phone)) {
        return i18n.t('validators:invalidField')
      }
    },

    phoneWithoutPlus: (phone = '') => {
      if (isEmpty(String(phone))) {
        return i18n.t('validators:notEmpty')
      }
      if (!/^[0-9]{3}[0-9]{9}$/.test(phone) || phone.length > 12) {
        return i18n.t('validators:phone:withoutPlus')
      }
    },

    isLatin: (field = '') => {
      if (!/^[a-zA-Z]/.test(field)) {
        return i18n.t('validators:invalidField')
      }
    },

    cardNumber: (number = '') => {
      if (!valid.number(number).isValid) {
        return i18n.t('validators:invalidField')
      }
    },

    number: (number = '') => {
      if (!/^(\d+(?:[.,]\d{2})?)$/.test(number)) {
        return i18n.t('validators:numberFormat')
      }
    },

    cardExpires: (expires = '') => {
      if (!valid.expirationDate(expires).isValid) {
        return i18n.t('validators:expiredData')
      }
    },

    cardCvv: (cvv = '') => {
      if (cvv.length !== 3) {
        return i18n.t('validators:invalidField')
      }
    },

    isMatching: (password = '', passwordConfirmation = '') => {
      if (password != passwordConfirmation) {
        return i18n.t('validators:isMatching')
      }
    },

    isFilled: (field = '') => {
      if (isEmpty(String(field))) {
        return i18n.t('validators:notEmpty')
      }
    },

    correctAddress: (field = '') => {
      if (isEmpty(String(field)) || !/\b\d+\b/.test(field)) {
        return i18n.t('validators:correctAddress')
      }
    },

    isUrl: (field = '') => {
      if (!field) return
      if (!/^https?:\/\/[\w-]+(\.[\w-]+)+\S*$/gm.test(field)) {
        return i18n.t('validators:notValidUrl')
      }
    },

    isFilledShort: (field = '') => {
      if (isEmpty(String(field))) {
        return i18n.t('validators:isFilledShort')
      }
    },

    isLongerThan: (n, field) => {
      if (String(field).length < n) {
        return `${i18n.t('validators:minimum')} ${n} ${i18n.t('validators:characters')}`
      }
    },

    edrpouRegex: (field = '') => {
      if (!/^[0-9]{8,10}$/.test(field)) {
        return i18n.t('validators:edrpouRegex')
      }
    },

    mfoRegex: (field = '') => {
      if (!/^[0-9]{6}$/.test(field)) {
        return i18n.t('validators:mfoRegex')
      }
    },

    correctTimeRange: (field = {}) => {
      if (!field.opened) return

      if (!/^((([0-1][0-9])|(2[0-4])):[0-5][0-9]){2}$/.test(`${field.from}${field.to}`))
        return i18n.t('validators:timeFormat')

      if (Number.parseInt(field.from.replace(':', '')) >= Number.parseInt(field.to.replace(':', '')))
        return i18n.t('validators:timeRange')
    },

    liqpayActivationCodeRegex: (field = '') => {
      if (!/^[0-9]{8}$/.test(field)) {
        return i18n.t('validators:liqpayActivationCodeRegex')
      }
    }
  }
}

export default new Validator()
