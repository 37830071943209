import React from 'react'
import './styles.scss'

export const Table = ({ children, className = '' }) => {
  return <table className={`custom-table ${className}`}>{children}</table>
}

export const TableHead = ({ children, className = '' }) => {
  return <thead className={`custom-table__head ${className}`}>{children}</thead>
}

export const TableBody = ({ children, className = '' }) => {
  return <tbody className={`custom-table__body ${className}`}>{children}</tbody>
}

export const TableHeader = ({ children, className = '' }) => {
  return <th className={`custom-table__header ${className}`}>{children}</th>
}

export const TableRow = ({ children, className = '', onClick }) => {
  return <tr className={`custom-table__row ${className}`} onClick={onClick}>{children}</tr>
}

export const TableCell = ({ children, className = '', onClick }) => {
  return <td className={`custom-table__cell ${className}`} onClick={onClick}>{children}</td>
}

export const TableActions = ({ children, elemRef }) => {
  const viewHeight = window.innerHeight
  const viewSpaceBelow = viewHeight - elemRef.getBoundingClientRect()?.top

  const styles = {
    bottom: viewSpaceBelow <= 180 ? '48px' : 'auto',
  }
  return (
    <div style={styles} className='custom-table__actions with-input-shadow'>
      {children}
    </div>
  )
}
