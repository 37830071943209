import React from 'react'
import './styles.scss'

const AddButton = ({ children, disabled, ...props }) => {
  return (
    <button className={`add-btn ${disabled ? 'btn-disabled' : ''}`} {...props}>
      {children}
    </button>
  )
}

export default React.memo(AddButton)
