import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useFilters } from '../../contexts/filtersContext'
import { Dropdown } from '../UIkit'
import { DISABLE_FILTERS_PATHES } from '../../constants'
import { useLocation } from 'react-router-dom'

const Filter = () => {
  const [isDisabled, setIsDisabled] = useState(false)
  const location = useLocation()
  const { t, i18n } = useTranslation()

  const { cityFilter, vendorFilter, setCityFilter, setVendorFilter } = useFilters()

  const { cities, addresses, cityFetching } = useSelector((state) => state.vendors)

  const citiesFilterList = useMemo(
    () => [{ value: '', label: t('header:filter:city') }, ...cities.map((city) => ({ value: city, label: city }))],
    [cities, i18n.language]
  )

  const vendorsFilterList = useMemo(() => [{ value: '', label: t('header:filter:vendor') }, ...addresses], [
    addresses,
    i18n.language,
  ])

  const handleChangeCity = (param) => setCityFilter(param)
  const handleChangeVendor = (param) => setVendorFilter(param)

  useEffect(() => {
    if (DISABLE_FILTERS_PATHES.includes(location.pathname)) {
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
  }, [location.pathname])

  return (
    <div className='header__filter'>
      <Dropdown
        isDisabled={isDisabled}
        placeholder={t('header:city')}
        value={cityFilter}
        list={citiesFilterList}
        onChange={handleChangeCity}
        loading={cityFetching}
      />
      <Dropdown
        isDisabled={isDisabled}
        placeholder={t('header:vendor')}
        value={vendorFilter}
        list={vendorsFilterList}
        onChange={handleChangeVendor}
        loading={cityFetching}
      />
    </div>
  )
}

export default React.memo(Filter)
